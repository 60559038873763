import React, { useEffect, useState } from 'react';
import { CheckCircle } from 'lucide-react';
import Header from "../../components/header";
import {
    createPaymentOrder,
    cancelSubscription,
    startTrial,
    initializeRazorpayPayment,
    Subscription,
    getCurrentSubscription,
    formatValidityDate,
    calculateDaysRemaining
  } from '../../services/subscription';
import styled from 'styled-components';

const S = {
  PageWrapper: styled.div`
    height: calc(100vh - 64px);
    margin-left: 220px;
    background: white;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  `,

  HeaderSection: styled.div`
    background: #2563EB;
    padding: 8px 24px;  /* Reduced vertical padding */
    color: white;
    text-align: center;
    flex-shrink: 0;
    min-height: 80px;   /* Reduced min-height */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;          /* Reduced gap for tighter spacing */

    .header-title {
      font-size: 22px;
      font-weight: 600;
      margin-bottom: 4px;  /* Add small margin to title */
    }

    .current-plan {
      font-size: 14px;
    }

    .plan-info {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 13px;
      color: #93C5FD;
    }
  `,

  PlanToggle: styled.div`
  display: inline-flex;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 24px;
  padding: 2px;
  margin-bottom: 8px;  /* Further reduced margin */

  button {
    padding: 4px 24px;  /* Reduced vertical padding */
    border: none;
    background: transparent;
    color: white;
    border-radius: 20px;
    font-size: 13px;
    cursor: pointer;
    transition: all 0.2s;

    &.active {
      background: white;
      color: #2563EB;
    }

    .save-tag {
      background: #22C55E;
      padding: 1px 8px;
      border-radius: 10px;
      font-size: 11px;
      margin-left: 6px;
      color: white;
    }
  }
`,

  Container: styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 16px;
    flex: 1;
    overflow-y: auto;
  `,

  PlansWrapper: styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    margin-bottom: 80px;
    
    @media (max-width: 1024px) {
      grid-template-columns: 1fr;
    }
  `,

  PlanCard: styled.div<{ isSelected?: boolean }>`
    background: ${props => props.isSelected ? '#2563EB' : 'white'};
    color: ${props => props.isSelected ? 'white' : '#1F2937'};
    border-radius: 8px;
    padding: 20px;
    cursor: pointer;
    transition: all 0.2s;
    height: fit-content;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border: 1px solid ${props => props.isSelected ? '#2563EB' : '#E5E7EB'};
    
    .plan-name {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 4px;
    }

    .plan-desc {
      color: ${props => props.isSelected ? 'rgba(255, 255, 255, 0.8)' : '#6B7280'};
      font-size: 13px;
      margin-bottom: 16px;
    }

    .price {
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 12px;

      .period {
        font-size: 13px;
        font-weight: normal;
        opacity: 0.8;
      }
    }

    .platform {
      font-size: 13px;
      color: ${props => props.isSelected ? 'rgba(255, 255, 255, 0.8)' : '#6B7280'};
      margin-bottom: 16px;
    }

    .features {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 12px;
    }

    .feature {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 13px;

      svg {
        color: ${props => props.isSelected ? '#93C5FD' : '#2563EB'};
        width: 16px;
        height: 16px;
      }
    }
  `,

  BottomSection: styled.div`
    position: fixed;
    bottom: 0;
    left: 220px;
    right: 0;
    background: white;
    border-top: 1px solid #E5E7EB;
    box-shadow: 0 -1px 2px rgba(0, 0, 0, 0.05);
  `,

  UserCounter: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    background: #F9FAFB;
    font-size: 13px;

    .counter-controls {
      display: flex;
      align-items: center;
      gap: 12px;
    }
  `,

  Button: styled.button<{ variant?: 'cancel' }>`
    padding: 8px 16px;
    border-radius: 6px;
    border: none;
    background: ${props => props.variant === 'cancel' ? '#EF4444' : '#2563EB'};
    color: white;
    font-weight: 500;
    font-size: 13px;
    cursor: pointer;
    transition: all 0.2s;

    &:hover {
      background: ${props => props.variant === 'cancel' ? '#DC2626' : '#1D4ED8'};
    }
  `,

  PaymentSection: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;

    .price {
      font-size: 20px;
      font-weight: 600;

      .period {
        font-size: 13px;
        font-weight: normal;
        color: #6B7280;
        margin-left: 4px;
      }
    }

    .monthly-rate {
      font-size: 13px;
      color: #6B7280;
    }
  `
};

const SubscriptionPage = () => {
    const [planType, setPlanType] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState('premium');
    const [userCount, setUserCount] = useState(1);
    const [currentSubscription, setCurrentSubscription] = useState<Subscription | null>(null);
    const [loading, setLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
      fetchSubscription();
    }, []);
  
    const fetchSubscription = async () => {
      try {
        setLoading(true);
        const response = await getCurrentSubscription();
        setCurrentSubscription(response.data.data);
        
        if (response.data.data?.subscriptionPlan?.planType) {
          setSelectedPlan(response.data.data.subscriptionPlan.planType);
        }
        
        if (response.data.data?.quantity) {
          setUserCount(response.data.data.quantity);
        }
      } catch (error) {
        console.error('Error fetching subscription:', error);
      } finally {
        setLoading(false);
      }
    };

 

    const handleBuySubscription = async () => {
        try {
          setIsLoading(true);
          const plan = subscriptionPlans.find(p => p.id === selectedPlan);
          const planId = planType ? plan?.yearlyId : plan?.id;
          
          if (!planId) {
            throw new Error('Invalid plan selected');
            return;
          }
          
          const response = await createPaymentOrder({
            plan: planId, // Now planId is definitely string
            quantity: userCount
          });
      
          await initializeRazorpayPayment(
            response.data,
            {
              email: localStorage.getItem('user_email') || '',
              name: localStorage.getItem('user_name') || '',
              phone: localStorage.getItem('user_phone') || ''
            },
            {
              onSuccess: (response) => {
                setIsLoading(false);
                fetchSubscription();
              },
              onError: (error) => {
                setIsLoading(false);
                console.error('Payment failed:', error);
              },
              onDismiss: () => {
                setIsLoading(false);
              }
            }
          );
        } catch (error) {
          setIsLoading(false);
          console.error('Error initiating payment:', error);
        }
      };

    const handleCancelSubscription = async () => {
      if (!currentSubscription?._id) return;
      
      try {
        await cancelSubscription(currentSubscription._id);
        fetchSubscription();
      } catch (error) {
        console.error('Error cancelling subscription:', error);
      }
    };

    const handleStartTrial = async () => {
      try {
        await startTrial(selectedPlan);
        fetchSubscription();
      } catch (error) {
        console.error('Error starting trial:', error);
      }
    };
  
    // Get current plan details
    const currentPlanDetails = {
      status: currentSubscription?.subscriptionPlan?.planType || 'free',
      isTrail: currentSubscription?.isTrail || false,
      quantity: currentSubscription?.quantity || 1,
      daysRemaining: currentSubscription ? calculateDaysRemaining(currentSubscription) : 0
    };
  
    const subscriptionPlans = [
        {
          id: '64468a1113bdc104cec11179', // Premium Quarterly
          yearlyId: '64468a4213bdc104cec1117a', // Premium Yearly
          title: 'Premium Plan',
          platform: 'Android & Web',
          description: 'Great for business managing teams and advertises online',
          quarterlyAmount: 750,
          yearlyAmount: 1500,
          features: [
            'Dashboard Screen',
            'Premium integration like Facebook',
            'Desktop web app',
            'Forms',
            'Send Quotation',
            'Activity Feed screen',
            'Priority support'
          ]
        },
        {
          id: '658050be5f1d953b2780d0fc', // Ultimate Quarterly
          yearlyId: '65805a165f1d953b2780d0ff', // Ultimate Yearly
          title: 'Ultimate Plan',
          platform: 'Android, iOS & web',
          description: 'For power users that are looking to automate their sales',
          quarterlyAmount: 1000,
          yearlyAmount: 2000,
          features: [
            'Call recording',
            'Whatsapp Business API',
            'E-mail Broadcasts',
            'Whatsapp broadcast',
            'Automation'
          ]
        }
      ]
  
    const calculatePrice = (planId: string) => {
      const plan = subscriptionPlans.find(p => p.id === planId);
      if (!plan) return 0;
      return (planType ? plan.yearlyAmount : plan.quarterlyAmount) * userCount;
    };
  
    const calculateMonthlyRate = (planId: string) => {
      const price = calculatePrice(planId);
      return (price / (planType ? 12 : 3)).toFixed(2);
    };
  
    if (loading) {
      return <div>Loading...</div>;
    }
  
    return (
      <>
        <Header />
        <S.PageWrapper>
          <S.HeaderSection>
            <h1 className="header-title">Manage Subscriptions</h1>
            <S.PlanToggle>
              <button 
                className={!planType ? 'active' : ''} 
                onClick={() => setPlanType(false)}
              >
                Quarterly
              </button>
              <button 
                className={planType ? 'active' : ''} 
                onClick={() => setPlanType(true)}
              >
                Yearly
                <span className="save-tag">Save 50%</span>
              </button>
            </S.PlanToggle>
            <div className="current-plan">
              You are currently on {currentPlanDetails.status} plan
            </div>
            {currentSubscription && currentSubscription.status !== 'free' && (
              <div className="plan-info">
                <CheckCircle size={14} />
                <span>
                  Plan for {currentPlanDetails.quantity} users
                  {currentPlanDetails.daysRemaining > 0 && (
                    <>
                      {` expiring in ${currentPlanDetails.daysRemaining} days `}
                      <span style={{ fontSize: '12px', opacity: 0.8 }}>
                        ({formatValidityDate(currentSubscription)})
                      </span>
                    </>
                  )}
                  {currentPlanDetails.daysRemaining === 0 && ' (Expired)'}
                </span>
              </div>
            )}
          </S.HeaderSection>
  
          <S.Container>
            <S.PlansWrapper>
              {subscriptionPlans.map((plan) => (
               <S.PlanCard
               key={plan.id}
               isSelected={selectedPlan === (planType ? plan.yearlyId : plan.id)}
               onClick={() => setSelectedPlan(planType ? plan.yearlyId : plan.id)}
             >
                  <div className="plan-name">{plan.title}</div>
                  <div className="plan-desc">{plan.description}</div>
                  <div className="price">
                    ₹{planType ? plan.yearlyAmount : plan.quarterlyAmount}
                    <span className="period">/user/{planType ? 'year' : 'quarter'}</span>
                  </div>
                  <p className="platform">Available on {plan.platform}</p>
                  <div className="features">
                    {plan.features.map((feature, index) => (
                      <div key={index} className="feature">
                        <CheckCircle size={16} />
                        <span>{feature}</span>
                      </div>
                    ))}
                  </div>
                </S.PlanCard>
              ))}
            </S.PlansWrapper>
          </S.Container>
  
          <S.BottomSection>
            <S.UserCounter>
              <span>Number of users</span>
              <div className="counter-controls">
                <S.Button onClick={() => setUserCount(Math.max(1, userCount - 1))}>-</S.Button>
                <span>{userCount}</span>
                <S.Button onClick={() => setUserCount(userCount + 1)}>+</S.Button>
              </div>
            </S.UserCounter>
  
            <S.PaymentSection>
              <div>
                <div className="price">
                  Pay ₹{calculatePrice(selectedPlan)}
                  <span className="period">/{planType ? 'year' : 'quarter'}</span>
                </div>
                <div className="monthly-rate">
                  ₹{calculateMonthlyRate(selectedPlan)}/month
                </div>
              </div>
              <div style={{ display: 'flex', gap: '10px' }}>
              <S.Button 
  variant={currentPlanDetails.status !== 'free' ? 'cancel' : undefined}
  disabled={isLoading}
  onClick={() => {
    if (currentPlanDetails.status !== 'free') {
      handleCancelSubscription();
    } else {
      handleBuySubscription();
    }
  }}
>
  {isLoading ? 'Processing...' : (currentPlanDetails.status !== 'free' ? 'Cancel Subscription' : 'Buy Subscription')}
</S.Button>

                {currentPlanDetails.status === 'free' && !currentSubscription?.isTrail && (
                  <S.Button onClick={handleStartTrial}>
                    Start Trial
                  </S.Button>
                )}
              </div>
            </S.PaymentSection>
          </S.BottomSection>
        </S.PageWrapper>
      </>
    );
};
  
export default SubscriptionPage;