import React from 'react';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import type { TableRowSelection } from 'antd/es/table/interface';
import moment from 'moment';
import styled from 'styled-components';
import { ITaskData } from './types';

const StyledTable = styled(Table)`
  font-family: 'Gilroy', sans-serif;
  background: white;
  border-radius: 12px;

  // Remove ALL possible border radius
  * {
    border-radius: 0 !important;
  }

  .ant-table {
    background: transparent;
    border-spacing: 0 !important;
    border-collapse: collapse !important;
  }

  // Header styling
  .ant-table-thead {
    tr th {
      height: 24px !important;
      padding: 10px 16px !important;
      background: #f8fafd !important;
      font-weight: 400 !important;
      color: #64748b !important;
      text-align: left !important;
      border-bottom: 1px solid #edf2f7 !important;
      font-size: 12px !important;
      letter-spacing: 0.3px;
      text-transform: capitalize;

      &::before {
        display: none !important;
      }
    }
  }

  // Body styling with specific column weights
  .ant-table-tbody {
    > tr > td {
      height: 24px !important;
      padding: 8px 16px !important;
      background: white !important;
      text-align: left !important;
      border-bottom: 1px solid #f1f5f9 !important;
      color: #334155 !important;
      font-size: 13px !important;
      font-weight: 400 !important;

      // Name column - slightly heavier
      &:nth-child(2) {
        font-weight: 500 !important;
        color: #1e293b !important;
      }

      // Due time column - slightly heavier
      &:nth-child(4) {
        font-weight: 500 !important;
        color: #1e293b !important;
      }

      &.ant-table-cell-ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    // Subtle hover
    > tr:hover > td {
      background: #fafbff !important;
    }

    // Very subtle zebra striping
    > tr:nth-child(even) > td {
      background: #fafbfd !important;
    }
  }

  // Selection column
  .ant-table-selection-column {
    padding-left: 16px !important;
    width: 40px !important;
  }

  // Checkbox refinements
  .ant-checkbox-wrapper {
    .ant-checkbox-inner {
      border-color: #e2e8f0 !important;
      width: 14px !important;
      height: 14px !important;
      
      &:hover {
        border-color: #60a5fa !important;
      }
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #3b82f6 !important;
      border-color: #3b82f6 !important;
    }
  }

  // Maintain scroll behavior
  .ant-table-container {
    min-height: 0 !important;
  }
  
  .ant-table-body {
    min-height: 0 !important;
    max-height: none !important;
  }

  // Row height
  .ant-table-row {
    height: 38px !important;
  }

  // Refined scrollbar
  .ant-table-body::-webkit-scrollbar {
    width: 5px !important;
  }

  .ant-table-body::-webkit-scrollbar-track {
    background: #f8fafc !important;
  }

  .ant-table-body::-webkit-scrollbar-thumb {
    background: #e2e8f0 !important;
    border-radius: 20px !important;
    
    &:hover {
      background: #cbd5e0 !important;
    }
  }

  // Status badge styling
  .follow-up-badge {
    padding: 2px 8px;
    background: #EFF6FF;
    color: #3B82F6;
    font-size: 12px;
    font-weight: 500;
  }

  // Ensure no rounded corners anywhere
  .ant-table-content,
  .ant-table-row,
  .ant-table-row td,
  .ant-table-row:last-child td {
    border-radius: 0 !important;
  }
`;

const TaskGrid: React.FC<{
  data: ITaskData[];
  isLoading: boolean;
  handleEditTask: (_id: string) => void;
  selectedRowKeys: React.Key[];
  onSelectChange: (newSelectedRowKeys: React.Key[], selectedRows: ITaskData[]) => void;
}> = ({
  data,
  isLoading,
  handleEditTask,
  selectedRowKeys,
  onSelectChange
}) => {
  const columns: ColumnsType<ITaskData> = [
    {
      title: "Lead Name",
      key: "leadName",
      render: (record: ITaskData) => record.lead?.[0]?.name || '-',
      align: 'left',
      width: '20%'
    },
    {
      title: "Task owner",
      dataIndex: "createdBy",
      key: "taskOwner",
      align: 'left',
      width: '15%'
    },
    {
      title: "Due time",
      key: "dueTime",
      render: (record: ITaskData) => 
        record.toBePerformAt ? 
        moment.utc(record.toBePerformAt).add(5, 'hours').add(30, 'minutes').format("DD MMM YYYY, hh:mm a") : 
        '-',
      align: 'left',
      width: '20%',
     
     
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type: string) => type?.toLowerCase() || '-',
      align: 'left',
      width: '15%'
    },
    {
      title: "Assigned to",
      key: "assignedTo",
      render: (record: ITaskData) => {
        if (!record.assignedTo?.length) return 'N/A';
        return record.assignedTo[0]?.name || 'N/A';
      },
      align: 'left',
      width: '15%'
    },
    {
      title: "Note",
      dataIndex: "notes",
      key: "note",
      render: (notes: string) => notes || 'N/A',
      align: 'left',
      width: '15%'
    }
  ];

  const rowSelection: TableRowSelection<ITaskData> = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: false,
    columnWidth: 48
  };

  return (
    <StyledTable
      rowSelection={rowSelection}
      columns={columns}
      dataSource={data}
      loading={isLoading}
      pagination={false}
      rowKey="_id"
      // Remove fixed scroll height to let table adjust naturally
      scroll={{ y: undefined }}
      onRow={(record: ITaskData) => ({
        onClick: (e) => {
          const target = e.target as HTMLElement;
          if (target.tagName.toLowerCase() !== 'input') {
            handleEditTask(record._id);
          }
        },
        style: { cursor: 'pointer' }
      })}
    />
  );
};

export default TaskGrid;