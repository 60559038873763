import styles from "./bulkSelector.module.scss";
import { MdRefresh } from "react-icons/md";

interface IProps {
  onSelect: (i: number) => void;
  onReset: () => void;
  selected: number | null;
  className?: any;
}

const BulkSelector = ({ onSelect, onReset, selected, className }: IProps) => {
  return (
    <div className={`${styles.bulk_container} ${className}`}>
      <div className={styles.button_group}>
        <button onClick={() => onSelect(25)}>Select next 25</button>
        <button onClick={() => onSelect(50)}>Next 50</button>
        {/* <button>Select all</button> */}
      </div>
      {selected ? <span>{selected} leads selected</span> : null}

      <button onClick={onReset} className={styles.reset_button}>
        <MdRefresh size={18} />
        Reset
      </button>
    </div>
  );
};

export default BulkSelector;
