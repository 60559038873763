import axios from "axios";
import { API_URL } from "../config/config";
import { getHeaderOptions } from "./getHeaderOptions";
import { loadRazorpayScript, RAZORPAY_CONFIG } from "../config/razorpay.config";

export const API_ENDPOINT = API_URL + "api/v1";

// Types
export interface SubscriptionPlan {
  _id: string;
  name: string;
  planId: string;
  amount: number;
  monthlyAmount: number;
  currency: string;
  period: "yearly" | "quarterly";
  interval: string;
  planType: "premium" | "ultimate";
}


export interface RazorpayOrderResponse {
  id: string;
  currency: string;
  amount: number;
  apiKey: string;
  status: string;
  created_at: number;
}
export interface Subscription {
  _id: string;
  subscriptionPlan: SubscriptionPlan;
  status: string;
  quantity: number;
  isTrail: boolean;
  extraDetails: {
    current_start: number | null;
    current_end: number | null;
    ended_at: number | null;
    created_at: number;
    id?: string;
    entity?: string;
    plan_id?: string;
    customer_id?: string;
    notes?: {
      systemUserId?: string;
      systemSubscriptionId?: string;
      phone?: string;
      email?: string;
    };
  };
  createdAt: string;
  customerId?: string;
  organization?: string;
  subscriptionId?: string;
  paymentGateway?: string;
  isDeleted?: boolean;
  updatedAt?: string;
}

export interface SubscriptionUsage {
  totalUsers: number;
  plan: string;
  planType: "premium" | "ultimate";
  daysRemaining: number;
  validUntil: string;
  isActive: boolean;
  isTrial: boolean;
  trialDaysLeft: number;
  monthlyPerUserCost: number;
  totalAmount: string;
  currency: string;
}

export interface CreatePaymentOrderPayload {
  plan: string; // Keep as 'plan' to match backend
  quantity: number;
}
export interface PaymentOrder {
  id: string;
  currency: string;
  amount: number;
  apiKey: string;
}

// API Calls
export const getCurrentSubscription = () => {
  console.log('Fetching current subscription...');
  const headers = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  
  return axios.get<{ data: Subscription }>(`${API_ENDPOINT}/subscription`, { 
    headers 
  }).then(response => {
    console.log('Subscription fetched successfully:', response.data);
    return response;
  }).catch(error => {
    console.error('Error fetching subscription:', error);
    throw error;
  });
};

export const createPaymentOrder = async (payload: CreatePaymentOrderPayload) => {
  console.log('Creating payment order...', payload);
  const headers = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };

  try {
    const response = await axios.post<RazorpayOrderResponse>(
      `${API_ENDPOINT}/subscription/razorpay-order`,
      payload,
      { headers }
    );

    if (!response.data || !response.data.id) {
      throw new Error('Invalid order response from server');
    }

    console.log('Payment order created successfully:', response.data);
    return response;
  } catch (error) {
    console.error('Error creating payment order:', error);
    throw error;
  }
};

export const cancelSubscription = async (subscriptionId: string) => {
  console.log('Cancelling subscription...', subscriptionId);
  const headers = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };

  return axios.post(`${API_ENDPOINT}/subscription/${subscriptionId}/cancel`, {
    isImmediately: true
  }, { headers })
    .then(response => {
      console.log('Subscription cancelled successfully:', response.data);
      return response;
    })
    .catch(error => {
      console.error('Error cancelling subscription:', error);
      throw error;
    });
};

export const startTrial = async (planId: string) => {
  console.log('Starting trial...', planId);
  const headers = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };

  return axios.post(`${API_ENDPOINT}/subscription/trial`, {
    isTrail: true,
    plan: planId
  }, { headers })
    .then(response => {
      console.log('Trial started successfully:', response.data);
      return response;
    })
    .catch(error => {
      console.error('Error starting trial:', error);
      throw error;
    });
};

// Date Calculations
export const calculateSubscriptionEndDate = (subscription: Subscription): Date => {
  console.log('Calculating subscription end date...');
  console.log('Creation date:', subscription.createdAt);
  console.log('Plan period:', subscription.subscriptionPlan.period);
  console.log('Is trial:', subscription.isTrail);

  const createdDate = new Date(subscription.createdAt);
  const endDate = new Date(createdDate);

  if (subscription.isTrail) {
    // Trial period - 14 days
    endDate.setDate(endDate.getDate() + 14);
    console.log('Trial end date calculated:', endDate);
  } else {
    // Regular subscription
    if (subscription.subscriptionPlan.period === "yearly") {
      endDate.setFullYear(endDate.getFullYear() + 1);
    } else if (subscription.subscriptionPlan.period === "quarterly") {
      endDate.setMonth(endDate.getMonth() + 3);
    }
    console.log('Subscription end date calculated:', endDate);
  }

  return endDate;
};

export const calculateDaysRemaining = (subscription: Subscription): number => {
  console.log('Calculating days remaining...');

  const endDate = calculateSubscriptionEndDate(subscription);
  const now = new Date();
  
  const daysRemaining = Math.max(0, Math.ceil(
    (endDate.getTime() - now.getTime()) / (24 * 60 * 60 * 1000)
  ));
  
  console.log('Days remaining:', daysRemaining);
  return daysRemaining;
};

// Formatting
export const formatValidityDate = (subscription: Subscription): string => {
  console.log('Formatting validity date...');
  
  const endDate = calculateSubscriptionEndDate(subscription);
  const formattedDate = endDate.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
  
  console.log('Formatted date:', formattedDate);
  return formattedDate;
};

export const formatSubscriptionPrice = (subscription: Subscription): string => {
  console.log('Formatting subscription price...');
  
  const amount = subscription.subscriptionPlan.amount;
  const currency = subscription.subscriptionPlan.currency;
  
  // Use Indian formatting for INR
  const locale = currency === 'INR' ? 'en-IN' : 'en-US';
  
  const formatted = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(amount);
  
  console.log('Formatted price:', formatted);
  return formatted;
};

// Status and Usage
export const isSubscriptionActive = (subscription: Subscription): boolean => {
  console.log('Checking subscription status...');
  
  if (subscription.status !== 'active') {
    console.log('Subscription not active, status:', subscription.status);
    return false;
  }
  
  const daysRemaining = calculateDaysRemaining(subscription);
  const isActive = daysRemaining > 0;
  
  console.log('Is active:', isActive);
  return isActive;
};

export const getMonthlyPerUserCost = (subscription: Subscription): number => {
  console.log('Calculating monthly per user cost...');
  
  const monthlyAmount = subscription.subscriptionPlan.monthlyAmount;
  const perUserCost = monthlyAmount / subscription.quantity;
  
  console.log('Monthly per user cost:', perUserCost);
  return perUserCost;
};

export const getSubscriptionUsage = (subscription: Subscription): SubscriptionUsage => {
  console.log('Getting subscription usage details...');
  
  const usage: SubscriptionUsage = {
    totalUsers: subscription.quantity,
    plan: subscription.subscriptionPlan.name,
    planType: subscription.subscriptionPlan.planType,
    daysRemaining: calculateDaysRemaining(subscription),
    validUntil: formatValidityDate(subscription),
    isActive: isSubscriptionActive(subscription),
    isTrial: subscription.isTrail,
    trialDaysLeft: subscription.isTrail ? calculateDaysRemaining(subscription) : 0,
    monthlyPerUserCost: getMonthlyPerUserCost(subscription),
    totalAmount: formatSubscriptionPrice(subscription),
    currency: subscription.subscriptionPlan.currency
  };
  
  console.log('Subscription usage:', usage);
  return usage;
};

// Razorpay Integration
export const initializeRazorpayPayment = async (
  paymentOrder: RazorpayOrderResponse,
  userDetails: {
    email: string;
    phone?: string;
    name: string;
  },
  callbacks: {
    onSuccess: (response: any) => void;
    onError: (error: any) => void;
    onDismiss?: () => void;
  }
) => {
  try {
    await loadRazorpayScript();

    const options = {
      key: paymentOrder.apiKey,
      amount: paymentOrder.amount,
      currency: paymentOrder.currency,
      name: RAZORPAY_CONFIG.COMPANY_NAME,
      description: RAZORPAY_CONFIG.DESCRIPTION,
      image: RAZORPAY_CONFIG.COMPANY_LOGO,
      order_id: paymentOrder.id,
      prefill: {
        email: userDetails.email,
        contact: userDetails.phone,
        name: userDetails.name,
      },
      handler: function(response: any) {
        console.log('Payment successful:', response);
        callbacks.onSuccess(response);
      },
      modal: {
        ondismiss: function() {
          console.log('Checkout form closed');
          callbacks.onDismiss?.();
        }
      },
      theme: {
        color: RAZORPAY_CONFIG.THEME_COLOR
      }
    };

    const rzp = new window.Razorpay(options);
    rzp.open();

    rzp.on('payment.failed', function(response: any) {
      console.error('Payment failed:', response.error);
      callbacks.onError(response.error);
    });

  } catch (error) {
    console.error('Error initializing Razorpay:', error);
    callbacks.onError(error);
  }
};


// Test Helper
export const testSubscription = (subscription: Subscription) => {
  console.log('\n=== Testing Subscription ===');
  console.log('Plan:', subscription.subscriptionPlan.name);
  console.log('Type:', subscription.subscriptionPlan.planType);
  console.log('Period:', subscription.subscriptionPlan.period);
  console.log('Status:', subscription.status);
  console.log('Is Trial:', subscription.isTrail);
  console.log('Users:', subscription.quantity);
  
  const usage = getSubscriptionUsage(subscription);
  console.log('\nUsage Details:');
  console.log('Days Remaining:', usage.daysRemaining);
  console.log('Valid Until:', usage.validUntil);
  console.log('Is Active:', usage.isActive);
  console.log('Monthly/User:', usage.monthlyPerUserCost);
  console.log('Total Amount:', usage.totalAmount);
  
  if (usage.isTrial) {
    console.log('Trial Days Left:', usage.trialDaysLeft);
  }
  
  return usage;
};