import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  LayoutDashboard,
  Home,
  FileText,
  Calendar,
  Settings,
  List,
  Plug,
  Inbox,
  Megaphone,
  MessageCircle,
  User,
  HelpCircle,
  Bolt
} from 'lucide-react';

interface SidebarProps {
  userdata?: any;
}

const Sidebar: React.FC<SidebarProps> = ({ userdata }) => {
  const openHelpWebsite = () => {
    window.open("https://help.3sigmacrm.com", "_blank");
  };

  return (
    <aside id="sidebar" className="sidebar d-flex flex-column" style={{ 
      width: "200px", 
      overflowY: "auto",
      overflowX: "hidden",
      borderRight: "1px solid #e9ecef",
      backgroundColor: "#ffffff",
      position: "fixed",
      top: 0,
      left: 0,
      bottom: 0,
      borderRadius: 0,
      margin: 0
    }}>
   <div className="d-flex justify-content-center align-items-center" style={{ 
        padding: "1rem",
        position: "relative"
      }}>
        <div style={{
          position: "absolute",
          bottom: 0,
          left: "20%",
          right: "20%",
          borderBottom: "2px dotted #e9ecef"
        }} />
        <a href="/dashboard" className="d-flex align-items-center">
          <img 
            src="assets/img/3sigma_logo.png" 
            alt="3Sigma Logo" 
            style={{ 
              height: "26px",
              transition: "transform 0.2s ease"
            }}
            className="hover:scale-105"
          />
        </a>
      </div>

      <ul className="sidebar-nav" style={{ 
        listStyle: 'none', 
        padding: "0.5rem",
        margin: 0,
        width: '100%',
        boxSizing: 'border-box'
      }}>
        <li>
          <NavLink 
            className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`} 
            style={({ isActive }) => ({
              display: 'flex',
              alignItems: 'center',
              padding: '0.5rem 0.75rem',
              marginBottom: '0.25rem',
              borderRadius: '6px',
              color: isActive ? '#0d6efd' : '#495057',
              backgroundColor: isActive ? '#e7f1ff' : 'transparent',
              transition: 'all 0.2s ease',
              textDecoration: 'none',
              fontSize: '0.9rem'
            })} 
            to="/dashboard"
          >
            <LayoutDashboard size={20} opacity={0.5} />
            <span className="ms-2">Dashboard</span>
          </NavLink>

          <NavLink 
            className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
            style={({ isActive }) => ({
              display: 'flex',
              alignItems: 'center',
              padding: '0.5rem 0.75rem',
              marginBottom: '0.25rem',
              borderRadius: '6px',
              color: isActive ? '#0d6efd' : '#495057',
              backgroundColor: isActive ? '#e7f1ff' : 'transparent',
              transition: 'all 0.2s ease',
              textDecoration: 'none',
              fontSize: '0.9rem'
            })} 
            to="/leads"
          >
            <Home size={20} opacity={0.5} />
            <span className="ms-2">Leads</span>
          </NavLink>

          <NavLink 
            className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
            style={({ isActive }) => ({
              display: 'flex',
              alignItems: 'center',
              padding: '0.5rem 0.75rem',
              marginBottom: '0.25rem',
              borderRadius: '6px',
              color: isActive ? '#0d6efd' : '#495057',
              backgroundColor: isActive ? '#e7f1ff' : 'transparent',
              transition: 'all 0.2s ease',
              textDecoration: 'none',
              fontSize: '0.9rem'
            })} 
            to="/content"
          >
            <FileText size={20} opacity={0.5} />
            <span className="ms-2">Content</span>
          </NavLink>

          <NavLink 
            className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
            style={({ isActive }) => ({
              display: 'flex',
              alignItems: 'center',
              padding: '0.5rem 0.75rem',
              marginBottom: '0.25rem',
              borderRadius: '6px',
              color: isActive ? '#0d6efd' : '#495057',
              backgroundColor: isActive ? '#e7f1ff' : 'transparent',
              transition: 'all 0.2s ease',
              textDecoration: 'none',
              fontSize: '0.9rem'
            })} 
            to="/tasks"
          >
            <Calendar size={20} opacity={0.5} />
            <span className="ms-2">Task</span>
          </NavLink>

          <NavLink 
            className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
            style={({ isActive }) => ({
              display: 'flex',
              alignItems: 'center',
              padding: '0.5rem 0.75rem',
              marginBottom: '0.25rem',
              borderRadius: '6px',
              color: isActive ? '#0d6efd' : '#495057',
              backgroundColor: isActive ? '#e7f1ff' : 'transparent',
              transition: 'all 0.2s ease',
              textDecoration: 'none',
              fontSize: '0.9rem'
            })} 
            to="/automation"
          >
            <Settings size={20} opacity={0.5} />
            <span className="ms-2">Automation</span>
          </NavLink>

          <NavLink 
            className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
            style={({ isActive }) => ({
              display: 'flex',
              alignItems: 'center',
              padding: '0.5rem 0.75rem',
              marginBottom: '0.25rem',
              borderRadius: '6px',
              color: isActive ? '#0d6efd' : '#495057',
              backgroundColor: isActive ? '#e7f1ff' : 'transparent',
              transition: 'all 0.2s ease',
              textDecoration: 'none',
              fontSize: '0.9rem'
            })} 
            to="/form"
          >
            <List size={20} opacity={0.5} />
            <span className="ms-2">Form</span>
          </NavLink>

          <NavLink 
            className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
            style={({ isActive }) => ({
              display: 'flex',
              alignItems: 'center',
              padding: '0.5rem 0.75rem',
              marginBottom: '0.25rem',
              borderRadius: '6px',
              color: isActive ? '#0d6efd' : '#495057',
              backgroundColor: isActive ? '#e7f1ff' : 'transparent',
              transition: 'all 0.2s ease',
              textDecoration: 'none',
              fontSize: '0.9rem'
            })} 
            to="/whatsappInbox"
          >
            <Inbox size={20} opacity={0.5} />
            <span className="ms-2">Inbox</span>
          </NavLink>

          <NavLink 
            className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
            style={({ isActive }) => ({
              display: 'flex',
              alignItems: 'center',
              padding: '0.5rem 0.75rem',
              marginBottom: '0.25rem',
              borderRadius: '6px',
              color: isActive ? '#0d6efd' : '#495057',
              backgroundColor: isActive ? '#e7f1ff' : 'transparent',
              transition: 'all 0.2s ease',
              textDecoration: 'none',
              fontSize: '0.9rem'
            })} 
            to="/whatsappBroadcast"
          >
            <Megaphone size={20} opacity={0.5} />
            <span className="ms-2">Campaigns</span>
          </NavLink>

          <NavLink 
            className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
            style={({ isActive }) => ({
              display: 'flex',
              alignItems: 'center',
              padding: '0.5rem 0.75rem',
              marginBottom: '0.25rem',
              borderRadius: '6px',
              color: isActive ? '#0d6efd' : '#495057',
              backgroundColor: isActive ? '#e7f1ff' : 'transparent',
              transition: 'all 0.2s ease',
              textDecoration: 'none',
              fontSize: '0.9rem'
            })} 
            to="/template"
          >
            <MessageCircle size={20} opacity={0.5} />
            <span className="ms-2">Template</span>
          </NavLink>

          <NavLink 
            className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
            style={({ isActive }) => ({
              display: 'flex',
              alignItems: 'center',
              padding: '0.5rem 0.75rem',
              marginBottom: '0.25rem',
              borderRadius: '6px',
              color: isActive ? '#0d6efd' : '#495057',
              backgroundColor: isActive ? '#e7f1ff' : 'transparent',
              transition: 'all 0.2s ease',
              textDecoration: 'none',
              fontSize: '0.9rem'
            })} 
            to="/profile"
          >
            <User size={20} opacity={0.5} />
            <span className="ms-2">WABAprofile</span>
          </NavLink>

          {userdata?.role?.name === 'super_admin' && (
            <NavLink 
              className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
              style={({ isActive }) => ({
                display: 'flex',
                alignItems: 'center',
                padding: '0.5rem 0.75rem',
                marginBottom: '0.25rem',
                borderRadius: '6px',
                color: isActive ? '#0d6efd' : '#495057',
                backgroundColor: isActive ? '#e7f1ff' : 'transparent',
                transition: 'all 0.2s ease',
                textDecoration: 'none',
                fontSize: '0.9rem'
              })} 
              to="/integrations"
            >
              <Plug size={20} opacity={0.5} />
              <span className="ms-2">Integrations</span>
            </NavLink>
          )}
        </li>
      </ul>

      <div className="mt-auto mb-2" style={{ padding: "0 0.75rem" }}>
        <div style={{
          background: "#f8f9fa",
          border: "1px solid #e9ecef",
          borderRadius: "6px",
          padding: "0.5rem"
        }}>
          <NavLink
            className="nav-link get-help-link"
            to="#"
            onClick={openHelpWebsite}
            style={{
              fontSize: "0.85em",
              padding: "0.5rem 0.75rem",
              display: "flex",
              alignItems: "center",
              color: "#495057",
              borderRadius: "4px",
              marginBottom: "0.25rem",
              transition: "background-color 0.2s ease",
            }}
            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#edf2f7"}
            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "transparent"}
          >
            <HelpCircle size={16} opacity={0.5} />
            <span className="ms-2">Get Help</span>
          </NavLink>
          
          <NavLink
  className="nav-link"
  to="#"
  onClick={() => window.open("https://help.3sigmacrm.com/changelog/web-version-2.7.0", "_blank")}
  style={{
    fontSize: "0.85em",
    padding: "0.5rem 0.75rem",
    display: "flex",
    alignItems: "center",
    color: "#495057",
    borderRadius: "4px",
    transition: "background-color 0.2s ease",
  }}
  onMouseEnter={(e) => e.currentTarget.style.backgroundColor = "#edf2f7"}
  onMouseLeave={(e) => e.currentTarget.style.backgroundColor = "transparent"}
>
  <Bolt size={16} opacity={0.5} />
  <span className="ms-2">What's New</span>
</NavLink>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;