// src/views/ActivityFeed/ActivityFeedDrawer.tsx
import React, { useState, useEffect } from 'react';
import { Drawer } from 'antd';
import ActivityFeed from './ActivityFeed';
import activityFeedDrawerManager from './ActivityFeedDrawerManager';

const ActivityFeedDrawer: React.FC = () => {
  const [visible, setVisible] = useState(false);
  const [initialDates, setInitialDates] = useState<{fromDate?: string; toDate?: string}>();

  useEffect(() => {
    activityFeedDrawerManager.registerOpenDrawer((filters) => {
      if (filters?.fromDate && filters?.toDate) {
        setInitialDates({
          fromDate: filters.fromDate,
          toDate: filters.toDate
        });
      }
      setVisible(true);
    });

    return () => activityFeedDrawerManager.unregisterOpenDrawer();
  }, []);

  return (
    <Drawer 
      title="Activity Feed"
      placement="right"
      width={600}
      onClose={() => setVisible(false)}
      open={visible}
    >
      <ActivityFeed 
        initialStartDate={initialDates?.fromDate}
        initialEndDate={initialDates?.toDate}
      />
    </Drawer>
  );
};

export default ActivityFeedDrawer;