import React, { useEffect, useState } from 'react';
import { Steps } from 'intro.js-react';
import 'intro.js/minified/introjs.min.css';

const TOUR_CONFIG = {
  STORAGE_KEY: '3sigma_tour_state',
  CURRENT_VERSION: '1.0.0',
  DEFAULT_STATE: {
    completed: false,
    version: '1.0.0',
    completionType: null as string | null,
    completedAt: null as string | null
  }
};

interface TourMetadata {
  completedAt: string;
  completionType: string;
  version: string;
}

const LeadsTour: React.FC = () => {
  const [enabled, setEnabled] = useState(() => {
    try {
      const tourComplete = localStorage.getItem('leadsTourComplete');
      const sigmaComplete = localStorage.getItem('3sigma_tour_completed');
      return !(tourComplete === 'true' || sigmaComplete === 'true');
    } catch (error) {
      console.error('Tour state read error:', error);
      return true;
    }
  });

  const [initialStep] = useState(0);

  const steps = [
    {
      intro: `
        <div style="text-align: left;">
          <div style="margin-bottom: 16px;">
            <h3 style="margin-bottom: 12px; font-family: system-ui, -apple-system, sans-serif; color: #0f172a;">
              👋 Hey there! Welcome to your new sales companion
            </h3>
            <p style="margin-bottom: 12px; color: #475569;">
              Let's take a quick tour of your 3Sigma Mobile CRM. We'll show you how to:
            </p>
          </div>
          <ul style="list-style: none; padding: 0; margin: 0 0 16px 0;">
            <li style="margin-bottom: 8px; display: flex; align-items: center; color: #1e293b;">
              <span style="margin-right: 8px;">•</span>
              Find exactly the leads you need, when you need them
            </li>
            <li style="margin-bottom: 8px; display: flex; align-items: center; color: #1e293b;">
              <span style="margin-right: 8px;">•</span>
              Keep track of where each lead stands
            </li>
            <li style="margin-bottom: 8px; display: flex; align-items: center; color: #1e293b;">
              <span style="margin-right: 8px;">•</span>
              Save time with quick actions for multiple leads
            </li>
            <li style="margin-bottom: 8px; display: flex; align-items: center; color: #1e293b;">
              <span style="margin-right: 8px;">•</span>
              Never miss a follow-up with activity tracking
            </li>
          </ul>
          <p style="margin: 0; color: #475569;">Ready? It'll only take a couple of minutes! 🚀</p>
        </div>
      `,
    },
    {
      element: '.leads-section-1',
      intro: "This is your command center - where all your leads live. Think of it as your sales dashboard where you can see everything that's happening at a glance.",
      position: 'bottom'
    },
    {
      element: '#filter-img',
      intro: "Need to find specific leads? Click here to open up powerful filters - perfect for when you're looking for things like 'hot leads from last week' or 'follow-ups due today'.",
      position: 'right'
    },
    {
      element: '[data-tour="filter-bar"]',
      intro: "Here's your quick-access bar. These smart filters are like shortcuts - one click and you'll see exactly what you're looking for. Try them out!",
      position: 'bottom'
    },
    {
      element: '[data-tour="quick-filters"]',
      intro: "Want to narrow things down? These dropdowns let you quickly sort by when leads came in, their current status, or who's handling them. Super handy for daily updates!",
      position: 'bottom'
    },
    {
      element: '#dropdownMenuButton2',
      intro: `
        <div style="text-align: left;">
          <div style="margin-bottom: 12px;">
            <p style="color: #1e293b; margin: 0;">
              Ready to add new leads? Click here to choose how you want to bring them in:
            </p>
          </div>
          <ul style="list-style: none; padding: 0; margin: 8px 0;">
            <li style="margin-bottom: 8px; display: flex; align-items: center; color: #1e293b;">
              <span style="margin-right: 8px;">•</span>
              Add them one by one (great for quick entries)
            </li>
            <li style="margin-bottom: 8px; display: flex; align-items: center; color: #1e293b;">
              <span style="margin-right: 8px;">•</span>
              Upload a spreadsheet (perfect for bulk imports)
            </li>
            <li style="margin-bottom: 0; display: flex; align-items: center; color: #1e293b;">
              <span style="margin-right: 8px;">•</span>
              Set up a lead form (let them flow in automatically)
            </li>
          </ul>
        </div>
      `,
      position: 'left',
      tooltipClass: 'add-leads-tooltip'
    },
    {
      element: '[data-tour="leads-grid"]',
      intro: "Here's where the magic happens - your leads table. Each row is a potential opportunity. Click any lead to dive into their details and history.",
      position: 'top'
    },
    {
      element: '[data-tour="bulk-actions"]',
      intro: `
        <div style="text-align: left;">
          <h3 style="color: #0F172A; font-family: system-ui, -apple-system, sans-serif; margin-bottom: 16px;">
            Work Smarter, Not Harder
          </h3>
          <p style="color: #475569; margin-bottom: 16px;">
            Need to update multiple leads at once? Here's how to save tons of time:
          </p>
          <ul style="list-style: none; padding: 0; margin: 0;">
            <li style="margin-bottom: 12px; display: flex; align-items: center; color: #1e293b;">
              <span style="margin-right: 12px; color: #2563EB;">•</span>
              <span style="font-size: 15px;">Check off several leads at once</span>
            </li>
            <li style="margin-bottom: 12px; display: flex; align-items: center; color: #1e293b;">
              <span style="margin-right: 12px; color: #2563EB;">•</span>
              <span style="font-size: 15px;">Update their status in one go</span>
            </li>
            <li style="margin-bottom: 12px; display: flex; align-items: center; color: #1e293b;">
              <span style="margin-right: 12px; color: #2563EB;">•</span>
              <span style="font-size: 15px;">Tag multiple leads in seconds</span>
            </li>
            <li style="margin-bottom: 12px; display: flex; align-items: center; color: #1e293b;">
              <span style="margin-right: 12px; color: #2563EB;">•</span>
              <span style="font-size: 15px;">Move groups of leads between lists</span>
            </li>
          </ul>
          <div style="margin-top: 16px; padding-top: 16px; border-top: 1px solid #E2E8F0;">
            <p style="color: #64748B; margin: 0; font-style: italic; font-size: 14px;">
              💡 Perfect for after a meeting when you need to update multiple leads quickly!
            </p>
          </div>
        </div>
      `,
      position: 'bottom',
      tooltipClass: 'bulk-actions-tooltip'
    },
    {
      element: '[data-tour="edit-columns"]',
      intro: `
        <div style="text-align: left;">
          <h3 style="color: #0F172A; font-family: system-ui, -apple-system, sans-serif; margin-bottom: 16px;">
            Make It Yours
          </h3>
          <p style="color: #475569; margin-bottom: 16px;">
            Set up your view exactly how you like it:
          </p>
          <ul style="list-style: none; padding: 0; margin: 0;">
            <li style="margin-bottom: 12px; display: flex; align-items: center; color: #1e293b;">
              <span style="margin-right: 12px; color: #2563EB;">•</span>
              <span style="font-size: 15px;">Pick which info you want to see</span>
            </li>
            <li style="margin-bottom: 12px; display: flex; align-items: center; color: #1e293b;">
              <span style="margin-right: 12px; color: #2563EB;">•</span>
              <span style="font-size: 15px;">Arrange columns in your preferred order</span>
            </li>
            <li style="margin-bottom: 12px; display: flex; align-items: center; color: #1e293b;">
              <span style="margin-right: 12px; color: #2563EB;">•</span>
              <span style="font-size: 15px;">Keep your setup for next time</span>
            </li>
          </ul>
          <div style="margin-top: 16px; padding-top: 16px; border-top: 1px solid #E2E8F0;">
            <p style="color: #64748B; margin: 0; font-style: italic; font-size: 14px;">
              💡 Focus on what matters most to you and your sales process
            </p>
          </div>
        </div>
      `,
      position: 'left',
      tooltipClass: 'edit-columns-tooltip'
    },
    {
      element: '[data-tour="refresh"]',
      intro: `
        <div style="text-align: left;">
          <h3 style="color: #0F172A; font-family: system-ui, -apple-system, sans-serif; margin-bottom: 16px;">
            Start Fresh
          </h3>
          <p style="color: #475569; margin-bottom: 16px;">
            Need a clean slate? Hit refresh to:
          </p>
          <ul style="list-style: none; padding: 0; margin: 0;">
            <li style="margin-bottom: 12px; display: flex; align-items: center; color: #1e293b;">
              <span style="margin-right: 12px; color: #2563EB;">•</span>
              <span style="font-size: 15px;">Clear your current search</span>
            </li>
            <li style="margin-bottom: 12px; display: flex; align-items: center; color: #1e293b;">
              <span style="margin-right: 12px; color: #2563EB;">•</span>
              <span style="font-size: 15px;">Remove active filters</span>
            </li>
            <li style="margin-bottom: 12px; display: flex; align-items: center; color: #1e293b;">
              <span style="margin-right: 12px; color: #2563EB;">•</span>
              <span style="font-size: 15px;">See the latest updates</span>
            </li>
          </ul>
          <div style="margin-top: 16px; padding-top: 16px; border-top: 1px solid #E2E8F0;">
            <p style="color: #64748B; margin: 0; font-style: italic; font-size: 14px;">
              💡 Perfect for starting your day with a fresh view of your leads
            </p>
          </div>
        </div>
      `,
      position: 'bottom',
      tooltipClass: 'refresh-tooltip'
    }
  ];

  const saveTourState = (type = 'normal'): boolean => {
    try {
      localStorage.setItem('leadsTourComplete', 'true');
      localStorage.setItem('3sigma_tour_completed', 'true');
      
      const metadata: TourMetadata = {
        completedAt: new Date().toISOString(),
        completionType: type,
        version: '1.0'
      };
      localStorage.setItem('3sigma_tour_metadata', JSON.stringify(metadata));
      
      return true;
    } catch (error) {
      console.error('Tour state save error:', error);
      return false;
    }
  };

  const onExit = (): void => {
    if (saveTourState('normal')) {
      setEnabled(false);
    }
  };

  useEffect(() => {
    // Add custom styles
    const style = document.createElement('style');
    style.textContent = `
      .introjs-tooltip {
        min-width: 450px !important;
        max-width: 550px !important;
        padding: 20px !important;
        border-radius: 8px !important;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
        font-family: "Gilroy", sans-serif !important;
      }
      /* ... rest of your styles ... */
    `;
    document.head.appendChild(style);

    // Storage event listener
    const handleStorageChange = (e: StorageEvent): void => {
      if (e.key === 'leadsTourComplete' || e.key === '3sigma_tour_completed') {
        const newValue = e.newValue === 'true';
        setEnabled(!newValue);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    // Cleanup function
    return () => {
      document.head.removeChild(style);
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <Steps
      enabled={enabled}
      steps={steps}
      initialStep={initialStep}
      onExit={onExit}
      options={{
        dontShowAgain: {
          label: "Don't show again",
          persistentCallback: () => {
            const saved = saveTourState('dont_show');
            if (saved) {
              localStorage.setItem('3sigma_tour_completed', 'true');
            }
            return saved;
          }
        },
        nextLabel: 'Next →',
        prevLabel: '← Back',
        doneLabel: 'Got it!',
        showStepNumbers: false,
        showBullets: true,
        exitOnOverlayClick: false,
        exitOnEsc: true,
        scrollToElement: true,
        tooltipClass: 'custom-tooltip',
        highlightClass: 'custom-highlight',
        buttonClass: 'custom-button',
        overlayOpacity: 0.7,
      }}
    />
  );
};

export default LeadsTour;