import { Routes, Route } from "react-router-dom";
import "antd/dist/antd.css";
import "./App.css";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Content from "./views/content/content";
import ContentDetail from "./views/content/contentDetails";
import Dashboard from "./views/dashboard/dashboard";
import FollowUps from "./views/followUps/followUps";
import Leads from "./views/leads/leads";
import Login from "./views/login";
import { useAnalytics } from "./hooks/useAnalytics";
import { trackUserAction } from "./config/firebase";
import { OtpVerification } from "./views/otpVerification";
import Signup from "./views/signup/signup";
import PrivateRoute from "./routes/privateRoute";
import Automation from "./views/automation/automation";
import WhatsappInbox from "./views/whatsappInbox/whatsappinbox";
import WhatsappBroadcast from "./views/whatsappBroadcast/whatsappbroadcast";
import CreateWhatsappBroadcast from "./views/createWhatsappBroadcast/createWhatsappBroadcast";
import UserList from "./views/userForm/userList";
import WebForm from "./views/webForm/webForm";
import Test from "./views/webForm/Test";
import Template from "./views/template";
import Profile from "./views/profile";
import SubscriptionPage from './views/Subscription/SubscriptionPage';
import { Toaster } from "react-hot-toast";
import Integrations from "./views/integrations/integrations";
import "./ag-grid-theme-builder.css";
import Modal from "react-modal";
import ActivityFeed from "./views/ActivityFeed/ActivityFeed";
import CallLogsScreen from "./components/CallLogsScreen";
import CallLogsDrawer from "./components/CallLogsDrawer";
import ActivityFeedDrawer from "./views/ActivityFeed/ActivityFeedDrawer";
import NotificationComponent from "./components/NotificationComponent";
import { useEffect } from "react";

// Set Modal Root Element
Modal.setAppElement("#root");

// Use a single googleClientId definition
const googleClientId =
  "322938904388-p7l4fso2n9g93n8irg3c9get69rn3vk4.apps.googleusercontent.com";

// Route Component
const RouteComponent = () => {
  useAnalytics();

  return (
    <>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Login />} />
        <Route path="/otp-verify" element={<OtpVerification />} />
        <Route path="/sign-up" element={<Signup />} />
        <Route path="/form/:_id" element={<WebForm />} />
        <Route path="/test" element={<Test />} />

        {/* Protected Routes */}
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/leads"
          element={
            <PrivateRoute>
              <Leads />
            </PrivateRoute>
          }
        />
        <Route
          path="/tasks"
          element={
            <PrivateRoute>
              <FollowUps />
            </PrivateRoute>
          }
        />
        <Route
          path="/content"
          element={
            <PrivateRoute>
              <Content />
            </PrivateRoute>
          }
        />
        <Route
          path="/automation"
          element={
            <PrivateRoute>
              <Automation />
            </PrivateRoute>
          }
        />
        <Route
          path="/get-details/:_id"
          element={
            <PrivateRoute>
              <ContentDetail />
            </PrivateRoute>
          }
        />
        <Route
          path="/whatsappInbox"
          element={
            <PrivateRoute>
              <WhatsappInbox />
            </PrivateRoute>
          }
        />
        <Route
          path="/whatsappBroadcast"
          element={
            <PrivateRoute>
              <WhatsappBroadcast />
            </PrivateRoute>
          }
        />
        <Route
          path="/createWhatsappBroadcast"
          element={
            <PrivateRoute>
              <CreateWhatsappBroadcast />
            </PrivateRoute>
          }
        />
        <Route
          path="/editWhatsappBroadcast/:id"
          element={
            <PrivateRoute>
              <CreateWhatsappBroadcast />
            </PrivateRoute>
          }
        />
        <Route
          path="/template"
          element={
            <PrivateRoute>
              <Template />
            </PrivateRoute>
          }
        />
        <Route
          path="/form/"
          element={
            <PrivateRoute>
              <UserList />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path="/integrations/"
          element={
            <PrivateRoute>
              <Integrations />
            </PrivateRoute>
          }
        />
        <Route
          path="/activity-feed"
          element={
            <PrivateRoute>
              <ActivityFeed />
            </PrivateRoute>
          }
        />
        <Route
          path="/SubscriptionPage"
          element={
            <PrivateRoute>
              <SubscriptionPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/call-logs"
          element={
            <PrivateRoute>
              <CallLogsScreen />
            </PrivateRoute>
          }
        />
      </Routes>
    </>
  );
};

// Main App Component
const App = () => {
  return (
    <GoogleOAuthProvider clientId={googleClientId}>
      <NotificationComponent />
      {/* Render the main routing component */}
      <RouteComponent />

      {/* Mount CallLogsDrawer globally */}
      <CallLogsDrawer />
      <ActivityFeedDrawer />
     

      {/* Single Toaster instance for entire app */}
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          duration: 4000,
        }}
      />
    </GoogleOAuthProvider>
  );
};

export default App;
