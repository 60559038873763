import React from "react";
import styled from "styled-components";

interface PropsType {
  children: React.ReactNode; // Define the children prop
}
const InputStyledComp: React.FC<PropsType> = (props) => {
  return <Root>{props.children}</Root>;
};
export default InputStyledComp;
const Root = styled.div`
  background-color: #fff;
  padding: 50px 80px;
  width: 100%;
  margin: auto;
  height: 100%;
  @media (max-width: 500px) {
    padding: 10px 46px;
  }
  input {
    width: 100%;
  }
  .field_name,
  .title {
    width: fit-content;
    font-size: 24px;
    font-weight: 600;
    color: #000;
    position: relative;
    input {
      border: none;
    }
    @media (max-width: 500px) {
        font-size: 18px;
  }
  }
  .sr_number {
    position: relative;
    span {
      position: absolute;
      left: -54px;
      top: 22px;
      transform: translateY(-50%);
      @media (max-width: 500px) {
        left: -36px;
        }
    }
    .text_danger {
      color: red;
      position: absolute;
      right: -12px;
      top: 2px;
    }
  }
  .discription {
    background: transparent;
    border: none;
    display: block;
    width: 100%;
  }
  .discription::placeholder {
    color: #000;
  }
  input:focus {
    outline: none !important;
    border-color: none;
    box-shadow: 0 0 10px #fff;
  }
  /* .input_box {
        padding: 0px 10px;
        margin-top:50px;
        width: 100%;
        border: 1px solid #000;
        border-width: 0px 0px 2px 0px;
        font-size:20px;
        font-family: "Gilroy-Regular";
        color:#000;
    } */
  input::placeholder {
    color: #0000008d;
  }
  .react-tel-input {
    margin-top: 50px;
  }
  .phone_field {
    input.phone_input_left {
      padding-left: 50px;
      margin-top: 50px;
      width: 100%;
      border: 0px solid #000;
      border-width: 0px 0px 2px 0px;
      font-size: 20px;
      /* font-family: "Gilroy-Medium"; */
      color: #000;
      border-radius: 0px;
    }
    .flag-dropdown {
      border: none;
      bottom: 4px;
    }
  }
  .dragggable_file {
    position: relative;
    margin-top: 20px;
    input[type="file"] {
      width: 100%;
      opacity: 0;
      height: 170px;
      display: block;
    }
    span {
      background-color: #3faefd40;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 10px;
      border: 1px dashed;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer;
    }
  }
  textarea {
    padding: 0px 10px;
    margin-top: 50px;
    width: 100%;
    border-radius: 8px;
    font-size: 20px;
    font-family: "Gilroy-Medium";
    color: #000;
  }
  .input_box {
    padding: 0px 10px;
    margin-top: 50px;
    width: 100%;
    border: 1px solid #000;
    border-width: 0px 0px 2px 0px;
    font-size: 20px;
    font-family: "Gilroy-Medium";
    color: #000;
    cursor: pointer;
  }

  .delete_btn {
    background: none;
    position: absolute;
    top: -4px;
    right: 12px;
    width: fit-content;
  }
  .end_screen_box {
    text-align: center;
    font-size: 38px;
  }
  .custome_date_box {
    position: relative;
    .date_input_box {
      width: 100%;
      position: absolute;
      bottom: 0px;
      opacity: 0;
    }
  }
  .all_files {
    display: flex;
    gap: 10px;
    flex-wrap:wrap;
    span {
      border: 1px solid #00000080;
      border-radius: 10px;
      padding: 0px 0px 0px 14px;
      display: flex;
      align-items: center;
    }
    button {
      background: none;
      display: flex;
      align-items: center;
      justify-content: center;
      svg{
        color: #dc1717;
      }
    }
  }
`;
