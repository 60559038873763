import axios from "axios";
import { API_URL } from "../config/config";
import { getHeaderOptions } from "./getHeaderOptions";

export const API_ENDPOINT = API_URL + "api/v1";

export const getUserPreferences = () => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.get(`${API_ENDPOINT}/user/preference`, { headers: obj });
};

export const toggleDailyEmailPreference = async (isActive: boolean) => {
  const headers = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };

  return axios.post(
    `${API_ENDPOINT}/user/manage-report-subscription`,
    {
      user: "64904fa1a4117de1310876f8",
      duration: ["daily"],
      isActive
    },
    { headers }
  );
};