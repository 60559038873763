import React, { PropsWithChildren, useEffect, useState } from "react";
import { RiCloseFill } from "react-icons/ri";
import "rsuite/dist/rsuite.min.css";
import styled from "styled-components";
import DynamicConfirmationModal from "./DynamicConfirmationModal";
interface DrawerI {
  openWithHeader: boolean;
  setOpenWithHeader: React.Dispatch<React.SetStateAction<boolean>>;
  drawerTitle: string;
  customHeader?: boolean;
  size: "lg" | "sm" | "xs" | "full";
}
const GlobalDrawer: React.FC<PropsWithChildren<DrawerI>> = ({
  openWithHeader,
  setOpenWithHeader,
  drawerTitle,
  children,
  size,
  customHeader = false,
}) => {
  const [formCloseModel, setFormCloseModel] = useState(false);

  useEffect(() => {
    if (openWithHeader) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [openWithHeader]);
  return (
    <Root className={openWithHeader ? "active" : ""}>
      <DynamicConfirmationModal
        onConfirmation={() => {
          setFormCloseModel(false);
          setOpenWithHeader(false);
        }}
        showModal={formCloseModel}
        toggleModal={() => {
          setFormCloseModel(false);
        }}
        title="Are you sure to want to Close this"
      />

      <div className="bg_action" onClick={() => setFormCloseModel(true)}></div>
      <div
        style={{
          width: size == "lg" ? "968px" : size == "sm" ? "500px" : "360px",
        }}
        className="content_card"
        // size={size}
        // enforceFocus={false}
        // open={openWithHeader}
        // onClose={() => setFormCloseModel(true)}
      >
        <div className="drawer_header">
          <h2 className="title">{drawerTitle}</h2>
          {!customHeader ? (
            <button
              onClick={() => setFormCloseModel(true)}
              className="cancel_button"
            >
              Cancel
            </button>
          ) : (
            <div className="d-flex align-items-center gap-3">
              <span onClick={() => setFormCloseModel(true)}>
                <svg
                  width="13"
                  height="16"
                  viewBox="0 0 13 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.0625 0V0.888889H0V2.66667H0.8125V14.2222C0.8125 14.6937 0.983705 15.1459 1.28845 15.4793C1.5932 15.8127 2.00652 16 2.4375 16H10.5625C10.9935 16 11.4068 15.8127 11.7115 15.4793C12.0163 15.1459 12.1875 14.6937 12.1875 14.2222V2.66667H13V0.888889H8.9375V0H4.0625ZM4.0625 4.44444H5.6875V12.4444H4.0625V4.44444ZM7.3125 4.44444H8.9375V12.4444H7.3125V4.44444Z"
                    fill="#DF3737"
                    fill-opacity="0.7"
                  />
                </svg>
              </span>
              <span
                className="close-btn"
                onClick={() => {
                  setOpenWithHeader(false);
                }}
              >
                <RiCloseFill />
              </span>
            </div>
          )}
        </div>
        <div className="content_body">{children}</div>
      </div>
    </Root>
  );
};

export default GlobalDrawer;

const Root = styled.section`
  & span {
    font-size: 24px;
    font-family: "Gilroy-Bold";
    color: #272c36;
  }

  /* Hide the cancel svg icon */
  &
    button.rs-drawer-body-close.rs-btn-icon.rs-btn-icon-placement-left.rs-btn.rs-btn-subtle.rs-btn-sm {
    display: none;
  }
  display: none;
  &.active {
    display: block;
  }
  .drawer_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 14px 10px 14px;
    background: #ffffff;
    position: sticky;
    top: 0px;
    z-index: 999;
    height: 46px;
  }

  .drawer_header div span {
    cursor: pointer;
    margin-bottom: 5px;
  }

  .bg_action {
    background: #0000000a;
    position: fixed;
    left: 0px;
    width: 100%;
    height: 100%;
    top: 0px;
    z-index: 999;
    /* backdrop-filter: blur(1px); */
    cursor: pointer;
  }
  .cancel_button {
    background: #ff4e59;
    color: #fff;
    font-family: "Gilroy-Medium";
    font-weight: 700;
    font-size: 16px;
    border-radius: 6px;
    padding: 7px 12px;
  }
  h2.title {
    font-size: 18px;
    color: #000000;
    margin: 0px;
    line-height: normal;
    font-weight: 400;
    font-family: 'Gilroy-Bold';
  }

  .content_card {
    position: fixed;
    top: 0px;
    right: -4px;
    z-index: 999;
    background: #ebf0f4;
    height: 101%;
    overflow: scroll;
    margin-right: -10px;
  }
  .content_body {
    padding: 17px 13px;
    height: 94%;
  }
  .content_body .form-label {
    font-weight: 600;
    color: #000000;
    font-family: 'Gilroy-Regular';
    font-size: 12px;
  }

  .content_body div button {
    height: 39px;
    font-weight: 600;
    color: #000000;
    font-family: 'Gilroy-Regular';
    padding: 12px 21px !important;
    line-height: 0;
  }
`;
