// src/views/whatsappInbox/components/MessageMedia/index.tsx

import React, { useState, useCallback } from 'react';
import { IoIosCloudDownload } from "react-icons/io";
import { Spin, Modal } from 'antd';
import { FileText, Video, Image as ImageIcon, X, PlayIcon } from 'lucide-react';
import styled from 'styled-components';

// Styled Components
const MediaWrapper = styled.div`
  max-width: 280px; // Slightly reduced for better fit
  border-radius: 8px;
  overflow: hidden;
  background: #f8fafc;
  margin: 4px 0;
`;

const AudioWrapper = styled.div`
  padding: 8px 12px;
  background: #ffffff;
  border-radius: 8px;
  width: 280px;

  audio {
    width: 100%;
    height: 40px;
  }

  audio::-webkit-media-controls-panel {
    background-color: #ffffff;
  }

  audio::-webkit-media-controls-play-button {
    background-color: #dcf8c6;
    border-radius: 50%;
  }

  audio::-webkit-media-controls-timeline {
    background-color: #f0f2f5;
    border-radius: 2px;
    height: 3px;
  }
`;

const MediaPlaceholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f0f2f5;
  padding: 24px;
  gap: 12px;
  min-height: 120px;
  cursor: pointer;
  
  &:hover {
    background: #e5e7eb;
  }

  .icon-wrapper {
    background: white;
    padding: 12px;
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }

  .text {
    font-size: 13px;
    color: #64748b;
    text-align: center;
  }
`;
const MediaContent = styled.div`
  position: relative;
  cursor: pointer;
`;

const ImagePreview = styled.img`
  width: 100%;
  height: auto;
  display: block;
  max-height: 300px;
  object-fit: contain;
`;

const VideoPreview = styled.video`
  width: 100%;
  height: auto;
  max-height: 300px;
  display: block;
  background: #000;
`;

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
`;

const DocumentPreview = styled.div`
  padding: 16px;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
`;

const DownloadButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: rgba(0, 0, 0, 0.05);
  color: #666;
  cursor: pointer;
  
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

const PreviewModal = styled(Modal)`
  .ant-modal-content {
    background: transparent;
    box-shadow: none;
    padding: 0;
  }
  
  .ant-modal-close {
    top: 16px;
    right: 16px;
    color: white;
    background: rgba(0,0,0,0.5);
    border-radius: 50%;
    padding: 8px;
    z-index: 1000;

    &:hover {
      background: rgba(0,0,0,0.7);
    }
  }

  .preview-content {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    
    &.document {
      background: white;
      border-radius: 8px;
      padding: 24px;
      max-height: 90vh;
      overflow-y: auto;
    }
  }
`;

const ProgressIndicator = styled.div<{ progress: number }>`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  background: #3b82f6;
  width: ${props => props.progress}%;
  transition: width 0.2s;
`;

// Types
interface MediaMessage {
  _id: string;
  messageType: 'video' | 'image' | 'document' | 'audio'; // Added audio
  downloadUrl?: string;
  message: {
    body?: string;
    id?: string;
    mime_type?: string;
    filename?: string;
    voice?: boolean; // Added voice property
  };
}

interface MessageMediaProps {
  message: MediaMessage;
  onDownload: () => Promise<void>;
  integrationInfo: any;
}

// Media cache
const mediaCache = new Map<string, string>();

export const MessageMedia: React.FC<MessageMediaProps> = ({
  message,
  onDownload,
  integrationInfo
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loadError, setLoadError] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);

  const getCachedUrl = useCallback(() => {
    return mediaCache.get(message._id);
  }, [message._id]);

  const handleMediaClick = async (e: React.MouseEvent) => {
    e.stopPropagation();
    if (message.downloadUrl) {
      handlePreview();
      return;
    }
    
    try {
      setIsLoading(true);
      setLoadError(false);
      setDownloadProgress(0);

      // Simulate progress for better UX
      const progressInterval = setInterval(() => {
        setDownloadProgress(prev => Math.min(prev + 10, 90));
      }, 200);

      await onDownload();
      
      clearInterval(progressInterval);
      setDownloadProgress(100);

      // Cache the downloaded URL
      if (message.downloadUrl) {
        mediaCache.set(message._id, message.downloadUrl);
      }

      handlePreview();
    } catch (error) {
      console.error('Media download failed:', error);
      setLoadError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePreview = () => {
    if (message.downloadUrl || getCachedUrl()) {
      setIsPreviewOpen(true);
    }
  };

  const renderMediaContent = () => {
    if (message.downloadUrl || getCachedUrl()) {
      const url = getCachedUrl() || message.downloadUrl;
      
      switch (message.messageType) {
        case 'image':
          return (
            <div className="media-preview">
              <ImagePreview
                src={url}
                alt={message.message.filename || 'Image'}
                loading="lazy"
                onClick={handlePreview}
              />
            </div>
          );
          
        case 'video':
          return (
            <div className="media-preview">
              <VideoPreview controls preload="metadata">
                <source src={url} type={message.message.mime_type} />
              </VideoPreview>
              <div className="preview-overlay" onClick={handlePreview}>
                <PlayIcon size={24} />
              </div>
            </div>
          );

          case 'audio':
    return (
      <div className="media-preview">
        <audio controls style={{ width: '100%' }}>
          <source src={url} type={message.message.mime_type} />
          Your browser does not support the audio element.
        </audio>
      </div>
    );
        case 'document':
          return (
            <DocumentPreview onClick={handlePreview}>
              <div className="doc-icon">
                <FileText size={24} />
              </div>
              <div className="doc-info">
                <span className="filename">
                  {message.message.filename || 'Document'}
                </span>
                <span className="filetype">
                  {message.message.mime_type?.split('/')[1]?.toUpperCase() || 'FILE'}
                </span>
              </div>
            </DocumentPreview>
          );
      }
    }
  
    // Better placeholder with type-specific icons
    return (
      <MediaPlaceholder onClick={handleMediaClick}>
        <div className="icon-wrapper">
          {message.messageType === 'image' && <ImageIcon size={24} color="#3b82f6" />}
          {message.messageType === 'video' && <Video size={24} color="#3b82f6" />}
          {message.messageType === 'document' && <FileText size={24} color="#3b82f6" />}
          {message.messageType === 'audio' && (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#3b82f6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
      <path d="M12 2a3 3 0 0 0-3 3v7a3 3 0 0 0 6 0V5a3 3 0 0 0-3-3Z"/>
      <path d="M19 10v2a7 7 0 0 1-14 0v-2"/>
      <line x1="12" x2="12" y1="19" y2="22"/>
    </svg>
  )}
        </div>
        <div className="text">
          Click to download {message.messageType}
        </div>
      </MediaPlaceholder>
    );
  };

  const renderPreviewContent = () => {
    const url = getCachedUrl() || message.downloadUrl;
    if (!url) return null;
  
    switch (message.messageType) {
      case 'image':
        return (
          <div className="preview-content">
            <img 
              src={url}
              alt={message.message.filename || 'Image'}
              style={{
                maxHeight: '90vh',
                maxWidth: '90vw',
                objectFit: 'contain'
              }}
            />
          </div>
        );
  
      case 'video':
        return (
          <div className="preview-content">
            <video
              src={url}
              controls
              autoPlay
              style={{
                maxHeight: '90vh',
                maxWidth: '90vw'
              }}
            />
          </div>
        );
  
      case 'document':
        if (message.message.mime_type?.includes('pdf')) {
          return (
            <div className="preview-content document">
              <iframe
                src={url}
                style={{
                  width: '100%',
                  height: '80vh',
                  border: 'none'
                }}
                title="Document Preview"
              />
            </div>
          );
        }
        return (
          <div className="preview-content document">
            <div className="download-prompt">
              <FileText size={48} className="mb-4" />
              <h3>{message.message.filename}</h3>
              <a 
                href={url}
                download
                className="download-button"
                onClick={(e) => e.stopPropagation()}
              >
                Download File
              </a>
            </div>
          </div>
        );
    }
  };

  return (
    <MediaWrapper>
      <MediaContent>
        {renderMediaContent()}
        
        {isLoading && (
          <LoadingOverlay>
            <Spin />
          </LoadingOverlay>
        )}

        {loadError && (
          <LoadingOverlay>
            <div style={{ textAlign: 'center' }}>
              <div style={{ color: '#ff4444', marginBottom: '8px' }}>
                Download failed
              </div>
              <button 
                onClick={handleMediaClick}
                style={{
                  padding: '6px 12px',
                  background: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer'
                }}
              >
                Retry
              </button>
            </div>
          </LoadingOverlay>
        )}

        {downloadProgress > 0 && downloadProgress < 100 && (
          <ProgressIndicator progress={downloadProgress} />
        )}
      </MediaContent>

      <PreviewModal
        open={isPreviewOpen}
        onCancel={() => setIsPreviewOpen(false)}
        footer={null}
        width="auto"
        centered
        closeIcon={<X className="text-white" />}
      >
        {renderPreviewContent()}
      </PreviewModal>
    </MediaWrapper>
  );
};

export default MessageMedia;