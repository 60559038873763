import React from 'react';
import { createPortal } from 'react-dom';
import { RiCloseFill } from 'react-icons/ri';
import './PopupComponent.css';

// Simple props interface
interface PopupProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title?: string;
  footer?: React.ReactNode;
  size?: 'sm' | 'md' | 'lg';
  loading?: boolean;
  headerActions?: React.ReactNode; 
}

// Quick usage example:
// const MyComponent = () => {
//   const [isOpen, setIsOpen] = useState(false);
//   return (
//     <PopupComponent 
//       isOpen={isOpen}
//       onClose={() => setIsOpen(false)}
//       title="My Popup"
//     >
//       Content here
//     </PopupComponent>
//   );
// };

const PopupComponent: React.FC<PopupProps> = ({
  isOpen,
  onClose,
  children,
  title,
  footer,
  size = 'md',
  loading = false,
  headerActions  // Add this line
}) => {
  if (!isOpen) return null;

  const content = (
    <div className="popup-backdrop" onClick={(e) => e.target === e.currentTarget && onClose()}>
      <div className={`popup-content popup-${size}`}>
        {/* Header */}
        <div className="popup-header">
  <h2>{title}</h2>
  <div className="flex items-center ml-auto"> {/* This ensures proper spacing */}
    {headerActions}
    <button className="popup-close ml-2" onClick={onClose}>
      <RiCloseFill />
    </button>
  </div>
</div>

        {/* Body */}
        <div className="popup-body">
          {loading && (
            <div className="popup-loader">
              <div className="spinner"></div>
            </div>
          )}
          {children}
        </div>

        {/* Footer */}
        {footer && (
          <div className="popup-footer">
            {footer}
          </div>
        )}
      </div>
    </div>
  );

  return createPortal(content, document.body);
};

export default PopupComponent;