// broadcast.ts
import axios from "axios";
import { API_ENDPOINT } from "./userService";
import { getHeader } from "./templates";
import type { CampaignQueryParams } from '../views/whatsappBroadcast/campaignViewTypes';

export async function getBroadCasts(data: any) {
  return axios.get(`${API_ENDPOINT}/campaign`, {
    headers: getHeader(),
    params: data,
  });
}

export async function createBroadCastFun(data: any) {
  return axios.post(`${API_ENDPOINT}/campaign`, data, {
    headers: getHeader(),
  });
}

export async function getCampaignById(id: string, params?: CampaignQueryParams) {
  // Remove undefined values from params
  const cleanParams = params ? Object.fromEntries(
    Object.entries(params).filter(([_, v]) => v !== undefined)
  ) : {};

  return axios.get(`${API_ENDPOINT}/campaign/${id}`, {
    headers: getHeader(),
    params: cleanParams
  });
}