import React, { useEffect, useState, useMemo  } from "react";
import Chart from "react-apexcharts";
import moment from "moment";
import { ApexOptions } from "apexcharts";
import { ICount } from "./dashboard";
import { Tabs } from "./dashboard.types";
import { fetchDashboardGraphData } from "../../services/dashboardService";

interface IDashboardAreaChart {
  filterFromDate: number;
  isOrganization: boolean;
  selectedUserId: string;
  selectedTeamId: string;
  tabName: Tabs;
  leadCount: ICount;
}

interface DashboardDataPoint {
  count: number;
  year: number;
  month: number;
  day: number;
}

interface APIResponse<T> {
  statusCode: number;
  status: boolean;
  data: T;
}

interface ChartDataPoint {
  x: number;
  y: number;
}

export const DashboardAreaChart: React.FC<IDashboardAreaChart> = ({
  filterFromDate,
  isOrganization,
  selectedUserId,
  selectedTeamId,
  tabName,
  leadCount,
}) => {
  const [chartData, setChartData] = useState<ChartDataPoint[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const getTabTitle = useMemo(() => {
    switch(tabName) {
      case 'lead': return 'Leads';
      case 'call': return 'Calls';
      case 'activity': return 'Activities';
      case 'sales': return 'Sales';
      default: return '';
    }
  }, [tabName]);

  const series = useMemo(() => [{
    name: getTabTitle,
    data: chartData
  }], [chartData, getTabTitle]);

  const chartOptions = useMemo((): ApexOptions => ({
    chart: {
      type: 'area',
      background: '#ffffff',
      toolbar: { show: false },
      zoom: { enabled: false }
    },
    stroke: {
      curve: 'smooth',
      width: 2
    },
    dataLabels: { enabled: false },
    xaxis: {
      type: 'datetime',
      labels: {
        formatter: (val: string) => moment(new Date(val)).format('DD MMM'),
        style: {
          fontSize: '10px',
          fontFamily: 'Gilroy, sans-serif'
        }
      },
      axisBorder: { show: false }
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '12px',
          fontFamily: 'Gilroy, sans-serif'
        }
      }
    },
    tooltip: {
      x: { format: 'dd MMM yyyy' },
      y: {
        formatter: (val: number) => `${val} ${getTabTitle}`
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 0.3,
        opacityFrom: 0.4,
        opacityTo: 0.1,
        stops: [0, 100]
      }
    },
    colors: ['#4facfe'],
    grid: {
      show: false,
      padding: { left: 0, right: 0 }
    }
  }), [getTabTitle]);

  useEffect(() => {
    let mounted = true;
    
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const fromDate = moment().subtract(filterFromDate, "d").utc().format();
        const toDate = moment().utc().format();
        
        const response = await fetchDashboardGraphData(
          fromDate,
          toDate,
          tabName,
          isOrganization,
          selectedUserId,
          selectedTeamId
        );

        if (!mounted) return;

        if (response?.status === 200 && Array.isArray(response.data.data)) {
          const formattedData = response.data.data
            .filter((item: { count?: number; year?: number; month?: number; day?: number }) => 
              item && 
              typeof item.count !== 'undefined' && 
              item.year && 
              item.month && 
              item.day
            )
            .map((item: { year: number; month: number; day: number; count: number }) => ({
              x: moment(`${item.year}-${String(item.month).padStart(2, '0')}-${String(item.day).padStart(2, '0')}`, 'YYYY-MM-DD').valueOf(),
              y: Number(item.count)
            }))
            .filter((item: { x: number; y: number }): item is ChartDataPoint => 
              !isNaN(item.x) && !isNaN(item.y)
            )
            .sort((a: ChartDataPoint, b: ChartDataPoint) => a.x - b.x);

          setChartData(formattedData);
        } else {
          setChartData([]);
        }
      } catch (err) {
        console.error("Error fetching dashboard data:", err);
        if (mounted) setChartData([]);
      } finally {
        if (mounted) setIsLoading(false);
      }
    };

    fetchData();
    return () => { mounted = false; };
  }, [filterFromDate, isOrganization, selectedUserId, selectedTeamId, tabName]);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center p-4">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (!chartData.length) {
    return (
      <div className="d-flex flex-column align-items-center justify-content-center p-4">
        <i className="fas fa-chart-line mb-3 text-muted" style={{ fontSize: '2rem' }}></i>
        <h3 className="fm-gilroy fw-bold">No Data Available</h3>
        <p className="text-muted">No activity data for selected period</p>
      </div>
    );
  }

  return (
    <Chart
      type="area"
      height={250}
      options={chartOptions}
      series={series}
    />
  );
};

export default DashboardAreaChart;