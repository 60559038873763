interface ContactLinksGeneratorProps {
  number: string;
  email?: string;
  message?: string;
}

export default class ContactLinksGenerator {
  public _number: string; // Changed from private to public
  private _email: string;
  private _message: string;

  constructor({ number, email = "", message = "" }: ContactLinksGeneratorProps) {
    // Format the phone number with the default country code if needed
    this._number = this.formatPhoneNumber(number, "+91"); // Default to +91 for India
    this._email = email;
    this._message = message;
  }

  private formatPhoneNumber(phoneNumber: string, defaultCountryCode: string): string {
    // Remove any non-numeric characters except "+"
    let cleanedNumber = phoneNumber?.replace(/[^+\d]/g, "");

    // Check if the number starts with "+" or a valid country code (like "91" for India)
    if (cleanedNumber?.startsWith("+") || cleanedNumber?.startsWith("91")) {
      return cleanedNumber; // Already has a valid country code, return as is
    }

    // Trim leading zeros only if there's no country code
    cleanedNumber = cleanedNumber?.replace(/^0+/, "");

    // If no country code is detected, add the default country code
    return `${defaultCountryCode}${cleanedNumber}`;
  }

  get tel() {
    return `tel:${this._number}`;
  }

  get chat() {
    return `sms:${this._number}?body=${encodeURIComponent(this._message)}`;
  }

  get mail() {
    return `mailto:${this._email}`;
  }

  get whatsapp() {
    // Use wa.me format and remove "+" sign from the phone number
    const whatsappNumber = this._number.replace(/\+/g, "");
    const messageQuery = this._message ? `?text=${encodeURIComponent(this._message)}` : "";
    return `https://wa.me/${whatsappNumber}${messageQuery}`;
  }
}
