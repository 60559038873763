// types.ts
import styled from "styled-components";

export const Error = styled.p`
  color: red;
  margin-left: 4px;
  margin-top: 4px;
  font-size: 12px;
`;

export interface ButtonType {
  type: string;
  text: string;
  url?: string;
  phone_number?: string;
}

// constants.ts
export const MAX_BUTTON_TEXT_LENGTH = 25;
export const MAX_URL_LENGTH = 2000;