import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import DrawerComponent from "../../components/drawer";
import { Toggle } from "rsuite";
import AutomationRuleDrawer from "./automationRuleDrawer";
import toast, { Toaster } from "react-hot-toast";
import EmptyState from "./EmptyState";
import {
  getAutomationList,
  deleteAutomation,
  updateAutomation,
} from "../../services/automationService";
import { Spin } from "antd";
import moment from "moment";
import { MdOndemandVideo, MdOutlineAdd, MdOutlineHelp } from "react-icons/md";
import { getTemplates } from "../../services/templates";
import { getContentByType } from "../../services/contentService";


interface AutomationItem {
  _id: string;
  name: string;
  description: string;
  isActive: boolean;
  lastRunAt: string | null;
  totalNoOfRun: number;
  totalAction: number;
  totalActionCompleted: number;
  totalActionFailed: number;
}

const Automation: React.FC = () => {
  const [searchText, setSearchText] = useState<string>("");
  const [autoRuleDrawer, setAutoRuleDrawer] = React.useState<boolean>(false);
  const [automationList, setAutomationList] = React.useState<AutomationItem[]>([]);
  const [updateAutomationDetail, setUpdateAutomationDetail] =
    React.useState<any>({});
  const [isUpdateAuto, setIsUpdateAuto] = useState<boolean>(false);
  const [isPageLoad, setIsPageLoad] = useState<boolean>(false);
  const [whatsappTemplate, setWhatsappTemplate] = useState<any>([]);
  const [emailTemplate, setEmailTemplate] = useState<any>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [searchType, setSearchType] = useState<string>("");

  useEffect(() => {
    async function handleDataFetch() {
      await handleGetAutomationList();
      await fetchWhatsappTemplated();
      await getEmailTemplate();
    }

    handleDataFetch();
  }, []);

  useEffect(() => {
    if (searchType === "Send Email") {
      getEmailTemplate();
    } else if (searchType === "Send Whatsapp") {
      fetchWhatsappTemplated();
    }

    if (!searchValue && !searchType) {
      getEmailTemplate();
      fetchWhatsappTemplated();
    }
  }, [searchValue, searchType]);

  const fetchWhatsappTemplated = async () => {
    const payload: any = {
      page: 1,
      perPage: 2000,
    };

    if (searchValue) {
      payload.search = searchValue;
    }

    const { data } = await getTemplates(payload);

    setWhatsappTemplate(data.data);
  };

  const getEmailTemplate = async () => {
    try {
      let response: any;

      if (searchValue) {
        response = await getContentByType("email_template", {
          search: searchValue,
        });
      } else {
        response = await getContentByType("email_template");
      }
      const data = response.data?.data;
      setEmailTemplate(data);
    } catch (error) {
      console.error("error", error);
    }
  };

  const handleGetAutomationList = async () => {
    try {
      setIsPageLoad(true);
      const response = await getAutomationList();
      setIsPageLoad(false);
      if (response && response.status) {
        setAutomationList(response.data.data);
      }
    } catch (err) {
      setIsPageLoad(false);
      console.log(err, "Error");
    }
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e?.currentTarget?.value);
    if (e.currentTarget.value.length > 1) {
      // const updatedList = StoreData?.leads?.leads?.filter((lead: any) => {
      //   return (
      //     lead?.name
      //       ?.toLowerCase()
      //       .search(e?.currentTarget?.value?.toLowerCase()) !== -1
      //   );
      // });
    }
  };
  const autoRuleDrawerToggle = () => {
    setAutoRuleDrawer(!autoRuleDrawer);
    // setUpdateAutomationDetail({});
    setIsUpdateAuto(false);
  };

  const autoRuleDrawerDeleteToggle = (data: any) => {
    setUpdateAutomationDetail(data);
    setIsUpdateAuto(true);
    setAutoRuleDrawer(!autoRuleDrawer);
  };

  const handleRuleToggle = async (data: any) => {
    try {
      let response = await updateAutomation(data._id, {
        isActive: !data.isActive,
      });
      if (response && response.status) {
        toast.success(response?.data?.message);
        handleGetAutomationList();
      }
    } catch (err) {
      toast.error("error while update rule");
    }
  };

  const onAutomationDelete = async (id: string) => {
    try {
      const res = await deleteAutomation(id);
      if (res && res.status) {
        toast.success(res?.data?.message);
        handleGetAutomationList();
        setAutoRuleDrawer(!autoRuleDrawer);
      }
    } catch (err) {
      toast.error("error while deleting rule.");
    }
  };

  const onTemplateSearch = (value: string, type: string) => {
    console.log("value>>", value, type);
    setSearchType(type);
    setSearchValue(value);
  };

  const onResetTemplates = () => {
    setSearchType("");
    setSearchValue("");
  };

  return (
    <div id="main" className="main">
    <Header onSearch={(e: any) => onSearch(e)} />
    <section className="auto-section-1">
  <label>
    Automation Rules ({automationList.length})
  </label>
  <div className="auto-section-1-actions">
    <a 
      href="https://help.3sigmacrm.com/automation/automations" 
      target="_blank" 
      rel="noopener noreferrer"
      className="help-btn"
    >
      <MdOutlineHelp size={18} />
      Help
    </a>
    <a 
      href="https://youtu.be/McvKjl6jurs" 
      target="_blank" 
      rel="noopener noreferrer"
      className="help-btn"
    >
      <MdOndemandVideo size={18} />
      Video
    </a>
    <button
      className="btn fw-bold auto_btn"
      type="button"
      onClick={() => autoRuleDrawerToggle()}
    >
      <MdOutlineAdd size={20} />
      New rule
    </button>
  </div>
</section>
  
    <Spin size="large" tip="Loading..." spinning={isPageLoad}>
      <section className="auto-section-2">
        <table className="data-table">
          <thead>
            <tr>
              <th>Rule Name</th>
              <th>Last Run</th>
              <th>Runs</th>
              <th>Completed</th>
              <th>Failed</th>
              <th>Leads Processed</th>
              <th>Success</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {automationList.length > 0 ? (
              automationList.map((item: any) => {
                const timeAgo = !item.lastRunAt ? 'Never run' : moment(item.lastRunAt).fromNow();
                const successRate = item.totalAction > 0
                  ? Math.round((item.totalActionCompleted / item.totalAction) * 100)
                  : 0;
  
                return (
                  <tr 
  key={item._id} 
  onClick={(e: React.MouseEvent<HTMLTableRowElement>) => {
    // Check if click target is the toggle switch
    const target = e.target as HTMLElement;
    if (target.closest('.rs-toggle') || target.classList.contains('rs-toggle')) {
      return;
    }
    
    console.log('Opening drawer for rule:', item.name);
    setUpdateAutomationDetail(item);
    setIsUpdateAuto(true);
    setAutoRuleDrawer(true);
  }}
  className="clickable-row"
>
                    <td>
                      <div className="name-cell">
                        <div className="primary text-truncate" title={item.name}>
                          {item.name}
                        </div>
                        <div className="secondary text-truncate" title={item.description}>
                          {item.description}
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="last-run-cell">
                        <i className="bi bi-lightning-fill"></i>
                        {timeAgo}
                      </div>
                    </td>
                    <td className="number-cell">{item.totalNoOfRun}</td>           
<td className="number-cell">{item.totalActionCompleted}</td>   
<td className="number-cell">{item.totalActionFailed}</td>      
<td className="number-cell">{item.totalLead}</td>              
                    <td className="number-cell">{successRate}%</td>
                    
                    <td>
                    <Toggle
  checked={item.isActive}
  onClick={(e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent row click
  }}
  onChange={() => {
    console.log('Toggling rule status:', item.name);
    handleRuleToggle(item);
  }}
/>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <EmptyState
                    onCreateNew={autoRuleDrawerToggle}
                    isPageLoad={isPageLoad}
                  />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </section>
    </Spin>
  
    {/* Keep your existing drawer code */}
    <div className="automatic_rule_drawer">
      <DrawerComponent
        openWithHeader={autoRuleDrawer}
        setOpenWithHeader={autoRuleDrawerToggle}
        drawerTitle="Automation Rules"
        size="lg"
      >
        <AutomationRuleDrawer
          autoRuleDrawerToggle={autoRuleDrawerToggle}
          onAutomationDelete={onAutomationDelete}
          updateAutomationDetail={updateAutomationDetail}
          isUpdateAuto={isUpdateAuto}
          handleGetAutomationList={handleGetAutomationList}
          emailTemplate={emailTemplate}
          whatsappTemplate={whatsappTemplate}
          onSearch={onTemplateSearch}
          searchValue={searchValue}
          resetTemplates={onResetTemplates}
        />
      </DrawerComponent>
    </div>
    <Toaster position="top-right" reverseOrder={false} />
  </div>  );
};

export default Automation;
