// src/config/firebase.ts

import { initializeApp, FirebaseApp } from "firebase/app";
import { getMessaging, Messaging } from "firebase/messaging";
import { Analytics, getAnalytics, logEvent } from "firebase/analytics";

export const firebaseConfig = {
  apiKey: "AIzaSyBcNWq7pVUi9n2C4JCVC8oSR2lX3l546ZQ",
  authDomain: "sigma-8207c.firebaseapp.com",
  databaseURL: "https://sigma-8207c.firebaseio.com",
  projectId: "sigma-8207c",
  storageBucket: "sigma-8207c.appspot.com",
  messagingSenderId: "322938904388",
  appId: "1:322938904388:web:2c93949fee2f770b418111",
  measurementId: "G-W8X2WG5LJY",
};

let firebaseApp: FirebaseApp | null = null;
let messagingInstance: Messaging | null = null;
let analyticsInstance: Analytics | null = null;

// Enable this flag to see detailed analytics logs
const ANALYTICS_DEBUG = process.env.NODE_ENV === "development";


const logAnalyticsEvent = (message: string, data?: any) => {
  if (ANALYTICS_DEBUG) {
    console.group("🔍 Firebase Analytics Event");
    console.log("Event:", message);
    if (data) {
      console.log("Data:", data);
    }
    console.log("Timestamp:", new Date().toISOString());
    console.groupEnd();
  }
};

const initializeFirebase = () => {
  try {
    if (!firebaseApp) {
      console.log("📱 Initializing Firebase...");
      firebaseApp = initializeApp(firebaseConfig);
      messagingInstance = getMessaging(firebaseApp);
      analyticsInstance = getAnalytics(firebaseApp);
      console.log("✅ Firebase initialized successfully");
    }
    return {
      app: firebaseApp,
      messaging: messagingInstance,
      analytics: analyticsInstance,
    };
  } catch (error) {
    console.error("❌ Firebase initialization failed:", error);
    return {
      app: null,
      messaging: null,
      analytics: null,
    };
  }
};

// Analytics utility functions
export const trackEvent = (
  eventName: string,
  eventParams?: Record<string, any>
) => {
  try {
    if (analyticsInstance) {
      logEvent(analyticsInstance, eventName, eventParams);
      logAnalyticsEvent(`Event tracked: ${eventName}`, eventParams);
    } else {
      console.warn(
        "⚠️ Analytics not initialized. Event not tracked:",
        eventName
      );
    }
  } catch (error) {
    console.error("❌ Error tracking event:", eventName, error);
  }
};

export const trackPageView = (
  pageName: string,
  pageParams?: Record<string, any>
) => {
  try {
    const pageViewData = {
      page_title: pageName,
      page_location: window.location.href,
      page_path: window.location.pathname,
      ...pageParams,
    };

    trackEvent("page_view", pageViewData);
    logAnalyticsEvent(`Page view tracked: ${pageName}`, pageViewData);
  } catch (error) {
    console.error("❌ Error tracking page view:", pageName, error);
  }
};

export const trackUserAction = (
  actionName: string,
  actionParams?: Record<string, any>
) => {
  try {
    const actionData = {
      action_name: actionName,
      timestamp: new Date().toISOString(),
      ...actionParams,
    };

    trackEvent("user_action", actionData);
    logAnalyticsEvent(`User action tracked: ${actionName}`, actionData);
  } catch (error) {
    console.error("❌ Error tracking user action:", actionName, error);
  }
};

// Initialize firebase and export instances
const { app, messaging, analytics } = initializeFirebase();

export { app, messaging, analytics };
