import React, { FC, useEffect, useState } from "react";
import { getLeadDetail } from "../../services/leadService";
import { useSelector } from "react-redux";
import { MdOutlineEdit } from "react-icons/md";
import { IoDocumentOutline } from "react-icons/io5";


interface InformationProps {
  leadToBeUpdate: string;
  onEdit?: () => void;
}

const Information: FC<InformationProps> = ({ leadToBeUpdate, onEdit }) => {
  const stateData = useSelector((state: any) => state?.rootReducers);
  const [leadData, setLeadData] = useState<Record<string, any>>({});
  const [customFields, setCustomFields] = useState<Record<string, any>>({});

  useEffect(() => {
    fetchLeadDetails();
  }, []);

  const fetchLeadDetails = async () => {
    try {
      const { data } = await getLeadDetail(leadToBeUpdate);
      const extraDetails = data.data.extraDetails || {};

      // Safely get filter values, with fallback to empty array
      const filterValues = stateData?.userData?.userDetails?.userPreference?.customForm?.map(
        ({ value }: { value: string }) => value
      ) || [];

      const processedFields = processExtraDetails(extraDetails);

      const customFieldsData: Record<string, any> = {};
      const leadFieldsData: Record<string, any> = {};

      // Safely process fields
      Object.entries(processedFields).forEach(([key, value]) => {
        try {
          const originalKey = key.toLowerCase().replace(/ /g, "_");
          if (filterValues.includes(originalKey)) {
            customFieldsData[key] = value;
          } else {
            leadFieldsData[key] = value;
          }
        } catch (error) {
          console.error(`Error processing field ${key}:`, error);
        }
      });

      setLeadData(leadFieldsData);
      setCustomFields(customFieldsData);
    } catch (error) {
      console.error("Error fetching lead details:", error);
      // Set empty objects to prevent UI crashes
      setLeadData({});
      setCustomFields({});
    }
  };

  const processExtraDetails = (details: Record<string, any>): Record<string, any> => {
    const processed: Record<string, any> = {};
    
    try {
      Object.entries(details).forEach(([key, value]) => {
        // Skip null/undefined keys
        if (!key) return;

        try {
          const formattedKey = key
            .replace(/_/g, " ")
            .replace(/\?$/, "")
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");

          // Handle field_data array specially
          if (key === "field_data" && Array.isArray(value)) {
            value.forEach((field: any) => {
              if (field && typeof field === "object" && field.name) {
                const fieldKey = field.name
                  .replace(/_/g, " ")
                  .split(" ")
                  .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" ");
                processed[fieldKey] = formatValue(field.values);
              }
            });
          } else {
            processed[formattedKey] = formatValue(value);
          }
        } catch (error) {
          console.error(`Error processing key ${key}:`, error);
          processed[key] = "-"; // Fallback for errored fields
        }
      });
    } catch (error) {
      console.error("Error in processExtraDetails:", error);
    }

    return processed;
  };

  const formatValue = (value: any): string | React.ReactNode => {
    const MAX_LENGTH = 200;

    try {
      // Handle null/undefined
      if (value === null || value === undefined) return "-";

      // Handle arrays
      if (Array.isArray(value)) {
        if (value.length === 0) return "-";
        
        // Check if array contains objects
        if (value.some(item => typeof item === "object" && item !== null)) {
          try {
            return (
              <pre
                style={{
                  background: "#f9f9f9",
                  padding: "8px",
                  borderRadius: "6px",
                  maxHeight: "150px",
                  overflow: "auto",
                  whiteSpace: "pre-wrap",
                  wordWrap: "break-word",
                  maxWidth: "100%",
                }}
              >
                {JSON.stringify(value, null, 2)}
              </pre>
            );
          } catch (error) {
            console.error("Error stringifying array of objects:", error);
            return "-";
          }
        }

        // Regular array of primitives
        return (
          <ul style={{ paddingLeft: "20px", wordWrap: "break-word" }}>
            {value.map((item, idx) => (
              <li key={idx} style={{ marginBottom: "4px", wordWrap: "break-word" }}>
                {String(item)}
              </li>
            ))}
          </ul>
        );
      }

      // Handle objects
      if (typeof value === "object" && value !== null) {
        try {
          return (
            <pre
              style={{
                background: "#f9f9f9",
                padding: "8px",
                borderRadius: "6px",
                maxHeight: "150px",
                overflow: "auto",
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
                maxWidth: "100%",
              }}
            >
              {JSON.stringify(value, null, 2)}
            </pre>
          );
        } catch (error) {
          console.error("Error stringifying object:", error);
          return "-";
        }
      }

      // Handle boolean
      if (typeof value === "boolean") return value ? "Yes" : "No";

      // Handle numbers
      if (typeof value === "number") return value.toLocaleString();

      // Handle strings
      if (typeof value === "string") {
        if (value.length > MAX_LENGTH) {
          return <ExpandableText text={value} maxLength={MAX_LENGTH} />;
        }

        if (value.startsWith("http") || value.startsWith("www")) {
          return (
            <a
              href={value}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: "#1a73e8", wordWrap: "break-word" }}
            >
              {value}
            </a>
          );
        }

        return value || "-";
      }

      // Fallback for any other type
      return String(value) || "-";

    } catch (error) {
      console.error("Error in formatValue:", error);
      return "-";
    }
  };

  const EmptyState: FC<{ message: string }> = ({ message }) => (
    <div style={{ textAlign: "center", padding: "20px", color: "#6b7280" }}>
      <IoDocumentOutline size={40} style={{ marginBottom: "8px", color: "#9ca3af" }} />
      <p style={{ fontSize: "14px", fontWeight: 500 }}>{message}</p>
    </div>
  );

  const ExpandableText: FC<{ text: string; maxLength: number }> = ({ text, maxLength }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    return (
      <div>
        {isExpanded ? text : `${text.slice(0, maxLength)}...`}
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          style={{
            background: "none",
            border: "none",
            color: "#1a73e8",
            cursor: "pointer",
            padding: "0",
            marginLeft: "8px",
            fontSize: "14px",
          }}
        >
          {isExpanded ? "Show Less" : "See More"}
        </button>
      </div>
    );
  };

  return (
    <div
      style={{
        padding: "20px",
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        boxShadow: "0 2px 6px rgba(0,0,0,0.1)",
      }}
    >
      {/* Leadform Fields Section */}
      <div style={{ marginBottom: "20px" }}>
        <h5
          style={{
            fontSize: "18px",
            fontWeight: 600,
            color: "#1a202c",
            marginBottom: "12px",
            borderBottom: "2px solid #e5e7eb",
            paddingBottom: "6px",
          }}
        >
          Leadform Fields
        </h5>
        {Object.keys(customFields).length > 0 ? (
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <tbody>
              {Object.entries(customFields).map(([key, value], index) => (
                <tr
                  key={index}
                  style={{
                    background: index % 2 === 0 ? "#f9f9f9" : "#fff",
                    borderBottom: "1px solid #e5e7eb",
                  }}
                >
                  <td
                    style={{
                      padding: "10px",
                      fontWeight: 500,
                      color: "#4a5568",
                      width: "40%",
                      fontSize: "14px",
                    }}
                  >
                    {key}
                  </td>
                  <td
                    style={{
                      padding: "10px",
                      color: "#2d3748",
                      fontSize: "14px",
                    }}
                  >
                    {value}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <EmptyState message="No Leadform Fields Available" />
        )}
      </div>

      {/* General Fields Section */}
      <h5
        style={{
          fontSize: "18px",
          fontWeight: 600,
          color: "#1a202c",
          marginBottom: "12px",
          borderBottom: "2px solid #e5e7eb",
          paddingBottom: "6px",
        }}
      >
        General Information
      </h5>
      {Object.keys(leadData).length > 0 ? (
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <tbody>
            {Object.entries(leadData).map(([key, value], index) => (
              <tr
                key={index}
                style={{
                  background: index % 2 === 0 ? "#f9f9f9" : "#fff",
                  borderBottom: "1px solid #e5e7eb",
                }}
              >
                <td
                  style={{
                    padding: "10px",
                    fontWeight: 500,
                    color: "#4a5568",
                    width: "40%",
                    fontSize: "14px",
                  }}
                >
                  {key}
                </td>
                <td
                  style={{
                    padding: "10px",
                    color: "#2d3748",
                    fontSize: "14px",
                  }}
                >
                  {value}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <EmptyState message="No General Information Available" />
      )}
    </div>
  );
};

export default Information;