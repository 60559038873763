// src/components/NotificationComponent.tsx
import React, { useEffect } from 'react';
import { NotificationService } from '../services/NotificationService';

const NotificationComponent: React.FC = () => {
  useEffect(() => {
    const initializeNotifications = async () => {
      try {
        const service = NotificationService.getInstance();
        await service.initialize();
        
        if (Notification.permission === 'default') {
          await Notification.requestPermission();
        }
      } catch (err) {
        console.error('Notification initialization failed:', err);
      }
    };

    initializeNotifications();
  }, []);

  return null;
};

export default NotificationComponent;