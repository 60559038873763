import { Card, CardBody, CardHeader } from "reactstrap";
import ImgTag from "../../components/ImgTag";
import callIcon from "../../assets/images/icons/call.png";
import crownIcon from "../../assets/images/icons/crown.png";
import incomingIcon from "../../assets/images/icons/incoming.png";
import outgoinfIcon from "../../assets/images/icons/outgoing.png";

import RangeDatePicker from "../../components/RangeDatePicker";
import { DateRange } from "./dashboard";

interface IDashboardCallLeaderBoard {
  title: string;
  tableData: TableData[];
  callBackFun: Function;
  dateRangeValue: DateRange;
}

interface typeArray {
  type: string;
  count: number;
  duration: number;
}

interface profileObject {
  filePath: string;
  fileName: string;
  uploadedBy: string;
  uploadedAt: string;
  fileSize: string;
}

export interface TableData {
  user: string;
  profile: profileObject;
  types: typeArray[];
}

const DashboardCallLeaderBoard = ({
  title,
  tableData,
  callBackFun,
  dateRangeValue,
}: IDashboardCallLeaderBoard) => {
  return (
    <Card>
      <CardHeader className="d-flex align-items-center justify-content-between">
        <DashboardCallLeaderBoardHeader title={title} />
        <RangeDatePicker
          dateRangeValue={dateRangeValue}
          callBackFun={callBackFun}
        />
      </CardHeader>
      <CardBody>
        <div className="table-responsive">
          <table className="table table-borderless vertical-middle custom-table">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col"></th>
                <th scope="col">
                  <p className="p-2 bg-transparent flextabletd rounded-15  px-3">
                    <img src={callIcon} className="me-1" /> Total
                  </p>
                </th>
                <th scope="col">
                  <p className="p-2 bg-transparent flextabletd rounded-15  px-3">
                    <img src={outgoinfIcon} className="me-1" /> Outgoing
                  </p>
                </th>
                <th scope="col">
                  <p className="p-2 bg-transparent flextabletd rounded-15  px-3">
                    <img src={incomingIcon} className="me-1" /> Incoming
                  </p>
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData.length !== 0 ? (
                tableData.map((item, key) => {
                  const totalCtn = item.types
                    .map((item) => item.count)
                    .reduce((total, currentValue) => total + currentValue, 0);
                  const outgoinCtn = item.types
                    .map((item) => (item.type === "OUTGOING" ? item.count : 0))
                    .reduce((total, currentValue) => total + currentValue, 0);
                  const incomingCtn = item.types
                    .map((item) => (item.type !== "OUTGOING" ? item.count : 0))
                    .reduce((total, currentValue) => total + currentValue, 0);
                  const baseURL = "";
                  return (
                    <tr key={key}>
                      <td>
                        <div className="d-flex align-items-center">
                          {key === 0 ? (
                            <h3 className="crowntext d-flex align-items-center">
                              1 <img className="ms-1" src={crownIcon} />
                            </h3>
                          ) : (
                            <h4 className="ms-2">{key + 1}</h4>
                          )}

                          {item?.profile?.filePath ? (
                            <ImgTag
                              src={baseURL + item?.profile?.filePath}
                              className="avatar-md rounded border rounded-circle object-fit-cover mx-3"
                            />
                          ) : null}
                        </div>
                      </td>

                      <td style={{ minWidth: 150 }}>
                        <h5 className="mb-0 font-smalls fm-gilroy">{item.user}</h5>
                      </td>
                      <td>
                        <p className="mb-0 font-smalls fw-bold">{totalCtn}</p>
                      </td>
                      <td>
                        <p className="mb-0 font-smalls fw-bold">{outgoinCtn}</p>
                      </td>
                      <td>
                        <p className="mb-0 font-smalls fw-bold">{incomingCtn}</p>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={4} className="text-center fw-bold rounded">
                    <h2 className="no_data_found my-0">No data yet.</h2>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </CardBody>
    </Card>
  );
};

export default DashboardCallLeaderBoard;

interface IDashboardCallLeaderBoardHeader {
  title: string;
}

const DashboardCallLeaderBoardHeader = ({
  title,
}: IDashboardCallLeaderBoardHeader) => {
  return (
    <div className="d-flex align-items-center">
      <div className="fw-bold text-dark d-flex flex-column align-items-center fm-gilroy">
        <span>{title}</span>
      </div>
    </div>
  );
};
