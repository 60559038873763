import React, { useState, useEffect } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { Spin, notification } from 'antd';
import axios from 'axios';

interface GoogleCalendarIntegrationProps {
  token: string;
  userId: string;
  onSuccess: () => void;
  onError: (error: string) => void;
}

const GoogleCalendarIntegration: React.FC<GoogleCalendarIntegrationProps> = ({
  token,
  userId,
  onSuccess,
  onError,
}) => {
  const [loading, setLoading] = useState(true);
  const [isConnected, setIsConnected] = useState(false);

  const checkCalendarStatus = async () => {
    try {
      // Force a new API call (prevent caching)
      const timestamp = new Date().getTime();
      // Add debug log for token
      console.log('Using token:', token);

      const response = await fetch(
        'https://mapi2.3sigmacrm.com/api/v1/user/google-token-and-status?' + timestamp,
        {
          method: 'GET',
          headers: {
            'Authorization': `${token}`,
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache'
          },
        }
      );

      console.log('Raw Response:', response);
      const data = await response.json();
      console.log('Response Data:', data);

      if (data.status && data.data) {
        const hasCalendarScope = data.data.scope?.includes('https://www.googleapis.com/auth/calendar.events.owned');
        setIsConnected(data.data.status === 'active' && hasCalendarScope);
      }
    } catch (error) {
      console.error('Error checking calendar status:', error);
      setIsConnected(false);
    } finally {
      setLoading(false);
    }
  };

  // Initial check on mount
  useEffect(() => {
    const initCheck = async () => {
      if (token) {
        console.log('Initiating calendar status check with token');
        await checkCalendarStatus();
      } else {
        console.log('No token available');
        setLoading(false);
      }
    };

    initCheck();
  }, [token]);

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/calendar.events.owned',
    onSuccess: async (codeResponse) => {
      try {
        setLoading(true);
        const response = await fetch(
          'https://mapi2.3sigmacrm.com/api/v1/user/get-google-tokens',
          {
            method: 'POST',
            headers: {
              'Authorization': `${token}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ code: codeResponse.code })
          }
        );

        const data = await response.json();
        if (data.status) {
          await checkCalendarStatus();
          notification.success({
            message: 'Calendar connected successfully!'
          });
          onSuccess();
        }
      } catch (error) {
        console.error('Google login failed:', error);
        notification.error({
          message: 'Connection failed'
        });
        onError('Connection failed');
      } finally {
        setLoading(false);
      }
    }
  });

  return (
    <div style={{ 
      width: '100%',
      maxWidth: '600px',
      margin: '40px auto',
      padding: '30px',
      background: 'white',
      borderRadius: '8px',
      boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
      textAlign: 'center'
    }}>
      <div style={{ 
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '30px'
      }}>
        <img 
  src={require('../../assets/images/integration/googlecalender.png')}
  alt="Google Calendar"
  style={{
    width: '28px',
    height: '28px',
    marginRight: '15px'
  }}
/>
        <h2 style={{ 
          margin: 0,
          fontSize: '24px',
          color: '#333'
        }}>
          Google Calendar
        </h2>
      </div>

      {loading ? (
  <Spin />
) : isConnected ? (
  <div>
    <div style={{ 
      marginBottom: '20px', 
      color: '#52c41a',
      fontSize: '16px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '8px'
    }}>
      <span style={{ color: '#52c41a' }}>✓</span>
      Your Google Calendar is connected and working properly
    </div>
    <p style={{ 
      color: '#666',
      fontSize: '15px'
    }}>
      All your tasks will be automatically synced with your Google Calendar.
    </p>
    <div style={{ textAlign: 'center', marginTop: '20px' }}>
      <button
        onClick={async () => {
          try {
            setLoading(true);
            const response = await fetch(
              'https://mapi2.3sigmacrm.com/api/v1/integration/user-integration/google-disconnect',
              {
                method: 'PUT',
                headers: {
                  'Authorization': `${token}`,
                  'Content-Type': 'application/json'
                },
                body: JSON.stringify({ 
                  scope: 'https://www.googleapis.com/auth/calendar.events.owned'
                })
              }
            );

            const data = await response.json();
            if (data.status) {
              setIsConnected(false);
              notification.success({
                message: 'Calendar disconnected successfully!'
              });
              onSuccess();
            }
          } catch (error) {
            console.error('Disconnect failed:', error);
            notification.error({
              message: 'Failed to disconnect Calendar'
            });
            onError('Disconnect failed');
          } finally {
            setLoading(false);
          }
        }}
        style={{
          padding: '8px 16px',
          backgroundColor: 'transparent',
          color: '#4285F4',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
          fontSize: '14px',
          fontWeight: '500'
        }}
      >
        Disconnect Calendar
      </button>
    </div>
  </div>
) : (
  <div>
    <p style={{ 
      marginBottom: '25px',
      color: '#666',
      fontSize: '15px'
    }}>
      Connect your Google Calendar to sync your tasks automatically.
    </p>
    <button
      onClick={() => googleLogin()}
      style={{
        padding: '12px 24px',
        backgroundColor: '#4285F4',
        color: 'white',
        border: 'none',
        borderRadius: '6px',
        cursor: 'pointer',
        fontSize: '15px',
        fontWeight: '500',
        transition: 'background-color 0.2s'
      }}
    >
      Connect with Google Calendar
    </button>
  </div>
)}
    </div>
  );
};

export default GoogleCalendarIntegration;