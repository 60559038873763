import { Card, CardBody, CardHeader } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import RangeDatePicker from "../../components/RangeDatePicker";
import { DateRange } from "./dashboard";
import styled from "styled-components";

interface IDashboardPieChart {
  title: string;
  pieChartData: PieChartData[];
  legendPosition?: "top" | "right" | "bottom" | "left";
  chartHeight?: number;
  callBackFun: Function;
  dateRangeValue: DateRange;
}

export interface PieChartData {
  label: string;
  value: number;
}

export interface CallLeaderBoard {
  label: string;
  value: number;
}

const DashboardPieChart = ({
  title,
  pieChartData,
  chartHeight,
  legendPosition,
  callBackFun,
  dateRangeValue,
}: IDashboardPieChart) => {
  const chartOptions: ApexOptions = {
    chart: {
      type: 'pie',
      background: 'transparent',
      fontFamily: 'Gilroy, sans-serif',
    },
    labels: pieChartData?.length ? 
      pieChartData.map((data: PieChartData) => `${data.label} - ${data.value}`) : 
      ['No Data'],
    series: pieChartData?.length ? 
      pieChartData.map((data: PieChartData) => data.value) : 
      [0],
    noData: {
      text: 'No data available',
      align: 'center',
      verticalAlign: 'middle',
      style: {
        fontSize: '14px',
        fontFamily: 'Gilroy-Medium, sans-serif'
      }
    }
  };

  let isCount = pieChartData?.some((e: any) => e?.rate);

  return (
    <Card>
      <CardHeader className="d-flex align-items-center justify-content-between">
        <DashboardPieChartHeader title={title} />
        <RangeDatePicker
          dateRangeValue={dateRangeValue}
          callBackFun={callBackFun}
        />
      </CardHeader>
      <CardBody className="p-0">
        {pieChartData
          .map((data) => data.value)
          .reduce((total, currentValue) => total + currentValue, 0) <= 0 ? (
          <h2 className="no_data_found">No data yet.</h2>
        ) : (
          <>
            <div style={{ width: "100%" }}>
            <ReactApexChart
  options={chartOptions}
  series={chartOptions.series}
  type="pie"
  height={chartHeight || 210}
  className="apex-pie-chart"
/>
            </div>

            {isCount && (
  <div className="table-responsive px-3 mt-3" style={{ maxHeight: '300px', overflowY: 'auto' }}>
                <table className="table">
                  <thead>
                    <tr>
                      <th className="fm-gilroy">Source</th>
                      <th className="text-end fm-gilroy">Count</th>
                      <th className="text-end fm-gilroy">Conversion Rate</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pieChartData?.map((e: any, i: number) => (
                      <tr key={`metrics-${i}`}>
                        <td className="font-smalls fm-gilroy">{e?.label}</td>
                        <td className="text-end font-smalls fm-gilroy">
                          {new Intl.NumberFormat().format(e?.value)}
                        </td>
                        <td className="text-end font-smalls fm-gilroy">
                          {typeof e?.rate === 'number' ? `${e?.rate.toFixed(1)}%` : 'N/A'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default DashboardPieChart;

interface IDashboardPieChartHeader {
  title: string;
}

const DashboardPieChartHeader = ({ title }: IDashboardPieChartHeader) => {
  return (
    <div className="d-flex align-items-center">
      <div className="fw-bold text-dark d-flex flex-column align-items-center fm-gilroy">
        <span>{title}</span>
      </div>
    </div>
  );
};

const Heading = styled.h4`
  color: #000;
  font-size: 12px;
  font-family: "Gilroy-Bold";
`;

const ConversionContainer = styled.div`
  display: flex;
  flex-direction: column;

  span {
    color: #000;
    font-size: 12px;
    font-family: "Gilroy-Bold";
  }
`;