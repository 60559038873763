import {
  LEAD_COUNT,
  RESET_LEAD_FILER,
  SET_LEADS,
  SET_LEAD_FILTER,
  SET_LEAD_ID,
} from "../actions/actionTypes";
import {
  FilterParams,
  advanceFilterKeys,
} from "../views/leads/leadsGrid/leads.types";

const activityFilter = undefined;
const assign = undefined;
const byOrganization = undefined;
const customFieldFilter = undefined;
const date = undefined;
const isFollowUp = undefined;
const isNotAssign = undefined;
const isNotCalled = undefined;
const isUntouched = undefined;
const label = undefined;
const note = undefined;
const paginationParams = { perPage: 50, page: 1 };
const search = undefined;
const sort = { orderBy: undefined, isAscending: undefined };
const source = undefined;
const status = undefined;
const taskFilter = undefined;
const teamMembers = undefined;
const teams = undefined;

const initialFilterParams = {
  activityFilter,
  assign,
  byOrganization,
  customFieldFilter,
  date,
  isFollowUp,
  isNotAssign,
  isNotCalled,
  isUntouched,
  label,
  note,
  paginationParams,
  search,
  sort,
  source,
  status,
  taskFilter,
  teamMembers,
  teams,
};

interface LeadStateI {
  leads: any;
  filterParam: FilterParams;
  leadCount: number;
  isLoading: boolean;
  selectedLeadIds: string[];
}

const leadsInitialState = {
  leads: [],
  isLoading: true,
  leadCount: 0,
  filterParam: initialFilterParams,
  selectedLeadIds: [],
};

const leadReducer = (
  state: LeadStateI = leadsInitialState,
  action: { type: string; payload: any }
): LeadStateI => {
  switch (action.type) {
    case SET_LEADS:
      return {
        ...state,
        leads: action.payload,
        isLoading: false,
      };
    case SET_LEAD_FILTER:
      const advanceFilters = advanceFilterKeys.reduce((acc: any, key) => {
        acc[key] = action.payload[key];
        return acc;
      }, {});

      const filterParam = {
        ...state.filterParam,
        // ...advanceFilters,
        ...action.payload,
      };
      return {
        ...state,
        filterParam,
      };
    case RESET_LEAD_FILER:
      return {
        ...state,
        filterParam: {},
      };
    case LEAD_COUNT:
      return {
        ...state,
        leadCount: action.payload,
      };
    case SET_LEAD_ID:
      return {
        ...state,
        selectedLeadIds: action.payload,
      };
    default:
      return state;
  }
};

export default leadReducer;
