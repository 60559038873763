import React, { useState, useEffect, useCallback } from 'react';
import { Drawer, Input, List, Spin, Button, message } from 'antd';
import { SearchOutlined, ReloadOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';
import InfiniteScroll from 'react-infinite-scroll-component';
import callLogsDrawerManager from './CallLogsDrawerManager';
import {
  getCallLogs,
  CallLog,
  PaginationQuery,
  PaginatedResult,
} from '../services/callLogsService';
import CallLogItem from './CallLogItem';
import './CallLogsDrawer.css';

const CallLogsDrawer: React.FC = () => {
  // States
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [callLogs, setCallLogs] = useState<CallLog[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');

  // Fetch logs function
  const fetchCallLogs = async (page: number, isRefresh: boolean = false, search?: string) => {
    console.log('⭐ fetchCallLogs Called:', { 
      page, 
      isRefresh, 
      currentLogsLength: callLogs.length,
      search 
    });
    
    try {
      setIsRefreshing(true);
      
      const response = await getCallLogs({
        page,
        perPage: 10,
        search: search?.trim()
      });

      const newData = response.data;
      const totalRecords = response.total;
      const currentCount = isRefresh ? newData.length : callLogs.length + newData.length;
      const shouldHaveMore = totalRecords > currentCount;

      console.log('📊 Pagination Status:', {
        newDataLength: newData.length,
        currentCount,
        totalRecords,
        shouldHaveMore,
        page
      });

      // Update data
      if (isRefresh) {
        setCallLogs(newData);
      } else {
        setCallLogs(prevLogs => [...prevLogs, ...newData]);
      }

      setHasMore(shouldHaveMore);
      setCurrentPage(page);

    } catch (error) {
      console.error('❌ Error:', error);
      message.error('Failed to fetch call logs');
    } finally {
      setIsRefreshing(false);
    }
  };

  // Fetch more data for infinite scroll
  const fetchMoreData = useCallback(() => {
    console.log('📑 fetchMoreData Called:', {
      currentPage,
      hasMore,
      isRefreshing,
      currentLogsLength: callLogs.length
    });
    
    if (!hasMore || isRefreshing) {
      console.log('⏸ Skip fetchMore:', { hasMore, isRefreshing });
      return;
    }
    
    fetchCallLogs(currentPage + 1, false, searchText);
  }, [currentPage, hasMore, isRefreshing, callLogs.length, searchText]);

  // Handle drawer open
  const openDrawer = useCallback(() => {
    console.log('🔓 Opening drawer');
    setDrawerVisible(true);
    setCurrentPage(1);
    fetchCallLogs(1, true, searchText);
  }, [searchText]);

  // Handle drawer close
  const closeDrawer = useCallback(() => {
    console.log('🔒 Closing drawer');
    setDrawerVisible(false);
  }, []);

  // Register with drawer manager
  useEffect(() => {
    console.log('🔌 Registering drawer opener');
    callLogsDrawerManager.registerOpenDrawer(openDrawer);
    return () => {
      console.log('🔌 Unregistering drawer opener');
      callLogsDrawerManager.unregisterOpenDrawer();
    };
  }, [openDrawer]);

  // Handle search with debounce
  const debouncedSearch = useCallback(
    debounce((value: string) => {
      console.log('🔍 Searching:', value);
      setCurrentPage(1);
      fetchCallLogs(1, true, value);
    }, 500),
    []
  );

  useEffect(() => {
    if (drawerVisible && searchText.trim() !== '') {
      debouncedSearch(searchText);
    }
    return () => debouncedSearch.cancel();
  }, [searchText, drawerVisible, debouncedSearch]);

  // Handle refresh
  const handleRefresh = useCallback(() => {
    console.log('🔄 Refreshing data');
    setSearchText('');
    setCurrentPage(1);
    fetchCallLogs(1, true);
  }, []);

  // Scroll tracking
  useEffect(() => {
    const scrollContainer = document.getElementById('drawerScroll');
    if (!scrollContainer) return;

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = scrollContainer;
      console.log('📜 Scroll Position:', {
        scrollTop,
        scrollHeight,
        clientHeight,
        remainingScroll: scrollHeight - scrollTop - clientHeight,
        hasMore,
        isRefreshing
      });

      // Near bottom check
      if (scrollHeight - scrollTop - clientHeight < 100 && hasMore && !isRefreshing) {
        console.log('🔄 Triggering fetch from scroll');
        fetchMoreData();
      }
    };

    scrollContainer.addEventListener('scroll', handleScroll);
    return () => scrollContainer.removeEventListener('scroll', handleScroll);
  }, [hasMore, isRefreshing, fetchMoreData]);

  return (
    <Drawer
      title="Call Logs"
      placement="right"
      onClose={closeDrawer}
      visible={drawerVisible}
      width={600}
    >
      <div 
        id="drawerScroll" 
        style={{ 
          height: 'calc(100vh - 55px)',
          overflowY: 'auto',
          position: 'relative',
          padding: '20px'
        }}
      >
        <div className="search-container">
          <Input
            placeholder="Search by name or phone number"
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            allowClear
            className="search-input"
          />
          <Button
            icon={<ReloadOutlined />}
            onClick={handleRefresh}
            className="refresh-button"
            loading={isRefreshing}
          >
            Refresh
          </Button>
        </div>

        <InfiniteScroll
          dataLength={callLogs.length}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={
            <div className="loader">
              <Spin tip="Loading more..." />
            </div>
          }
          scrollableTarget="drawerScroll"
          scrollThreshold={0.8}
          style={{ overflow: 'visible' }}
        >
          <List
            dataSource={callLogs}
            renderItem={(item) => <CallLogItem key={item._id} log={item} />}
            className="call-logs-list"
          />
        </InfiniteScroll>

        {callLogs.length === 0 && !isRefreshing && (
          <div className="empty-state">
            <h4>No Call Logs Found</h4>
            <p>Try adjusting your search criteria.</p>
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default CallLogsDrawer;