import React, { PropsWithChildren, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { setNote } from "../actions/actions";
import { createNewNote } from "../services/noteService";
import ErrorText from "./errorText";
interface Props {
  leadIds: Array<{ id: string | undefined }>;
  handleNotesSubmit: (data: any) => void;
}

const CreateNoteForm: React.FC<PropsWithChildren<Props>> = ({
  leadIds,
  handleNotesSubmit,
}) => {
  const [message, setMessage] = useState<string>("");
  const [errors, setErrors] = useState<{ note: string }>({
    note: "",
  });

  const dispatch = useDispatch();
  const StateData = useSelector((state: any) => {
    return state?.rootReducers?.note?.notes;
  });
  const onTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
    setErrors({ note: "" });
  };

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (message === "") {
      setErrors({
        note: "Note is required.",
      });
    } else {
      try {
        const response = await createNewNote({
          lead: leadIds[0]?.id,
          description: message,
        });

        if (response && response.status) {
          const responseData = response?.data;
          const tempArray = [...StateData];
          tempArray?.unshift(responseData?.data);
          dispatch(setNote(tempArray));
          setMessage("");
          handleNotesSubmit(response?.data?.lead);
          toast.success(responseData?.message);
        }
      } catch (err) {
        toast.error("error while creating new note!");
      }
    }
  };

  return (
    <Root>
      <div className="row">
        <div className="form-group">
          <label className="form-label">Note</label>
          <textarea
            className="form-control"
            // style={{ height: "100px" }}
            rows={4}
            cols={50}
            placeholder="Enter Your Note"
            onChange={(e) => onTextChange(e)}
            value={message}
          />
          {errors.note && errors.note ? (
            <ErrorText message={errors.note} />
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="auto_form_btn">
        <button
          type="button"
          className="btn btn-primary-save"
          onClick={(e) => handleSubmit(e)}
        >
          Add Notes
        </button>
      </div>
      <Toaster position="top-right" reverseOrder={false} />
    </Root>
  );
};

export default CreateNoteForm;

const Root = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  .form-group {
    margin: 6px 0px;
  }
  .auto_form_btn {
    position: sticky;
    margin-top: auto;
    bottom: 0px;
  }
  .select_date {
    display: block;
    background: #fff;
    border-radius: 2px;
    padding: 8px;
    border: 1px solid #ced4da;
    span.rs-picker-toggle-value {
      color: #000;
    }
    &:focus-visible {
      outline: none;
      box-shadow: none;
      border: none;
    }
  }
`;
