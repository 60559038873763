import React, { useState, useCallback, useEffect } from 'react';
import toast, { Toaster } from "react-hot-toast";
import ErrorText from "../../components/errorText";
import TabContent from './TabContent';
import PopupComponent from '../../components/PopupComponent';
import { 
  createLeadList, 
  deleteLeadList, 
  formPutLeadList, 
  getSingleLeadList 
} from "../../services/leadListService";
import { LeadListTriggerData } from "./LeadListTriggerTypes";
import { Trash2 } from 'lucide-react';
import GenericConfirmationModal from '../../components/GenericConfirmationModal';

interface Props {
  mode: string;
  id?: string;
  getLeadList: () => void;
  onClose: () => void;
  isOpen: boolean;
}

export interface TeamMembersT {
  _id: string;
  name: string;
  organization: string;
  role: string;
}

// Get user data from localStorage
let userData = localStorage.getItem("userData") || "{}";
if (userData === "undefined") {
  userData = "{}";
}

const AddEditList: React.FC<Props> = ({
  mode,
  id,
  getLeadList,
  onClose,
  isOpen
}) => {
  // Core states
  const [listName, setListName] = useState('');
  const [error, setError] = useState({ listName: '' });
  const [isLoading, setIsLoading] = useState(false);
  
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isPinned, setIsPinned] = useState(false);

  // Distribution states
  const [enableLeadDistribution, setEnableLeadDistribution] = useState(false);
  const [distributionType, setDistributionType] = useState("round-robin");
  
  // Members and access states
  const [checkedState, setCheckedState] = useState<{
    [_id: string]: { recepient: boolean; access: boolean };
  }>({});
  
  // Trigger/Automation state
  const [triggerData, setTriggerData] = useState<LeadListTriggerData>({
    event: '',
    targetValues: ''
  });

  
  // Fetch existing list data
  const fetchLeadList = useCallback(async (id: string) => {
    setIsLoading(true);
    try {
      const response = await getSingleLeadList(id);
      if (response && response.status === 200) {
        const data = response.data.data;
        setListName(data.name);
        setEnableLeadDistribution(data.isDistributionActive);
        setDistributionType(data.distributionType);
        
        // Set trigger data
        if (data.automationRule) {
          console.log('Loaded automation rule:', data.automationRule);
          const rule = data.automationRule;
          // Handle both array and single value cases
          const targetValues = rule.condition?.targetValues 
            ? Array.isArray(rule.condition.targetValues) 
              ? rule.condition.targetValues 
              : [rule.condition.targetValues]
            : [];
          console.log('Target values before processing:', targetValues);
          setTriggerData({
            event: rule.event,
            targetValues: targetValues.join(','),
            isValid: true
          });
        }
        
        // Set recipients and access
        const recipients = data.recipients;
        const readAccess = data.readAccess;
        setIsPinned(data.isPinned || false);
        const checkedStateObject: {
          [_id: string]: { recepient: boolean; access: boolean };
        } = {};

        if (recipients && typeof recipients === "object") {
          Object.keys(recipients.ids).forEach((id: string) => {
            const recipient = recipients.ids[id];
            if (recipient.status && recipient.waitage === 1) {
              checkedStateObject[id] = { recepient: true, access: false };
            }
          });
        }

        if (readAccess && Array.isArray(readAccess)) {
          readAccess.forEach((id: string) => {
            if (checkedStateObject[id]) {
              checkedStateObject[id].access = true;
            } else {
              checkedStateObject[id] = { recepient: false, access: true };
            }
          });
        }
        setCheckedState(checkedStateObject);
      }
    } catch (err) {
      console.error(err);
      toast.error("Error fetching lead list data");
    } finally {
      setIsLoading(false);
    }
  }, []);

  // Add this inside your existing component
useEffect(() => {
  // Reset key states when mode changes or popup closes
  if (mode === 'add' || !isOpen) {
    setListName('');
    setIsPinned(false);
    setEnableLeadDistribution(false);
    setDistributionType("round-robin");
    setCheckedState({});
    setTriggerData({
      event: '',
      targetValues: ''
    });
  }
}, [mode, isOpen]);

  useEffect(() => {
    if (mode === "edit" && id) {
      fetchLeadList(id);
    }
  }, [mode, id, fetchLeadList]);

  const handleListNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setListName(e.target.value);
    setError({ ...error, listName: "" });
  };

  const handleDeleteList = async () => {
    if (!id) return; // Ensure we have an ID to delete

    try {
      setIsLoading(true);
      const response = await deleteLeadList(id);
      
      if (response.status === 200) {
        toast.success("Lead list deleted successfully");
        getLeadList(); // Refresh the list
        onClose(); // Close the modal
      }
    } catch (err) {
      console.error(err);
      toast.error("Error deleting lead list");
    } finally {
      setIsLoading(false);
      setShowDeleteModal(false);
    }
  };

  
  const handleSubmit = async () => {
    if (!listName.trim()) {
      setError({ ...error, listName: "List Name is required" });
      return;
    }
  
    // Base data object with required fields
    const data: any = {
      name: listName,
      isPinned: isPinned,
    };
  
    // Only add distribution if enabled
    if (enableLeadDistribution) {
      data.isDistributionActive = true;
      data.distributionType = distributionType;
    }
  
    // Only add recipients if any are selected
    const recipientsObject = Object.keys(checkedState).reduce(
      (accumulator: any, id) => {
        if (checkedState[id]?.recepient) {
          accumulator[id] = {
            status: true,
            waitage: 1,
          };
        }
        return accumulator;
      },
      {}
    );
  
    if (Object.keys(recipientsObject).length > 0) {
      data.recipients = {
        ids: recipientsObject,
      };
    }
  
    // Only add access control if any members have access rights
    const accessMembers = Object.keys(checkedState)?.filter(
      (id) => checkedState[id]?.access
    );
    if (accessMembers.length > 0) {
      data.readAccess = accessMembers;
    }
  
    // Add automation rule only if event is selected and it requires conditions
    if (triggerData.event) {
      const eventsWithoutConditions = [
        'lead_edited',
        'quotation_created',
        'quotation_edited',
        'incoming_call_activity',
        'outgoing_call_activity',
        'missed_call_activity'
      ];
  
      const automationRule: any = {
        event: triggerData.event,
        action: {
          day_0: [
            {
              type: "move_to_list",
              data: {
                targetListId: id || "",
              },
            },
          ],
        },
      };
  
      // Only add condition if the event requires it
      if (!eventsWithoutConditions.includes(triggerData.event) && triggerData.targetValues) {
        console.log('Processing target values:', triggerData.targetValues);
        // Handle both single value and comma-separated values
        const targetValueArray = triggerData.targetValues.includes(',')
          ? triggerData.targetValues.split(',').filter(Boolean)
          : [triggerData.targetValues];
        console.log('Processed target array:', targetValueArray);
        automationRule.condition = {
          targetValues: targetValueArray
        };
        // Add debug log for final automation rule
        console.log('Final automation rule:', automationRule);
      }
  
      data.automationRule = automationRule;
    }
  
    try {
      setIsLoading(true);
      console.log('Submitting data:', data);
      console.log('Current trigger data:', triggerData);
      let response;
  
      if (id) {
        response = await formPutLeadList(id, data);
      } else {
        response = await createLeadList(data);
        if (response?.status && triggerData.event) {
          const newListId = response.data.data._id;
          // Update automation rule with new list ID
          if (data.automationRule) {
            data.automationRule.action.day_0[0].data.targetListId = newListId;
            response = await formPutLeadList(newListId, data);
          }
        }
      }
  
      if (response && response.status) {
        console.log('Success response:', response.data);
        toast.success(response?.data?.message);
        getLeadList();
        onClose();
      }
    } catch (err) {
      console.error(err);
      toast.error("Error while saving lead list!");
    } finally {
      setIsLoading(false);
    }
  };

// Update the footerContent section in AddEditList:
const footerContent = (
  <div className="d-flex justify-content-end gap-2 w-full">
    <button
      type="button"
      className="px-4 py-2 rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 transition-colors"
      onClick={onClose}
      style={{
        minWidth: '80px',
        fontWeight: 500
      }}
    >
      Cancel
    </button>
    <button
      type="button"
      className="px-4 py-2 rounded-md text-white bg-blue-500 hover:bg-blue-600 transition-colors"
      onClick={handleSubmit}
      disabled={isLoading || !listName.trim()}
      style={{
        minWidth: '120px',
        fontWeight: 500,
        backgroundColor: '#0095FF'
      }}
    >
      {mode === 'add' ? 'Add List' : 'Update List'}
    </button>
  </div>
);

  const mainContent = (
    <>
      <div className="mb-4">
  <label className="form-label fw-bold">List Name</label>
  <div className="d-flex align-items-center">
    <input
      type="text"
      className="form-control flex-grow-1"
      placeholder="Enter list name"
      value={listName}
      onChange={handleListNameChange}
      disabled={isLoading}
    />
    {mode === 'edit' && (
      <div 
        className="ml-2 text-danger cursor-pointer" 
        onClick={() => setShowDeleteModal(true)} 
        title="Delete List"
      >
        <Trash2 size={20} />
      </div>
    )}
  </div>
  {error.listName && <ErrorText message={error.listName} />}
</div>

      <div className="mb-4">
        <div className="form-check">
          <input
            type="checkbox"
            className="form-check-input"
            id="isPinned"
            checked={isPinned}
            onChange={(e) => setIsPinned(e.target.checked)}
            disabled={isLoading}
          />
          <label className="form-check-label" htmlFor="isPinned">
            Pin this list for quick access
          </label>
        </div>
      </div>

      <TabContent 
        mode={mode}
        id={id}
        isLoading={isLoading}
        triggerData={triggerData}
        setTriggerData={setTriggerData}
        checkedState={checkedState}
        setCheckedState={setCheckedState}
        enableLeadDistribution={enableLeadDistribution}
        setEnableLeadDistribution={setEnableLeadDistribution}
        distributionType={distributionType}
        setDistributionType={setDistributionType}
      />
    </>
  );

  return (
    <>
      <PopupComponent
        isOpen={isOpen}
        onClose={onClose}
        title={mode === 'add' ? 'Add List' : 'Edit List'}
        footer={footerContent}
        size="lg"
        loading={isLoading}
      >
        {mainContent}
      </PopupComponent>

      {/* Delete Confirmation Modal */}
      <GenericConfirmationModal
        showModal={showDeleteModal}
        toggleModal={() => setShowDeleteModal(!showDeleteModal)}
        onConfirmation={handleDeleteList}
        message="Deleting this list will permanently remove:
        - The entire lead list
        - All leads within this list
        - Associated tasks
        - Related notes
        
        This action cannot be undone. Are you absolutely sure you want to proceed?"
        title="Delete Lead List"
        confirmText="Delete"
        isWarning={true}
        confirmClassName="btn-danger"
      />
    </>
  );
};

export default AddEditList;