import React, { useState } from "react";
import { Button } from "antd";
import { startCase } from "lodash";
import moment from "moment";
import DynamicConfirmationModal from "../../components/DynamicConfirmationModal";
import styled from "styled-components";


export default function Template({
  template,
  deleteTemplate,
  editTemplate,
}: any) {
  const { name, language, status, category, createdBy, createdAt, components } = template;
  const [hover, setHover] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);


  const getStatusColor = (status: string): string => {
    return {
      rejected: "#a42931",
      pending: "#FFC107",
      approved: "#4bb04f",
    }[status.toLowerCase()] || "#4bb04f";
  };

  const bodyComponent = components?.find((item: any) => item.type === "BODY");
  const bodyText = bodyComponent?.text?.replace(
    /\{\{(\d+)\}\}/g,
    (_: any, i: number) => template?.mapper?.[i] || `{{${i}}}`
  );

  const toggleModal = () => setShowConfirmation(!showConfirmation);


  
  return (
    <>
      <div
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className="container_body_template"
      >
       
        <div className="container_body_template_header">
          <div>
            <TemplateName
              className="text-dark fm-gilroyBold text-capitalize fs-6"
            >
              {name?.split("_").join(" ")}
            </TemplateName>
            <h6 className="fs-8 fw-bold text-muted">{category}</h6>
          </div>
          <div>
            {hover ? (
              <ActionButtons>
                <Button
                  onClick={() => editTemplate(template)}
                  className="border-0"
                  icon={<i className="fa-solid fa-pen"></i>}
                  title="Edit template"
                />
                <Button
                  onClick={toggleModal}
                  className="border-0"
                  danger
                  icon={<i className="fa-solid fa-trash"></i>}
                  title="Delete template"
                />
              </ActionButtons>
            ) : (
              <StatusBadge
                style={{
                  backgroundColor: getStatusColor(status),
                }}
                className="label rounded-8 fs-7 py-1 px-2"
              >
                {startCase(status)}
              </StatusBadge>
            )}
          </div>
        </div>

        <div className="container_body_template_body p-2">
          <TemplateText>{bodyText}</TemplateText>
        </div>

        <div className="container_body_template_footer align-items-center">
          <h6 className="fs-6 fw-bold fm-gilroyBold bg-lighter rounded-8 py-1 px-2 text-dark text-uppercase">
            {language?.split("_").join(" ")}
          </h6>
          <h6 className="fs-11 fw-bold text-muted">
            Created by {createdBy} on {moment(createdAt).format("Do MMM YYYY")}
          </h6>
        </div>
      </div>

      <DynamicConfirmationModal
        onConfirmation={() => {
          toggleModal();
          deleteTemplate(template._id);
        }}
        showModal={showConfirmation}
        toggleModal={toggleModal}
        title="Are you sure you want to delete this template?"
      />
    </>
  );
}

const TemplateName = styled.h6`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-bottom: 4px;
`;

const ActionButtons = styled.div`
  display: flex;
  gap: 8px;
  opacity: 0;
  animation: fadeIn 0.2s ease-in-out forwards;
  
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
`;



const StatusBadge = styled.div`
  font-weight: 500;
  transition: background-color 0.2s ease;
`;

const TemplateText = styled.p`
  font-size: 16px;
  white-space: pre-wrap;
  margin: 0;
  color: #2d3748;
  line-height: 1.5;
`;