// Header.tsx
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import axios from 'axios';
import { APP_VERSION } from '../config/config';
import BusinessSwitcherModal from './BusinessSwitcherModal';
import Sidebar from './Sidebar';
import { FilterParams } from '../views/leads/leadsGrid/leads.types';
import { toggleDailyEmailPreference } from '../services/userService';
import GenericConfirmationModal from './GenericConfirmationModal';
import toast from 'react-hot-toast';
import { MailCheck } from 'lucide-react';

interface HeaderProps {
  onSearch?: (value: string) => void;
  closeIconClick?: () => void;
  isSearch?: boolean;
  searchPlaceholder?: string;
  isOpen?: boolean;
  setIsOpen?: (value: boolean) => void;
}

const emailPreview = `Subscribe to get your daily business report email like this from 3Sigma CRM:

📊 Daily Performance Summary
──────────────────
New Leads:  15 leads added
──────────────────
Calls Activity:
• Total Calls: 45 calls
• Connected: 32 calls
• Avg Call Time: 3.5 mins
──────────────────
Sales Updates:
• Total Sales: ₹75,000
• sales : 12 completed
──────────────────
Tasks:
• Due Today: 8 tasks
• Completed: 5 tasks
• Overdue: 2 tasks

Note: If you've already subsribed for daily email reports once no need do it again, no need to subscribe again.`;

const Header: React.FC<HeaderProps> = ({
  onSearch,
  closeIconClick,
  isSearch,
  searchPlaceholder,
  isOpen,
  setIsOpen,
}) => {
  const { filterParam }: { filterParam: FilterParams } = useSelector(
    (state: any) => state.rootReducers.leads
  );
  const [filterval, setfilterval] = useState<string>("");
  const [showSwitcherModal, setShowSwitcherModal] = useState(false);
  const [userdata, setUserdata] = useState<any>({});
  const navigate = useNavigate();
  const [showEmailConfirmModal, setShowEmailConfirmModal] = useState(false);
  const [emailPrefLoading, setEmailPrefLoading] = useState(false);

  const debouncedSearch = useRef(
    _.debounce((e: any) => {
      onSearch && onSearch(e);
    }, 500)
  ).current;

  const handleChange = (propogate?: boolean) => {
    setIsOpen && setIsOpen(!isOpen);
    if (propogate) {
      closeIconClick && closeIconClick();
    }
  };

  useEffect(() => {
    setfilterval(filterParam?.search || "");
  }, [filterParam?.search]);


  const handleLogout = () => {
    localStorage.clear();
    setTimeout(() => {
      navigate("/");
      window.location.reload();
    }, 1000);
  };

  useEffect(() => {
    let local: any = window.localStorage?.getItem("userData");
    if (local) {
      setUserdata(JSON.parse(local));
    } else {
      handleLogout();
    }
  }, []);

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        handleLogout();
      }
      return Promise.reject(error);
    }
  );

  const handleEmailPreference = async () => {
    setEmailPrefLoading(true);
    try {
      await toggleDailyEmailPreference(true);
      toast.success("Successfully subscribed to daily sales reports!", {
        position: "top-right",
        duration: 3000
      });
      setShowEmailConfirmModal(false);
    } catch (error) {
      console.error('Failed to update email preference:', error);
      toast.error("Unable to subscribe to daily reports. Please try again.", {
        position: "top-right",
        duration: 4000
      });
    } finally {
      setEmailPrefLoading(false);
    }
  };

  
  const handleSwitchClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const dropdown = document.querySelector('.user-info-dropdown-toggler');
    (dropdown as any)?.click();
    setShowSwitcherModal(true);
  };

  return (
    <>
      <header
        id="header"
        className="header fixed-top d-flex align-items-center"
        style={{
          height: "48px",
          padding: "0 10px",
        }}
      >
        <nav className="header-nav ms-auto">
          <ul className="d-flex align-items-center">
            {window.location.href.split("/").includes("leads") && (
              <li
                style={{
                  backgroundColor: "#ffffff",
                  borderRadius: "12px",
                  display: "flex",
                  alignItems: "center",
                  height: "35px",
                  width: "500px",
                  marginRight: "12px",
                  padding: "0 16px",
                  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.06)",
                  border: "1px solid #f0f0f0",
                  transition: "all 0.2s ease",
                  position: "relative"
                }}
                className="leads-search-bar hover:shadow-md focus-within:shadow-lg focus-within:border-blue-200"
              >
                <i
                  className="fa fa-search cursor-pointer"
                  style={{
                    fontSize: "16px",
                    color: "#666",
                    marginRight: "12px",
                    transition: "color 0.2s ease"
                  }}
                />

                <input
                  type="text"
                  placeholder="Search leads by name, email or phone..."
                  value={filterval}
                  className="form-control placeholder-gray-400"
                  style={{
                    outline: "none",
                    border: "none",
                    width: "100%",
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#2c3e50",
                    backgroundColor: "transparent",
                    transition: "all 0.2s ease"
                  }}
                  onChange={(e) => {
                    setfilterval(e.target.value);
                    debouncedSearch(e);
                  }}
                />

                {filterval && (
                  <i
                    className="fa fa-times-circle cursor-pointer hover:text-gray-700"
                    style={{
                      fontSize: "16px",
                      color: "#999",
                      marginLeft: "12px",
                      transition: "all 0.2s ease",
                      opacity: "0.8"
                    }}
                    onClick={() => {
                      setfilterval("");
                      debouncedSearch({ target: { value: "" } });
                    }}
                  />
                )}
              </li>
            )}
     {window.location.href.split("/").includes("dashboard") && (
        <li style={{ marginRight: "12px" }}>
          <button
            onClick={() => setShowEmailConfirmModal(true)}
            disabled={emailPrefLoading}
            style={{
              backgroundColor: "#06402b",
              color: "white",
              borderRadius: "8px",
              height: "36px",
              padding: "0 16px",
              border: "none",
              display: "flex",
              alignItems: "center",
              gap: "8px",
              transition: "all 0.2s ease",
              cursor: "pointer",
              fontSize: "14px",
              fontWeight: "500",
              boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)"
            }}
            className="hover:bg-indigo-600 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <MailCheck size={18} />
            {emailPrefLoading ? "Processing..." : "Get Daily Email Reports"}
          </button>
        </li>
      )}
            <li
              className="nav-item dropdown mt-0"
              style={{
                backgroundColor: "#ffffff",
                borderRadius: "10px",
                paddingRight: "10px",
                marginRight: 22,
              }}
            >
              <a
                className="nav-link nav-profile d-flex align-items-center pe-0 user-info-dropdown-toggler"
                href="dashboard"
                data-bs-toggle="dropdown"
                style={{ textDecoration: "none" }}
              >
                <span className="d-md-block dropdown-toggle">
                  {userdata?.firstName} {userdata?.lastName}
                </span>
                <svg
                  width="10"
                  height="6"
                  viewBox="0 0 10 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.175 0L5 3.71159L8.825 0L10 1.14825L5 6L0 1.14825L1.175 0Z"
                    fill="black"
                  />
                </svg>
              </a>

              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile user-info-dropdown">
                <li>
                  <div className="row gap-4 align-items-center">
                    <div className="col-md-4">
                      {!userdata?.bucketUrl || !userdata?.profile?.filePath ? (
                        <img
                          src={"assets/img/user.png"}
                          alt="Profile"
                          className="rounded-circle"
                        />
                      ) : (
                        <img
                          src={userdata?.bucketUrl + userdata?.profile?.filePath}
                          alt="Profile"
                          className="rounded-circle"
                        />
                      )}
                    </div>
                    <div className="col-md-6">
                      <h6 className="mb-1">
                        {userdata?.firstName} {userdata?.lastName}
                      </h6>
                      <p>Super admin</p>
                      <p>{userdata?.organization?.name}</p>
                    </div>
                  </div>
                  
                  <span className="phone-number">
                    + {userdata?.countryCode} {userdata?.phone}
                  </span>
                  
                  <div className="line" />
                  
                  <a 
                    onClick={handleSwitchClick} 
                    className="dropdown-item d-flex align-items-center"
                    href="#"
                  >
                    <span className="ms-2">Switch Business Account</span>
                  </a>

                  <a onClick={handleLogout} className="logout mb-2 mt-2 d-flex align-items-center">
                    <svg
                      width="18"
                      height="14"
                      viewBox="0 0 18 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.5 3.11111L12.231 4.20778L14.553 6.22222H5.4V7.77778H14.553L12.231 9.78444L13.5 10.8889L18 7M1.8 1.55556H9V0H1.8C0.81 0 0 0.7 0 1.55556V12.4444C0 13.3 0.81 14 1.8 14H9V12.4444H1.8V1.55556Z"
                        fill="#FF4747"
                        fillOpacity="0.82"
                      />
                    </svg>
                    <span className="ms-2">Logout</span>
                  </a>
                  
                  <div className="version text-center">
                    Version {APP_VERSION}
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </header>
      <GenericConfirmationModal
      showModal={showEmailConfirmModal}
      toggleModal={() => setShowEmailConfirmModal(false)}
      onConfirmation={handleEmailPreference}
      title="Preview Daily Business Report"
      message={emailPreview}
      confirmText="Subscribe"
      cancelText="Maybe Later"
    />

      <Sidebar userdata={userdata} />

      <BusinessSwitcherModal 
        isOpen={showSwitcherModal}
        onClose={() => setShowSwitcherModal(false)}
      />
    </>
  );
};

export default Header;