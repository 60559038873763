import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import toast from 'react-hot-toast';
import styled from 'styled-components';
import { Loader } from 'lucide-react';

interface DragDropProps {
  onFileUpload: (file: File) => Promise<void>;
}

const DragDropCSV: React.FC<DragDropProps> = ({ onFileUpload }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>('');

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (!file) return;

    if (file.size > 5 * 1024 * 1024) {
        toast.error('File size should be less than 5MB');
      return;
    }

    if (!file.name.endsWith('.csv')) {
        toast.error('Please upload a CSV file');
      return;
    }

    setError('');
    setSelectedFile(file);
    setIsLoading(true);

    try {
      await onFileUpload(file);
    } catch (err) {
        toast.error('Error processing file');
    } finally {
      setIsLoading(false);
    }
  }, [onFileUpload]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'text/csv': ['.csv']
    },
    multiple: false,
    disabled: isLoading
  });

  return (
    <div>
      <DropZoneStyle 
        {...getRootProps()} 
        $isDragActive={isDragActive} 
        $hasError={!!error}
        $isLoading={isLoading}
      >
        <input {...getInputProps()} />
        <div className="text-center">
          {isLoading ? (
            <>
              <Loader className="animate-spin" size={24} />
              <p style={{ marginTop: '10px' }}>Processing file...</p>
            </>
          ) : (
            <>
              <span role="img" aria-label="upload" style={{ fontSize: '2rem', marginBottom: '10px', display: 'block' }}>
                📤
              </span>
              {selectedFile ? (
                <p style={{ fontSize: '16px', color: '#3a4b86' }}>
                  Selected: {selectedFile.name}
                </p>
              ) : (
                <>
                  <p style={{ fontSize: '16px', marginBottom: '8px' }}>
                    {isDragActive ? 'Drop your CSV file here' : 'Drag & Drop your CSV file here'}
                  </p>
                  <p style={{ fontSize: '14px', color: '#666' }}>or click to select a file</p>
                </>
              )}
            </>
          )}
        </div>
      </DropZoneStyle>
      {error && (
        <ErrorMessage>{error}</ErrorMessage>
      )}
    </div>
  );
};

interface DropZoneStyleProps {
  $isDragActive: boolean;
  $hasError: boolean;
  $isLoading: boolean;
}

const DropZoneStyle = styled.div<DropZoneStyleProps>`
  border: 2px dashed ${props => props.$hasError ? '#dc2626' : props.$isDragActive ? '#3b82f6' : '#3a4b86'};
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  cursor: ${props => props.$isLoading ? 'not-allowed' : 'pointer'};
  background: ${props => props.$isDragActive ? '#f8fafc' : 'white'};
  transition: all 0.2s ease;
  opacity: ${props => props.$isLoading ? '0.6' : '1'};

  &:hover {
    border-color: ${props => props.$hasError ? '#dc2626' : '#2a3b76'};
    background: #f8f9ff;
  }
`;

const ErrorMessage = styled.p`
  color: #dc2626;
  font-size: 14px;
  margin-top: 8px;
  text-align: center;
`;

export default DragDropCSV;