// src/views/ActivityFeed/ActivityFeedDrawerManager.ts
interface ActivityDrawerFilters {
    fromDate?: string;
    toDate?: string;
  }
  
  type OpenDrawerFunction = (filters?: ActivityDrawerFilters) => void;
  
  class ActivityFeedDrawerManager {
    private openDrawerFn: OpenDrawerFunction | null = null;
  
    registerOpenDrawer(fn: OpenDrawerFunction) {
      this.openDrawerFn = fn;
    }
  
    unregisterOpenDrawer() {
      this.openDrawerFn = null;
    }
  
    openDrawer(filters?: ActivityDrawerFilters) {
      if (this.openDrawerFn) {
        this.openDrawerFn(filters);
      }
    }
  }
  
  export default new ActivityFeedDrawerManager();