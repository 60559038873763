import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { Table, Tabs, Empty } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { MessageCircle, Send } from "lucide-react";
import { getBroadCasts, getCampaignById } from "../../services/broadcast";
import Header from "../../components/header";
import moment from "moment";

import { useSelector } from "react-redux";
import CampaignView from './campaignView';

import type { CampaignFormData, CampaignViewProps, LeadStatusType, } from './campaignViewTypes';

import "./whatsappBroadcast.css";

interface CampaignItem {
  key: string;
  id: string;
  name: string;
  scheduleAt: string;
  rawScheduleAt?: string;
  totalQueued: number;
  totalProcessed: number;
  type: string;
  createdBy: string;
  status: string;
}


interface Lead {
  _id: string;
  key: string;
  name: string;
  status: string[];
  lastActivity: string;
  phone?: string;
  email?: string;
  lastActivityType?: string;
}



const { TabPane } = Tabs;
const PER_PAGE = 20;
const now = moment();

const WhatsappBroadcast: React.FC = () => {
  const [allCampaigns, setAllCampaigns] = useState<any[]>([]);
  const [apiTotal, setApiTotal] = useState(0);
  const [displayData, setDisplayData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [isDetail, setIsDetail] = useState(false);
  const [formDetail, setFormDetail] = useState<any>({});
  const [messagingLimitTier, setMessagingLimitTier] = useState("");
  const [numericMessagingLimit, setNumericMessagingLimit] = useState("");
  const [activeTab, setActiveTab] = useState("current");
 

  const [selectedCampaignId, setSelectedCampaignId] = useState<string | null>(null);



  const { userDetails } = useSelector((state: any) => {
    return state?.rootReducers.userData;
  });

  const filterDataByTab = (data: any[], tab: string) => {
    const now = moment();
    return data.filter(item => {
      const scheduleTime = moment(item.scheduleAt, "Do MMM YY HH:mm A");
      return tab === "current" 
        ? scheduleTime.isSameOrBefore(now) 
        : scheduleTime.isAfter(now);
    });
  };



const columns: ColumnsType<any> = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    sorter: false,
    width: '20%',
    render: (text: string) => (
      <div className="broadcast_type_container">{text}</div>
    ),
  },
  {
    title: "Scheduled At",
    dataIndex: "scheduleAt",
    key: "scheduleAt",
    sorter: false,
    width: '15%',
  },
  {
    title: "Recipients",
    dataIndex: "totalQueued",
    key: "totalQueued",
    sorter: false,
    width: '10%',
    render: (value: number) => (
      <div className="text-center">{value}</div>
    ),
  },
  {
    title: "Sent to",
    dataIndex: "totalProcessed",
    key: "totalProcessed",
    sorter: false,
    width: '10%',
    render: (value: number) => (
      <div className="text-center">{value}</div>
    ),
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    width: '15%',
    render: (_: any, record: any) => (
      <div className="broadcast_type_container">
        {record.type?.toLowerCase() === "whatsapp" ? (
          <img alt="WhatsApp" src="assets/img/whatsapp.png" />
        ) : record.type?.toLowerCase() === "gmail" ? (
          <img alt="Gmail" src="assets/img/gmail.png" />
        ) : record.type?.toLowerCase() === "brevo" ? (
          <img alt="Brevo" src="assets/img/brevo.png" />
        ) : (
          <img alt="mail" src="assets/img/email.png" />
        )}
        {record.type}
      </div>
    ),
  },
  {
    title: "Created by",
    dataIndex: "createdBy",
    key: "createdBy",
    width: '15%',
    render: (_: any, record: any) => {
      const userName = userDetails?.organizationEmployee?.find(
        (e: any) => e._id === record.createdBy
      );
      return (
        <div className="broadcast_type_container">
          {userName?.firstName} {userName?.lastName ?? ""}
        </div>
      );
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: '15%',
    render: (status: string) => {
      const colorMap: { [key: string]: string } = {
        pending: "#F7CB73",
        cancelled: "#E32C2C",
        approved: "#4CB050",
        completed: "#3FAEFD",
        failed: "#FF4747",
        rejected: "#FF4747",
      };
      const color = colorMap[status?.toLowerCase()] || "#8c8c8c";
      
      return (
        <div
          className="broadcast_status_container"
          style={{ backgroundColor: color }}
        >
          {status}
        </div>
      );
    },
  },
];

const EmptyState = ({ activeTab }: { activeTab: string }) => (
  <div className="flex flex-col items-center justify-center py-12">
    <div className="mb-4">
      <MessageCircle className="w-12 h-12 text-gray-300" />
    </div>
    <Empty 
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={
        <div className="text-gray-500">
          {activeTab === 'current' 
            ? "No current campaigns found" 
            : "No scheduled campaigns yet"}
        </div>
      }
    />
  </div>
);
  
  const fetchData = async (currentPage: number = 1) => {
    try {
      setLoading(true);
      const response = await getBroadCasts({
        page: currentPage,
        perPage: PER_PAGE,
      });
  
      const data = response.data;
      setApiTotal(data.total);
  
      const message = data.messagingLimitTier;
      const numericLimit = message ? message.split("_")[1] : "";
      setMessagingLimitTier(message);
      setNumericMessagingLimit(numericLimit);
  
      const transformedData: CampaignItem[] = data.data?.map((ele: any) => ({
        key: ele._id,
        id: ele._id,
        name: ele.name,
        scheduleAt: ele.scheduleAt
          ? moment(ele.scheduleAt).format("Do MMM YY HH:mm A")
          : "-",
        rawScheduleAt: ele.scheduleAt,
        totalQueued: ele.totalQueued,
        totalProcessed: ele.totalProcessed,
        type: ele.serviceType,
        createdBy: ele.createdBy,
        status: ele.status,
      })) || [];
  
      setAllCampaigns(transformedData);
      
      const filtered = transformedData.filter((item: CampaignItem) => {
        if (!item.rawScheduleAt) return activeTab === 'current';
        const scheduleTime = moment(item.rawScheduleAt);
        return activeTab === 'current' 
          ? scheduleTime.isSameOrBefore(now) 
          : scheduleTime.isAfter(now);
      });
      setDisplayData(filtered);
    } catch (error) {
      console.error('Error fetching broadcasts:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData(1);
  }, []);

  useEffect(() => {
    if (allCampaigns.length > 0) {
      const now = moment();
      const filtered = allCampaigns.filter((item: CampaignItem) => {
        if (!item.rawScheduleAt) return activeTab === 'current';
        const scheduleTime = moment(item.rawScheduleAt);
        return activeTab === 'current' 
          ? scheduleTime.isSameOrBefore(now) 
          : scheduleTime.isAfter(now);
      });
      setDisplayData(filtered);
    }
  }, [activeTab, allCampaigns]);
  
  const formatLastActivity = (lastActivity: string, lastActivityType: string) => {
    if (!lastActivity) return 'No activity';
    return `${lastActivityType || 'Activity'} ${moment(lastActivity).fromNow()}`;
  };

  const onRowClicked = (record: any) => {
    if (record.id) {
      setSelectedCampaignId(record.id);
      setIsDetail(true);
    }
  };

  return (
    <>
      <div id="main" className="main px-2">
        <Header />
        {!isDetail ? (
          <div className="whatsapp-broadcast" style={{ padding: "0 15px" }}>
            <section className="auto-section-1 px-0">
              <div className="d-flex align-items-center justify-content-between w-100">
                <div className="align-items-center auto-section-1-sub-1">
                  <label>Campaigns ({apiTotal})</label>
                </div>
                <div className="d-flex align-items-center ms-auto">
                  <p className="messaging-limit me-3">
                    Messaging limit: {numericMessagingLimit} new conversations / 24 hours
                  </p>
                  <NavLink
                    className="btn fw-bold auto_btn w-auto"
                    to="/createWhatsappBroadcast"
                    style={{ fontSize: 14 }}
                  >
                    <Send size={16} color="white" className="me-2" />
                    New Campaign
                  </NavLink>
                </div>
              </div>
            </section>

            <div className="campaign-table-container">
              <Tabs 
                activeKey={activeTab} 
                onChange={setActiveTab}
                className="campaign-tabs"
              >
                <TabPane tab="Current Campaigns" key="current" />
                <TabPane tab="Scheduled Campaigns" key="scheduled" />
              </Tabs>

              <Table 
  columns={columns}
  dataSource={displayData}
  loading={loading}
  pagination={
    activeTab === 'current' ? {
      total: apiTotal,
      pageSize: PER_PAGE,
      current: page,
      onChange: (newPage) => {
        setPage(newPage);
        fetchData(newPage);
      },
      showSizeChanger: false,
      position: ['bottomCenter'],
      showTotal: (totalItems: number) => `Total ${totalItems} items`,
    } : false
  }
  onRow={(record) => ({
    onClick: () => onRowClicked(record),
  })}
  scroll={{ 
    y: 'calc(100vh - 300px)',
    scrollToFirstRowOnChange: true
  }}
  className="campaigns-table"
  locale={{
    emptyText: <EmptyState activeTab={activeTab} />,
  }}
/>
            </div>
            </div>
        ) : (
          selectedCampaignId && (
            <CampaignView
              campaignId={selectedCampaignId}
              onClose={() => {
                setSelectedCampaignId(null);
                setIsDetail(false);
              }}
            />
          )
        )}
      </div>
    </>
  );
};

export default WhatsappBroadcast;