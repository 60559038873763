/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  PropsWithChildren,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import {
  automationWhenTriggerData,
  automationActionData,
  customActivityType,
} from "../../utils/globalConstants";
import DrawerComponent from "./actionsDrawer";
import ActionCreateTask from "./actionCreateTask";
import ActionCommonDrawer from "./actionCommonDrawer";
import DayGroup from "./DayGroup";
import DelayControl from "./DelayControl";
import {
  getAllIntegration,
  getFilePageContent,
  getLeadList,
  getUserOrganizationList,
  createAutomation,
  updateAutomation,
} from "../../services/automationService";
import { useSelector } from "react-redux";
import * as yup from "yup";
import toast, { Toaster } from "react-hot-toast";
import ErrorText from "../../components/errorText";
import ConfirmationModal from "../../components/confirmationModal";
import EmailTemplateList from "../createWhatsappBroadcast/EmailTemplateList";
import TemplateList from "../whatsappTemplate/templateList";
import styled from "styled-components";
import _ from "lodash";
import "./automation.css";
import TriggerSection from "./triggerSection";
import ActionSection from "./actionSection";
import { transformAnyOption } from "./transformAnyOption";

interface AutomationState {
  dayActions: {
    [key: string]: Array<{
      type: string;
      data: any;
    }>;
  };
  dayDelays: {
    [key: string]: number;
  };
}

// Around line ~30 in automationRuleDrawer.tsx
interface AutomationData {
  name: string;
  description: string;
  event: string;
  targetValues: string;
  // Add this to the interface
  condition?: {
    listIds?: string[];
    targetValues?: string[];
    field?: string;
  };
}

interface DayAction {
  type: string;
  data: any;
  isConfigured?: boolean;
  onDeleteDay: (dayKey: string) => void;
}

interface DayActionsType {
  [key: string]: DayAction[];
}

interface autoFormI {
  name: string;
  description: string;
  event: string;
  targetValues: string;
}
interface AutoDataI {
  autoRuleDrawerToggle: () => void;
  onAutomationDelete: (id: string) => void;
  updateAutomationDetail: any;
  isUpdateAuto: boolean;
  handleGetAutomationList: () => void;
  emailTemplate?: any;
  whatsappTemplate?: any;
  onSearch: (search: string, type: string) => void;
  searchValue: string;
  resetTemplates: () => void;
}
const AutomationRuleDrawer: React.FC<PropsWithChildren<AutoDataI>> = ({
  autoRuleDrawerToggle,
  onAutomationDelete,
  updateAutomationDetail,
  isUpdateAuto,
  handleGetAutomationList,
  emailTemplate,
  whatsappTemplate,
  onSearch,
  searchValue,
  resetTemplates,
}) => {
  const StoreData = useSelector((state: any) => {
    return state?.rootReducers;
  });
  const [addActionForm, setAddActionForm] = useState<any>([]);
  const [actionBaseDrawer, setActionBaseDrawer] =
    React.useState<boolean>(false);
  const [actionDrawerTitle, setActionDrawerTitle] = React.useState<string>("");
  const [selectedAction, setSelectedAction] = React.useState<string>("");
  const [delayValue, setDelayValue] = React.useState<number>(1);
  const [isShowAutoEvent, setIsShowAutoEvent] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const mountedRef = useRef(true);
  const processedDataRef = useRef<string | null>(null);
  const isProcessingRef = useRef(false);
  const isInitialMount = useRef(true);
  const [automationData, setAutomationData] = useState<AutomationData>({
    name: "",
    description: "",
    event: "",
    targetValues: "",
    condition: {}, // Now TypeScript knows about this property
  });
  const [dayActions, setDayActions] = useState<AutomationState["dayActions"]>({
    day_1: [],
  });

  const [dayDelays, setDayDelays] = useState<AutomationState["dayDelays"]>({});
  const [currentEditingAction, setCurrentEditingAction] = useState<{
    dayKey: string;
    actionIndex: number;
  } | null>(null);

  // StoreData

  const [isDelayFirst, setIsDelayFirst] = useState<boolean>(false);
  const { organizationLeadList } = StoreData?.userData.userDetails;

  const [errors, setErrors] = useState({
    name: "",
    description: "",
    event: "",
  });

  // Add these right after your existing error states
  const [dayErrors, setDayErrors] = useState<{
    [key: string]: {
      actions?: string;
      delay?: string;
    };
  }>({});

  const [generalError, setGeneralError] = useState<string>("");

  const [sourceByData, setSourceByData] = useState<any>([]);
  const [statusData, setStatusData] = useState<any>([]);
  const [labelData, setlabelData] = useState<any>([]);
  const [statusLabelData, setStatusLabelData] = useState<any>([]);
  const [taskData, setTaskData] = useState<any>([]);
  const [viewPageData, setViewPageData] = useState<any>([]);
  const [viewFileData, setViewFileData] = useState<any>([]);
  const [leadListData, setLeadListData] = useState<any>([]);
  const [activityListData, setActivityListData] = useState<any>([]);
  const [userListData, setUserListData] = useState<any>([]);
  const [delayMinMaxMsg, setDelayMinMaxMsg] = useState<string>("");
  const [actionDaysData, setActionDaysData] = useState<any>([]);
  const [updateActionDays, setUpdateActionDays] = useState<any>("");
  const [updateTaskActionData, setUpdateTaskActionData] = useState<any>({});
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [isActionError, setIsActionError] = useState<boolean>(false);
  const [isActionFormError, setIsActionFormError] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<any>("");

  useEffect(() => {
    if (
      automationData?.event === "view_page" ||
      automationData?.event === "view_file"
    ) {
      handleGetFilePageContent();
    }
    setStatusLabelData(
      automationData?.event === "label_change"
        ? StoreData.user.userPreferences.labels
        : StoreData.user.userPreferences.status
    );
  }, [automationData?.event]);

  const transformEditData = (data: any) => {
    if (!data?.action) {
      return {
        dayActions: { day_1: [] },
        dayDelays: {},
      };
    }

    const result = {
      dayActions: {} as { [key: string]: any },
      dayDelays: {},
    };

    // Convert from API format (day_0) to UI format (day_1)
    Object.entries(data.action).forEach(([dayKey, actions]: [string, any]) => {
      const dayNum = parseInt(dayKey.split("_")[1]);
      const uiDayKey = `day_${dayNum + 1}`; // day_0 -> day_1

      const actionArray = Array.isArray(actions) ? actions : [actions];

      result.dayActions[uiDayKey] = actionArray;
    });

    return result;
  };

  // In automationRuleDrawer.tsx, around line ~90
  useEffect(() => {
    if (!StoreData?.user?.userPreferences) return;

    // if (isUpdateAuto) {
    setStatusData(StoreData?.user?.userPreferences?.status);
    setlabelData(StoreData?.user?.userPreferences?.labels);
    setTaskData(StoreData?.user?.userPreferences?.taskType);
    setActivityListData(StoreData?.user?.userPreferences?.activityType);
    //   return;
    // }
  }, [StoreData?.user?.userPreferences]);

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);

      try {
        await handleGetAllIntegration();
        await handleGetUserOrganization();
        await handleGetLeadList();
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };
    loadData();
  }, []);

  useEffect(() => {
    if (!actionBaseDrawer && searchValue?.length) {
      resetTemplates && resetTemplates();
    }
  }, [actionBaseDrawer]);

  const isArrayGap = (array: any) => {
    return array[0] + array.length != array[array.length - 1] + 1;
  };

  useEffect(() => {
    if (!actionBaseDrawer && searchValue) {
      resetTemplates && resetTemplates();
    }
  }, [actionBaseDrawer]);

  useEffect(() => {
    if (!isUpdateAuto || !updateAutomationDetail) return;

    // IMPORTANT: Set this first to show the condition dropdown
    // setIsShowAutoEvent(true); // THIS WAS MISSING

    // Set the automation data with condition
    setAutomationData({
      name: updateAutomationDetail.name,
      description: updateAutomationDetail.description,
      event:
        (updateAutomationDetail.event === "create_lead" &&
          updateAutomationDetail.condition?.listIds?.[0]) ||
        (updateAutomationDetail.event === "create_lead" &&
          organizationLeadList.some(
            (item: any) =>
              item._id === updateAutomationDetail?.condition?.targetValues
          ))
          ? "new_lead_added"
          : updateAutomationDetail.event,
      // IMPORTANT: Set the condition value
      targetValues:
        updateAutomationDetail.condition?.listIds?.[0] ||
        updateAutomationDetail?.condition?.targetValues ||
        "",
    });

    let isAutoEvent = [
      "edit_lead",
      "create_incoming_activity",
      "create_outgoing_activity",
      "create_miscalled_activity",
      "create_quotation",
      "edit_quotation",
    ];
    if (
      !(
        isAutoEvent.filter((x: string) => x === updateAutomationDetail.event)
          .length > 0
      )
    ) {
      setIsShowAutoEvent(true);
    } else {
      setIsShowAutoEvent(false);
    }

    // Transform actions
    const transformed = transformEditData(updateAutomationDetail);

    setDayActions(transformed.dayActions);

    // Handle delays
    // let delayObj: any = {};

    // Object.keys(transformed.dayActions).map((key, index, arr) => {
    //   let result: any = arr[arr.length - 1].split("_")[1];
    //   result -= parseInt(arr[index].split("_")[1]);

    //   console.log("result>>", result);

    //   delayObj[key] = !index ? 1 : result || 1;
    // });

    // console.log("delayObj>>", delayObj);
    // // IMPORTANT: Only set delays if they exist
    // if (Object.keys(delayObj).length > 0) {
    //   setDayDelays(delayObj);
    // }

    const sortedDays = Object.keys(transformed.dayActions).sort(
      (a, b) => parseInt(a.split("_")[1]) - parseInt(b.split("_")[1])
    );

    if (sortedDays.length === 0) return;

    const tempDelays: Record<string, number> = {};

    sortedDays.forEach((dayKey, index) => {
      if (index < sortedDays.length - 1) {
        const currentDayNumber = parseInt(dayKey.split("_")[1]);
        const nextDayNumber = parseInt(sortedDays[index + 1].split("_")[1]);

        // Calculate delay
        tempDelays[dayKey] = nextDayNumber - currentDayNumber; // Subtract 1 if no gap means delay is 0
      } else {
        // Last day has no delay
        tempDelays[dayKey] = 0;
      }
    });

    if (Object.keys(tempDelays).length > 0) {
      setDayDelays(tempDelays);
    }
  }, [isUpdateAuto, updateAutomationDetail]);

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      const validate = () => {
        // Validate day numbering
        const hasInvalidDays = Object.keys(dayActions).some(
          (key) => !key.startsWith("day_") || key.includes("day_0")
        );
        if (hasInvalidDays) {
        }

        // Validate action structure
        Object.entries(dayActions).forEach(([day, actions]) => {
          if (!Array.isArray(actions)) {
          }
        });
      };

      validate();
    }
  }, [dayActions]);

  let schema = yup.object().shape({
    name: yup.string().required("Rule name is required"),
    description: yup.string().required("Description is required"),
    event: yup.string().required("Event is required"),
  });

  const addFormAction = () => {
    let tempArray: any = [...addActionForm];
    if (tempArray.length === 0 && selectedAction === "delay") {
      setIsDelayFirst(true);
      return;
    }
    tempArray.push("");
    setAddActionForm(tempArray);
    setIsActionFormError(false);
    setIsDelayFirst(false);
  };

  const removeAction = (i: number) => {
    let tempArray: any = [...addActionForm];
    let tempDayArray: any = [...actionDaysData];
    tempArray.splice(i, 1);
    tempDayArray.splice(i, 1);
    setAddActionForm(tempArray);
    setActionDaysData(tempDayArray);
    setDelayValue(1);
  };

  const handlePlusMinusValue = (
    e: React.ChangeEvent<HTMLInputElement>,
    tab: string
  ) => {
    const { value } = e.currentTarget;
    let tempArray: any = [...actionDaysData];
    let finalNo =
      tab === "plus"
        ? delayValue + 1
        : tab === "minus"
        ? delayValue - 1
        : tab === ""
        ? Number(value)
        : 1;

    let objIndex = tempArray.findIndex((x: any) => x.type === "delay");

    if (objIndex === -1) {
      let tempObj = {
        type: "delay",
        data: { delay: finalNo },
      };
      tempArray.push(tempObj);
    } else {
      tempArray[objIndex].data.delay = finalNo;
    }
    setActionDaysData(tempArray);

    if (finalNo >= 1 && finalNo <= 365) {
      setDelayValue(finalNo);
      setDelayMinMaxMsg("");
    } else if (finalNo < 1) {
      setDelayMinMaxMsg("Minimum value is 1.");
    } else {
      setDelayMinMaxMsg("Maximum value is 365.");
    }
  };
  const SubActionSelectOnchange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.currentTarget;
    let selectName =
      selectedAction === "status_change"
        ? "status"
        : selectedAction === "label_change"
        ? "label"
        : selectedAction === "move_to_list" || selectedAction === "copy_to_list"
        ? "targetListId"
        : selectedAction === "push_notification"
        ? "push_notification"
        : selectedAction === "assign_lead"
        ? "assignToUser"
        : selectedAction;
    let tempArray: any = [...actionDaysData];
    let tempObj;
    if (selectName === "label") {
      tempObj = {
        type: name,
        data: { [selectName]: [value] },
      };
    } else {
      tempObj = {
        type: name,
        data: { [selectName]: value },
      };
    }
    setIsActionError(false);
    // let DataExist = tempArray.filter((x: any) => x.type === name);
    // if (DataExist.length > 0) {
    //   let index = tempArray.findIndex((x: any) => x.type === name);
    //   tempArray.splice(index, 1, tempObj);
    // } else {
    tempArray.push(tempObj);
    // }
    setUpdateActionDays(value);
    setActionDaysData(tempArray);
  };

  // Add this after existing function declarations
  const transformExistingToNewFormat = (automationDetail: any) => {
    const result: {
      actions: { [key: string]: Array<any> };
      delays: { [key: string]: number };
    } = {
      actions: {},
      delays: {},
    };

    let currentDay = 1;

    // Handle each day's actions from the existing format
    Object.entries(automationDetail.action || {}).forEach(
      ([dayKey, actions]: [string, any]) => {
        const dayNum = parseInt(dayKey.split("_")[1]);

        // If it's an array, process all actions
        if (Array.isArray(actions)) {
          const dayActions = actions.filter(
            (action) => action.type !== "delay"
          );
          const delayAction = actions.find((action) => action.type === "delay");

          if (dayActions.length > 0) {
            result.actions[`day_${currentDay}`] = dayActions;
          }

          if (delayAction) {
            result.delays[`day_${currentDay}`] = delayAction.data.delay;
            currentDay += delayAction.data.delay;
          }

          currentDay++;
        }
      }
    );

    return result;
  };

  // In automationRuleDrawer.tsx
  // In automationRuleDrawer.tsx, around line ~300

  // NEW: Add this function

  const onSaveDrawerClose = (objData: any) => {
    if (!objData || Object.keys(objData).length === 0) {
      setIsActionError(true);
      return;
    }

    if (currentEditingAction) {
      const { dayKey, actionIndex } = currentEditingAction;

      setDayActions((prev) => {
        const newActions = { ...prev };
        if (newActions[dayKey]) {
          newActions[dayKey] = newActions[dayKey].map((action, idx) =>
            idx === actionIndex ? { ...action, data: objData } : action
          );
        }
        return newActions;
      });
    }

    setActionBaseDrawer(false);
    setIsActionError(false);
  };

  const getCommaSeparatedId = (event: string) => {
    let comma_ids = "";
    if (event === "create_lead") {
      comma_ids = sourceByData.map((item: any) => {
        return item._id;
      });
      // .join(",");
    } else if (event === "status_change" || event === "label_change") {
      comma_ids = statusLabelData.map((item: any) => {
        return item.value;
      });
      // .join(",");
    } else if (event === "view_page") {
      comma_ids = viewPageData.map((item: any) => {
        return item._id;
      });
      // .join(",");
    } else if (event === "view_file") {
      comma_ids = viewFileData.map((item: any) => {
        return item._id;
      });
      // .join(",");
    } else if (event === "create_task") {
      comma_ids = taskData.map((item: any) => {
        return item.value;
      });
      // .join(",");
    } else if (event === "edit_task") {
      comma_ids = "toBePerformAt,isCompleted";
    } else if (event === "create_activity") {
      comma_ids = activityListData.map((item: any) => {
        return item.value;
      });
      // .join(",");
    }

    return comma_ids;
  };
  const handleAutoFormChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.currentTarget;

    if (name === "event") {
      let isAutoEvent = [
        "edit_lead",
        "create_incoming_activity",
        "create_outgoing_activity",
        "create_miscalled_activity",
        "create_quotation",
        "edit_quotation",
      ];
      if (!(isAutoEvent.filter((x: string) => x === value).length > 0)) {
        setIsShowAutoEvent(true);
      } else {
        setIsShowAutoEvent(false);
      }
    }

    setAutomationData({
      ...automationData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: null,
    });
  };

  const handleGetAllIntegration = async () => {
    try {
      const response = await getAllIntegration({
        isAscending: false,
        page: 1,
        perPage: 15,
      });
      if (response && response.status) {
        setSourceByData(response.data.data);
      }
    } catch (err) {
      console.log(err, "Error");
    }
  };

  const handleGetUserOrganization = async () => {
    try {
      const response = await getUserOrganizationList({
        page: 1,
        perPage: 15,
      });
      if (response && response.status) {
        let tempArray = [...response.data.data];
        if (
          StoreData.userData.userDetails.role.name === "super_admin" ||
          StoreData.userData.userDetails.role.name === "admin"
        ) {
          let tempObj = {
            firstName: StoreData.userData.userDetails.firstName,
            _id: StoreData.userData.userDetails._id,
          };
          tempArray.push(tempObj);
        }
        setUserListData(tempArray);
      }
    } catch (err) {
      console.log(err, "Error");
    }
  };

  const handleGetLeadList = async () => {
    try {
      const response = await getLeadList({
        isAscending: false,
        page: 1,
        perPage: 15,
      });
      if (response && response.status) {
        setLeadListData(response.data.data);
      }
    } catch (err) {
      console.log(err, "Error");
    }
  };

  const handleGetFilePageContent = async () => {
    try {
      const response = await getFilePageContent({
        isAscending: false,
        page: 1,
        perPage: 15,
        type: automationData?.event === "view_page" ? "page" : "file",
      });
      console.log("page file response", response);
      if (response && response.status) {
        if (automationData?.event === "view_page") {
          setViewPageData(response.data.data);
        } else {
          setViewFileData(response.data.data);
        }
      }
    } catch (err) {
      console.log(err, "Error");
    }
  };

 /**
 * Handles submission of automation rule creation/update
 * Processes "any" options for all trigger types and ensures correct data format
 */
const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
  e.preventDefault();

  const isFormValid = await schema.isValid(automationData, {
    abortEarly: false,
  });

  const isDayActionsValid = validateDayActions();

  if (isFormValid && isDayActionsValid) {
    try {
      const formattedActions = formatForApiSubmission();
      let condition: { [key: string]: string | string[] } = {};
      
      if (automationData.targetValues) {
        let targetValues: string | string[] = automationData.targetValues;

        // Handle "any" option transformation for all trigger types
        if (targetValues === "any") {
          // Get transformed values based on trigger type
          const transformedValues = transformAnyOption({
            event: automationData.event,
            // For create_lead: combine integration and custom sources
            sourceByData,
            // For status/label: use appropriate preference data
            statusLabelData: automationData.event === "label_change" ? 
                           StoreData?.user?.userPreferences?.labels : 
                           StoreData?.user?.userPreferences?.status,
            // For view_page/file: use appropriate view data
            viewData: automationData.event === "view_page" ? viewPageData : viewFileData,
            // For tasks: use task type data
            taskData: StoreData?.user?.userPreferences?.taskType
          });

          // Validate transformation result
          if (!transformedValues || transformedValues.length === 0) {
            toast.error(`No valid options found for ${automationData.event} trigger`);
            return;
          }

          // Format the transformed values based on trigger type
          // Special case for edit_task
if (automationData.event === 'edit_task') {
  targetValues = ['toBePerformAt', 'isCompleted'];
} else {
  // All other events keep array format
  targetValues = transformedValues;
}
        }

        // Determine the condition key based on trigger type
        const conditionKey = automationData.event === "edit_task" ? "field" : "targetValues";

        // Format condition value based on trigger type
        let conditionValue: string | string[];
        if (automationData.event === "status_change" || 
            automationData.event === "label_change") {
          // Ensure array format for status/label conditions
          conditionValue = Array.isArray(targetValues) ? targetValues : [targetValues];
        } else if (automationData.event === "create_lead" && targetValues === "any") {
          // Special handling for create_lead with "any"
          conditionValue = Array.isArray(targetValues) ? targetValues : targetValues.split(',');
        } else {
          // Default handling for other triggers
          conditionValue = targetValues;
        }

        condition = {
          [conditionKey]: conditionValue
        };
      }

      // Construct the final payload
      const payload = {
        name: automationData.name,
        description: automationData.description,
        // Handle event name transformation for new leads
        ...(!isUpdateAuto && {
          event: automationData.event === "new_lead_added" ? 
                 "create_lead" : 
                 automationData.event
        }),
        // Include condition if targetValues exists
        ...(automationData.targetValues && { condition }),
        action: formattedActions,
      };

      // Make API call based on create/update mode
      let response;
      if (isUpdateAuto) {
        response = await updateAutomation(updateAutomationDetail._id, payload);
      } else {
        response = await createAutomation(payload);
      }

      // Handle API response
      if (response?.status) {
        toast.success(response?.data?.message);
        autoRuleDrawerToggle();
        handleGetAutomationList();
      } else {
        throw new Error('API response status not OK');
      }
    } catch (err) {
      console.error('Error in automation submission:', err);
      toast.error("Error while creating/updating automation rule");
    }
  } else {
    // Handle validation errors
    if (!isFormValid) {
      schema.validate(automationData, { abortEarly: false }).catch((err) => {
        const validationErrors = err.inner.reduce(
          (acc: { [key: string]: string }, error: { path: string; message: string }) => ({
            ...acc,
            [error.path]: error.message,
          }),
          {}
        );
        setErrors(validationErrors);
      });
    }
  }
};

  // Add this after your helper functions
  // In automationRuleDrawer.tsx
  const formatForApiSubmission = () => {
    const formattedActions: { [key: string]: any[] } = {};
    let dayCounter = 0;

    Object.entries(dayActions)
      .sort(([a], [b]) => parseInt(a.split("_")[1]) - parseInt(b.split("_")[1]))
      .forEach(([dayKey, actions], index, array) => {
        // Assign actions to the current day
        formattedActions[`day_${dayCounter}`] = actions;

        // Only add delay if it's not the last day and delay exists
        if (index < array.length - 1) {
          const delay = dayDelays[dayKey] || 1;
          dayCounter += delay;
        }

        // Increment for the next day
        // dayCounter++;
      });

    return formattedActions;
  };

  const validateAction = (action: { type: string; data: any }) => {
    switch (action.type) {
      case "status_change":
        return !!action.data.status;
      case "label_change":
        return !!action.data.label;
      case "send_email":
        return !!action.data.content;
      case "send_whatsapp":
        return !!action.data.template;
      default:
        return true;
    }
  };

  const onConfirmationDelete = () => {
    setShowDeleteModal(false);
    onAutomationDelete(updateAutomationDetail._id);
  };

  const DeleteModalToggle = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  const ShowInfoAction = (value: string, dayKey: string) => {
    // Get the action data
    const dayData = dayActions[dayKey];

    const actionData = dayData?.find((a) => a.type === value);

    setCurrentEditingAction({
      dayKey: dayKey,
      actionIndex: dayData.findIndex((a) => a.type === value),
    });

    if (actionData) {
      if (value === "create_task") {
        setUpdateTaskActionData(actionData.data);
      } else {
        const tempValue = Object.values(actionData.data)[0];
        setUpdateActionDays(tempValue);
      }

      if (value === "send_whatsapp" && actionData?.data?.template) {
        setSelectedTemplate({ _id: actionData?.data?.template ?? "" });
      }

      if (value === "send_email" && actionData?.data?.content) {
        setSelectedTemplate({ _id: actionData?.data?.content ?? "" });
      }

      // Set drawer title and open it
      const data =
        automationActionData.find((x) => x.value === value)?.name || "";
      setActionDrawerTitle(data);
      setSelectedAction(value);
      setActionBaseDrawer(true);
    }
  };

  const handleTemplateSelect = (template: any, isEmail: boolean) => {
    if (currentEditingAction) {
      const { dayKey, actionIndex } = currentEditingAction;

      setDayActions((prev) => ({
        ...prev,
        [dayKey]: prev[dayKey].map((action, idx) =>
          idx === actionIndex
            ? {
                ...action,
                data: isEmail
                  ? { content: template._id, templateData: template }
                  : { template: template._id, templateData: template },
                isConfigured: true,
              }
            : action
        ),
      }));

      setSelectedTemplate(template);
      setActionBaseDrawer(false);
    }
  };

  const handleTemplateSearch = _.debounce((searchValue: string) => {
    if (actionDrawerTitle === "Send Email") {
      onSearch(searchValue, "Send Email");
    } else if (actionDrawerTitle === "Send Whatsapp") {
      onSearch(searchValue, "Send Whatsapp");
    }
  }, 300);

  const handleSearchChange = _.debounce(
    (newValue) => onSearch && onSearch(newValue, actionDrawerTitle),
    200
  );

  const validateDayActions = () => {
    let isValid = true;
    const newErrors: { [key: string]: any } = {};

    // Reset errors
    setDayErrors({});
    setGeneralError("");

    // Check if we have any days
    if (Object.keys(dayActions).length === 0) {
      setGeneralError("At least one day with actions is required");
      isValid = false;
      return isValid;
    }

    // Validate each day
    Object.entries(dayActions).forEach(([dayKey, actions]) => {
      // Check if day has any actions
      if (!actions || actions.length === 0) {
        newErrors[dayKey] = {
          ...newErrors[dayKey],
          actions: "At least one action is required",
        };
        isValid = false;
      }

      // Check if actions are properly configured
      actions.forEach((action, index) => {
        if (!action.type) {
          newErrors[dayKey] = {
            ...newErrors[dayKey],
            actions: "Please select an action type",
          };
          isValid = false;
        }

        // Check if action has required data
        if (action.type && Object.keys(action.data || {}).length === 0) {
          newErrors[dayKey] = {
            ...newErrors[dayKey],
            actions: "Please configure all actions",
          };
          isValid = false;
        }
      });

      // Validate delay if present
      if (dayDelays[dayKey]) {
        if (dayDelays[dayKey] < 1 || dayDelays[dayKey] > 365) {
          newErrors[dayKey] = {
            ...newErrors[dayKey],
            delay: "Delay must be between 1 and 365 days",
          };
          isValid = false;
        }
      }
    });

    setDayErrors(newErrors);
    return isValid;
  };

  useEffect(() => {
    return () => {
      mountedRef.current = false;
      processedDataRef.current = null;
      isProcessingRef.current = false;
    };
  }, []);

  return (
    <div className="row auto_rule_form">
      <Toaster position="top-right" reverseOrder={false} />
      {isUpdateAuto && (
        <div style={{ textAlign: "right" }}>
          <i
            className="fa fa-trash-o"
            style={{ color: "red", fontSize: "18px", cursor: "pointer" }}
            onClick={DeleteModalToggle}
          />
        </div>
      )}

      {/* Rule Name */}
      <div className="col-md-12 form-section">
        <div className="form-group">
          <label className="input-label">
            Rule name
            <span className="required-star">*</span>
          </label>
          <input
            type="text"
            name="name"
            autoComplete="off"
            className={`form-control ${isUpdateAuto ? "disabled_input" : ""}`}
            value={automationData?.name}
            placeholder="Enter your rule name"
            onChange={(e) => handleAutoFormChange(e)}
            disabled={isUpdateAuto}
            maxLength={100}
          />
          {errors.name && <ErrorText message={errors.name} />}
        </div>
      </div>

      {/* Rule Description */}
      <div className="col-md-12 form-section">
        <div className="form-group">
          <label className="input-label">
            Rule description
            <span className="required-star">*</span>
          </label>
          <input
            type="text"
            name="description"
            autoComplete="off"
            className="form-control"
            value={automationData?.description}
            placeholder="Enter brief description"
            onChange={(e) => handleAutoFormChange(e)}
            maxLength={200}
          />
          {errors.description && <ErrorText message={errors.description} />}
        </div>
      </div>

      {/* Trigger Section */}
      <TriggerSection
        automationData={automationData}
        automationWhenTriggerData={automationWhenTriggerData}
        isShowAutoEvent={isShowAutoEvent}
        isUpdateAuto={isUpdateAuto}
        handleAutoFormChange={handleAutoFormChange}
        sourceByData={sourceByData}
        statusLabelData={statusLabelData}
        viewPageData={viewPageData}
        viewFileData={viewFileData}
        taskData={taskData}
        activityListData={activityListData}
        customActivityType={customActivityType}
        organizationLeadList={organizationLeadList}
        errors={errors}
      />

      {/* Then Section */}
      <ActionSection
        dayActions={dayActions}
        taskData={taskData}
        isUpdateAuto={isUpdateAuto}
        setDayActions={setDayActions}
        ShowInfoAction={ShowInfoAction}
        setDayDelays={setDayDelays}
        statusData={statusData}
        userListData={userListData}
        labelData={labelData}
        leadListData={leadListData}
        whatsappTemplate={whatsappTemplate}
        emailTemplate={emailTemplate}
        dayErrors={dayErrors}
        dayDelays={dayDelays}
      />

      {generalError && (
        <div className="col-md-12">
          <div
            className="error-message"
            style={{ color: "red", textAlign: "center", marginBottom: "10px" }}
          >
            {generalError}
          </div>
        </div>
      )}

      <div className="d-flex justify-content-center auto_form_btn">
        <button type="reset" className="btn btn-primary" onClick={handleSubmit}>
          {isUpdateAuto ? "Update" : "Create"} Automation Rule
        </button>
      </div>

      <div className="automatic_rule_drawer">
        <DrawerComponent
          openWithHeader={actionBaseDrawer}
          setOpenWithHeader={setActionBaseDrawer}
          drawerTitle={actionDrawerTitle}
          size="xs"
        >
          {actionDrawerTitle === "Send Email" ||
          actionDrawerTitle === "Send Whatsapp" ? (
            <section className="auto-section-2">
              <div className="container">
                <div
                  className="container_body"
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    paddingBottom: 100,
                  }}
                >
                  {/* SearchBox */}
                  <SearchContainer className="d-flex justify-content-center align-items-center">
                    <div className="d-flex justify-content-center align-items-center w-100 leads-search-bar">
                      <i
                        className="fa fa-search cursor-pointer"
                        aria-hidden="true"
                        style={{ fontSize: "14px" }}
                      />
                      <input
                        type="text"
                        placeholder="Type to search..."
                        className="form-control background-transparent search-input"
                        onChange={(e) => handleSearchChange(e.target.value)}
                      />
                    </div>
                  </SearchContainer>

                  {actionDrawerTitle === "Send Email" ? (
                    <EmailTemplateList
                      templates={emailTemplate}
                      onEmailTemplateSelect={(template) => {
                        setSelectedTemplate(template);
                        onSaveDrawerClose({ content: template._id });
                      }}
                      selectedId={selectedTemplate?._id}
                    />
                  ) : (
                    <TemplateList
                      templates={whatsappTemplate}
                      onTemplateSelect={(template) => {
                        setSelectedTemplate(template);
                        onSaveDrawerClose({ template: template._id });
                      }}
                      selectedId={selectedTemplate?._id}
                    />
                  )}
                </div>
              </div>
            </section>
          ) : actionDrawerTitle === "Create task" ? (
            <ActionCreateTask
              addUpdateModalValue={updateTaskActionData}
              onSaveDrawerClose={(taskData) => {
                if (currentEditingAction) {
                  const { dayKey, actionIndex } = currentEditingAction;
                  setDayActions((prev) => {
                    const newActions = { ...prev };
                    if (newActions[dayKey]) {
                      newActions[dayKey] = newActions[dayKey].map(
                        (action, idx) =>
                          idx === actionIndex
                            ? {
                                ...action,
                                data: taskData,
                              }
                            : action
                      );
                    }
                    return newActions;
                  });
                }
                setActionBaseDrawer(false);
                setUpdateTaskActionData({});
              }}
            />
          ) : (
            <ActionCommonDrawer
              actionDrawerTitle={actionDrawerTitle}
              selectedAction={selectedAction}
              handlePlusMinusValue={handlePlusMinusValue}
              SubActionSelectOnchange={SubActionSelectOnchange}
              delayValue={delayValue}
              statusData={statusData}
              labelData={labelData}
              leadListData={leadListData}
              userListData={userListData}
              delayMinMaxMsg={delayMinMaxMsg}
              onSaveDrawerClose={onSaveDrawerClose}
              updateActionDays={updateActionDays}
              isActionError={isActionError}
            />
          )}
        </DrawerComponent>
        <ConfirmationModal
          onConfirmation={onConfirmationDelete}
          showModal={showDeleteModal}
          toggleModal={DeleteModalToggle}
          message={"Are you sure you want to delete this rule"}
          title="Rule"
        />
      </div>
    </div>
  );
};

export default AutomationRuleDrawer;

const SearchContainer = styled.div`
  background-color: #fff;
  border-radius: 10px;
  height: 35px;

  .search-input {
    outline: none;
    border: none;
    background-color: transparent;
  }
`;
