import React from "react";
import styled from "styled-components";
import facebook from "../../../assets/images/facebook-logo.svg";
import nineacres from "../../../assets/images/integration/99acres.png";
import excel from "../../../assets/images/integration/excel.png";
import googleforms from "../../../assets/images/integration/googleforms.png";
import housing from "../../../assets/images/integration/housing.jpg";
import indiamart from "../../../assets/images/integration/indiamart.png";
import justdial from "../../../assets/images/integration/justdial.png";
import leadform from "../../../assets/images/integration/leadform.png";
import magicbricks from "../../../assets/images/integration/magicbricks.png";
import tradeindia from "../../../assets/images/integration/tradeIndia.png";
import typeform from "../../../assets/images/integration/typeform.png";
import word from "../../../assets/images/integration/word.png";
import wordpress from "../../../assets/images/integration/wordpress.png";
import zapiuer from "../../../assets/images/integration/zapiuer.svg";
import customform from "../../../assets/images/integration/Custom Form.jpeg";
import LeadLabel from "../../../components/leadLabel";
import LeadStatus from "../../../components/leadStatus";
import ContactLinksGenerator from "../../../utils/contactlinksgenerator";
import moment from "moment";
import "../leads.css";
import { addCountryCode } from "../../../utils/helpers";
import { FaEnvelope, FaPhone, FaRegClock, FaWhatsapp } from "react-icons/fa";
import { Phone, Mail, Clock } from "lucide-react"; // Corrected WhatsApp import

const StyledImg = styled.img`
  margin-right: 5px;
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px; /* Consistent space between icons */
`;

const IconHolder = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.disabled
      ? "#E0E0E0"
      : "#f1f3f5"}; /* Light background for enabled, gray for disabled */
  box-shadow: ${(props) =>
    props.disabled
      ? "none"
      : "0 1px 3px rgba(0, 0, 0, 0.1)"}; /* Shadow for enabled icons */
  cursor: ${(props) =>
    props.disabled ? "default" : "pointer"}; /* Pointer cursor if enabled */
`;

const nameRenderer: React.FC<any> = (props) => {
  const { value } = props;
  if (!value) {
    return <span>-</span>;
  }
  return (
    <div className="h-100 align-items-center d-flex">
      <div className="lead_cell">
        <div className="cell_label" title={value}>
          {/* {(value || "").length > 16
            ? value.substr(0, 16).concat("...")
            : value} */}
          {value}
        </div>
      </div>
    </div>
  );
};

const phoneRenderer: React.FC<any> = (props) => {
  const { value, data } = props;
  if (!value || !data) {
    return <span>-</span>; // Display a dash if data is missing
  }

  const { email, phone } = data;
  // Use ContactLinksGenerator to handle phone number formatting and link generation
  const contactObj = new ContactLinksGenerator({
    number: phone,
    email: email,
    message: "Hello", // Optional: custom message for WhatsApp link
  });

  return (
    <IconWrapper>
      {/* Phone Icon */}
      <IconHolder disabled={!phone}>
        <a
          onClick={(e) => {
            e.stopPropagation(); // Prevents row click event from triggering
            if (!phone) e.preventDefault(); // Prevent click if disabled
          }}
          href={phone ? contactObj.tel : undefined}
        >
          <Phone size={14} color={phone ? "#007BFF" : "#A9A9A9"} />{" "}
          {/* Blue for enabled, gray for disabled */}
        </a>
      </IconHolder>

      {/* Email Icon */}
      <IconHolder disabled={!email}>
        <a
          onClick={(e) => {
            e.stopPropagation(); // Prevents row click event from triggering
            if (!email) e.preventDefault(); // Prevent click if disabled
          }}
          href={email ? contactObj.mail : undefined}
        >
          <Mail size={14} color={email ? "#0056D2" : "#A9A9A9"} />{" "}
          {/* Darker blue for enabled, gray for disabled */}
        </a>
      </IconHolder>

      {/* WhatsApp Icon */}
      <IconHolder disabled={!phone}>
        <a
          onClick={(e) => {
            e.stopPropagation(); // Prevents row click event from triggering
            if (!phone) e.preventDefault(); // Prevent click if disabled
          }}
          href={phone ? contactObj.whatsapp : undefined}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaWhatsapp size={14} color={phone ? "#25D366" : "#A9A9A9"} />{" "}
          {/* WhatsApp green for enabled, gray for disabled */}
        </a>
      </IconHolder>
    </IconWrapper>
  );
};

const statusRenderer: React.FC<any> = (props) => {
  const { value, preferences } = props;
  if (!value || !preferences) {
    return <span>-</span>;
  }

  return (
    <div className="d-flex align-items-center h-100">
      <div className="lead-subtext">
        <LeadStatus preferences={preferences} status={value} isTag={true} />
      </div>
    </div>
  );
};

const labelRenderer: React.FC<any> = (props) => {
  const { value, preferences } = props;
  if (!value || !preferences) {
    return <span>-</span>;
  }

  return (
    <div className="d-flex align-items-center h-100">
      <div className="lead-subtext">
        <LeadLabel preferences={preferences} label={value} isTag={true} />
      </div>
    </div>
  );
};

const sourceRenderer: React.FC<any> = (props) => {
  const { value, data } = props;
  if (!value || !data) {
    return <span>-</span>;
  }

  const { customSource } = data;
  const imageMap: { [key: string]: string | JSX.Element } = {
    "99Acres": nineacres,
    CSV: excel,
    "Google spread sheets": googleforms,
    Housing: housing,
    Indiamart: indiamart,
    "Just Dial": justdial,
    "Magic Bricks": magicbricks,
    Tradeindia: tradeindia,
    "Type Form": typeform,
    Word: word,
    Wordpress: wordpress,
    Zapier: zapiuer,
    Facebook: facebook,
    "Lead Form": leadform,
    "Custom Form": customform,
    "WhatsApp Business": <FaWhatsapp size={16} color="#25D366" />, // WhatsApp Business icon
  };

  const source =
    typeof value === "string" ? value : value?.name || customSource;
  const icon = imageMap[source];

  return (
    <div className="h-100 align-items-center d-flex">
      <div className="lead_cell">
        <div className="cell_label d-flex align-items-center" title={source}>
          {typeof icon === "string" ? (
            <StyledImg src={icon} height="12" width="12" />
          ) : (
            icon
          )}
          <span>{source}</span>
        </div>
      </div>
    </div>
  );
};

const lastActivityRenderer: React.FC<any> = (props) => {
  const { value, data } = props;
  if (!value || !data) {
    return <span>-</span>;
  }

  const { lastActivityType } = data;
  const formattedTime = moment(value).fromNow();

  return (
    <div
      className="h-100"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        lineHeight: "1.1", // Slightly tighter line height
        fontSize: "0.85rem",
      }}
    >
      <span style={{ fontWeight: "500" }}>{lastActivityType}</span>
      <span
        style={{
          display: "flex",
          alignItems: "center",
          fontSize: "0.75rem",
          color: "#6c757d",
        }}
      >
        <Clock size={10} style={{ marginRight: "2px" }} /> {formattedTime}
      </span>
    </div>
  );
};

const assignedToRenderer: React.FC<any> = (props) => {
  const { value } = props;
  if (!value) {
    return <span>-</span>;
  }
  return value ? (
    <div className="d-flex align-items-center h-100">
      <div className="lead-subtext">
        <div
          style={{
            backgroundColor: "#2298FF",
            color: "#FFF",
            borderRadius: "10px",
            fontFamily: "Gilroy-Regular",
          }}
          className="cell_label p-1 px-2 fitContent"
        >
          {value?.firstName
            ? value?.firstName
            : "" + " " + value?.lastName
            ? value?.lastName
            : ""}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

const locationRenderer: React.FC<any> = (props) => {
  const { value } = props;
  if (!value) {
    return <span>-</span>;
  }
  return (
    <div className="h-100 align-items-center d-flex">
      <div className="lead_cell">
        <div className="cell_label" title={value}>
          {value.address}
        </div>
      </div>
    </div>
  );
};

const dateTimeRenderer: React.FC<any> = (props) => {
  const { value } = props;
  if (!value) {
    return <span>-</span>;
  }

  // Format the date for a readable full format and relative format
  const formattedDate = moment(value).format("MMMM D, YYYY, hh:mm A"); // e.g., "November 12, 2024, 09:41 AM"
  const relativeTime = moment(value).fromNow(); // e.g., "3 days ago" or "in 2 days"

  return (
    <div className="h-100 align-items-center d-flex flex-column">
      <span style={{ fontSize: "0.85rem", fontWeight: "500" }}>
        {relativeTime}
      </span>
      <span
        style={{ fontSize: "0.75rem", color: "#6c757d" }}
        title={formattedDate} // Tooltip to show the exact date on hover
      >
        {formattedDate} {/* Displays full date below the relative time */}
      </span>
    </div>
  );
};

const dateRenderer: React.FC<any> = (props) => {
  const { value } = props;
  if (!value) {
    return <span>-</span>;
  }
  const formattedDate = moment(value).format("DD MMM YYYY"); // e.g., 12 Nov 2024
  return (
    <div className="h-100 align-items-center d-flex">
      <div className="lead_cell">
        <div className="cell_label" title={formattedDate}>
          {formattedDate}
        </div>
      </div>
    </div>
  );
};

const amountRenderer: React.FC<any> = (props) => {
  const { value } = props;
  if (!value) {
    return <span>-</span>;
  }
  return (
    <div className="h-100 align-items-center d-flex">
      <div className="lead_cell">
        <div className="cell_label">{`₹ ${value.toLocaleString("en-IN")}`}</div>
      </div>
    </div>
  );
};

export {
  labelRenderer,
  nameRenderer,
  phoneRenderer,
  sourceRenderer,
  statusRenderer,
  lastActivityRenderer,
  assignedToRenderer,
  locationRenderer,
  dateRenderer,
  amountRenderer,
  dateTimeRenderer,
};
