import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import styled from "styled-components";

interface PropsType {
  children: React.ReactNode; // Define the children prop
}
const MultipleOption: React.FC<PropsType> = (props) => {
  return <Root>{props.children}</Root>;
};
export default MultipleOption;

const Root = styled.div`
  background-color: #fff;
  padding: 50px 80px;
  width: 100%;
  margin: auto;
  height: 100%;
  overflow: scroll;
  @media (max-width: 500px){
    padding: 10px 18px 10px 50px;
  }
  input {
    width: 100%;
  }
  .field_name,
  .title {
    width: fit-content;
    font-size: 24px;
    font-weight: 600;
    color: #000;
    position: relative;
    @media (max-width: 500px){
      font-size: 18px;
      line-height: 22px;
      word-break: break-word;
    }

    input {
      border: none;
    }
   
  }
  .sr_number {
    position: relative;
    span {
      position: absolute;
      left: -45px;
      top: 50%;
      transform: translateY(-50%);
    }
    .text_danger {
      color: red;
      position: absolute;
      right: -12px;
      top: 2px;
    }
  }
  .discription {
    background: transparent;
    border: none;
    display: block;
    width: 100%;
  }
  .discription::placeholder {
    color: #000;
  }
  input:focus {
    outline: none !important;
    border-color: none;
    box-shadow: 0 0 10px #fff;
  }

  .input_box {
    padding: 0px 10px;
    width: 100%;
    border: 1px solid #000;
    border-width: 0px 0px 2px 0px;
    font-size: 20px;
    font-family: "Gilroy-Medium";
    color: #000;
    margin: 10px 0px;
  }
  input::placeholder {
    color: #000;
  }
  .react-tel-input {
    margin-top: 50px;
  }
  .phone_field {
    input.phone_input_left {
      padding-left: 50px;
      margin-top: 50px;
      width: 100%;
      border: 0px solid #000;
      border-width: 0px 0px 2px 0px;
      font-size: 20px;
      font-family: "Gilroy-Medium";
      color: #000;
      border-radius: 0px;
    }
    .flag-dropdown {
      border: none;
      bottom: 4px;
    }
  }

  .option_box {
    display: flex;

    span {
      font-size: 18px;
      width: 100px;
    }
  }
  .multi_option {
    display: flex;
    flex-direction: column;
    width: max-content;
    button {
      display: block;
      padding: 10px;
      min-width: 200px;
      text-align: left;
      border-radius: 6px;
      margin: 10px 0px;
      transition: all 0.5s;
      text-transform: capitalize;
      font-weight: 800;
      font-size: 16px;
      color: #000;
      background: #1a1a1a21;
      word-break: break-all;
      span {
        background: #fff;
        width: 20px;
        display: inline-block;
        margin-right: 14px;
        border-radius: 4px;
        text-align: center;
        color:#000;
      }
      &:hover {
        background-color: #c0bfbf;
      }
    }
    button.active {
      background-color: #00b33f;
      color:#fff;
      /* background-color: #3faefd; */
    }
  }
  .multi_option_btn {
    input {
      display: block;
      border-radius: 6px;
      background-color: #ebf0f4;
      margin: 10px 0px;
      padding: 10px;
      width: fit-content;
      border: none;
      /* .active{
                background-color:#c0bfbf;
            }
            &:hover{
                background-color:#c0bfbf;
            } */
    }
  }
  .option_btn {
    color: #3faefd;
    background: none;
    font-size: 18px;
    &:hover {
      background: none;
    }
  }
  .delete_btn {
    background: none;
    position: absolute;
    top: -4px;
    right: 12px;
    width: fit-content;
  }

  .option_box_field {
    position: relative;
    display: flex;
    input {
      border-radius:6px 0px 0px 6px;
      padding: 10px 0px 10px 45px;
      color: #000;
      font-weight: 600;
    }
    input::placeholder{
        color:#00000082;
    }
    span {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: 14px;
      color: #000;
      left: 6px;
      background: #fff;
      padding: 0px 6px;
      border-radius: 2px;
    }
    .delete_option{
      border-radius:0px 6px 6px 0px;
        width: fit-content;
       img{
        width: 12px;
       }
      
    }
  }
      input::placeholder{
        color:#0000008d;
    }
`;
