import React, { PropsWithChildren } from "react";
import { Drawer, Button } from "rsuite";
import "rsuite/dist/rsuite.min.css";


interface DrawerI {
  openWithHeader: boolean;
  setOpenWithHeader: React.Dispatch<React.SetStateAction<boolean>>;
  drawerTitle: string;
  size: 'lg'|'sm'|'xs'|'full';
}
const LeadDrawerComponent: React.FC<PropsWithChildren<DrawerI>> = ({
  openWithHeader,
  setOpenWithHeader,
  drawerTitle,
  children,
  size,
}) => {
  return (
    <>
      <Drawer
        className="lead_from"
        size={size}
        enforceFocus={false}
        open={openWithHeader}
        onClose={() => setOpenWithHeader(false)}
      >
        <Drawer.Header>
          <Drawer.Title className="modal-title">{drawerTitle}</Drawer.Title>
          <Drawer.Actions>
            <Button onClick={() => setOpenWithHeader(false)}>Cancel</Button>
          </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body>
          {/* <Placeholder.Paragraph /> */}
          {children}
        </Drawer.Body>
      </Drawer>
    </>
  );
};

export default LeadDrawerComponent;
