// Types and interfaces for Lead List Trigger functionality
import { ReactNode } from 'react';

/**
 * Base trigger data interface
 */
export interface LeadListTriggerData {
  event: string;
  targetValues: string;
  isValid?: boolean;
}

/**
 * Props interface for the trigger component
 */
export interface LeadListTriggerProps {
  onChange: (triggerData: LeadListTriggerData) => void;
  initialData?: LeadListTriggerData;
  isLoading?: boolean;
  error?: string;
}

/**
 * Source data interface from integrations
 */
export interface SourceData {
  _id: string;
  name: string;
  isActive: boolean;
}

/**
 * Option interface for dropdowns
 */
export interface SelectOption {
  value: string;
  name: string;
  isActive?: boolean;
}

/**
 * Custom source from user preferences
 */
export interface CustomSource {
  value: string;
  name: string;
  isActive: boolean;
}

/**
 * Activity type interface
 */
export interface ActivityType {
  value: string;
  name: string;
  isActive?: boolean;
}

/**
 * Task type interface
 */
export interface TaskType {
  value: string;
  name: string;
  isActive?: boolean;
}

/**
 * Page/File content interface
 */
export interface ContentItem {
  _id: string;
  details: {
    title: string;
  };
  isActive?: boolean;
}

/**
 * Lead list interface
 */
export interface LeadList {
  _id: string;
  name: string;
  isActive?: boolean;
}

/**
 * Redux store interface for user preferences
 */
export interface UserPreferences {
  customSource: CustomSource[];
  status: SelectOption[];
  labels: SelectOption[];
  taskType: TaskType[];
  activityType: ActivityType[];
}

/**
 * All available trigger options
 */
export const TRIGGER_OPTIONS: SelectOption[] = [
  { value: 'create_lead', name: 'New lead is created' },
  { value: 'edit_lead', name: 'Lead is edited' },
  { value: 'status_change', name: 'Status is updated' },
  { value: 'label_change', name: 'Label is updated' },
  { value: 'viewed', name: 'Page is viewed' },
  { value: 'viewed', name: 'File is viewed' },
  { value: 'quotation_created', name: 'Quotation created' },
  { value: 'quotation_edited', name: 'Quotation edited' },
  { value: 'create_task', name: 'Create task' },
  { value: 'edit_task', name: 'Task is edited' },
  { value: 'create_activity', name: 'Activity is created' },
  { value: 'incoming_call_activity', name: 'Incoming call activity' },
  { value: 'outgoing_call_activity', name: 'Outgoing call activity' },
  { value: 'missed_call_activity', name: 'Missed call activity' }
  
];

/**
 * Error messages
 */
export const ERROR_MESSAGES = {
  REQUIRED_EVENT: 'Please select a trigger event',
  REQUIRED_TARGET: 'Please select a target value',
  FAILED_LOAD: 'Failed to load trigger data',
};

/**
 * Source label mapping
 */
export const SOURCE_LABELS: { [key: string]: string } = {
  create_lead: 'Lead source is',
  status_change: 'Status is updated to',
  label_change: 'Label is updated to',
  view_page: 'Page is',
  view_file: 'File is',
  create_task: 'Task type is',
  edit_task: 'Task action is',
  create_activity: 'Activity is',
 
};

// Utility type for handling API responses
export interface ApiResponse<T> {
  status: boolean;
  data: {
    data: T;
    message?: string;
  };
}