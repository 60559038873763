/* eslint-disable react-hooks/exhaustive-deps */
import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import moment from "moment";
import ActivityItem from "../../components/activityItem";
import LeadNoteItems from "../../components/leadNoteItems";
import LeadStatus from "../../components/leadStatus";
import EmptyState from "./EmptyState";
import {
  assignLeadInList,
  copyLeadInList,
  deleteBulkLeads,
  getLeadDetail,
  moveLeadInList,
  shareContentLead,
} from "../../services/leadService";
import ContactLinksGenerator from "../../utils/contactlinksgenerator";
import _ from "lodash";
import LeadLabel from "../../components/leadLabel";
import DrawerComponent from "../../components/drawer";
import FileUpload from "./FileUpload";
import { useSelector, useDispatch } from "react-redux";
import toast from "react-hot-toast";
import {
  setActivityList,
  setNote,
  setLeadTaskList,
  leadFileList,
} from "../../actions/actions";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";
import {
  getCustomFormResponse,
  getLeadFormResponse,
} from "../../services/formService";
import FormResponse from "../userForm/FormResponse";
import TaskDrawer from "../common/TaskDrawer";
import CreateTaskForm from "../../components/createTaskForm";
import { deleteTask, taskById } from "../../services/taskService";

import CreateActivityForm from "../../components/createActivityForm";
import { activityById, createActivity } from "../../services/activityService";
import ConfirmationModal from "../../components/confirmationModal";
import CreateNoteForm from "../../components/createNoteForm";
import GlobalDrawer from "../../components/GlobalDrawer";
import { getAllLeadList } from "../../services/leadListService";
import {
  FaExternalLinkAlt,
  FaPhone,
  FaPhoneAlt,
  FaWhatsapp,
} from "react-icons/fa";
import {
  MdEdit,
  MdOutlineAttachFile,
  MdOutlineMail,
  MdSave,
} from "react-icons/md";
import { addCountryCode, convertToIST } from "../../utils/helpers";

import { getAllContent } from "../../services/contentService";
import { IoIosArrowDown } from "react-icons/io";

import CreateLeadForm from "./createLeadForm";
import DynamicConfirmationModal from "../../components/DynamicConfirmationModal";
import Information from "./Information";
import NewTaskItem from "../../components/newTaskItem";
import { MdOutlineEdit } from "react-icons/md";


// import InfiniteScroll from "react-infinite-scroll-component";
interface LeadDataI {
  _id: string;
  leadListId?: string;
  name: string;
  integration: string;
  customSource: string;
  createdAt: Date;
  status: Array<string>;
  saleValue: string;
  website: string;
  label: Array<any>;
  deleteLeadFromDrawer: (id: string) => void;
  copyLeadToList: () => void;
  moveLeadToList: () => void;
  onLabelClick: () => void;
  onStatusClick: () => void;
  setFrom: React.Dispatch<React.SetStateAction<string>>;
  handleEditModal: (name: string, id: any, action: string) => void;
  handleLeadDetailsModalOpen: (title: string, name: string) => void;
  onLeadFileUpload: (e: any) => void;
  editLead: (e: any, id: string) => void;
  leadFileDelete: (obj: any) => void;
  refreshData?: () => void;
  onDeleteLead?: () => void;
}

interface SelectedLeadI {
  activities: Array<any>;
  label: Array<any>;
  notes: Array<{
    createdAt: Date;
    createdBy: Date;
    description: string;
    lead: string;
    _id: string;
  }>;
  tasks: Array<any>;
  phone: string;
  email: string;
}

const LeadDetails: React.FC<PropsWithChildren<LeadDataI>> = ({
  _id,
  leadListId,
  name,
  integration,
  customSource,
  createdAt,
  status,
  saleValue,
  website,
  label,
  deleteLeadFromDrawer,
  copyLeadToList,
  moveLeadToList,
  onLabelClick,
  onStatusClick,
  setFrom,
  handleEditModal,
  handleLeadDetailsModalOpen,
  onLeadFileUpload,
  editLead,
  leadFileDelete,
  refreshData,
  onDeleteLead,
}) => {
  const StoreData = useSelector((state: any) => {
    return state?.rootReducers;
  });
  const [selectedLeadData, setSelectedLeadData] = useState<SelectedLeadI>({
    activities: [],
    label: [],
    notes: [],
    tasks: [],
    phone: "",
    email: "",
  });
  const createLeadFormRef = useRef();
  const [disableLeadInfo, setDisableLeadInfo] = useState<boolean>(true);
  const [leadData, setLeadData] = useState<any>({});
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [formData, setFormData] = useState({});
  const [viewFormResponse, setViewFormResponse] = useState<boolean>(false);
  const [openWithHeader, setOpenWithHeader] = useState<boolean>(false);
  const [action, setAction] = useState<string>("");
  const [taskData, setTaskData] = useState<any>({});
  const [activityData, setActivityData] = useState<any>({});
  const dispatch = useDispatch();
  const StateData = useSelector((state: any) => {
    return state?.rootReducers;
  });
  const [openFileDrawer, setOpenFileDrawer] = useState<any>(false);
  const [copyLeadDrawer, setCopyLeadDrawer] = useState(false);
  const [shareLeadDrawer, setShareLeadDrawer] = useState(false);
  const [assignLeadDrawer, setAssignLeadDrawer] = useState(false);
  const [activeLeadOption, setActiveLeadOption] = useState("copy");
  const [leadOwnerName, setLeadOwnerName] = useState<any>("");
  const [extraDetails, setExtraDetails] = useState<any>({});
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [leadListData, setLeadListData] = useState<any>([]);

  const [activeLeadId, setActiveLeadId] = useState("");
  const [contentData, setContentData] = useState<any>([]);
  const [contentType, setContentType] = useState("message");
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const selectedLeadContactDetailsGenerator = new ContactLinksGenerator({
    number:
      selectedLeadData.phone?.length >= 10
        ? "91" + selectedLeadData.phone
        : selectedLeadData.phone,
    email: selectedLeadData?.email || "",
  });

  const teamMembers =
    StateData?.userData?.userDetails?.organizationEmployee?.map((item: any) => {
      return { value: item?._id, label: item?.firstName + item?.lastName };
    });

  useEffect(() => {
    fetchLeadDetails();
  }, [_id]);

  const fetchLeadDetails = async () => {
    try {
      const response = await getLeadDetail(_id);
      if (response && response.status) {
        let responseData = response?.data?.data;
        let leadOwnerName =
          StateData?.userData?.userDetails?.organizationEmployee?.find(
            (x: any) => x._id == responseData.createdBy
          );
        let first_name = leadOwnerName?.firstName;
        let last_name = leadOwnerName?.lastName ? leadOwnerName?.lastName : "";
        setLeadData(responseData);
        dispatch(setActivityList(responseData?.activities));
        dispatch(setLeadTaskList(responseData?.tasks));
        dispatch(setNote(responseData?.notes));
        dispatch(leadFileList(responseData?.files));
        setExtraDetails(responseData.extraDetails || {});
        setLeadOwnerName(first_name + " " + last_name);
        setSelectedLeadData({
          ...selectedLeadData,
          activities: responseData?.activities,
          label: responseData?.label,
          notes: responseData?.notes,
          tasks: responseData?.tasks,
          phone: responseData?.phone,
          email: responseData?.email,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getAllCustomFormResponse = async () => {
    const response = await getLeadFormResponse(_id);
    setFormData(response.data.data[0]);
    setViewFormResponse(true);
  };

  const handleDelete = () => {
    if (action === "editTask") {
      onConfirmation(taskData?._id || "", "task");
    }
    if (action === "editActivity") {
      onConfirmation(activityData?._id || "", "activity");
    }
  };

  const handleEditActivityModal = async (
    name: string,
    _id: any,
    action: string
  ) => {
    if (name === "activity") {
      try {
        const response = await activityById(_id);
        if (response && response.status) {
          setActivityData(response.data.data);
          setAction("editActivity");
          setOpenWithHeader(true);
        }
      } catch (err) {
        toast.error("error while fetching activity data.");
      }
    }
    if (name === "task") {
      try {
        const response = await taskById(_id);
        if (response && response.status) {
          setTaskData(response.data.data);
          setAction("editTask");
          setOpenWithHeader(true);
        }
      } catch (err) {
        toast.error("error while fetching activity data.");
      }
    }
  };

  const toggleConfirmationModal = () => {
    setShowConfirmationModal(!showConfirmationModal);
  };

  const onConfirmation = async (id: string, name: string) => {
    if (name === "task" && id) {
      try {
        const response = await deleteTask(id);
        if (response && response.status) {
          toast.success(response?.data?.message);
          const tempArray = [...StateData?.leadTask?.leadTask];

          const tempData = tempArray.findIndex((x) => x._id == taskData._id);
          tempArray.splice(tempData, 1);
          dispatch(setLeadTaskList(tempArray));

          setTaskData({});
          toggleConfirmationModal();
        }
      } catch (err) {
        toast.error("Error while deleting task!");
      }
      setOpenWithHeader(false);
      setAction("");
    }
    if (name === "activity" && id) {
      // try {
      //   const response = await deleteTask(id);
      //   if (response && response.status) {
      //     toast.success(response?.data?.message);
      //     handleTaskFollowup(response?.data?.data);
      //     setTaskData({});
      //   }
      // } catch (err) {
      //   toast.error("Error while deleting task!");
      // }
      setOpenWithHeader(false);
      setAction("");
    }
  };

  const handleTaskFollowup = (responseData?: any) => {
    if (responseData) {
      const tempArray = [...StateData?.leadTask?.leadTask];
      if (action === "addTask") {
        tempArray?.unshift(responseData?.data);
        setLeadData(responseData?.lead);
      } else {
        const tempData = tempArray.findIndex(
          (x) => x._id == responseData.data._id
        );
        tempArray.splice(tempData, 1, responseData.data);
      }
      dispatch(setLeadTaskList(tempArray));
    }
  };

  const handleCopyLead = async (leadId: string) => {
    try {
      const data = {
        leadIDs: [leadData._id],
        targetListId: leadId || null,
      };

      if (activeLeadOption == "copy") {
        const response = await copyLeadInList(data);
        toast.success(response?.data?.message);
      } else {
        const response = await moveLeadInList(data);
        toast.success(response?.data?.message);
      }
      setCopyLeadDrawer(false);
    } catch (err) {
      console.log();
    }
  };

  const handleAssignLead = async (leadId: string) => {
    try {
      const data = {
        leadIds: [leadData._id],
        assignToUser: leadId,
      };
      const response = await assignLeadInList(data);
      toast.success(response?.data?.message);
      setAssignLeadDrawer(false);
    } catch (err) {}
  };

  const handleGetLeadList = async () => {
    try {
      const response = await getAllLeadList({
        isAscending: false,
        page: 1,
        perPage: 50,
      });
      if (response && response.status) {
        let defaultLead = {
          name: "Default lead list",
          _id: "",
        };
        setLeadListData([defaultLead, ...response.data.data]);
      }
    } catch (err) {
      console.log(err, "Error");
    }
  };

  useEffect(() => {
    handleGetLeadList();
  }, []);
  const drawerTitle =
    action === "addTask"
      ? "Add Task"
      : action === "editTask"
      ? "Edit Task"
      : action === "addActivity"
      ? "Add Activity"
      : "Edit Activity";

  // const onScrollLeadMore = () => {
  //   // 20 more records in .5 secs
  //   // setTimeout(() => {
  //   //   setActivityData(StateData.concat(Array.from({ length: 3 })));
  //   // }, 500);
  // };
  // phoneNumber.substring(phoneNumber.length - 10)

  const validateNumber = (number: string) => {
    if (number?.length < 11) {
      return `91${number}`;
    } else {
      return `${number}`;
    }
  };
  //  Share lead
  const shareContent = async (data: any, share: string) => {
    if (data.type == "file" || data.type == "page") {
      try {
        const body = {
          contentId: data._id,
          leadIds: [_id],
          performedAt: createdAt,
          contentType: data.type,
        };
        const response = await shareContentLead(body);
        const message = `Hi ${name || ""},

          Here's the link to view new one:
          ${response.data.data.uniqueLink}`;
        if (share == "whatsapp") {
          const whatsappUrl = `https://api.whatsapp.com/send?phone=${encodeURIComponent(
            validateNumber(selectedLeadContactDetailsGenerator?._number)
          )}&text=${encodeURIComponent(message)}`;
          window.open(whatsappUrl, "_blank");
        } else {
          const subject = `Hi ${name || ""}, ${
            StateData?.userData?.userDetails?.firstName
          } ${StateData?.userData?.userDetails?.lastName} has shared ${
            data.type
          } with you`;
          const emailUrl = `mailto:${
            selectedLeadContactDetailsGenerator?.mail
          }?subject=${subject}&body=${encodeURIComponent(message)}`;
          window.open(emailUrl, "_blank");
        }
      } catch (err) {
        console.log();
      }
    } else {
      try {
        const body = {
          type: "share",
          // contentId: data._id,
          leadIds: [_id],
          performedAt: createdAt,
          // contentType: data.type
        };

        const response = await createActivity(body);
        console.log("response ======>", response.data.data.uniqueLink);

        const message = (
          data.details?.description || data.details?.message
        )?.replace(/@lead name/g, name || "");
        if (share == "whatsapp") {
          const whatsappUrl = `https://api.whatsapp.com/send?phone=${encodeURIComponent(
            validateNumber(selectedLeadContactDetailsGenerator?._number)
          )}&text=${encodeURIComponent(message)}`;
          window.open(whatsappUrl, "_blank");
        } else {
          const subject = `Hi ${name || ""}, ${
            StateData?.userData?.userDetails?.firstName
          } ${StateData?.userData?.userDetails?.lastName} has shared ${
            data.type
          } with you`;
          const emailUrl = `mailto:${
            selectedLeadContactDetailsGenerator?.mail
          }?subject=${subject}&body=${encodeURIComponent(message)}`;
          window.open(emailUrl, "_blank");
        }
      } catch (err) {
        console.log();
      }
    }
  };

  const handleDeleteLead = async () => {
    try {
      const response = await deleteBulkLeads({
        leadIDs: [_id],
      });
      if (response && response.status) {
        toast.success(response?.data?.message);
        onDeleteLead && onDeleteLead();
      }
    } catch (err) {
      console.log(err, "Error");
    }
  };

  const fetchContent = async () => {
    const data = {
      isAscending: false,
      page: 1,
      perPage: 500,
    };
    const response = await getAllContent(data);
    if (response && response.status) {
      setContentData(response?.data?.data);
    }
  };

  useEffect(() => {
    fetchContent();
  }, []);

  const getEmployeeInfoById = (id: string) => {
    let name = "";

    let info = StateData?.userData?.userDetails?.organizationEmployee?.find(
      (e: any) => e._id === id
    );

    name = `${info?.firstName ?? "-"}  ${info?.lastName ?? ""}`;

    return name || "-";
  };

  const getListInfoById = (id: string) => {
    let name = "";

    let info = StateData?.userData?.userDetails?.organizationLeadList?.find(
      (e: any) => e._id === id
    );

    name = info?.name;

    return name || "-";
  };

  const isDeleteable =
    StateData?.userData?.userDetails?.userPreference?.screenPermission?.includes(
      "lead_profile_screen > options_modal > delete"
    );

  return (
    <div className="row" style={{ height: "100%" }}>
      <div className="col-md-4" style={{ paddingRight: "0" }}>
        <div
          className="popup-section-4-left"
          style={{ padding: "0px", height: "100%" }}
        >
          <div>
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
              <DropdownToggle
                className="border-0 d-flex align-items-center"
                style={{
                  marginLeft: "auto",
                  fontSize: "13px",
                  background: "none",
                  fontWeight: "700",
                  color: "#00000099",
                }}
              >
                Options
                <svg
                  width="18"
                  height="15"
                  viewBox="0 0 18 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.5575 5.36249L9 8.23124L12.4425 5.36249L13.5 6.24999L9 9.99999L4.5 6.24999L5.5575 5.36249Z"
                    fill="black"
                    fill-opacity="0.6"
                  />
                </svg>
              </DropdownToggle>
              <DropdownMenu className="lead_options_dropdown">
                <DropdownItem
                  tag="div"
                  className="dropdown_label"
                  style={{ fontSize: "16px" }}
                >
                  Lead options
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    setCopyLeadDrawer(true);
                    setActiveLeadOption("copy");
                  }}
                  className="d-flex gap-3 align-items-center"
                >
                  <svg
                    width="19"
                    height="22"
                    viewBox="0 0 19 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17 20H6V6H17M17 4H6C5.46957 4 4.96086 4.21071 4.58579 4.58579C4.21071 4.96086 4 5.46957 4 6V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H17C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20V6C19 5.46957 18.7893 4.96086 18.4142 4.58579C18.0391 4.21071 17.5304 4 17 4ZM14 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V16H2V2H14V0Z"
                      fill="black"
                    />
                  </svg>
                  Copy to list
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    setAssignLeadDrawer(true);
                  }}
                  className="d-flex gap-3 align-items-center"
                >
                  <svg
                    width="18"
                    height="20"
                    viewBox="0 0 18 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16 2C16.5304 2 17.0391 2.21071 17.4142 2.58579C17.7893 2.96086 18 3.46957 18 4V18C18 18.5304 17.7893 19.0391 17.4142 19.4142C17.0391 19.7893 16.5304 20 16 20H2C1.46957 20 0.960859 19.7893 0.585786 19.4142C0.210714 19.0391 0 18.5304 0 18V4C0 3.46957 0.210714 2.96086 0.585786 2.58579C0.960859 2.21071 1.46957 2 2 2H6.18C6.6 0.84 7.7 0 9 0C10.3 0 11.4 0.84 11.82 2H16ZM9 2C8.73478 2 8.48043 2.10536 8.29289 2.29289C8.10536 2.48043 8 2.73478 8 3C8 3.26522 8.10536 3.51957 8.29289 3.70711C8.48043 3.89464 8.73478 4 9 4C9.26522 4 9.51957 3.89464 9.70711 3.70711C9.89464 3.51957 10 3.26522 10 3C10 2.73478 9.89464 2.48043 9.70711 2.29289C9.51957 2.10536 9.26522 2 9 2ZM4 6V4H2V18H16V4H14V6H4ZM9 8C9.53043 8 10.0391 8.21071 10.4142 8.58579C10.7893 8.96086 11 9.46957 11 10C11 10.5304 10.7893 11.0391 10.4142 11.4142C10.0391 11.7893 9.53043 12 9 12C8.46957 12 7.96086 11.7893 7.58579 11.4142C7.21071 11.0391 7 10.5304 7 10C7 9.46957 7.21071 8.96086 7.58579 8.58579C7.96086 8.21071 8.46957 8 9 8ZM5 16V15C5 13.9 6.79 13 9 13C11.21 13 13 13.9 13 15V16H5Z"
                      fill="black"
                    />
                  </svg>
                  Assign lead
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    setCopyLeadDrawer(true);
                    setActiveLeadOption("move");
                  }}
                  className="d-flex gap-3 align-items-center"
                >
                  <svg
                    width="19"
                    height="21"
                    viewBox="0 0 19 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 15H14V12L19 16.5L14 21V18H10V15ZM9 7H14.5L9 1.5V7ZM2 0H10L16 6V10.34C15.37 10.12 14.7 10 14 10C12.4087 10 10.8826 10.6321 9.75736 11.7574C8.63214 12.8826 8 14.4087 8 16C8 17.54 8.58 18.94 9.53 20H2C0.89 20 0 19.1 0 18V2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0Z"
                      fill="black"
                    />
                  </svg>
                  Move to list
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    setShareLeadDrawer(true);
                  }}
                  className="d-flex gap-3 align-items-center"
                >
                  <svg
                    width="18"
                    height="15"
                    viewBox="0 0 18 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 7L11 0V4C4 5 1 10 0 15C2.5 11.5 6 9.9 11 9.9V14L18 7Z"
                      fill="black"
                    />
                  </svg>
                  Quick share
                </DropdownItem>

                {!isDeleteable && (
                  <DropdownItem
                    onClick={() => {
                      setDeleteModal(true);
                    }}
                    className="d-flex gap-3 align-items-center"
                  >
                    <svg
                      width="16"
                      height="18"
                      viewBox="0 0 16 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 0V1H0V3H1V16C1 16.5304 1.21071 17.0391 1.58579 17.4142C1.96086 17.7893 2.46957 18 3 18H13C13.5304 18 14.0391 17.7893 14.4142 17.4142C14.7893 17.0391 15 16.5304 15 16V3H16V1H11V0H5ZM5 5H7V14H5V5ZM9 5H11V14H9V5Z"
                        fill="#A42A31"
                      />
                    </svg>
                    Delete
                  </DropdownItem>
                )}
              </DropdownMenu>
            </Dropdown>
          </div>

          <div style={{ padding: "10px" }}>
            <div className="d-flex justify-content-center flex-column align-items-center">
              <h3
                className="text-capitalize text-black"
                style={{
                  fontSize: "16px",
                  fontWeight: "700",
                  fontFamily: "Gilroy-Bold",
                }}
              >
                {name || "No Name"}
              </h3>
              {saleValue && <h5 className="text-black">₹ {saleValue}</h5>}
              {/* <div className="dropdown">
                            <button className="btn btn-sm btn-secondary ms-2 pt-1 pb-0 pe-2 ps-2 mt-1 dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li><p className="dropdown-item">Assign Lead</p></li>
                                <li><p className="dropdown-item" onClick={() => copyLeadToList()}>Copy Lead to List</p></li>
                                <li><p className="dropdown-item" onClick={() => moveLeadToList()}>Move Lead to List</p></li>
                                <li><p className="dropdown-item" onClick={() => deleteLeadFromDrawer(_id)}>Delete Lead</p></li>
                            </ul>
                        </div> */}
            </div>
            <ul className="popup-section-4-left-1 mt-1 text-center">
              <li>
                {selectedLeadContactDetailsGenerator?._number?.length > 4 ? (
                  <a
                    href={`tel:+${validateNumber(
                      selectedLeadContactDetailsGenerator?._number
                    )}`}
                  >
                    <span
                      className="link_icon"
                      style={{ backgroundColor: "#2296f3" }}
                    >
                      <FaPhoneAlt />
                    </span>
                  </a>
                ) : (
                  <span
                    className="link_icon"
                    style={{ backgroundColor: "#adadad" }}
                  >
                    <FaPhoneAlt />
                  </span>
                )}
                {/* <img alt="tel" src="assets/img/telephone.png" height={30} width={30} /> */}
              </li>
              {/* <img alt="email" src="assets/img/email.png" height={30} width={30} /> */}
              <li>
                {selectedLeadContactDetailsGenerator?.mail ? (
                  selectedLeadContactDetailsGenerator?.mail?.length > 8 ? (
                    <a href={selectedLeadContactDetailsGenerator?.mail}>
                      <span
                        className="link_icon"
                        style={{ backgroundColor: "#ffc108" }}
                      >
                        <MdOutlineMail />
                      </span>
                    </a>
                  ) : (
                    <span
                      className="link_icon"
                      style={{ backgroundColor: "#adadad" }}
                    >
                      <MdOutlineMail />
                    </span>
                  )
                ) : (
                  <span
                    className="link_icon"
                    style={{ backgroundColor: "#adadad" }}
                  >
                    <MdOutlineMail />
                  </span>
                )}
              </li>
              <li>
                {selectedLeadContactDetailsGenerator?._number?.length > 4 ? (
                  <a
                    href={`https://api.whatsapp.com/send?phone=${addCountryCode(
                      selectedLeadData?.phone
                    )}`}
                    target="_blank"
                  >
                    <span
                      className="link_icon"
                      style={{ backgroundColor: "#29a81a" }}
                    >
                      <FaWhatsapp />
                    </span>
                  </a>
                ) : (
                  <span
                    className="link_icon"
                    style={{ backgroundColor: "#adadad" }}
                  >
                    <FaWhatsapp />
                  </span>
                )}

                {/* <img
                  alt="wp"
                  src="assets/img/whatsapp.png"
                  height={30}
                  width={30}
                  style={{ opacity: 0.7 }}
                /> */}
              </li>
              <li>
                <a onClick={() => setOpenFileDrawer(true)}>
                  <span
                    className="link_icon"
                    style={{ backgroundColor: "#a42a31" }}
                  >
                    <MdOutlineAttachFile />
                  </span>
                  {/* <img
                    alt="file"
                    src="assets/img/CircleClip.png"
                    height={30}
                    width={30}
                  /> */}
                </a>
              </li>
              <li>
                <a onClick={(e) => editLead(e, _id)}>
                  <span
                    className="link_icon"
                    style={{ backgroundColor: "#26a1f4" }}
                  >
                    <MdEdit />
                  </span>
                  {/* <img
                    alt="edit"
                    src="assets/img/edit.png"
                    height={30}
                    width={30}
                  /> */}
                </a>
              </li>
              <li>
                {leadData?.customForms?.length > 0 ? (
                  <a onClick={(e) => getAllCustomFormResponse()}>
                    <span
                      className="link_icon"
                      style={{ backgroundColor: "#ff9697" }}
                    >
                      <FaExternalLinkAlt />
                    </span>
                  </a>
                ) : (
                  <span
                    className="link_icon"
                    style={{ backgroundColor: "#adadad" }}
                  >
                    <FaExternalLinkAlt />
                  </span>
                )}
              </li>
              <DrawerComponent
                openWithHeader={openFileDrawer}
                setOpenWithHeader={setOpenFileDrawer}
                drawerTitle={`Files ${StateData?.leadFile?.leadFile?.length}`}
                size="sm"
              >
                <FileUpload
                  onLeadFileUpload={onLeadFileUpload}
                  StateData={StateData}
                  leadFileDelete={leadFileDelete}
                  leadId={_id}
                />
              </DrawerComponent>
            </ul>
            <ul className="popup-section-4-left-2">
              {selectedLeadData?.phone && (
                <li>
                  <span>Phone</span>
                  <br />
                  {selectedLeadData?.phone}
                </li>
              )}

              {selectedLeadData?.email && (
                <li>
                  <span>Email</span>
                  <br />
                  {selectedLeadData?.email}
                </li>
              )}

              {status?.length > 0 && (
                <li>
                  <div className="d-flex">
                    <span>Status</span>
                    <br />
                    {/* <i
                      className="bi bi-pencil ms-3 mt-1 cursor-pointer"
                      onClick={() => {
                        onStatusClick();
                        setFrom("details");
                      }}
                    ></i> */}
                    <div
                      className="status_edit_icon"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <MdOutlineEdit
                        color={"#fff"}
                        size={12}
                        onClick={() => {
                          onStatusClick();
                          setFrom("details");
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>

                  {!_.isEmpty(_id) && (
                    <LeadStatus
                      status={status}
                      preferences={{
                        status: [],
                      }}
                      isTag={true}
                    />
                  )}
                </li>
              )}
              <li>
                <div className="d-flex">
                  <span>Label</span>
                  <br />
                  {/* <i
                    className="bi bi-pencil ms-3 cursor-pointer"
                    onClick={() => {
                      onLabelClick();
                      setFrom("details");
                    }}
                  ></i> */}
                  <div
                    className="status_edit_icon"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <MdOutlineEdit
                      color={"#fff"}
                      size={12}
                      onClick={() => {
                        onLabelClick();
                        setFrom("details");
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
                {!_.isEmpty(_id) && (
                  <LeadLabel
                    label={label}
                    preferences={{
                      labels: [],
                    }}
                    isTag={true}
                    showAll={true}
                  />
                )}
              </li>

              {/* <li><span>Sale Value</span><br />&#8377; {saleValue}</li>
                       
                        {website ? <li><span>Webiste</span><br />{website}</li> : ''} */}
              <li>
                <span>Date added </span>
                <br />
                {createdAt && moment(createdAt).format("DD MMM YYYY")}
              </li>

              <li>
                <span>Lead Source</span>
                <br />
                {customSource !== ""
                  ? _.capitalize(customSource?.split("_")?.join(" "))
                  : _.capitalize(integration)}
              </li>

              <li>
                <span>Created by</span>
                <br />
                {getEmployeeInfoById(leadData?.createdBy)}
              </li>

              <li>
                <span>Assigned to</span>
                <br />
                {getEmployeeInfoById(leadData?.assignedTo)}
              </li>

              {leadData?.list ? (
                <li>
                  <span>List</span>
                  <br />
                  {getListInfoById(leadData?.list)}
                </li>
              ) : null}
            </ul>
          </div>
        </div>
      </div>
      <div className="col-md-8">
        <div
          className="popup-section-4-right"
          style={{ padding: "14px 25px", height: "100%" }}
        >
          <ul
            className="nav nav-pills d-flex gap-2"
            id="pills-tab"
            role="tablist"
          >
            <li className="nav-item flex-fill" role="presentation">
              <button
                className="nav-link active"
                id="pills-activites-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-activites"
                type="button"
                role="tab"
                aria-controls="pills-activites"
                aria-selected="true"
              >
                Activities ({leadData?.totalActivities || 0})
              </button>
            </li>
            <li className="nav-item flex-fill" role="presentation">
              <button
                className="nav-link"
                id="pills-task-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-task"
                type="button"
                role="tab"
                aria-controls="pills-task"
                aria-selected="false"
              >
                Tasks ({leadData?.totalTasks || 0})
              </button>
            </li>
            <li className="nav-item flex-fill" role="presentation">
              <button
                className="nav-link"
                id="pills-notes-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-notes"
                type="button"
                role="tab"
                aria-controls="pills-notes"
                aria-selected="false"
              >
                Notes ({leadData?.totalNotes || leadData?.totalNote || 0})
              </button>
            </li>
            <li className="nav-item flex-fill" role="presentation">
              <button
                className="nav-link"
                id="pills-Info-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-Info"
                type="button"
                role="tab"
                aria-controls="pills-Info"
                aria-selected="false"
              >
                Info
              </button>
            </li>
          </ul>
          <div className="tab-content pt-2" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="pills-activites"
              role="tabpanel"
              aria-labelledby="activites-tab"
            >
              <div className="row popup-section-4-add-11">
                <button
                  className="btn align-items-center"
                  onClick={() => {
                    setAction("addActivity");
                    setOpenWithHeader(true);
                  }}
                >
                  <svg
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.5 16.2C5.3105 16.2 1.9 12.969 1.9 9C1.9 5.031 5.3105 1.8 9.5 1.8C13.6895 1.8 17.1 5.031 17.1 9C17.1 12.969 13.6895 16.2 9.5 16.2ZM9.5 0C8.25244 0 7.0171 0.232792 5.86451 0.685084C4.71191 1.13738 3.66464 1.80031 2.78249 2.63604C1.00089 4.32387 0 6.61305 0 9C0 11.3869 1.00089 13.6761 2.78249 15.364C3.66464 16.1997 4.71191 16.8626 5.86451 17.3149C7.0171 17.7672 8.25244 18 9.5 18C12.0196 18 14.4359 17.0518 16.2175 15.364C17.9991 13.6761 19 11.3869 19 9C19 7.8181 18.7543 6.64778 18.2769 5.55585C17.7994 4.46392 17.0997 3.47177 16.2175 2.63604C15.3354 1.80031 14.2881 1.13738 13.1355 0.685084C11.9829 0.232792 10.7476 0 9.5 0ZM10.45 4.5H8.55V8.1H4.75V9.9H8.55V13.5H10.45V9.9H14.25V8.1H10.45V4.5Z"
                      fill="white"
                    />
                  </svg>
                  Add Activity
                </button>
              </div>
              <div
                className="detail_activity_scroll"
                // className={
                //   StateData.activity?.activities?.length > 3
                //     ? "row activity-scroll detail_activity_scroll"
                //     : "row"
                // }
              >
                {/* <InfiniteScroll
                  dataLength={StateData?.length}
                  next={onScrollLeadMore}
                  hasMore={true}
                  loader={<h4>Loading...</h4>}
                  scrollableTarget="scrollableDiv"
                > */}
                {StateData?.activity?.activities?.length > 0 ? (
                  StateData?.activity?.activities?.map(
                    (activity: any, i: number) => (
                      <ActivityItem
                        activity={activity}
                        name="activity"
                        key={i}
                        handleEditModal={handleEditActivityModal}
                      />
                    )
                  )
                ) : (
                  <EmptyState type="activities" />
                )}
                {/* </InfiniteScroll> */}
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-task"
              role="tabpanel"
              aria-labelledby="task-tab"
            >
              <div className="row popup-section-4-add-11">
                <button
                  className="btn align-items-center"
                  onClick={() => {
                    setAction("addTask");
                    setOpenWithHeader(true);
                  }}
                >
                  <svg
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.5 16.2C5.3105 16.2 1.9 12.969 1.9 9C1.9 5.031 5.3105 1.8 9.5 1.8C13.6895 1.8 17.1 5.031 17.1 9C17.1 12.969 13.6895 16.2 9.5 16.2ZM9.5 0C8.25244 0 7.0171 0.232792 5.86451 0.685084C4.71191 1.13738 3.66464 1.80031 2.78249 2.63604C1.00089 4.32387 0 6.61305 0 9C0 11.3869 1.00089 13.6761 2.78249 15.364C3.66464 16.1997 4.71191 16.8626 5.86451 17.3149C7.0171 17.7672 8.25244 18 9.5 18C12.0196 18 14.4359 17.0518 16.2175 15.364C17.9991 13.6761 19 11.3869 19 9C19 7.8181 18.7543 6.64778 18.2769 5.55585C17.7994 4.46392 17.0997 3.47177 16.2175 2.63604C15.3354 1.80031 14.2881 1.13738 13.1355 0.685084C11.9829 0.232792 10.7476 0 9.5 0ZM10.45 4.5H8.55V8.1H4.75V9.9H8.55V13.5H10.45V9.9H14.25V8.1H10.45V4.5Z"
                      fill="white"
                    />
                  </svg>
                  Add Task
                </button>
              </div>
              <TaskDrawer
                openWithHeader={openWithHeader}
                setOpenWithHeader={setOpenWithHeader}
                onTaskDelete={toggleConfirmationModal}
                showDelete={action.includes("edit")}
                drawerTitle={drawerTitle}
                size="xs"
              >
                {(action === "addTask" || action === "editTask") && (
                  <CreateTaskForm
                    leadId={_id}
                    updateTaskValue={taskData}
                    action={action === "addTask" ? "add" : "edit"}
                    status={""}
                    drawerClose={() => {
                      setOpenWithHeader(false);
                      setAction("");
                    }}
                    fetchTaskStatusFollowUps={handleTaskFollowup}
                  />
                )}
                {(action === "addActivity" || action === "editActivity") && (
                  <CreateActivityForm
                    leadIds={[{ id: _id }]}
                    updateModalValue={activityData}
                    action={action === "addActivity" ? "Add" : "Edit"}
                    formName={"Activity"}
                    handleActivityFormSubmit={(data) => {
                      setOpenWithHeader(false);
                      setAction("");
                      if (data) {
                        setLeadData(data);
                      }
                    }}
                  />
                )}
                {(action === "addNotes" || action === "editNotes") && (
                  <CreateNoteForm
                    leadIds={[{ id: _id }]}
                    handleNotesSubmit={(data) => {
                      setOpenWithHeader(false);
                      setAction("");

                      if (data) {
                        setLeadData(data);
                      }
                    }}
                  />
                )}
              </TaskDrawer>
              <div
                className={
                  StateData?.leadTask?.leadTask?.length > 3
                    ? "row activity-scroll"
                    : "row"
                }
              >
                {StateData?.leadTask?.leadTask?.length > 0 ? (
                  StateData?.leadTask?.leadTask?.map((task: any, i: number) => (
                    <NewTaskItem
                      activity={task}
                      onEditTask={(info) => {
                        setAction("editTask");
                        setTaskData(info);
                        setOpenWithHeader(true);
                      }}
                    />
                  ))
                ) : (
                  <EmptyState type="tasks" />
                )}
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-notes"
              role="tabpanel"
              aria-labelledby="notes-tab"
            >
              <div className="row popup-section-4-add-11">
                <button
                  className="btn align-items-center"
                  data-bs-toggle="modal"
                  onClick={() => {
                    setAction("addNotes");
                    setOpenWithHeader(true);
                  }}
                >
                  <svg
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.5 16.2C5.3105 16.2 1.9 12.969 1.9 9C1.9 5.031 5.3105 1.8 9.5 1.8C13.6895 1.8 17.1 5.031 17.1 9C17.1 12.969 13.6895 16.2 9.5 16.2ZM9.5 0C8.25244 0 7.0171 0.232792 5.86451 0.685084C4.71191 1.13738 3.66464 1.80031 2.78249 2.63604C1.00089 4.32387 0 6.61305 0 9C0 11.3869 1.00089 13.6761 2.78249 15.364C3.66464 16.1997 4.71191 16.8626 5.86451 17.3149C7.0171 17.7672 8.25244 18 9.5 18C12.0196 18 14.4359 17.0518 16.2175 15.364C17.9991 13.6761 19 11.3869 19 9C19 7.8181 18.7543 6.64778 18.2769 5.55585C17.7994 4.46392 17.0997 3.47177 16.2175 2.63604C15.3354 1.80031 14.2881 1.13738 13.1355 0.685084C11.9829 0.232792 10.7476 0 9.5 0ZM10.45 4.5H8.55V8.1H4.75V9.9H8.55V13.5H10.45V9.9H14.25V8.1H10.45V4.5Z"
                      fill="white"
                    />
                  </svg>
                  Add Notes
                </button>
              </div>
              <div
                className={
                  StateData?.note?.notes?.length > 3
                    ? "row activity-scroll"
                    : "row"
                }
              >
                {StateData?.note?.notes?.length > 0 ? (
                  StateData?.note?.notes?.map((activity: any, i: number) => (
                    <LeadNoteItems activity={activity} key={i} />
                  ))
                ) : (
                  <EmptyState type="notes" />
                )}
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="pills-Info"
              role="tabpanel"
              aria-labelledby="Info-tab"
            >
              <div className="d-flex justify-content-between align-items-center">
                {/* <h4 className=" mt-2">Lead Information</h4>
                <div>
                  <Button
                    size="small"
                    onClick={(e) =>
                      setDisableLeadInfo((prevState) => !prevState)
                    }
                  >
                    {disableLeadInfo ? (
                      <MdEdit title="Edit Changes" size={20} />
                    ) : (
                      <MdSave
                        onClick={() =>
                          (createLeadFormRef?.current as any)?.handleSubmit()
                        }
                        title="Save Changes"
                        size={20}
                      />
                    )}
                  </Button>
                </div> */}
              </div>
              {disableLeadInfo ? (
                <Information
                  leadToBeUpdate={_id}
                  onEdit={() => setDisableLeadInfo((prevState) => !prevState)}
                />
              ) : (
                <CreateLeadForm
                  isDetailsBox={true}
                  isEditMode={!disableLeadInfo}
                  disabled={disableLeadInfo}
                  showCreateNewLeadDrawer={() => {}}
                  getLeads={() => refreshData && refreshData()}
                  leadToBeUpdate={_id}
                  handleDrawerClick={() => {}}
                  selectedLeadId={leadListId || "0"}
                  leadList={StoreData.leadList.leadList}
                  ref={createLeadFormRef}
                  onClose={() => setDisableLeadInfo((prevState) => !prevState)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal
        message="Are you sure you want to delete this task?"
        onConfirmation={handleDelete}
        showModal={showConfirmationModal}
        toggleModal={toggleConfirmationModal}
        title={action === "editTask" ? "Task" : "Activity"}
      />
      {viewFormResponse && (
        <FormResponse
          open={viewFormResponse}
          handleClose={() => {
            setViewFormResponse(false);
          }}
          formDetails={formData}
          formFields={{}}
        />
      )}
      {/* Copy Lead */}
      <GlobalDrawer
        openWithHeader={copyLeadDrawer}
        setOpenWithHeader={() => {
          setCopyLeadDrawer(false);
        }}
        drawerTitle={activeLeadOption === "copy" ? "Copy Lead" : "Move Lead"}
        size="xs"
        customHeader
      >
        <label className="form-label">Select Destination lead list </label>
        <div>
          {leadListData?.length > 0
            ? leadListData?.map((item: any, ix: number) => {
                if (item._id != leadData.list) {
                  return (
                    <button
                      onClick={() => {
                        handleCopyLead(item._id);
                      }}
                      key={ix}
                      className="d-block bg-white w-100 p-3 rounded mb-2"
                      style={{ margin: "8px 0px", textAlign: "left" }}
                    >
                      {item.name}
                    </button>
                  );
                } else {
                }
              })
            : "No Record Found"}
        </div>
      </GlobalDrawer>

      {/* Assign Lead */}
      <GlobalDrawer
        openWithHeader={assignLeadDrawer}
        setOpenWithHeader={() => {
          setAssignLeadDrawer(false);
        }}
        drawerTitle={"Assign Lead"}
        size="xs"
        customHeader
      >
        <label className="form-label">Select team member</label>
        <div>
          <button
            onClick={() =>
              handleAssignLead(StateData?.userData?.userDetails?._id)
            }
            className="d-block bg-white w-100 p-3 rounded mb-2"
            style={{ margin: "8px 0px", textAlign: "left" }}
          >
            Assign to self
          </button>
          {teamMembers?.map((item: any, ix: number) => {
            return (
              <button
                onClick={() => handleAssignLead(item.value)}
                className="d-block bg-white w-100 p-3 rounded mb-2"
                style={{ margin: "8px 0px", textAlign: "left" }}
              >
                {item?.label?.replace("null", "")}
              </button>
            );
          })}
        </div>
      </GlobalDrawer>

      {/* Share Lead Box */}

      <GlobalDrawer
        openWithHeader={shareLeadDrawer}
        setOpenWithHeader={() => {
          setShareLeadDrawer(false);
        }}
        drawerTitle={"Share Content"}
        size="xs"
        customHeader
      >
        <div className="share_lead_box">
          <label className="form-label">Select Content Type</label>
          <select
            className="form-control mb-4"
            value={contentType}
            onChange={(e) => setContentType(e.target.value)}
          >
            <option value="message"> Message</option>
            <option value="email_template"> Email</option>
            <option value="page"> Page</option>
            <option value="file"> File</option>
          </select>
          <div>
            {contentData
              .filter((i: any) => i.type == contentType)
              ?.map((item: any, ix: number) => {
                return (
                  <div className="share_card">
                    <button
                      onClick={() =>
                        setActiveLeadId(
                          activeLeadId == item._id ? "" : item._id
                        )
                      }
                      className="action_btn"
                    >
                      {item.details?.title || "Null"}
                      <IoIosArrowDown />
                    </button>
                    {activeLeadId == item._id && (
                      <div className="share_content">
                        <label className="form-label">Message : </label>
                        <p>
                          {item.details?.message?.replace(
                            /@lead name/g,
                            name || ""
                          ) ||
                            item.details?.description?.replace(
                              /@lead name/g,
                              name || ""
                            ) ||
                            ""}
                        </p>
                        <div className="share_buttons">
                          {/* <a href={`https://api.whatsapp.com/send?phone=${encodeURIComponent(validateNumber(selectedLeadContactDetailsGenerator?._number))}&text=${encodeURIComponent(item.details?.description)}`}
                          target="_blank">
                          Share via Whatsapp <FaWhatsapp />
                        </a>
                        <a href={`mailto:${selectedLeadContactDetailsGenerator?.mail}?subject=This%20Subject&body=${encodeURIComponent(item.details?.description)}`}
                        > Share via Email <MdOutlineMail /></a> */}

                          {selectedLeadContactDetailsGenerator?._number
                            ?.length > 4 && (
                            <button
                              onClick={() => shareContent(item, "whatsapp")}
                            >
                              Share via Whatsapp <FaWhatsapp />
                            </button>
                          )}
                          {selectedLeadContactDetailsGenerator?.mail &&
                            selectedLeadContactDetailsGenerator?.mail?.length >
                              8 && (
                              <button
                                onClick={() => shareContent(item, "email")}
                              >
                                Share via Email <MdOutlineMail />
                              </button>
                            )}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
          </div>
          <DynamicConfirmationModal
            onConfirmation={() => {
              setDeleteModal(false);

              handleDeleteLead();

              // setOpenWithHeader(false);
            }}
            showModal={deleteModal}
            toggleModal={() => {
              setDeleteModal(false);
            }}
            title="Are you sure you want delete?"
          />
        </div>
      </GlobalDrawer>
    </div>
  );
};

export default LeadDetails;
