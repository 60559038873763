import React, { useState, useCallback, useRef, useEffect } from 'react';
import { IoMdAdd } from "react-icons/io";
import { RiDeleteBin6Fill } from "react-icons/ri";
import toast from "react-hot-toast";
import { uploadFiles } from '../services/utilityService';

export interface FileWithProgress {
  name: string;           // From File interface
  fileName?: string;      // Optional for server files
  filePath?: string;      // Optional for server files
  progress?: number;      // For upload progress
  uploaded?: boolean;     // Upload status
  error?: string;        // Error handling
  size: number;          // From File interface
  // Add other File properties you need
}

interface FileUploadHandlerProps {
  files: Array<{fileName?: string; filePath?: string; name?: string}>;
  onFileChange: (files: FileWithProgress[]) => void;
  onDeleteServerFile: (path: string) => Promise<void>;
  maxSize?: number;
  acceptedTypes?: string[];
  activeId?: string;
}

const FileUploadHandler: React.FC<FileUploadHandlerProps> = ({
  files = [],
  onFileChange,
  onDeleteServerFile,
  maxSize = 100,
  acceptedTypes = ['.mp4', '.wmv', '.pdf', '.jpeg', '.jpg', '.png', '.gif', '.webp'],
  activeId
}) => {
  const [localFiles, setLocalFiles] = useState<FileWithProgress[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  
  useEffect(() => {
    return () => {
      localFiles.forEach(file => {
        if (file instanceof Blob) {
          URL.revokeObjectURL(URL.createObjectURL(file));
        }
      });
    };
  }, []);

  const validateFile = useCallback((file: File): string | null => {
    console.log('Validating file:', file.name);
    
    if (file.size > maxSize * 1024 * 1024) {
      return `File size exceeds ${maxSize}MB limit`;
    }

    const fileType = '.' + file.name.split('.').pop()?.toLowerCase();
    if (!acceptedTypes.includes(fileType)) {
      return `Invalid file type. Accepted: ${acceptedTypes.join(', ')}`;
    }

    return null;
  }, [maxSize, acceptedTypes]);

  const uploadFile = async (file: File): Promise<string> => {
    try {
      if (!activeId) {
        toast.error('Unable to upload file - missing content ID');
        throw new Error('Missing content ID');
      }
  
      const formData = new FormData();
      formData.append("files", file);
      formData.append("type", "content");
      formData.append("content", activeId);
  
      const response = await uploadFiles(formData);
      if (response.status === 200) {
        toast.success('File uploaded successfully');
        return response.data.data;
      }
      throw new Error('Upload failed');
    } catch (error) {
      console.error('Upload error:', error);
      toast.error('Failed to upload file');
      throw error;
    }
  };
  
  const handleFileSelect = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = Array.from(event.target.files || []);
    console.log('Files selected:', newFiles.map(f => f.name));
    
    const validFiles: FileWithProgress[] = [];
    
    newFiles.forEach(file => {
        const error = validateFile(file);
        if (error) {
            toast.error(`${file.name}: ${error}`);
            return;
        }
        
        // Spread first, then add our custom properties so they don't get overwritten
        const fileWithProgress: FileWithProgress = {
            ...file,            // Base File properties
            name: file.name,    // Ensure these aren't overwritten
            size: file.size,    // Ensure these aren't overwritten
            progress: 0,        // Our custom properties
            uploaded: false,    // Our custom properties
        };
        
        validFiles.push(fileWithProgress);
    });
    
    if (validFiles.length > 0) {
        setLocalFiles(prev => [...prev, ...validFiles]);
        onFileChange([...localFiles, ...validFiles]);
    }
    
    if (fileInputRef.current) {
        fileInputRef.current.value = '';
    }
}, [localFiles, onFileChange, validateFile]);
  

  const handleLocalFileDelete = useCallback((index: number) => {
    console.log('Deleting local file at index:', index);
    setLocalFiles(prev => {
      const newFiles = [...prev];
      newFiles.splice(index, 1);
      onFileChange(newFiles);
      return newFiles;
    });
  }, [onFileChange]);

  return (
    <div className="user_form_box">
      <label>Upload File</label>
      <div className="file_upload position-relative mb-3" style={{ width: '58px' }}>
        <input
          ref={fileInputRef}
          type="file"
          accept={acceptedTypes.join(',')}
          className="position-relative"
          style={{ zIndex: "9", opacity: 0 }}
          onChange={handleFileSelect}
          multiple
        />
        <span
          className="position-absolute w-100 h-100 top-0 d-flex align-items-center justify-content-center"
          style={{ border: "2px dashed #3FAEFDD1", fontSize: '44px', color: '#60bafc', borderRadius: '18px' }}
        >
          <IoMdAdd />
        </span>
      </div>

      {localFiles.map((file, index) => (
        <p key={`local-${index}`} className="file_name">
          {file.name}
          <button onClick={() => handleLocalFileDelete(index)}>
            <RiDeleteBin6Fill />
          </button>
        </p>
      ))}

      {files.map((file, index) => (
        <p key={`server-${index}`} className="file_name">
          {file.fileName || file.name}
          <button onClick={() => file.filePath && onDeleteServerFile(file.filePath)}>
            <RiDeleteBin6Fill />
          </button>
        </p>
      ))}
    </div>
  );
};

export default FileUploadHandler;