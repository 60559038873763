import React, { useState } from 'react';
import { Trash2, Users2, Calendar, X, CheckSquare } from 'lucide-react';
import { DatePicker } from 'antd';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

interface TaskBulkActionsBarProps {
  selectedCount: number;
  onClearSelection: () => void;
  onBulkAction: (
    action: string, 
    date?: string,
    assigneeId?: string
  ) => void;
}


interface Employee {
  value: string;
  label: string;
}

interface ConfirmationState {
  isOpen: boolean;
  action: string;
  message: string;
  selectedEmployee?: string;
}

const ActionBar = styled.div`
  position: fixed;
  bottom: 24px;
  left: 240px; // Account for sidebar
  right: 24px;
  margin: 0 auto;
  width: fit-content;
  background: #ffffff;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  gap: 24px;
  border: 1px solid #f0f0f0;
  z-index: 1000;
`;

// ADD this styled component
const DatePickerContainer = styled.div`
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 8px;
  z-index: 1001;
  width: 300px;

  .ant-picker {
    width: 100%;
  }
`;
const SelectionBadge = styled.div`
  background: rgba(37, 99, 235, 0.1);
  color: #2563eb;
  padding: 8px 16px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  min-width: 100px;
  text-align: center;
`;

const ActionGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding-left: 16px;
  border-left: 1px solid #f0f0f0;
`;

const ActionButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  padding: 8px;
  border-radius: 8px;
  border: none;
  background: transparent;
  cursor: pointer;
  color: #64748b;
  transition: all 0.2s;
  min-width: 64px;

  &:hover {
    background: #f8fafc;
    color: #2563eb;
  }

  svg {
    width: 20px;
    height: 20px;
  }

  span {
    font-size: 12px;
    font-weight: 500;
  }
`;



const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1100;
`;

const SelectWrapper = styled.div`
  margin-bottom: 24px;
  select {
    width: 100%;
    height: 40px;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid #e2e8f0;
  }
`;

const ModalContent = styled.div`
  position: relative;
  z-index: 1101;
  background: white;
  border-radius: 16px;
  padding: 24px;
  width: 400px;
  max-width: 90vw;
`;

const ModalTitle = styled.h3`
  margin: 0 0 16px;
  font-size: 18px;
  font-weight: 600;
  color: #1e293b;
`;

const ModalMessage = styled.p`
  margin: 0 0 24px;
  color: #64748b;
  font-size: 14px;
  line-height: 1.5;
`;

const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
`;

const Button = styled.button<{ isWarning?: boolean }>`
  padding: 8px 16px;
  border-radius: 8px;
  border: none;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;

  ${props => props.isWarning ? `
    background: #ef4444;
    color: white;
    &:hover { background: #dc2626; }
  ` : `
    background: #f1f5f9;
    color: #64748b;
    &:hover { background: #e2e8f0; }
  `}
`;

const getActionTitle = (action: string): string => {
  switch (action) {
    case 'delete': return 'Delete';
    case 'reassign': return 'Reassign';
    case 'updateDueDate': return 'Update Date';
    case 'complete': return 'Complete';
    default: return 'Confirm';
  }
};

const getConfirmationMessage = (action: string, count: number): string => {
  switch (action) {
    case 'delete':
      return `Are you sure you want to delete ${count} selected tasks? This action cannot be undone.`;
    case 'complete':
      return `Are you sure you want to mark ${count} tasks as complete?`;
      case 'reassign':
        return `Select an employee to reassign ${count} tasks:`;
    default:
      return 'Are you sure you want to continue?';
  }
};




const TaskBulkActionsBar: React.FC<TaskBulkActionsBarProps> = ({
  selectedCount,
  onClearSelection,
  onBulkAction
}) => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [confirmation, setConfirmation] = useState<ConfirmationState>({
    isOpen: false,
    action: '',
    message: ''
  });


  const handleActionClick = (action: string) => {
    if (action === 'updateDueDate') {
      setShowDatePicker(true);
    } else {
      setConfirmation({
        isOpen: true,
        action,
        message: getConfirmationMessage(action, selectedCount)
      });
    }
  };

  const handleDateChange = (date: moment.Moment | null) => {
    if (date) {
      // Format date as YYYY-MM-DD string without time
      const formattedDate = date.format('YYYY-MM-DD');
      // Call bulk action with formatted date string
      onBulkAction('updateDueDate', formattedDate);
      // Close the date picker
      setShowDatePicker(false);
    }
  };
  

  const formatName = (firstName: string, lastName: string | null) => {
    if (!firstName) return '';
    return lastName ? `${firstName} ${lastName}`.trim() : firstName.trim();
  };
  
  // Use useSelector to get employees
  const employees: Employee[] = useSelector((state: any) => {
    const orgEmployees = state?.rootReducers?.userData?.userDetails?.organizationEmployee;
    return orgEmployees?.map((item: any) => ({
      value: item?._id,
      label: formatName(item?.firstName, item?.lastName)
    })) || [];
  });
  
  const handleConfirm = () => {
    onBulkAction(confirmation.action);
    setConfirmation({ isOpen: false, action: '', message: '' });
  };

  return (
    <>
      <ActionBar>
        <SelectionBadge>
          {selectedCount} selected
        </SelectionBadge>

        <ActionGroup>
          <ActionButton
            onClick={() => handleActionClick('delete')}
            title="Delete selected"
          >
            <Trash2 />
            <span>Delete</span>
          </ActionButton>

          <ActionButton
            onClick={() => handleActionClick('reassign')}
            title="Reassign tasks"
          >
            <Users2 />
            <span>Reassign</span>
          </ActionButton>

        
          <div style={{ position: 'relative' }}>
    <ActionButton
      onClick={() => handleActionClick('updateDueDate')}
      title="Update due date"
    >
      <Calendar />
      <span>Due Date</span>
    </ActionButton>

    {showDatePicker && (
      <DatePickerContainer>
        <DatePicker
          showTime
          format="YYYY-MM-DD"
          onChange={handleDateChange}
          onOpenChange={(open) => !open && setShowDatePicker(false)}
          autoFocus
        />
      </DatePickerContainer>
    )}
  </div>

          <ActionButton
            onClick={() => handleActionClick('complete')}
            title="Mark as complete"
          >
            <CheckSquare />
            <span>Complete</span>
          </ActionButton>

          <ActionButton
            onClick={onClearSelection}
            title="Clear selection"
          >
            <X />
            <span>Clear</span>
          </ActionButton>
        </ActionGroup>

        
      </ActionBar>

      {confirmation.isOpen && (
  <ModalOverlay>
    <ModalContent>
      <ModalTitle>{getActionTitle(confirmation.action)}</ModalTitle>
      <ModalMessage>{confirmation.message}</ModalMessage>
      
      {/* Reassignment Select */}
      {confirmation.action === 'reassign' && (
        <SelectWrapper>
          <select
            value={confirmation.selectedEmployee || ''}
            onChange={(e) => setConfirmation(prev => ({
              ...prev,
              selectedEmployee: e.target.value
            }))}
            className="form-select"
          >
            <option value="">Select Employee</option>
            {employees.map((emp: Employee) => (
  <option key={emp.value} value={emp.value}>
    {emp.label}
  </option>
))}
          </select>
        </SelectWrapper>
      )}

      

      {/* Action Buttons */}
      <ModalActions>
        <Button 
          onClick={() => setConfirmation({ 
            isOpen: false, 
            action: '', 
            message: '',
            selectedEmployee: undefined 
          })}
        >
          Cancel
        </Button>


        {/* Show Confirm button only for non-datepicker actions */}
        {confirmation.action !== 'updateDueDate' && (
          <Button 
            isWarning={confirmation.action === 'delete'}
            disabled={confirmation.action === 'reassign' && !confirmation.selectedEmployee}
            onClick={() => {
              if (confirmation.action === 'reassign') {
                if (confirmation.selectedEmployee) {
                  onBulkAction('reassign', undefined, confirmation.selectedEmployee);
                }
              } else {
                onBulkAction(confirmation.action);
              }
              setConfirmation({ 
                isOpen: false, 
                action: '', 
                message: '',
                selectedEmployee: undefined 
              });
            }}
          >
            {getActionTitle(confirmation.action)}
          </Button>
        )}
      </ModalActions>
      
    </ModalContent>
  </ModalOverlay>
)}
    </>
  );
};

export default TaskBulkActionsBar;