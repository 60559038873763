import React from 'react'
import styled from 'styled-components'

interface PropsType {
    children: React.ReactNode; // Define the children prop
}
const PaymentFiels: React.FC<PropsType> = (props) => {
    return (
        <Root>
            {props.children}
        </Root>
    )
}
export default PaymentFiels;
const Root = styled.div`
    background-color:#fff;
    padding:50px 80px;
    width: 100%;
    margin:auto;
    height: 100%;
    text-align: center;
    @media (max-width: 500px){
        padding: 10px 64px;
    }
    input{
        width:100%;
    }
    .field_name,
  .title {
    width: fit-content;
    font-size: 24px;
    font-weight: 600;
    color: #000;
    position: relative;
    input {
      border: none;
    }
   
  }
  .sr_number {
    position: relative;
    span {
      position: absolute;
      left: -54px;
      top: 50%;
      transform: translateY(-50%);
    }
    .text_danger {
      color: red;
      position: absolute;
      right: -12px;
      top: 2px;
    }
  }
    .field_name input ,.discription , .input_box{
            text-align:center;
    }
    
    .discription{
        background: transparent;
        border: none;
        display:block;
        width:100%;
    }
    .discription::placeholder{
        color:#000;
    }
    input:focus{
        outline: none !important;
        border-color: none;
        box-shadow: 0 0 10px #fff;
    }
    .input_box {
        padding: 6px 10px;
        margin-top: 50px;
        width: 100px;
        font-size: 20px;
        font-family: "Gilroy-Medium";
        color: #000;
        cursor: pointer;
        border-radius: 10px;
        border: none;
        background: #e1e1e1;
    }
    input::placeholder{
        color:#000;
    }
    .delete_btn{
        background: none;
        position: absolute;
        top: -4px;
        right: 12px;
        width: fit-content;
    }
    .payment_amount{
        margin-top:20px;
    }
    input::placeholder{
        color:#0000008d;
    }
    button.pay_btn {
    background: #00b33f;
    padding: 12px 22px;
    border-radius: 10px;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
    display: block;
    margin: 50px auto;
    }
.payment_link{
    font-size: 26px;
    display: block;
    margin-top: 20px;
}
`