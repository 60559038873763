import React, { useEffect, useState } from "react";
import { postUserIntigration } from "../services/automationService";
import { getUserProfile } from "../services/authServices";
import { Button } from "antd";

declare global {
  interface Window {
    FB: any;
    fbAsyncInit: () => void;
  }
}

let FBApp2: any;
declare var FB: any;

interface IProps {
  onSuccess?: () => void;
}

const FacebookIntegration = ({ onSuccess }: IProps) => {
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);

  const fetchUserProfile = async (value: any) => {
    try {
      const obj = {
        integrationKey: "whatsapp_business",
        details: value,
      };

      const result = await postUserIntigration(obj);
      const { data } = await getUserProfile();

      setMessage(result.data.message);
      localStorage.setItem("userData", JSON.stringify(data.data));
      onSuccess && onSuccess();
    } catch (error) {
      console.error("Error fetching user profile:", error);
      setError(
        "An error occurred while fetching user profile. Please try again."
      );
    }
  };

  const onRefresh = async () => {
    try {
      const { data } = await getUserProfile();

      localStorage.setItem("userData", JSON.stringify(data.data));
      onSuccess && onSuccess();
    } catch (error) {}
  };

  useEffect(() => {
    const loadFBApp2 = () => {
      return new Promise<void>((resolve) => {
        const script = document.createElement("script");
        script.src = "https://connect.facebook.net/en_US/sdk.js";
        script.async = true;
        script.onload = () => {
          FB.init({
            appId: "883658973014756",
            cookie: true,
            xfbml: true,
            version: "v18.0",
          });
          FBApp2 = FB;
          resolve();
        };
        document.body.appendChild(script);
      });
    };

    loadFBApp2();
  }, []);

  const launchWhatsAppSignup = async () => {
    try {
      await postUserIntigration({
        integrationKey: "whatsapp_business",
      });

      FBApp2.login(
        function (response: any) {
          if (response.authResponse) {
            console.log("Facebook login response:", response);
            fetchUserProfile(response.authResponse);
          } else {
            console.log("User cancelled login or did not fully authorize.");
            setError(
              "Facebook login was cancelled or not fully authorized. Please try again."
            );
          }
        },
        {
          config_id: "1066369917929689",
          response_type: "code",
          override_default_response_type: true,
        }
      );
    } catch (error) {
      console.error("Error launching WhatsApp signup:", error);
      setError(
        "An error occurred while launching WhatsApp signup. Please try again."
      );
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const renderCard = (title: string, content: React.ReactNode) => (
    <div className="card">
      <h2 className="card-title">{title}</h2>
      {content}
    </div>
  );

  const renderWhatsAppAPIBenefits = () => (
    <div>
      <p
        style={{
          fontSize: "14px",
          lineHeight: "1.6",
          color: "#555",
          marginBottom: "16px",
        }}
      >
        The WhatsApp API integration in 3sigma CRM empowers you to automate and
        streamline your business communications, helping you reach your
        customers quickly and effectively. This feature offers a range of
        powerful tools designed to enhance your messaging experience:
      </p>
      <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
        {[
          {
            icon: "📢",
            title: "Send Campaigns",
            description:
              "Launch targeted WhatsApp campaigns to promote your products, services, or special offers. Schedule campaigns in advance and track their performance to optimize your reach.",
          },
          {
            icon: "💬",
            title: "Automated Drip Messaging",
            description:
              "Set up automated drip message sequences that nurture your leads over time, keeping them engaged at every stage of the sales process.",
          },
          {
            icon: "⚡",
            title: "Trigger Automated Messages",
            description:
              "Use triggers based on customer actions (e.g., new lead entries, missed calls, or completed forms) to automatically send personalized messages, ensuring quick and effective follow-ups.",
          },
          {
            icon: "📥",
            title: "Manage a Shared Inbox",
            description:
              "Collaborate with your team using a shared WhatsApp inbox where you can assign messages, respond to customer queries, and track conversations in real time—all from a single dashboard.",
          },
          {
            icon: "📋",
            title: "Create and Manage Templates",
            description:
              "Design and save message templates for common interactions, such as appointment reminders, sales follow-ups, or welcome messages. With templates, you can maintain consistency and save time when messaging clients.",
          },
        ].map((item, index) => (
          <li
            key={index}
            style={{
              display: "flex",
              alignItems: "flex-start",
              marginBottom: "16px",
            }}
          >
            <span style={{ fontSize: "24px", marginRight: "12px" }}>
              {item.icon}
            </span>
            <div>
              <h3
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  marginBottom: "4px",
                  color: "#333",
                }}
              >
                {item.title}
              </h3>
              <p
                style={{
                  fontSize: "14px",
                  lineHeight: "1.4",
                  color: "#555",
                  margin: 0,
                }}
              >
                {item.description}
              </p>
            </div>
          </li>
        ))}
      </ul>
      <div
        style={{
          marginTop: "16px",
          padding: "12px",
          backgroundColor: "#f0f4f8",
          borderRadius: "8px",
        }}
      >
        <h3
          style={{
            fontSize: "16px",
            fontWeight: "bold",
            marginBottom: "8px",
            color: "#333",
          }}
        >
          How Users Are Charged 💰
        </h3>
        <p
          style={{
            fontSize: "14px",
            lineHeight: "1.4",
            color: "#555",
            margin: 0,
          }}
        >
          The WhatsApp API feature is billed based on message volume and usage.
          Charges apply per message sent, including campaigns, automated
          sequences, and template messages. We offer flexible plans tailored to
          your needs, with all charges added to your monthly invoice for easy
          monitoring.
        </p>
      </div>
    </div>
  );

  const renderOnboardingGuide = () => (
    <div>
      <h3
        style={{
          fontSize: "18px",
          fontWeight: "bold",
          marginBottom: "12px",
          color: "#333",
        }}
      >
        Before You Begin
      </h3>
      <p
        style={{
          fontSize: "14px",
          lineHeight: "1.6",
          color: "#555",
          marginBottom: "12px",
        }}
      >
        Before you get started with the WhatsApp Business API (WABA) setup, make
        sure you have the following ready:
      </p>
      <ul style={{ paddingLeft: "20px", margin: "0 0 16px 0" }}>
        {[
          "Phone Number: Ensure you have your business phone number ready to receive the OTP for verification.",
          "Remove Existing WhatsApp Account: If you're currently using WhatsApp with this number, back up your data and remove it from the device.",
          "Facebook Business Manager Access: Ensure you have access to your Facebook Business Manager account.",
          "3Sigma Ultimate Plan: You need to be subscribed to the 3Sigma Ultimate Plan to proceed.",
          "Migration Note: If you're migrating from another provider, don't worry! All settings and templates will be seamlessly migrated to make the transition smooth. Be sure to remove two-factor authentication (2FA) before proceeding with the setup.",
        ].map((item, index) => (
          <li
            key={index}
            style={{ fontSize: "14px", marginBottom: "8px", color: "#333" }}
          >
            {item}
          </li>
        ))}
      </ul>
      <h3
        style={{
          fontSize: "18px",
          fontWeight: "bold",
          marginBottom: "12px",
          color: "#333",
        }}
      >
        Step-by-Step Process
      </h3>
      <ol style={{ paddingLeft: "20px", margin: 0 }}>
        {[
          "Sign Up for WABA API: Sign up for the WhatsApp Business API (WABA) using your Facebook Business Manager account.",
          "Permission Review: Grant the necessary permissions for the WABA API to manage your WhatsApp Business account.",
          "Business Information: Fill in your business details accurately. This information will be used to create your WhatsApp Business Account (WABA).",
          "Set Up Business Profile: Create your WhatsApp Business profile. This information will be visible to your customers when they contact you via WhatsApp.",
          "Verify Phone Number: Verify the phone number you want to use for your WhatsApp Business account. An OTP will be sent to this number.",
          "Connect to 3Sigma CRM: After verifying the number, authorize 3Sigma CRM to access your WABA account and complete the integration.",
          "Configure WABA in 3Sigma CRM: Once the setup is complete, configure your WhatsApp Business API settings within 3Sigma CRM to start managing your WhatsApp communications.",
        ].map((step, index) => (
          <li
            key={index}
            style={{ fontSize: "14px", marginBottom: "8px", color: "#333" }}
          >
            {step}
          </li>
        ))}
      </ol>
      <p
        style={{
          fontSize: "12px",
          fontStyle: "italic",
          color: "#666",
          marginTop: "12px",
        }}
      >
        Note: The integration process may take 15-20 minutes to complete. You
        can refresh the page to get the latest status.
      </p>
    </div>
  );

  const renderFAQ = () => (
    <div>
      <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
        {[
          {
            question: "What is the WhatsApp Business API?",
            answer:
              "The WhatsApp Business API is designed for medium and large businesses to communicate with their customers at scale through WhatsApp. It allows businesses to send messages, notifications, and updates directly to users.",
          },
          {
            question: "How can I use this feature in 3sigma CRM?",
            answer:
              "You can create and manage campaigns either from the lead screen or the campaigns menu. This allows you to select your audience, design messages, and track performance seamlessly.",
          },
          {
            question: "What types of messages can I send?",
            answer:
              "You can send promotional messages, updates, reminders, and any relevant information tailored to your leads.",
          },
          {
            question: "Are there any costs associated with using this feature?",
            answer:
              "Yes, using the WhatsApp Business API may incur costs based on the number of messages sent and the nature of the messages. Check with your account representative for detailed pricing.",
          },
          {
            question: "How do I ensure my messages reach my audience?",
            answer:
              "By using your official WhatsApp number and adhering to best practices for message frequency and content, you can ensure your campaigns are well-received.",
          },
        ].map((item, index) => (
          <li key={index} style={{ marginBottom: "16px" }}>
            <h3
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                marginBottom: "8px",
                color: "#333",
              }}
            >
              {item.question}
            </h3>
            <p
              style={{
                fontSize: "14px",
                lineHeight: "1.4",
                color: "#555",
                margin: 0,
              }}
            >
              {item.answer}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <div className="facebook-integration-container">
      <div className="content-wrapper">
        <h1>Connect to WhatsApp Business API 🚀</h1>
        <button
          className="facebook-login-button"
          onClick={launchWhatsAppSignup}
        >
          <i className="ri-facebook-circle-fill"></i> Login with Facebook
        </button>

        {message ? (
          <>
            <h4 className="success-message">{message}</h4>
            <button
              className="facebook-login-button"
              style={{ margin: "20px auto" }}
              name="Refresh"
              onClick={onRefresh}
            >
              Refresh
            </button>
          </>
        ) : null}

        {error && <p className="error-message">{error}</p>}
        <div className="card-wrapper">
          <div className="card-container">
            {renderCard(
              "WhatsApp Business API Features and Benefits 🌟",
              renderWhatsAppAPIBenefits()
            )}
            {renderCard("Onboarding Guide 📜", renderOnboardingGuide())}
            {renderCard("Frequently Asked Questions ❓", renderFAQ())}
          </div>
        </div>
      </div>

      <div className="bottom-links">
        <a
          href="https://help.3sigmacrm.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Setup Guide 📖
        </a>
        <a
          href="https://wa.me/918814048362"
          target="_blank"
          rel="noopener noreferrer"
        >
          Contact Support 💬
        </a>
        <a
          href="https://youtu.be/GwSNQb4BZoE"
          target="_blank"
          rel="noopener noreferrer"
        >
          Watch Demo Video 📹
        </a>
      </div>
      <button className="back-to-top" onClick={scrollToTop}>
        Back to Top ⬆
      </button>
      <style>{`
        .facebook-integration-container {
          font-family: 'Gilroy', sans-serif;
          background-color: #f8f9fa;
          min-height: 100vh;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 20px;
          position: relative;
        }

        .content-wrapper {
          width: 100%;
          max-width: 800px;
          margin-bottom: 80px;
        }

        h1 {
          font-size: 28px;
          font-weight: bold;
          margin-bottom: 20px;
          color: #333;
          text-align: center;
          font-family: 'Gilroy', sans-serif;
        }

        .facebook-login-button {
          background-color: #1877f2;
          border: 0;
          border-radius: 8px;
          color: #fff;
          cursor: pointer;
          font-family: 'Gilroy', sans-serif;
          font-size: 16px;
          font-weight: bold;
          padding: 10px 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 auto 20px;
        }

        .facebook-login-button i {
          font-size: 20px;
          margin-right: 8px;
        }

        .error-message {
          color: red;
          text-align: center;
          margin-bottom: 20px;
          font-family: 'Gilroy', sans-serif;
        }

        .card-wrapper {
          display: flex;
          justify-content: center;
        }

        .card-container {
          max-height: calc(100vh - 280px);
          overflow-y: auto;
          padding: 10px;
          scroll-behavior: smooth;
          width: 100%;
          max-width: 800px;
        }

        .card {
          background-color: #ffffff;
          border-radius: 12px;
          padding: 20px;
          margin-bottom: 20px;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
          font-family: 'Gilroy', sans-serif;
        }

        .card-title {
          font-size: 22px;
          font-weight: bold;
          margin-bottom: 16px;
          color: #333;
          font-family: 'Gilroy', sans-serif;
        }

        .bottom-links {
          position: fixed;
          bottom: 0;
          left: 0;
          right: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #ffffff;
          padding: 10px;
          box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
        }

        .bottom-links a {
          font-size: 14px;
          color: #007bff;
          text-decoration: none;
          font-family: 'Gilroy', sans-serif;
          display: flex;
          align-items: center;
          margin: 0 20px;
        }

        .bottom-links a:hover {
          text-decoration: underline;
        }

        @media screen and (max-width: 600px) {
          .bottom-links a {
            margin: 0 10px;
            font-size: 12px;
          }
        }

        .success-message {
          font-family: 'Gilroy', sans-serif;
          text-align: center;
          margin-top: 20px;
        }

        .back-to-top {
          position: fixed;
          bottom: 80px;
          right: 20px;
          background-color: #007bff;
          color: #ffffff;
          border: none;
          border-radius: 50%;
          width: 50px;
          height: 50px;
          font-size: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
          z-index: 999;
        }
      `}</style>
    </div>
  );
};

export default FacebookIntegration;
