// src/views/template/createTemplate/headerSection/HeaderFilePreview.tsx

import React from 'react';
import styled from 'styled-components';
import { FaRegFile, FaDownload } from "react-icons/fa";
import { formatFileSize } from './whatsapp-media-validator';



interface HeaderFilePreviewProps {
  file: File | null | undefined;  // Correct typing
  headerType: string;
}


export const HeaderFilePreview: React.FC<HeaderFilePreviewProps> = ({ file, headerType }) => {
  if (!file) return null;

  const handleDownload = () => {
    if (!file) return;  // Add null check
    const url = URL.createObjectURL(file);
    try {
      const a = document.createElement('a');
      a.href = url;
      a.download = file.name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } finally {
      URL.revokeObjectURL(url);  // Ensure cleanup
    }
  };

  return (
    <PreviewContainer>
      <FileInfo onClick={handleDownload}>
        <FaRegFile size={20} />
        <FileDetails>
          <FileName>{file.name}</FileName>
          <FileSize>{formatFileSize(file.size)}</FileSize>
        </FileDetails>
        <DownloadIcon>
          <FaDownload size={16} />
        </DownloadIcon>
      </FileInfo>
    </PreviewContainer>
  );
};

const PreviewContainer = styled.div`
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #e2e8f0;
`;

const FileInfo = styled.div`
  display: flex;
  align-items: center;
  padding: 12px;
  background: #f8fafc;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background: #edf2f7;
  }

  svg {
    color: #64748b;
  }
`;

const FileDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  flex: 1;
`;

const FileName = styled.span`
  font-size: 14px;
  color: #2d3748;
  font-weight: 500;
  word-break: break-all;
`;

const FileSize = styled.small`
  font-size: 12px;
  color: #64748b;
  margin-top: 2px;
`;

const DownloadIcon = styled.div`
  margin-left: 12px;
  color: #3FAEFD;
  opacity: 0.8;
  transition: opacity 0.2s ease;

  ${FileInfo}:hover & {
    opacity: 1;
  }
`;

export default HeaderFilePreview;