import React, { useEffect, useState, useRef } from "react";

const InputMaps = (props) => {
    const [location, setLocation] = useState({ lat: 28.6138954, lng: 77.2090057 });
    const [input, setInput] = useState("");
    const [marker, setMarker] = useState(null);
    const mapRef = useRef(null);
    const autocompleteRef = useRef(null);

    useEffect(() => {
      if (!props.disabled) {
        const initMap = () => {
          mapRef.current = new window.google.maps.Map(
            document.getElementById("map"),
            {
              center: location,
              zoom: 13,
            }
          );
          // Add a marker for the initial location
          setMarker(
            new window.google.maps.Marker({
              position: location,
              map: mapRef.current,
            })
          );
        };

        const initAutocomplete = () => {
          autocompleteRef.current = new window.google.maps.places.Autocomplete(
            document.getElementById("autocomplete"),
            { types: ["geocode"] }
          );
          autocompleteRef.current.addListener(
            "place_changed",
            handlePlaceChanged
          );
        };
        initMap();
        initAutocomplete();
      }
    }, []);

    const handlePlaceChanged = () => {
        const place = autocompleteRef.current.getPlace();
        if (place.geometry && place.geometry.location) {
            const coordinates = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng(),
            };
            setLocation(coordinates);
            setInput(place.formatted_address);
            mapRef.current.panTo(coordinates);
            if (marker) {
                marker.setPosition(coordinates);
            } else {
                setMarker(new window.google.maps.Marker({
                    position: coordinates,
                    map: mapRef.current,
                }));
            }
        }
    };

    const handleCurrentLocationClick = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const currentPosition = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    setLocation(currentPosition);
                    mapRef.current.panTo(currentPosition);
                    if (marker) {
                        marker.setPosition(currentPosition);
                    } else {
                        setMarker(new window.google.maps.Marker({
                            position: currentPosition,
                            map: mapRef.current,
                        }));
                    }
                },
                (error) => {
                    console.error('Error getting current location:', error);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    };

    const handleChange = (event) => {
        setInput(event.target.value);
    };

    return (
      <div className="map_inputs">
        <label className="form-label">
          {props.label}{" "}
          <span className="text-danger">{props.isRequired && "*"}</span>
        </label>
        <img
          src={"assets/img/world.png"}
          alt="img"
          onClick={handleCurrentLocationClick}
          className="form-icons"
        />
        <input
          {...props}
          placeholder="Search for an address"
          type="text"
          onChange={handleChange}
          value={input}
          id="autocomplete"
        />
        {!props.disabled && (
          <div
            id="map"
            style={{ height: "160px", width: "100%", marginTop: "10px" }}
          ></div>
        )}
      </div>
    );
};

export default InputMaps;
