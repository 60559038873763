// transformAnyOption.ts

interface Source {
    _id?: string;
    value?: string;
    isActive?: boolean;
  }
  
  interface ViewData {
    _id: string;
  }
  
  interface ValueData {
    value: string;
  }
  
  interface TransformOptions {
    event: string;
    sourceByData?: Source[];
    customSource?: Source[];
    statusLabelData?: ValueData[];
    viewData?: ViewData[];
    taskData?: ValueData[];
    activityListData?: ValueData[];  // Add this line
    customActivityType?: ValueData[]; // Add this line
  }

  function transformActivityAny(activityList: ValueData[] = [], customActivity: ValueData[] = []): string[] {
    const allActivities = [...(activityList || []), ...(customActivity || [])];
    return [...new Set(
      allActivities
        .filter((item): item is ValueData => Boolean(item?.value))
        .map(item => item.value)
    )];
  }
  
  export function transformAnyOption(options: TransformOptions): string[] {
    const { event } = options;
  
    try {
      switch(event) {
        case 'create_lead':
          return transformCreateLeadAny(options.sourceByData);
        case 'status_change':
        case 'label_change':
          return transformStatusLabelAny(options.statusLabelData);
        case 'view_page':
        case 'view_file':
          return transformViewPageFileAny(options.viewData);
        case 'create_task':
          return transformTaskAny(options.taskData);
        case 'edit_task':
          return ['toBePerformAt', 'isCompleted'];
          case 'create_activity':
      return [...new Set([
        ...(options.activityListData || []).map(item => item.value),
        ...(options.customActivityType || []).map(item => item.value)
      ].filter(Boolean))];
        default:
          return [];
      }
    } catch (error) {
      console.error('Error in transformAnyOption:', error);
      return [];
    }
  }
  
  function transformCreateLeadAny(sourceByData: Source[] = []): string[] {
    return [...new Set(
      sourceByData
        .filter((source): source is Source & { _id: string } => 
          Boolean(source?.isActive && source?._id))
        .map(source => source._id)
    )];
  }
  
  function transformStatusLabelAny(statusLabelData: ValueData[] = []): string[] {
    return [...new Set(
      Array.isArray(statusLabelData) ? statusLabelData
        .filter((item): item is ValueData => Boolean(item?.value))
        .map(item => item.value) : []
    )];
  }
  
  function transformViewPageFileAny(viewData: ViewData[] = []): string[] {
    return [...new Set(
      Array.isArray(viewData) ? viewData
        .filter((item): item is ViewData => Boolean(item?._id))
        .map(item => item._id) : []
    )];
  }
  
  function transformTaskAny(taskData: ValueData[] = []): string[] {
    return taskData.map(item => item.value);
  }