import React, { PropsWithChildren, useState, useMemo } from "react";
import { Each } from "../../utils/Each";
import styled from "styled-components";

// Interfaces
interface LeadListItem {
  _id: string;
  name: string;
  leadCount?: number;
  isPinned?: boolean;
}

interface Props {
  leadLists: LeadListItem[];
  onAddList: (mode: string) => void;
  onEditList: (mode: string, _id: string) => void;
  getLeadList: () => void;
  onDeleteClick: (id: string) => void;
  leadData: number;
  LeadNameChange: (_id: string, name: string) => void;
  selectedLeadList: {
    id: string;
    name: string;
  };
  isLeadSelection?: boolean;
}

interface MemoizedLeadItemProps {
  listData: LeadListItem;
  active: string;
  hoveredIndex: string | null;
  index: string;
  LeadNameChange: (_id: string, name: string) => void;
  setActive: (name: string) => void;
  setHoveredIndex: (index: string | null) => void;
  onEditList: (mode: string, _id: string) => void;
  isLeadSelection?: boolean;
}

// Styled Components
const DropdownContainer = styled.div`
  background-color: #ebf0f4;
  border-radius: 6px;
  margin-left: 0.25rem;
`;

const DropdownButton = styled.button`
  &.btn.dropdown-toggle {
    font-size: 13px;
    padding: 4px 12px;
    height: 26px;
    display: flex;
    align-items: center;
    gap: 6px;
    background-color: #ebf0f4;
    border: none;
    color: #1a1f36;
    font-weight: 500;
    border-radius: 6px;

    svg {
      width: 14px;
      height: 14px;
      margin-left: 4px;
    }

    &:hover {
      background-color: #e2e8f0;
    }
  }
`;

const DropdownList = styled.ul`
  overflow: auto;
  max-height: 80vh;
  background: white;
  padding: 0;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px, rgba(0, 0, 0, 0.06) 0px 1px 2px;
  border: 1px solid #e5e7eb;
  width: 320px;
  margin-top: 4px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #e5e7eb;
    border-radius: 4px;
    border: 2px solid white;

    &:hover {
      background: #d1d5db;
    }
  }
`;

const ListHeader = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid #e5e7eb;
  margin-bottom: 6px;
  background: white;
  position: sticky;
  top: 0;
  z-index: 1;
`;

const HeaderText = styled.span`
  color: #4b5563;
  font-size: 13px;
  font-weight: 500;
  margin-left: 4px;
`;

const AddButton = styled.button`
  margin-right: 0.5rem;
  background: #4f46e5;
  border: none;
  border-radius: 6px;
  padding: 5px 12px;
  font-size: 13px;
  font-weight: 500;
  color: white;
  transition: all 0.15s ease;
  height: 28px;
  display: flex;
  align-items: center;

  &:hover {
    background: #4338ca;
    transform: translateY(-1px);
  }

  &:active {
    background: #3730a3;
    transform: translateY(0);
  }
`;

const ListItem = styled.li<{ isActive: boolean }>`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 12px;
  transition: all 0.2s ease;
  max-width: 100%;
  min-height: 38px;
  position: relative;
  margin: 1px 0;
  background: transparent;

  &.dropdown-item {
    color: #374151;
    font-size: 13.5px;
    font-weight: ${props => props.isActive ? '500' : '400'};
    border-radius: 0;

    &:hover {
      background-color: #f9fafb;
    }

    ${(props) =>
      props.isActive &&
      `
      background-color: #f3f4f6;
    `}
  }

  > span {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 10px;
    display: flex;
    align-items: center;
    gap: 6px;
    line-height: 1.2;

    .pin-icon {
      color: #6366f1;
      width: 13px;
      height: 13px;
      flex-shrink: 0;
    }
  }

  .filter_btn {
    opacity: 0;
    transition: opacity 0.2s ease;
  }

  &:hover .filter_btn {
    opacity: 1;
  }
`;

const EditButton = styled.button`
  &.btn.btn-default {
    background: none;
    border: none;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.7;

    svg {
      width: 13px;
      height: 13px;
      color: #6b7280;
      transition: color 0.2s ease;
    }

    &:hover {
      opacity: 1;
      
      svg {
        color: #4f46e5;
      }
    }
  }
`;

const Divider = styled.li`
  height: 4px;
`;

// MemoizedLeadItem Component
const MemoizedLeadItem: React.FC<MemoizedLeadItemProps> = React.memo(
  ({
    listData,
    active,
    hoveredIndex,
    index,
    LeadNameChange,
    setActive,
    setHoveredIndex,
    onEditList,
    isLeadSelection,
  }) => (
    <ListItem
      isActive={active === listData?.name || hoveredIndex === index}
      className="lead dropdown-item dropdown-list-item"
      onClick={() => {
        LeadNameChange(listData?._id, listData?.name);
        setActive(listData?.name);
      }}
      onMouseEnter={() => setHoveredIndex(index)}
      onMouseLeave={() => setHoveredIndex(null)}
    >
      <span>
        {listData?.isPinned && (
          <svg 
            className="pin-icon"
            width="12" 
            height="12" 
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z" />
          </svg>
        )}
        {`${listData?.name} (${listData?.leadCount ?? 0})`}
      </span>

      {!isLeadSelection &&
        (active === listData?.name || hoveredIndex === index) && (
          <div className="filter_btn">
            <EditButton
              type="button"
              className="btn btn-default"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                onEditList("edit", listData?._id);
              }}
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.60103 3.66667L9.33204 4.24111L2.2708 9.77778H1.55534V9.21556L8.60103 3.66667ZM11.4006 0C11.2062 0 11.004 0.0611111 10.8563 0.177222L9.43314 1.29556L12.3494 3.58722L13.7725 2.46889C14.0758 2.23056 14.0758 1.83333 13.7725 1.60722L11.9528 0.177222C11.7973 0.055 11.6028 0 11.4006 0ZM8.60103 1.94944L0 8.70833V11H2.91626L11.5173 4.24111L8.60103 1.94944Z"
                  fill="currentColor"
                />
              </svg>
            </EditButton>
          </div>
        )}
    </ListItem>
  )
);

// Main Component
const LeadsList: React.FC<PropsWithChildren<Props>> = (props) => {
  const {
    leadLists,
    onAddList,
    getLeadList,
    onDeleteClick,
    onEditList,
    leadData,
    LeadNameChange,
    selectedLeadList,
    isLeadSelection = false,
  } = props;

  const [active, setActive] = useState<string>("Leads");
  const [hoveredIndex, setHoveredIndex] = useState<string | null>(null);

  const { pinnedLists, unpinnedLists } = useMemo(() => {
    const separated = leadLists.reduce(
      (acc: { pinnedLists: LeadListItem[]; unpinnedLists: LeadListItem[] }, list: LeadListItem) => {
        if (list.isPinned) {
          acc.pinnedLists.push(list);
        } else {
          acc.unpinnedLists.push(list);
        }
        return acc;
      },
      { pinnedLists: [], unpinnedLists: [] }
    );

    separated.pinnedLists.sort((a: LeadListItem, b: LeadListItem) => a.name.localeCompare(b.name));
    separated.unpinnedLists.sort((a: LeadListItem, b: LeadListItem) => a.name.localeCompare(b.name));

    return separated;
  }, [leadLists]);

  const defaultLeadItem = useMemo(
    () => (
      <ListItem
        isActive={active === "Leads"}
        className="lead dropdown-item"
        onClick={() => {
          LeadNameChange("0", "Leads");
          setActive("Leads");
        }}
      >
        <span>Default leadlist</span>
      </ListItem>
    ),
    [active, LeadNameChange]
  );

  return (
    <DropdownContainer className="dropdown ms-1">
      <DropdownButton
        className="btn dropdown-toggle lead_icon"
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        onClick={getLeadList}
      >
        {selectedLeadList.name} ({leadData})
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-play-circle-fill"
          viewBox="0 0 16 16"
        >
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.79 5.093A.5.5 0 0 0 6 5.5v5a.5.5 0 0 0 .79.407l3.5-2.5a.5.5 0 0 0 0-.814l-3.5-2.5z" />
        </svg>
      </DropdownButton>

      <DropdownList
        className="leads_dropdown lead_list dropdown-menu"
        aria-labelledby="dropdownMenuButton1"
      >
        {!isLeadSelection && (
          <ListHeader>
            <HeaderText>Lead list</HeaderText>
            <AddButton
              className="btn btn-primary"
              onClick={() => onAddList("add")}
            >
              Add new list
            </AddButton>
          </ListHeader>
        )}

        {defaultLeadItem}

        {/* Pinned Lists */}
        {pinnedLists.length > 0 && <Divider />}
        {pinnedLists.map((listData: LeadListItem, index: number) => (
          <MemoizedLeadItem
            key={listData._id}
            listData={listData}
            active={active}
            hoveredIndex={hoveredIndex}
            index={`pinned-${index}`}
            LeadNameChange={LeadNameChange}
            setActive={setActive}
            setHoveredIndex={setHoveredIndex}
            onEditList={onEditList}
            isLeadSelection={isLeadSelection}
          />
        ))}

        {/* Unpinned Lists */}
        {pinnedLists.length > 0 && unpinnedLists.length > 0 && <Divider />}
        {unpinnedLists.map((listData: LeadListItem, index: number) => (
          <MemoizedLeadItem
            key={listData._id}
            listData={listData}
            active={active}
            hoveredIndex={hoveredIndex}
            index={`unpinned-${index}`}
            LeadNameChange={LeadNameChange}
            setActive={setActive}
            setHoveredIndex={setHoveredIndex}
            onEditList={onEditList}
            isLeadSelection={isLeadSelection}
          />
        ))}
      </DropdownList>
    </DropdownContainer>
  );
};

export default React.memo(LeadsList);