// src/hooks/useAnalytics.ts

import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { trackPageView } from "../config/firebase";

export const useAnalytics = () => {
  const location = useLocation();
  const previousPath = useRef<string>(location.pathname);

  useEffect(() => {
    const currentPath = location.pathname;

    // Only track if the path has changed
    if (previousPath.current !== currentPath) {
      const pageName = currentPath.replace("/", "") || "home";

      // Add navigation timing data if available
      const navigationTiming: Record<string, any> = {};
      if (window.performance) {
        const perfData = window.performance.timing;
        navigationTiming.loadTime =
          perfData.loadEventEnd - perfData.navigationStart;
        navigationTiming.domReadyTime =
          perfData.domContentLoadedEventEnd - perfData.navigationStart;
      }

      // Track with additional metadata
      trackPageView(pageName, {
        previous_page: previousPath.current,
        ...navigationTiming,
        timestamp: new Date().toISOString(),
      });

      // Update the previous path
      previousPath.current = currentPath;
    }
  }, [location]);
};
