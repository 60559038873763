import Switch from "../../components/switch";
import { TeamMembersT } from "./addEditList";

interface ITeamMemberItem {
  teamMember: TeamMembersT;
  checked: boolean;
  handleChange: (id: string) => void;
}
const TeamMemberItem = ({
  teamMember,
  checked,
  handleChange,
}: ITeamMemberItem) => {
  const { _id, name, organization, role } = teamMember;
  return (
    <div className="leads-section-3 w-100 d-flex align-items-center justify-content-between ">
      <div className="leads-section-23-sub-1 text-black">
        <h5 title={name}>
          {name?.length > 16 ? name?.substring(0, 16)?.concat("...") : name}
        </h5>
        <p>
          {organization} - {role}
        </p>
      </div>
      <div className="custom_switch">
        <Switch
          checked={checked}
          value={name ? name : ""}
          onChange={(e) => handleChange(_id)}
          name="weighted"
          offstyle={"btn-secondary"}
          onstyle={"custom-switch-on"}
        />
      </div>
    </div>
  );
};

export default TeamMemberItem;
