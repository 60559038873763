import React, { useState, Dispatch, SetStateAction, ChangeEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faCalendar, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import PopupComponent from '../PopupComponent';
import styled from 'styled-components';
import { ActivityTypeConfig } from '../../views/ActivityFeed/activityTypes';

// Types remain the same
interface FilterBarProps {
    dateOptions: Array<{label: string; value: string}>;
    activityTypes: Record<string, ActivityTypeConfig>;
    applyDateFilter: (range: string) => void;
    setFilters: Dispatch<SetStateAction<FilterState>>;
    filters: FilterState;
    className?: string;
}

interface FilterState {
    type: string;
    team: string;
    userId: string;
    dateRange: string;
}

// Styled Components
const StyledButton = styled.button`
    background: #fff;
    border: 1px solid #ddd;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: #333;
    transition: all 0.2s;

    &:hover {
        border-color: #0066FF;
        color: #0066FF;
    }

    svg {
        font-size: 16px;
    }
`;

const ActionButton = styled(StyledButton)`
    &.primary {
        background: #0066FF;
        border-color: #0066FF;
        color: white;

        &:hover {
            background: #0052cc;
            border-color: #0052cc;
            color: white;
        }
    }

    &.secondary {
        background: #fff;
        border-color: #ddd;
        color: #666;

        &:hover {
            background: #f5f5f5;
            border-color: #ccc;
            color: #333;
        }
    }
`;

const FilterContainer = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    background: #e7f1ff;  
    padding: 8px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.1);
    max-width: 600px;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
`;



const DateDropdown = styled.div`
    position: relative;
    width: 100%;
`;

const DateOptions = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    background: white;
    border: 1px solid #E5E7EB;
    border-radius: 6px;
    box-shadow: 0 4px 12px rgba(0,0,0,0.1);
    margin-top: 4px;
    z-index: 100;
    width: 200px;
`;

const DateOption = styled.div`
    padding: 8px 12px;
    cursor: pointer;
    &:hover {
        background: #F3F4F6;
    }
`;

const CheckboxGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    margin: 16px 0;
`;

// Main Component
const FilterBar: React.FC<FilterBarProps> = ({
    filters,
    setFilters,
    dateOptions,
    activityTypes,
    applyDateFilter,
    className
}) => {
    const [showTypesPopup, setShowTypesPopup] = useState(false);
    const [showDateOptions, setShowDateOptions] = useState(false);
    const [selectedTypes, setSelectedTypes] = useState<string[]>(
        filters.type ? filters.type.split(',') : []
    );
    
    const userData = useSelector((state: any) => state?.rootReducers?.user?.userData);

    const handleTypeSelect = (type: string) => {
        setSelectedTypes(prev => {
            const newTypes = prev.includes(type) 
                ? prev.filter(t => t !== type)
                : [...prev, type];
            
            setFilters(current => ({
                ...current,
                type: newTypes.join(',')
            }));
            
            return newTypes;
        });
    };

    const handleDateSelect = (value: string) => {
        setFilters(prev => ({ ...prev, dateRange: value }));
        applyDateFilter(value);
        setShowDateOptions(false);
    };

    const popupFooter = (
        <div className="d-flex justify-content-end gap-2">
            <ActionButton 
                className="secondary"
                onClick={() => setShowTypesPopup(false)}
            >
                Cancel
            </ActionButton>
            <ActionButton 
                className="primary"
                onClick={() => setShowTypesPopup(false)}
            >
                Apply
            </ActionButton>
        </div>
    );

    return (
        <FilterContainer className={className}>
            <StyledButton onClick={() => setShowTypesPopup(true)}>
                <FontAwesomeIcon icon={faFilter} />
                {selectedTypes.length ? `${selectedTypes.length} Selected` : 'Activities'}
                <FontAwesomeIcon icon={faChevronDown} />
            </StyledButton>

            <DateDropdown>
                <StyledButton onClick={() => setShowDateOptions(!showDateOptions)}>
                    <FontAwesomeIcon icon={faCalendar} />
                    {filters.dateRange || 'Last 7 Days'}
                    <FontAwesomeIcon icon={faChevronDown} />
                </StyledButton>

                {showDateOptions && (
                    <DateOptions>
                        {dateOptions.map(option => (
                            <DateOption
                                key={option.value}
                                onClick={() => handleDateSelect(option.value)}
                            >
                                {option.label}
                            </DateOption>
                        ))}
                    </DateOptions>
                )}
            </DateDropdown>

            <PopupComponent
                isOpen={showTypesPopup}
                onClose={() => setShowTypesPopup(false)}
                title="Select Activities"
                size="md"
                footer={popupFooter}
            >
                <CheckboxGrid>
                    {Object.entries(activityTypes).map(([key, config]) => (
                        <div key={key} className="form-check">
                            <input
                                type="checkbox"
                                id={`activity-${key}`}
                                className="form-check-input"
                                checked={selectedTypes.includes(key)}
                                onChange={() => handleTypeSelect(key)}
                            />
                            <label 
                                className="form-check-label" 
                                htmlFor={`activity-${key}`}
                            >
                                {config.label}
                            </label>
                        </div>
                    ))}
                </CheckboxGrid>
            </PopupComponent>
        </FilterContainer>
    );
};

export default FilterBar;