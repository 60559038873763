import React, { useEffect, useState } from "react";
import Header from "../../components/header";
import { Toaster } from "react-hot-toast";
import UploadSheetFile from '../../UploadSheetFile';  
import { Spin } from "antd";
import "./integrations.css";
import { getIntegrations } from "../../services/integrations";
import { typeFormat } from "../../utils/helpers";
import { useSelector } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import GoogleCalendarIntegration from "./GoogleCalendarIntegration";
import GoogleMailIntegration from "./GoogleMailIntegration";
import ImportGoogleContacts from "../leads/ImportGoogleContacts";

interface Integration {
  _id: string;
  key: string;
  name: string;
  description?: string;
  isDisplayable: boolean;
  isActive: boolean;
}
interface IntegrationMessage {
  message: 'facebook' | 'disconnect' | 'connected' | 'popup';
  data?: any;
}
declare global {
  interface Window {
    FB: any;
  }
}


const GOOGLE_CLIENT_ID =
  "322938904388-p7l4fso2n9g93n8irg3c9get69rn3vk4.apps.googleusercontent.com";

const Integrations: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [integrationsList, setIntegrationsList] = useState<any[]>([]);
  const [selectedOption, setSelectedOption] = useState<Integration | null>(
    null
  );
  const [showModal, setShowModal] = useState(false);
  const [integrationMsg, setIntegrationMsg] = useState("");

  const user = useSelector(
    (state: any) => state?.rootReducers?.userData?.userDetails || ""
  );

  const systemIntegrations = useSelector(
    (state: any) =>
      state?.rootReducers?.userData?.userDetails?.systemIntegration?.filter(
        (_i: { isDisplayable: boolean; isActive: boolean }) =>
          _i.isDisplayable && _i.isActive
      ) || []
  );

  const getIntegrationDescription = (key: string) => {
    switch (key) {
      case "justdial":
        return "Connect JustDial to import lnquiries from Justdial";
      case "99acres":
        return "Automatically Import property leads from 99Acres";
      case "indiamart":
        return "Get business leads and inquiries from Indiamart directly";
      case "wordpress":
        return "Sync your Wordpress leads automatically";
      case "tradeindia":
        return "Import business leads from TradeIndia to your CRM";
      case "facebook":
        return "Connect Facebook/instagram leads from leadforms automatically";
      case "housing":
        return "Get real estate leads from Housing.com automatically";
      case "magicbricks":
        return "Import property inquiries from MagicBricks directly";
      case "zapier":
        return "Connect your Zapier workflows adn create leads automatically";
      case "google_lead_form":
        return "Automatically import leads from Google Forms";
      case "exporters_india":
        return "Connect with Exporterindia manage business leads";
      case "razorpay":
        return "Collect Payments directly from 3sigma Forms";
      case "brevo":
        return "Streamline your email marketing campaigns";
      case "pabbly":
        return "Connect 3sigma with pabbly";
      default:
        return "Connect to enhance your workflow";
    }
  };

  const allIntegrations = [
    ...systemIntegrations.map((integration: { key: string }) => ({
      ...integration,
      description: getIntegrationDescription(integration.key),
    })),
    {
      _id: "google_calendar",
      key: "google_calendar",
      name: "Google Calendar",
      description: "Sync your calendar events and manage meetings",
      isDisplayable: true,
      isActive: true,
    },
    {
      _id: "google_sheets",  
      key: "google_sheets",
      name: "Google Sheets",
      description: "Import and sync your leads directly from Google Sheets",
      isDisplayable: true,
      isActive: true,
    },
    {
      _id: "google_mail",
      key: "google_mail",
      name: "Gmail",
      description: "Connect Gmail to manage email communications",
      isDisplayable: true,
      isActive: true,
    }
    /*,
    {
      _id: "google_sheets",
      key: "google_sheets",
      name: "Google Sheets",
      description: "Import and sync your leads directly from Google Sheets",
      isDisplayable: true,
      isActive: true,
    }*/,
    {
      _id: "google_contacts",
      key: "google_contacts",
      name: "Google Contacts",
      description: "Import and sync leads from your Google Contacts",
      isDisplayable: true,
      isActive: true,
    }
  ];

  useEffect(() => {
    fetchIntegrations();
  }, []);


useEffect(() => {
  const handleMessage = (event: MessageEvent) => {
    if (event.origin !== 'https://integration.3sigmacrm.com') return;

    if (event.data.message === 'facebook') {
      if (window.FB) {
        window.FB.login(
          (response: any) => {
            if (response.status === 'connected') {
              const { accessToken, userID } = response.authResponse;
              const iframe = document.querySelector('iframe');
              if (iframe && selectedOption?.key === 'facebook') {
                iframe.src = `https://integration.3sigmacrm.com/integration/facebook/${user?._id}?token=${user?.token?.slice(7)}&accessToken=${accessToken}&fb_user_id=${userID}`;
              }
            }
          },
          {
            scope: 'public_profile,email,pages_show_list,pages_read_engagement,pages_manage_metadata,pages_manage_ads,ads_management,leads_retrieval,business_management'
          }
        );
      }
    }



    // Handle other integration messages
    if (event.data.message === 'connected' || event.data.message === 'disconnect') {
      setShowModal(false);
      fetchIntegrations();
      setIntegrationMsg(
        event.data.message === 'connected' 
          ? "Integration connected successfully"
          : "Integration disconnected successfully"
      );
    }
  };

  window.addEventListener('message', handleMessage);
  return () => window.removeEventListener('message', handleMessage);
}, [selectedOption, user]);

useEffect(() => {
  if (integrationMsg) {
    const timer = setTimeout(() => {
      setIntegrationMsg("");
    }, 5000); // Clear message after 5 seconds

    return () => clearTimeout(timer);
  }
}, [integrationMsg]);

  const fetchIntegrations = async () => {
    setLoading(true);
    try {
      const { data } = await getIntegrations();
      setIntegrationsList(data?.data?.length ? data.data : []);
    } catch (error) {
      console.error("Failed to fetch integrations:", error);
    }
    setLoading(false);
  };

  return (
    <div className="integrations-container">
      <Header />
      <h1 className="page-title">Integrations</h1>
      <div className="integrations-grid">
        {allIntegrations.map((item: Integration) => {
          const isConnected = integrationsList?.find(
            (i) => i.integrationId === item._id
          );
  
          return (
            <div key={item._id} className="integration-card">
              <div className="integration-header">
                <div className="integration-info">
                {item.key === "google_calendar" ||
item.key === "google_mail" ||
item.key === "google_contacts" ||
item.key === "google_sheets" ? (  // Add google_sheets to the condition
  <img
    src={require(`../../assets/images/integration/${
      item.key === "google_calendar"
        ? "googlecalender"
        : item.key === "google_mail"
        ? "gmail"
        : item.key === "google_sheets"
        ? "googlesheets"  // Add this case
        : "googlecontacts"
    }.png`)}
    className="integration-icon"
    alt=""
  />
                  ) : (
                    <span className="integration-icon">
                      {typeFormat(item?.key).icon}
                    </span>
                  )}
                  <span className="integration-name">{item.name}</span>
                </div>
                {isConnected && <span className="check-icon">✓</span>}
              </div>
  
              <p className="integration-desc">{item.description}</p>
  
              <button
                className={`connect-button ${isConnected ? "manage" : ""}`}
                onClick={() => {
                  setSelectedOption(item);
                  setShowModal(true);
                }}
              >
                {isConnected ? "Manage" : "Connect"}
              </button>
            </div>
          );
        })}
      </div>
  
      {showModal && (
  <div className="modal-overlay" onClick={() => setShowModal(false)}>
    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        {selectedOption?.key === "google_mail" && (
          <GoogleMailIntegration
            token={user?.token}
            userId={user?._id}
            onSuccess={() => {
              setIntegrationMsg("Gmail connected successfully");
              fetchIntegrations();
              setShowModal(false);
            }}
            onError={(error) => {
              setIntegrationMsg(`Failed to connect Gmail: ${error}`);
            }}
          />
        )}
        {selectedOption?.key === "google_calendar" && (
          <GoogleCalendarIntegration
            token={user?.token}
            userId={user?._id}
            onSuccess={() => {
              setIntegrationMsg("Calendar connected successfully");
              fetchIntegrations();
              setShowModal(false);
            }}
            onError={(error) => {
              setIntegrationMsg(`Failed to connect calendar: ${error}`);
            }}
          />
        )}
        {selectedOption?.key === "google_contacts" && (
          <ImportGoogleContacts
            selectedList={{ id: "", name: "" }}
          />
        )}
        {selectedOption?.key === "google_sheets" && (
          <UploadSheetFile
            selectedList={{ id: "", name: "" }}
          />
        )}
        {selectedOption &&
          !["google_mail", "google_calendar", "google_contacts", "google_sheets"].includes(
            selectedOption.key
          ) && (
            <iframe
              style={{ width: "100%", height: "500px", border: "none" }}
              src={`https://integration.3sigmacrm.com/integration/${
                selectedOption.key
              }/${user?._id}?token=${user?.token?.slice(7)}`}
            />
          )}
      </GoogleOAuthProvider>
    </div>
  </div>
)}
  
      {integrationMsg && (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <strong>{integrationMsg}</strong>
        </div>
      )}
  
      <Toaster position="top-right" reverseOrder={false} />
    </div>
  );
};

export default Integrations;
