import React, { useEffect, useRef } from "react";

const ScrollToBottomDetector: React.FC<{ onScrollToBottom: () => void }> = ({
  onScrollToBottom,
}) => {
  const prevScrollY = useRef<number>(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        (document.documentElement && document.documentElement.scrollTop) ||
        document.body.scrollTop;
      const scrollHeight =
        (document.documentElement && document.documentElement.scrollHeight) ||
        document.body.scrollHeight;
      const clientHeight =
        document.documentElement.clientHeight || window.innerHeight;

      if (
        scrollTop > prevScrollY.current &&
        scrollTop + clientHeight >= scrollHeight
      ) {
        onScrollToBottom();
      }

      prevScrollY.current = scrollTop;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [onScrollToBottom]);

  return null;
};

export default ScrollToBottomDetector;
