// ButtonInputs.tsx
import React, { useCallback } from "react";
import PhoneInput from "react-phone-input-2";
import { ButtonType, Error } from "./types";
import { toast } from "react-hot-toast";

const MAX_BUTTON_TEXT_LENGTH = 25;
const MAX_URL_LENGTH = 2000;



interface ButtonInputsProps {
  button: ButtonType;
  index: number;
  errorsObj: Record<string, string>;
  setFieldValue?: (field: string, value: any) => void;
  isSubmitting?: boolean;
  onTypeChange: (type: string, index: number) => void;
  availableTypes: Array<"URL" | "PHONE_NUMBER">;
}


const ButtonInputs: React.FC<ButtonInputsProps> = ({
  button,
  index,
  errorsObj,
  setFieldValue,
  isSubmitting = false,
  onTypeChange,
  availableTypes
}) => {
  const handlePhoneChange = useCallback((value: string) => {
    if (!setFieldValue) return;
    
    const cleanedValue = value.replace(/[^\d+]/g, '');
    const formattedValue = cleanedValue.startsWith('+') ? cleanedValue : '+' + cleanedValue;
    
    setFieldValue(`buttons.${index}.phone_number`, formattedValue);
  }, [setFieldValue, index]);

  const handleUrlChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (!setFieldValue) return;
    let value = e.target.value.trim();
    
    if (value.startsWith('www.')) {
      value = 'https://' + value;
    }
    
    if (!/^https?:\/\//i.test(value) && value.length > 0) {
      value = 'https://' + value;
    }

    if (value.length > MAX_URL_LENGTH) {
      toast.error(`URL cannot exceed ${MAX_URL_LENGTH} characters`);
      value = value.slice(0, MAX_URL_LENGTH);
    }
    
    setFieldValue(`buttons.${index}.url`, value);
  }, [setFieldValue, index]);

  const handleButtonTextChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (!setFieldValue) return;
    const value = e.target.value;
    
    if (value.length > MAX_BUTTON_TEXT_LENGTH) {
      toast.error(`Button text cannot exceed ${MAX_BUTTON_TEXT_LENGTH} characters`);
      return;
    }
    
    const sanitizedValue = value
      .replace(/[^a-zA-Z0-9\s-]/g, '')
      .replace(/\s+/g, ' ')
      .slice(0, MAX_BUTTON_TEXT_LENGTH);
    
    setFieldValue(`buttons.${index}.text`, sanitizedValue);
  }, [setFieldValue, index]);

  return (
    <div className="row mt-3">
      <div className="col-md-3">
        <label className="form-label">Type *</label>
        <select
          name={`buttons.${index}.type`}
          value={button.type}
          onChange={(e) => onTypeChange(e.target.value, index)}
          className="form-select py-2 h-45 bg-lighter"
          disabled={isSubmitting}
        >
          <option value="">Select Type</option>
          {availableTypes.map(type => (
            <option 
              key={type} 
              value={type}
              disabled={type === button.type ? false : !availableTypes.includes(type)}
            >
              {type === "PHONE_NUMBER" ? "Phone" : "URL"}
            </option>
          ))}
        </select>
        {errorsObj[`buttons.${index}.type`] && (
          <Error>{errorsObj[`buttons.${index}.type`]}</Error>
        )}
      </div>

      {button.type && (
        <>
          <div className="col-md-3">
            <label className="form-label">
              {button.type === "PHONE_NUMBER" ? "Button" : "Link"} Text *
            </label>
            <input
              type="text"
              name={`buttons.${index}.text`}
              className="form-control py-2 h-45 bg-lighter"
              placeholder={button.type === "PHONE_NUMBER" ? "Call Us" : "Visit Website"}
              value={button.text}
              onChange={handleButtonTextChange}
              disabled={isSubmitting}
            />
            {errorsObj[`buttons.${index}.text`] && (
              <Error>{errorsObj[`buttons.${index}.text`]}</Error>
            )}
            <small className="text-muted mt-1 d-block">
              {button.text.length}/{MAX_BUTTON_TEXT_LENGTH}
            </small>
          </div>

          {button.type === "PHONE_NUMBER" ? (
            <div className="col-md-6">
              <label className="form-label">Phone *</label>
              <div className="overflow-hidden rounded-8 border">
                <PhoneInput
                  country="in"
                  enableSearch={false}
                  disableDropdown={false}
                  placeholder="+91 Phone Number"
                  value={button.phone_number}
                  onChange={handlePhoneChange}
                  inputProps={{
                    className: "form-control py-2 h-45",
                    disabled: isSubmitting
                  }}
                  countryCodeEditable={true}
                />
              </div>
              {errorsObj[`buttons.${index}.phone_number`] && (
                <Error>{errorsObj[`buttons.${index}.phone_number`]}</Error>
              )}
            </div>
          ) : button.type === "URL" && (
            <div className="col-md-6">
              <label className="form-label">Website URL *</label>
              <input
                type="text"
                name={`buttons.${index}.url`}
                className="form-control py-2 h-45 bg-lighter"
                placeholder="www.example.com or https://example.com"
                value={button.url || ""}
                onChange={handleUrlChange}
                disabled={isSubmitting}
              />
              {errorsObj[`buttons.${index}.url`] && (
                <Error>{errorsObj[`buttons.${index}.url`]}</Error>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ButtonInputs;