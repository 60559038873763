import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;


export const DrawerContent = styled.div`
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;



export const StepIndicatorContainer = styled.div`
  padding: 0 1rem;
  margin-bottom: 1.5rem;
`;
export const StepIndicator = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

export const StepDot = styled.div<{ active: boolean; completed: boolean }>`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  background-color: ${(props) =>
    props.completed ? "#4285F4" : props.active ? "#4285F4" : "#E0E0E0"};
  color: ${(props) => (props.completed || props.active ? "white" : "#666")};
  z-index: 2;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

export const StepConnector = styled.div<{ completed: boolean }>`
  flex-grow: 1;
  height: 3px;
  background-image: linear-gradient(to right, #4285f4 50%, #e0e0e0 50%);
  background-size: 10px 3px;
  background-repeat: repeat-x;
  margin: 0 8px;
  opacity: ${(props) => (props.completed ? 1 : 0.5)};
  transition: opacity 0.3s ease;
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 2rem;
  position: relative;
  overflow-y: auto;
`;

export const MappingSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  position: relative;
`;

export const AuthStepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  margin-top: -20px; // Reduce top space
`;
export const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0 1rem;
`;

export const Title = styled.h2`
  color: #1f2937;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0;
`;
export const StepTitle = styled.h2`
  font-size: 28px;
  margin-bottom: 16px;
  color: #444;
  font-weight: 600;
  text-align: center;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
`;

export const StepDescription = styled.p`
  font-size: 15px;
  color: #666;
  text-align: center;
  margin-bottom: 24px;
  line-height: 1.5;
  max-width: 500px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
`;

export const GoogleButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  background-color: #4285f4;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 250px;
  margin: 0 auto;

  &:hover {
    background-color: #3367d6;
    transform: translateY(-1px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  svg {
    margin-right: 12px;
    font-size: 20px;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  .spinner {
    animation: ${spin} 1s linear infinite;
  }
`;

export const SheetList = styled.div`
  width: 95%;
  max-height: 400px;
  overflow-y: auto;
  padding: 8px;
  border-radius: 8px;
  background: #f8f9fa;
  margin: 0 auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
`;

export const SheetItem = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background: white;
  border-radius: 8px;
  margin-bottom: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid #e0e0e0;

  &:hover {
    background-color: #f8f9fa;
    transform: translateY(-1px);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  }
`;

export const SheetIcon = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 12px;
  display: flex;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export const SheetDetails = styled.div`
  flex: 1;
`;

export const SheetName = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: #333;
`;
export const SheetDate = styled.div`
  font-size: 14px;
  color: #666;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
`;

export const MappingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
  margin-bottom: 60px; // Space for button
`;


export const MappingItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  gap: 1rem;
  align-items: center;
  padding: 0.5rem 1rem;
`;

export const MappingLabel = styled.label`
  font-weight: 500;
  color: #374151;
`;

export const MappingSelect = styled.select`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #E5E7EB;
  border-radius: 0.375rem;
  background-color: white;
  color: #1F2937;
  
  &:focus {
    outline: none;
    border-color: #4A90E2;
    box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
  }
`;

export const ImportButton = styled.button`
  position: sticky; // Change from fixed to sticky
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: 1rem;
  background: linear-gradient(to right, #4A90E2, #357ABD);
  color: white;
  border: none;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  z-index: 10;
  margin-top: auto;
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);

  &:disabled {
    background: linear-gradient(to right, #9CB4D1, #8CA3BE);
    cursor: not-allowed;
  }

  &:hover:not(:disabled) {
    background: linear-gradient(to right, #357ABD, #2868A9);
  }
`;

export const SuccessIcon = styled.div`
  font-size: 64px;
  color: #4caf50;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CloseButton = styled(GoogleButton)`
  background-color: #757575;
  margin-top: 20px;

  &:hover {
    background-color: #616161;
  }
`;

// This style is for the dropdown menu item
export const DropdownItem = styled.li`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  svg {
    color: #3faefc;
  }

  &:hover {
    background-color: #f5f5f5;
  }
`;
// Add these new styled components to your UploadSheetFile.styles.ts

export const StepIndicatorSection = styled.div`
  position: relative;  // Added for disconnect button positioning
  padding: 1rem 1rem 2rem;  // Increased bottom padding to make room for button
`;

export const DisconnectButton = styled.button`
  position: absolute;
  right: 1rem;
  top: 4rem;  // Position it below the step indicators
  display: inline-flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  color: #dc2626;
  font-size: 0.875rem;
  border-radius: 0.375rem;
  border: none;
  background: transparent;
  cursor: pointer;
  
  &:hover {
    background-color: #fee2e2;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  svg {
    margin-right: 0.5rem;
    width: 1rem;
    height: 1rem;
  }

  .spinner {
    animation: ${spin} 1s linear infinite;
  }
`;