import { PropsWithChildren, useState } from "react"
import { Modal, Button } from 'rsuite';


interface DialogT {
    onClickCloseDialog:React.Dispatch<React.SetStateAction<boolean>>,
    // onHandleSubmit:React.Dispatch<React.SetStateAction<any>>,
    openDialog:boolean,
    boxSize:any,
}


const DialogBox: React.FC<PropsWithChildren<DialogT>> = ({boxSize , openDialog,onClickCloseDialog,children}) => {
   
    const handleClose = () => onClickCloseDialog(false);
    return(
        <>
        <Modal size={boxSize} open={openDialog} onClose={handleClose} dialogClassName='dialog_box_model'>
         <div className="dialog_model" style={{background:'#EBF0F4'}}>
          {children}
          <Modal.Footer>
            {/* <Button onClick={handleClose} appearance="primary">
              Ok
            </Button> */}
            {/* <Button onClick={handleClose} appearance="subtle">
              Cancel
            </Button> */}
            {/* <Button onClick={()=>{onHandleSubmit(false)}} appearance="subtle">
              Submit
            </Button> */}
          </Modal.Footer>
         </div>         
        </Modal>
      </>
    )
}
export default DialogBox