// src/services/NotificationService.ts
import { onMessage, getToken } from "firebase/messaging";
import { messaging, firebaseConfig } from "../config/firebase";
import { toast } from "react-hot-toast";
import TokenManager from "./TokenManager";

export class NotificationService {
  private static instance: NotificationService;
  private messageUnsubscribe: (() => void) | null = null;
  private tokenManager: TokenManager;

  private constructor() {
    this.tokenManager = TokenManager.getInstance();
  }

  public static getInstance(): NotificationService {
    if (!NotificationService.instance) {
      NotificationService.instance = new NotificationService();
    }
    return NotificationService.instance;
  }

  public async initialize(): Promise<void> {
    try {
      await this.registerServiceWorker();
      await this.initializeToken();
      await this.setupMessageListener();
    } catch (error) {
      console.error("Notification initialization failed:", error);
      throw error;
    }
  }

  private async initializeToken(): Promise<void> {
    try {
      await this.tokenManager.getValidToken();
    } catch (error) {
      console.error("Failed to initialize FCM token:", error);
      throw error;
    }
  }

  private async registerServiceWorker(): Promise<void> {
    if ("serviceWorker" in navigator) {
      try {
        const configString = encodeURIComponent(JSON.stringify(firebaseConfig));
        const registration = await navigator.serviceWorker.register(
          `/firebase-messaging-sw.js?config=${configString}`, 
          { scope: "/" }
        );

        // Wait for the service worker to be ready
        if (!registration.active) {
          await new Promise<void>((resolve) => {
            registration.addEventListener('activate', () => resolve(), { once: true });
          });
        }
      } catch (error) {
        console.error("Service worker registration failed:", error);
        throw error;
      }
    }
  }

  private async setupMessageListener(): Promise<void> {
    if (!messaging) return;

    if (this.messageUnsubscribe) {
      this.messageUnsubscribe();
    }

    this.messageUnsubscribe = onMessage(messaging, (payload) => {
      if (document.visibilityState === "visible" && payload.notification) {
        const { body } = payload.notification;
        toast(body || "", {
          duration: 4000,
          id: Date.now().toString(),
        });
      }
    });
  }
}

export default NotificationService;