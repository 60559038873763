import React, { useState } from "react";
import DOMPurify from 'dompurify';

interface Template {
  _id: number;
  title: string;
  type: string;
  details: any;
  message: string;
  subject: string;
  createdBy: string;
  createdAt: string;
  tag: string;
  data: any;
}

interface EmailTemplateListProps {
  templates: Template[];
  onEmailTemplateSelect: (template: Template) => void;
  selectedId?: number;
}

const EmailTemplateList: React.FC<EmailTemplateListProps> = ({
  templates,
  onEmailTemplateSelect,
  selectedId,
}) => {
  const [selectedTemplateId, setSelectedTemplateId] = useState<number | null>(
    selectedId || null
  );

  const isHtmlTemplate = (content: string): boolean => {
    // More robust HTML detection
    const htmlTagRegex = /<\/?[\w\s="/.':;#-\/?\[\]]+>/gi;
    return htmlTagRegex.test(content);
  };

  const renderTemplatePreview = (item: Template) => {
    const message = item?.details?.message || '';
    const hasHtmlTags = isHtmlTemplate(message);

    if (hasHtmlTags) {
      // Sanitize HTML to prevent XSS
      const sanitizedHTML = DOMPurify.sanitize(message);
      return (
        <div 
          dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
          style={{ 
            maxHeight: '100px', 
            overflow: 'hidden', 
            textOverflow: 'ellipsis',
            fontSize: 16 
          }} 
        />
      );
    }
    
    return (
      <p style={{ fontSize: 16 }}>
        {message}
      </p>
    );
  };

  const handleEmailTemplateChange = (template: Template) => {
    setSelectedTemplateId(template._id);
    onEmailTemplateSelect && onEmailTemplateSelect(template);
  };

  return (
    <>
      {templates.map((item: Template, key: number) => {
        const hasHtmlTags = isHtmlTemplate(item?.details?.message || '');

        return (
          <div
            className={`container_body_template border ${
              selectedTemplateId === item._id ? "selected_template" : ""
            }`}
            key={key}
            onClick={() => {
              handleEmailTemplateChange(item);
            }}
          >
            <div className="container_body_template_header">
              <div>
                <h6 style={{ fontWeight: 600 }}>
                  {item?.details?.title?.split("_").join(` `)}
                </h6>
                <h6 style={{ fontWeight: 400, fontSize: "12px" }}>
                  {item?.details?.subject}
                </h6>
              </div>
              <div>
                <div>
                  {item?.details?.tags?.map((tag: string, index: number) => (
                    <div
                      key={index}
                      className="label rounded-8 fs-7 py-1 px-2 mb-1"
                    >
                      {tag}
                    </div>
                  ))}
                  {/* HTML Template Indicator */}
                  {hasHtmlTags && (
                    <div 
                      className="label rounded-8 fs-7 py-1 px-2 mb-1 bg-primary text-white"
                    >
                      HTML Template
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="container_body_template_body p-2">
              {renderTemplatePreview(item)}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default EmailTemplateList;