import React, { useState, useEffect } from 'react';
import type { WhatsappTemplate, TemplateSearchParams, ExpandedSections } from './types'; 
import { getTemplates } from '../../services/templates';
import DrawerComponent from "../../components/drawer";
import type { TemplateSelectorProps } from './types';
import "./createWhatsappBroadcast.css";


interface Props {
  isOpen: boolean;
  onClose: () => void;
  onTemplateSelect: (template: WhatsappTemplate) => void;
  selectedTemplateId?: string;
  type: 'whatsapp' | 'email';
  expandedSections: ExpandedSections;
  setExpandedSections: React.Dispatch<React.SetStateAction<ExpandedSections>>;
  handleDiscard: () => void;
}

interface Template {
  _id: string;
  name: string;
  status: string;
  category?: string;
  message?: string;
  createdBy?: {
    firstName: string;
    lastName: string;
  };
}



const TemplateSelector: React.FC<TemplateSelectorProps> = ({
  isOpen,
  onClose,
  onTemplateSelect,
  selectedTemplate, // This should be the template ID
  type,
  expandedSections,
  setExpandedSections,
  handleDiscard
}) => {
  const [templates, setTemplates] = useState<WhatsappTemplate[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const PER_PAGE = 20;
  console.log('Selected Template ID:', selectedTemplate);
  
  const fetchTemplates = async () => {
    try {
      setLoading(true);
      const response = await getTemplates({
        page,
        perPage: PER_PAGE
      });
      
      if (response?.data?.data) {
        const approvedTemplates = response.data.data.filter(
          (template: any) => template.status === 'APPROVED'
        );
        setTemplates(approvedTemplates);
        setTotal(response.data.total);
      }
    } catch (error) {
      console.error('Error fetching templates:', error);
    } finally {
      setLoading(false);
    }
  };
  
 useEffect(() => {
    if (isOpen) {
      fetchTemplates();
    }
  }, [isOpen, page]);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleSelect = (template: WhatsappTemplate) => {
    onTemplateSelect(template);
  };
  return (
    <DrawerComponent
    openWithHeader={isOpen} 
    setOpenWithHeader={() => onClose()} // Fix: Remove argument
    drawerTitle={`Select ${type === 'whatsapp' ? 'WhatsApp' : 'Email'} Template`}
    size="lg"
    >
      <div className="template-selector">
      <div className="templates-grid">
      {templates.map((template) => (
        
        <div 
          key={template._id}
          // Compare IDs consistently
          className={`template-card ${selectedTemplate === template._id ? 'selected' : ''}`}
          onClick={() => onTemplateSelect(template)}
        >

              <div className="template-header">
                <h4 className="template-name">{template.name}</h4>
                <span className="status-badge approved">
                  {template.status}
                </span>
              </div>

              <div className="template-category">
                {template.category}
              </div>

              <div className="template-message">
                {template.components?.find((c: any) => c.type === 'BODY')?.text}
              </div>
            </div>
          ))}
        </div>

        <div className="template-actions">
          <button 
            className="btn me-2"
            onClick={handleDiscard}
          >
            Discard
          </button>
          <button
            className="btn auto_btn"
            onClick={onClose}
          >
            Save
          </button>
        </div>

        <div className="pagination">
          <button
            className="btn auto_btn me-2"
            onClick={() => setPage(p => p - 1)}
            disabled={page === 1}
          >
            Previous
          </button>
          <span>Page {page} of {Math.ceil(total/PER_PAGE)}</span>
          <button
            className="btn auto_btn ms-2" 
            onClick={() => setPage(p => p + 1)}
            disabled={page >= Math.ceil(total/PER_PAGE)}
          >
            Next  
          </button>
        </div>
      </div>
    </DrawerComponent>
  );
};

export default TemplateSelector;