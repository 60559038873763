import { faPhoneAlt, faUserCheck, faStickyNote, faTasks, faFileInvoice, faArrowRight, faUserEdit, faMicrophone, faClipboardCheck, faClipboardList, faShare, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export interface ActivityTypeConfig {
    label: string;
    icon: IconDefinition;
}

const activityTypes: Record<string, ActivityTypeConfig> = {
    INCOMING: { label: 'Incoming Call', icon: faPhoneAlt },
    OUTGOING: { label: 'Outgoing Call', icon: faPhoneAlt },
    MISSED: { label: 'Missed Call', icon: faPhoneAlt },
    share_viewed: { label: 'Share Viewed', icon: faShare },
    status_updated: { label: 'Status Update', icon: faClipboardCheck },
    label_updated: { label: 'Label Update', icon: faClipboardList },
    task_created: { label: 'Task Created', icon: faTasks },
    task_updated: { label: 'Task Updated', icon: faTasks },
    task_completed: { label: 'Task Completed', icon: faTasks },
    task_assigned: { label: 'Task Assigned', icon: faTasks },
    auto_lead_assign: { label: 'Auto Lead Assigned', icon: faUserCheck },
    lead_assigned: { label: 'Lead Assigned', icon: faUserCheck },
    note_created: { label: 'Note Created', icon: faStickyNote },
    quotation_created: { label: 'Quotation Created', icon: faFileInvoice },
    quotation_approved: { label: 'Quotation Approved', icon: faFileInvoice },
    lead_moved: { label: 'Lead Moved', icon: faArrowRight },
    lead_copied: { label: 'Lead Copied', icon: faArrowRight },
    lead_details_updated: { label: 'Lead Details Updated', icon: faUserEdit },
    new_voice_note: { label: 'New Voice Note', icon: faMicrophone },
    lead_score_update: { label: 'Lead Score Updated', icon: faClipboardCheck },
};

export const getActivityTypeConfig = (type: string): ActivityTypeConfig => {
    return activityTypes[type] || { label: 'Unknown Activity', icon: faExclamationCircle };
};

export default activityTypes;