import "./index.css";
import Header from "../../components/header";
import { Spin } from "antd";
import { useEffect, useMemo, useState } from "react";
import debounce from "lodash/debounce";
import { deleteTemplate, getTemplates } from "../../services/templates";
import Template from "./Template";
import { Each } from "../../utils/Each";
import CreateTemplate from "./createTemplate/createTemplate";
import { toast } from "react-hot-toast";
import ScrollToTopButton from "../../components/ScrollToTopButton";
import ScrollToBottomDetector from "../../components/ScrollToBottomDetector";
import FacebookIntegration from "../../components/FacebookIntegration";
import UltimatePlanInfo from "../../components/UltimatePlanInfo/UltimatePlanInfo";
import { styled } from "styled-components";

const PER_PAGE = 15;



export const SearchWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 15px;
`;

export const SearchIconWrapper = styled.div`
  position: absolute;
  left: 10px;
  display: flex;
  align-items: center;
  pointer-events: none;
  color: #666;
`;

export const StyledSearchInput = styled.input`
  width: 250px;
  height: 38px;
  padding: 8px 15px 8px 35px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  font-size: 14px;
  transition: all 0.2s;

  &:focus {
    outline: none;
    border-color: #3FAEFD;
    box-shadow: 0 0 0 2px rgba(63, 174, 253, 0.1);
  }

  &::placeholder {
    color: #9CA3AF;
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export default function Templates() {
  const [page, setPage] = useState(1);
  const [templates, setTemplates] = useState<any[]>([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [showFbButton, setShowFbButton] = useState(false);
  const [create, setCreate] = useState(false);
  const [editTemplateData, setEditTemplateData] = useState<any>(null);
  const [searchText, setSearchText] = useState<string>("");
  const [isUltimate, setIsUltimate] = useState<boolean | null>(null);

  // Load user data

useEffect(() => {
  const local = window.localStorage?.getItem("userData");
  if (local) {
    const userData = JSON.parse(local);
    setIsUltimate(userData?.subscriptionPlanType?.toLowerCase() === "ultimate");
  }
}, []);

  // Debounced search function
  const debouncedSearch = useMemo(
    () =>
      debounce((text: string) => {
        setPage(1);
        setTemplates([]); // Clear existing templates when searching
        setSearchText(text);
      }, 300),
    []
  );

  const fetchTemplates = async () => {
    try {
      setLoading(true);
      const payload = {
        page,
        perPage: PER_PAGE,
        ...(searchText && { search: searchText }),
      };

      const { data } = await getTemplates(payload);
      setTotal(data.total);
      setTemplates((prev) => (page === 1 ? data.data : [...prev, ...data.data]));
    } catch (error) {
      toast.error("Failed to fetch templates");
    } finally {
      setLoading(false);
      setInitialLoading(false);
    }
  };

  useEffect(() => {
    fetchTemplates();
  }, [page, searchText]);

  useEffect(() => {
    const userIntegrationsList = JSON.parse(
      localStorage.getItem("userData") || ""
    ).userIntegrations;

    const findData = userIntegrationsList.find(
      (item: any) =>
        item?.integration?._id === "646b9286ae8ef73b33caec2e" && item?.isActive
    );

    setShowFbButton(!findData);
  }, []);

  const deleteTemplateHandler = async (id: string) => {
    try {
      await deleteTemplate(id);
      setTemplates((prev) => prev.filter((item) => item._id !== id));
      setTotal((prev) => prev - 1);
      toast.success("Template deleted successfully.");
    } catch (error) {
      toast.error("Something went wrong while deleting template");
    }
  };

  const handleCreateSuccess = (value: string) => {
    setCreate(false);
    setEditTemplateData(null);
    if (value) {
      setPage(1);
      setTimeout(fetchTemplates, 1000);
    }
  };

  if (isUltimate === false) { // Explicitly check for false
    return (
      <div id="main" className="main">
        <Header />
        <UltimatePlanInfo />
      </div>
    );
  }

  if (create || editTemplateData) {
    return (
      <CreateTemplate
        editTemplateData={editTemplateData}
        toggleCreate={handleCreateSuccess}
      />
    );
  }

  return (
    <div style={{ padding: "20px 25px" }} id="main" className="main">
      <Header isSearch={false} />
  
      {showFbButton ? (
        <FacebookIntegration
          onSuccess={() => {
            setShowFbButton(false);
            setPage(1);
          }}
        />
      ) : (
        <div className="container">
          <div className="container_header">
            <div>
              <h4 className="text-dark container_header_title fm-gilroyBold">
                WhatsApp Templates ({total})
              </h4>
              {searchText && (
                <p className="text-muted mt-1">
                  Showing results for "{searchText}"
                </p>
              )}
            </div>
            <ActionContainer>
              <SearchWrapper>
                <SearchIconWrapper>
                  <i className="fa fa-search" />
                </SearchIconWrapper>
                <StyledSearchInput
                  type="text"
                  placeholder="Search templates..."
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => debouncedSearch(e.target.value)}
                />
              </SearchWrapper>
              <button
                className="btn fw-bold auto_btn"
                style={{ background: "#3FAEFD" }}
                onClick={() => setCreate(true)}
                disabled={loading}
              >
                <i className="fa fa-plus"></i>&nbsp;Create template
              </button>
            </ActionContainer>
          </div>

          <div style={{ minHeight: "5rem", paddingTop: loading ? "1rem" : 0 }}>
            <Spin 
              size="large" 
              tip={initialLoading ? "Loading templates..." : "Loading more..."} 
              spinning={loading}
            >
              <div className="container_body">
                <Each
                  of={templates}
                  render={(template) => (
                    <Template
                      key={template._id}
                      template={template}
                      deleteTemplate={deleteTemplateHandler}
                      editTemplate={setEditTemplateData}
                    />
                  )}
                />

                {!loading && !templates?.length && (
                  <div className="empty_container">
                    <h5>
                      {searchText
                        ? `No templates found matching "${searchText}"`
                        : "No templates created yet"}
                    </h5>
                    {!searchText && (
                      <button
                        className="btn fw-bold auto_btn mt-3"
                        style={{ background: "#3FAEFD" }}
                        onClick={() => setCreate(true)}
                      >
                        <i className="fa fa-plus"></i>&nbsp;Create your first template
                      </button>
                    )}
                  </div>
                )}
              </div>
            </Spin>
          </div>

          {templates.length < total && !loading && (
            <ScrollToBottomDetector
              onScrollToBottom={() => setPage((prev) => prev + 1)}
            />
          )}
        </div>
      )}
      <div className="scroll-top-wrapper">
        <ScrollToTopButton />
      </div>
    </div>
  );
}


