import axios from "axios";
import { API_URL } from "../config/config";
import { getHeaderOptions } from "./getHeaderOptions";

const API_ENDPOINT = API_URL + "api/v1";

export const getAllConversations = (data: any, params: any = {}) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.get(`${API_ENDPOINT}/whatsapp/conversation`, {
    headers: obj,
    data: data,
    params,
  });
};

export const searchLeads = (term: string) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.get(`${API_ENDPOINT}/lead/search`, {
    headers: obj,
    params: {
      search: term,
      page: 1,
      perPage: 15,
    },
  });
};

export const getConversationsFromId = (id: string) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.get(`${API_ENDPOINT}/whatsapp/conversation/${id}`, {
    headers: obj,
  });
};

export const assignConversation = (id: string, body: any) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.put(`${API_ENDPOINT}/whatsapp/conversation/${id}/assign`, body, {
    headers: obj,
  });
};

export const sendMessageToLead = (data: any) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.post(`${API_ENDPOINT}/whatsapp/send`, data, {
    headers: obj,
  });
};

export const updateProfile = (data: any) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.put(`${API_ENDPOINT}/whatsapp/profile`, data, {
    headers: obj,
  });
};

export const getWhatsappProfile = () => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.get(`${API_ENDPOINT}/whatsapp/profile`, {
    headers: obj,
  });
};

export const downloadChatMedia = (data: any) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
    "content-type": "multipart/form-data",
  };
  return axios.post(`${API_ENDPOINT}/whatsapp/download-media`, data, {
    headers: obj,
    // responseType: "blob",
    responseType: "arraybuffer",
  });
};

export const uploadMedia = (data: any) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
    "content-type": "multipart/form-data",
  };
  return axios.post(`${API_ENDPOINT}/whatsapp/upload-media`, data, {
    headers: obj,
  });
};

export const deleteChat = (id: string) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.delete(`${API_ENDPOINT}/whatsapp/conversation/${id}`, {
    headers: obj,
  });
};
