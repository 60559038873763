import { SET_ACTIVITIES } from "../actions/actionTypes";

interface ActivityI {
  formData: Array<any>;
}
const initialState = {
  formData: [],
};

const formDataReducer = (
  state: ActivityI = initialState,
  action: { type: string; payload: any }
): ActivityI => {
  switch (action?.type) {
    case "SET_UPDATED_FORM_DATA":
      return {
        ...state,
        formData: action?.payload,
      };
    default:
      return state;
  }
};

export default formDataReducer;
