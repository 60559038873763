// src/services/TokenManager.ts

import { getToken } from 'firebase/messaging';
import { messaging } from '../config/firebase';

export class TokenManager {
  private static instance: TokenManager;
  private static readonly TOKEN_VALID_DURATION = 3600000; // 1 hour
  private static readonly STORAGE_KEY = 'fcm_token_data';
  private static readonly VAPID_KEY = "BChSTS9tcwV1Ring5rMDhbPVtc83NVW6PD0DtGPEduxRcDP0hJztgTHsOcASoU9ZturcQJ2pTCMn1ogbMTsX_aI";

  private tokenData: {
    token: string | null;
    timestamp: number;
    status: 'ready' | 'refreshing' | 'error';
  };

  private constructor() {
    this.tokenData = this.loadTokenData();
  }

  public static getInstance(): TokenManager {
    if (!TokenManager.instance) {
      TokenManager.instance = new TokenManager();
    }
    return TokenManager.instance;
  }

  private loadTokenData() {
    const stored = localStorage.getItem(TokenManager.STORAGE_KEY);
    return stored ? JSON.parse(stored) : {
      token: null,
      timestamp: 0,
      status: 'ready' as const
    };
  }

  private saveTokenData(): void {
    localStorage.setItem(TokenManager.STORAGE_KEY, JSON.stringify(this.tokenData));
  }

  public async getValidToken(): Promise<string> {
    console.log('TM1. Getting valid token');
    if (this.isTokenValid()) {
      console.log('TM2. Using existing valid token');
      return this.tokenData.token!;
    }
    console.log('TM3. Token invalid or missing, refreshing');
    return this.refreshToken();
  }

  private async refreshToken(): Promise<string> {
    try {
      console.log('TM4. Starting token refresh');
      this.tokenData.status = 'refreshing';
      this.saveTokenData();
  
      const newToken = await this.requestNewToken();
      console.log('TM5. New token received');
  
      this.tokenData = {
        token: newToken,
        timestamp: Date.now(),
        status: 'ready'
      };
      this.saveTokenData();
      
      return newToken;
    } catch (error) {
      console.log('TM-ERROR: Token refresh failed:', error);
      this.tokenData.status = 'error';
      this.saveTokenData();
      throw error;
    }
  }
  

// In TokenManager.ts
private async requestNewToken(): Promise<string> {
  try {
    const registration = await navigator.serviceWorker.getRegistration();
    if (!registration?.active) {
      throw new Error('No active service worker found');
    }

    if (!messaging) {
      throw new Error('Firebase messaging not initialized');
    }

    const token = await getToken(messaging, {
      vapidKey: TokenManager.VAPID_KEY,
      serviceWorkerRegistration: registration  // Pass the registration explicitly
    });
    
    return token;
  } catch (error) {
    console.error('Error getting new token:', error);
    throw error;
  }
}

  private isTokenValid(): boolean {
    return (
      this.tokenData.token !== null &&
      this.tokenData.status === 'ready' &&
      Date.now() - this.tokenData.timestamp < TokenManager.TOKEN_VALID_DURATION
    );
  }
}

export default TokenManager;