// ButtonSection.tsx
import React, { useCallback } from 'react';
import ButtonInputs from './ButtonInputs';
import { ButtonType } from './types';

interface ButtonSectionProps {
  expandedSections: Record<string, boolean>;
  toggleSection: (sectionId: string) => void;
  handleChange: (e: React.ChangeEvent<any>) => void;
  values: {
    haveButtons: "yes" | "no";
    buttons: ButtonType[];
  };
  errorsObj: Record<string, string>;
  setFieldValue?: (field: string, value: any) => void;
  isSubmitting?: boolean;
}

const defaultButton: ButtonType = {
  type: "",
  text: "",
  url: "",
  phone_number: ""
};

const ButtonSection: React.FC<ButtonSectionProps> = ({
  expandedSections,
  toggleSection,
  handleChange,
  values,
  errorsObj,
  setFieldValue,
  isSubmitting = false
}) => {
  const getAvailableButtonTypes = useCallback((buttons: ButtonType[], currentIndex: number) => {
    const otherButtons = buttons.filter((_, idx) => idx !== currentIndex);
    
    const phoneCount = otherButtons.filter(b => b.type === "PHONE_NUMBER").length;
    const urlCount = otherButtons.filter(b => b.type === "URL").length;
    
    const availableTypes: Array<"URL" | "PHONE_NUMBER"> = [];
    
    // If current button is already a type, include it
    if (buttons[currentIndex]?.type === "PHONE_NUMBER") {
      availableTypes.push("PHONE_NUMBER");
    } else if (phoneCount === 0) {
      availableTypes.push("PHONE_NUMBER");
    }
    
    if (buttons[currentIndex]?.type === "URL") {
      availableTypes.push("URL");
    } else if (urlCount < 2) {
      availableTypes.push("URL");
    }
    
    return availableTypes;
  }, []);


  
  const handleAddButton = useCallback(() => {
    if (!setFieldValue || values.buttons.length >= 3) return;
    
    setFieldValue('buttons', [
      ...values.buttons, 
      {
        type: "",
        text: "",
        url: "",
        phone_number: ""
      }
    ]);
  }, [setFieldValue, values.buttons]);

  const handleRemoveButton = useCallback((index: number) => {
    if (!setFieldValue) return;
    const newButtons = values.buttons.filter((_, i) => i !== index);
    setFieldValue('buttons', newButtons);
  }, [setFieldValue, values.buttons]);

  const handleButtonTypeChange = useCallback((type: string, index: number) => {
    if (!setFieldValue) return;
    
    const newButtons = [...values.buttons];
    newButtons[index] = {
      type: type as ButtonType['type'],
      text: '',
      phone_number: type === 'PHONE_NUMBER' ? '' : undefined,
      url: type === 'URL' ? '' : undefined
    };
    
    setFieldValue('buttons', newButtons);
  }, [setFieldValue, values.buttons]);

  const canAddMoreButtons = useCallback(() => {
    if (values.buttons.length >= 3) return false;
    
    const phoneCount = values.buttons.filter(b => b.type === "PHONE_NUMBER").length;
    const urlCount = values.buttons.filter(b => b.type === "URL").length;
    
    return phoneCount < 1 || urlCount < 2;
  }, [values.buttons]);

  return (
    <div className="section_block">
      <div className={`section_heading ${expandedSections["section3"] ? "" : "mb-0"}`}>
        <div className="me-auto">
          <label>Buttons (optional)</label>
          <p>Add up to 3 buttons (max 2 URL buttons and 1 phone button)</p>
        </div>
        <svg
          viewBox="0 0 24 24"
          focusable="false"
          className={`arrow-icon ${expandedSections["section3"] ? "expanded" : ""}`}
          aria-hidden="true"
          onClick={() => !isSubmitting && toggleSection("section3")}
        >
          <path
            fill="currentColor"
            d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z"
          />
        </svg>
      </div>

      {expandedSections["section3"] && (
        <>
          <div className="radioInputs">
  <label htmlFor="have_buttons_none">
    <input
      type="radio"
      id="have_buttons_none"
      name="haveButtons"
      value="no"
      checked={values.haveButtons === "no"}
      onChange={handleChange}
      disabled={isSubmitting}
    />
    <span>None</span>
  </label>
  <label htmlFor="have_buttons_cta">
    <input
      type="radio"
      id="have_buttons_cta"
      name="haveButtons"
      value="yes"
      checked={values.haveButtons === "yes"}
      onChange={(e) => {
        handleChange(e);
        if (e.target.value === "yes") {
          setFieldValue?.("buttons", [{
            type: "",
            text: "",
            url: "",
            phone_number: ""
          }]);
        }
      }}
      disabled={isSubmitting}
    />
    <span>Call to Action</span>
  </label>
</div>

          {values.haveButtons === "yes" && (
            <div className="buttons-container mt-3">
              {values.buttons.map((button, index) => (
                <div 
                  key={index} 
                  className="button-input-container mb-3 border rounded-2 p-3"
                >
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h6 className="mb-0">Button {index + 1}</h6>
                    {values.buttons.length > 1 && (
                      <button
                        type="button"
                        onClick={() => handleRemoveButton(index)}
                        className="btn btn-outline-danger btn-sm"
                        disabled={isSubmitting}
                      >
                        Remove
                      </button>
                    )}
                  </div>
                  
                  <ButtonInputs
                    button={button}
                    index={index}
                    errorsObj={errorsObj}
                    setFieldValue={setFieldValue}
                    isSubmitting={isSubmitting}
                    onTypeChange={handleButtonTypeChange}
                    availableTypes={getAvailableButtonTypes(values.buttons, index)}
                  />
                </div>
              ))}
              
              {canAddMoreButtons() && (
                <div className="mt-3">
                  <button
                    type="button"
                    onClick={handleAddButton}
                    className="btn btn-outline-primary"
                    disabled={isSubmitting}
                  >
                    Add Button
                  </button>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default ButtonSection;