// BusinessSwitcherModal.tsx

import React, { useState, useEffect } from 'react';
import { Check } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import PopupComponent from './PopupComponent';
import { switchBusinessAccount } from '../services/authServices';
import { 
  resetAll, 
  userPreferences, 
  setCustomSource, 
  setUserDetails 
} from '../actions/actions';

export interface Business {
  _id: string;
  firstName: string;
  lastName: string;
  organization: {
    _id: string;
    name: string;
  } | null;
  role: {
    name: string;
    displayName: string;
  } | null;
}

interface BusinessSwitcherProps {
  isOpen: boolean;
  onClose: () => void;
}

const BusinessSwitcher: React.FC<BusinessSwitcherProps> = ({
  isOpen,
  onClose,
}) => {
  const [businesses, setBusinesses] = useState<Business[]>([]);
  const [currentOrgId, setCurrentOrgId] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [switchingToId, setSwitchingToId] = useState<string | null>(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isOpen) {
      try {
        const storedBusinesses = JSON.parse(localStorage.getItem('userBusinesses') || '[]');
        const userData = JSON.parse(localStorage.getItem('userData') || '{}');
        setBusinesses(storedBusinesses);
        setCurrentOrgId(userData?.organization?._id || '');
      } catch (error) {
        console.error('Error loading businesses:', error);
        toast.error('Failed to load business accounts');
      }
    }
  }, [isOpen]);

  const handleBusinessSwitch = async (business: Business) => {
    console.log('Starting business switch process for:', business._id);
    
    if (!business.organization) {
      console.log('Invalid business account - missing organization');
      toast.error('Invalid business account');
      return;
    }
  
    // Don't switch if already on this business
    if (business.organization._id === currentOrgId) {
      console.log('Already on selected business, no switch needed');
      onClose();
      return;
    }
  
    try {
      setLoading(true);
      setSwitchingToId(business._id);
      console.log('Initiating switch to business:', business.organization.name);
  
      // Show persistent toast that won't auto-dismiss
      toast.loading('Switching business account...', {
        duration: Infinity,
        position: 'top-center'
      });
  
      // First reset Redux store
      console.log('Resetting Redux store...');
      dispatch(resetAll());
      console.log('Redux store reset completed');
  
      console.log('Making API call to switch business account');
      const response = await switchBusinessAccount(business._id);
      console.log('Switch API response:', response?.data?.status);
  
      if (response?.data?.status) {
        console.log('Successfully received new business data');
  
        // Clear all existing data first
        console.log('Clearing localStorage');
        localStorage.clear();
  
        // Store new token first
        if (response.data.data?.token) {
          console.log('Setting new auth token');
          localStorage.setItem("auth_token", response.data.data.token);
        }
  
        // Then store user data
        if (response.data.data) {
          console.log('Setting new user data');
          localStorage.setItem("userData", JSON.stringify(response.data.data));
        }
  // Dispatch new Redux state (mirroring OTP logic)
dispatch(userPreferences(response.data.data.userPreference));
dispatch(setCustomSource(response.data.data.userPreference.customSource));
dispatch(setUserDetails(response.data.data));

        // Store preferences if available
        if (response.data.data?.userPreference) {
          console.log('Setting new user preferences');
          localStorage.setItem(
            "user_preferences", 
            JSON.stringify(response.data.data.userPreference)
          );
        }
  
        // Store business list if available
        if (Array.isArray(response.data.data?.multiAccountList)) {
          console.log('Setting new business list');
          localStorage.setItem(
            "userBusinesses", 
            JSON.stringify(response.data.data.multiAccountList)
          );
        }
  
        // Update toast to show success
        toast.dismiss();
        toast.success('Switching to ' + business.organization.name + '...');
  
        console.log('All data updated, preparing for page reload');
        
        // Small delay then reload
        setTimeout(() => {
          console.log('Reloading page to complete switch');
          window.location.reload();
        }, 500);
      } else {
        console.error('Switch failed - invalid response status');
        throw new Error('Switch failed');
      }
    } catch (error) {
      console.error('Error during business switch:', error);
      toast.dismiss();
      toast.error('Failed to switch business account');
    } finally {
      console.log('Cleaning up switch process');
      setLoading(false);
      setSwitchingToId(null);
    }
  };
  
  const formatRoleName = (roleName: string): string => {
    if (!roleName) return '';
    return roleName
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  if (!businesses.length) {
    return (
      <PopupComponent
        isOpen={isOpen}
        onClose={onClose}
        title="Switch Business Account"
        size="sm"
      >
        <div className="p-4 text-center text-gray-500">
          No business accounts available
        </div>
      </PopupComponent>
    );
  }

  return (
    <PopupComponent
      isOpen={isOpen}
      onClose={onClose}
      title="Switch Business Account"
      size="sm"
      loading={loading}
    >
      <div className="business-list" style={{ minWidth: '320px' }}>
        {businesses.map((business) => {
          if (!business.organization) return null;
          
          const isCurrentBusiness = business.organization._id === currentOrgId;
          const isSwitchingTo = business._id === switchingToId;
          
          return (
            <div
              key={business._id}
              onClick={() => !loading && handleBusinessSwitch(business)}
              className="business-item hover:bg-gray-50"
              style={{
                padding: '14px 16px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                cursor: loading ? 'default' : 'pointer',
                borderRadius: '8px',
                marginBottom: '8px',
                border: '1px solid #eee',
                backgroundColor: isCurrentBusiness ? '#f8fafc' : 'white',
                opacity: loading && !isSwitchingTo ? 0.5 : 1,
              }}
            >
              <div style={{ flex: 1 }}>
                <div className="text-sm font-medium text-gray-900">
                  {business.firstName} {business.lastName}
                </div>
                
                <div className="text-sm text-gray-600">
                  {business.organization.name}
                  {isSwitchingTo && (
                    <span className="ml-2 text-blue-500">Switching...</span>
                  )}
                </div>

                <div className="text-xs text-gray-500 capitalize">
                  {business.role?.displayName || formatRoleName(business.role?.name || '')}
                </div>
              </div>

              {isCurrentBusiness && (
                <div className="ml-3">
                  <Check size={18} className="text-blue-500" />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </PopupComponent>
  );
};

export default BusinessSwitcher;