import React, { useEffect, useState } from "react";
import moment from "moment";
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";

import { DateRange } from "../views/dashboard/dashboard";

interface DateFilter {
  from: string;
  to: string;
}

interface FlatRangeDatePicker {
  callBackFun: Function;
  dateRangeValue: DateRange;
}

const RangeDatePicker = ({
  callBackFun,
  dateRangeValue,
}: FlatRangeDatePicker) => {
  const [filter, setFilter] = useState(dateRangeValue);

  const handleDateFilter = (value?: any): void => {
    if (value && value.length === 2) {
      const [from, to] = value;
      if (from && to) {
        const filter: DateFilter = {
          from: moment(from).utc().format(),
          to: moment(to).utc().format(),
        };
        setFilter(filter);
        callBackFun(filter);
      }
    }
  };

  useEffect(() => {
    setFilter(dateRangeValue);
  }, [dateRangeValue]);

  return (
    <Flatpickr
      className="form-control border-0 dash-filter-picker w-auto theme-fm"
      options={{
        mode: "range",
        dateFormat: "d M, Y",
        defaultDate: [filter.from, filter.to],
      }}
      value={[filter.from, filter.to]}
      onChange={(date) => {
        handleDateFilter(date);
      }}
      style={{
        width: "280px",          // Adjust width to ensure full date range visibility
        fontSize: "0.6rem",       // Smaller font size for full date display
        color: "#495057",         // Adjust color for better readability
        padding: "6px",           // Adjust padding as needed
      }}
      placeholder="Select date range"
    />
  );
};

export default RangeDatePicker;
