import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import Select, { components, CSSObjectWithLabel, GroupBase, StylesConfig } from 'react-select';
import { ClearIndicatorProps as ReactSelectClearIndicatorProps } from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { ClearIndicatorProps } from 'react-select';
import { setLeadFilter } from '../../actions/actions';

import { FilterParams } from './leadsGrid/leads.types';

import { CalendarDays, Tags, Users, Flag, XCircle, ChevronDown } from 'lucide-react';
import styled from 'styled-components';

type StylesType = StylesConfig<any, false, GroupBase<any>>;





interface DropdownOption {
  value: string;
  label: string;
}

interface MenuPosition {
  top: number;
  left: number;
  width: number;
}

interface MenuState {
  isOpen: boolean;
  position: MenuPosition | null;
  activeKey: string | null;
}

interface DropdownProps {
  options: DropdownOption[];
  value: DropdownOption[] | null;
  onChange: (selected: DropdownOption[]) => void;
  placeholder?: string;
  icon: React.ComponentType<{ size: number }>;
  label: string;
}

// State type
interface DropdownState {
  isOpen: boolean;
}



interface QuickFiltersProps {
  onChange?: (() => void) | undefined;
}

interface CustomClearIndicatorProps extends ReactSelectClearIndicatorProps<any> {
  children: ReactNode;
}

// FIND the FilterState interface and UPDATE:
interface FilterState extends FilterParams {
  [key: string]: any;
  sort?: {
    orderBy?: string;
    isAscending?: boolean;
  };
  paginationParams?: {
    page: number;
    perPage: number;
  };
  teamMembers?: string[]; // ADD this line
}




const getDateRange = (dateType: string): { startedAt?: string; endedAt?: string } => {
  if (!dateType) return {};
  
  const today = new Date();
  today.setHours(23, 59, 59, 999);
  
  const getStartOfDay = (date: Date): Date => {
    const d = new Date(date);
    d.setHours(0, 0, 0, 0);
    return d;
  };

  const getStartOfWeek = (date: Date): Date => {
    const d = new Date(date);
    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6 : 1);
    d.setDate(diff);
    d.setHours(0, 0, 0, 0);
    return d;
  };

  switch(dateType) {
    case 'today': {
      return {
        startedAt: getStartOfDay(today).toISOString(),
        endedAt: today.toISOString()
      };
    }
    case 'yesterday': {
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);
      return {
        startedAt: getStartOfDay(yesterday).toISOString(),
        endedAt: new Date(yesterday.setHours(23, 59, 59, 999)).toISOString()
      };
    }
    case 'thisWeek': {
      return {
        startedAt: getStartOfWeek(today).toISOString(),
        endedAt: today.toISOString()
      };
    }
    case 'lastWeek': {
      const lastWeekStart = new Date(today);
      lastWeekStart.setDate(lastWeekStart.getDate() - 7);
      const lastWeekEnd = new Date(lastWeekStart);
      lastWeekEnd.setDate(lastWeekEnd.getDate() + 6);
      lastWeekEnd.setHours(23, 59, 59, 999);
      return {
        startedAt: getStartOfDay(getStartOfWeek(lastWeekStart)).toISOString(),
        endedAt: lastWeekEnd.toISOString()
      };
    }
    case 'last15Days': {
      const startDate = new Date(today);
      startDate.setDate(startDate.getDate() - 15);
      return {
        startedAt: getStartOfDay(startDate).toISOString(),
        endedAt: today.toISOString()
      };
    }
    case 'lastMonth': {
      const startDate = new Date(today);
      startDate.setMonth(startDate.getMonth() - 1);
      return {
        startedAt: getStartOfDay(startDate).toISOString(),
        endedAt: today.toISOString()
      };
    }
    default:
      return {};
  }
};



const dateLeadAdded = [
  { value: "today", label: "Today" },
  { value: "yesterday", label: "Yesterday" },
  { value: "thisWeek", label: "This Week" },
  { value: "lastWeek", label: "Last Week" },
  { value: "last15Days", label: "Last 15 Days" },
  { value: "lastMonth", label: "Last Month" }
];




const QuickFilters: React.FC<QuickFiltersProps> = ({ onChange }) => {
  const dispatch = useDispatch();
  const { filterParam } = useSelector((state: any) => state.rootReducers.leads);
  const userPreferences = useSelector((state: any) => state.rootReducers.user?.userPreferences);
  const [openDropdowns, setOpenDropdowns] = useState<{ [key: string]: boolean }>({});
  const [openMenuId, setOpenMenuId] = useState<string | null>(null);
  const [menuPosition, setMenuPosition] = useState<MenuPosition | null>(null);
  const menuTriggerRef = useRef<HTMLDivElement>(null);
  
  const StoreData = useSelector((state: any) => state?.rootReducers);
const employee = StoreData?.userData?.userDetails?.organizationEmployee;

 
  React.useEffect(() => {
    // Close dropdown after selection if there are any active filters
    if (filterParam.status?.length || filterParam.label?.length) {
      setOpenDropdowns({});
    }
  }, [filterParam]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const dropdownElements = document.querySelectorAll('.dropdown-container');
      let clickedInsideDropdown = false;
      
      dropdownElements.forEach(element => {
        if (element.contains(event.target as Node)) {
          clickedInsideDropdown = true;
        }
      });
      
      if (!clickedInsideDropdown) {
        setOpenDropdowns({});
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);
  

  
  const handleFilterChange = (key: string, selected: any) => {
    const newState = {
      ...filterParam,
      paginationParams: { page: 1, perPage: 50 }
    };

    if (!selected) {
      delete newState[key];
      dispatch(setLeadFilter(newState));
      onChange?.();
      // Close dropdown after clearing
      setOpenDropdowns(prev => ({ ...prev, [key]: false }));
      return;
    }

    if (key === 'date') {
      const dateRange = getDateRange(selected.value);
      if (dateRange.startedAt && dateRange.endedAt) {
        newState[key] = dateRange;
      }
      // Close date dropdown after selection
      setOpenDropdowns(prev => ({ ...prev, [key]: false }));
    } else {
      newState[key] = Array.isArray(selected) 
        ? selected.map(item => item.value)
        : [selected.value];
    }

    dispatch(setLeadFilter(newState));
    onChange?.();
  };
  
  // Add clearFilter helper
  const clearFilter = (key: string) => {
    const newState = {...filterParam};
    delete newState[key];
    dispatch(setLeadFilter(newState));
    onChange?.();
  };



  // Map values for each filter type
  const computeFilterValues = {
    
    date: (filterParam.date ? dateLeadAdded.find(d => {
      const range = getDateRange(d.value);
      return range.startedAt === filterParam.date?.startedAt && 
             range.endedAt === filterParam.date?.endedAt;
    }) : null),

    status: filterParam.status?.map((value: string) => {
      const option = userPreferences?.status?.find((s: any) => s.value === value);
      return option ? { value: option.value, label: option.name } : null;
    }).filter(Boolean) || [],

    label: filterParam.label?.map((value: string) => {
      const option = userPreferences?.labels?.find((l: any) => l.value === value);
      return option ? { value: option.value, label: option.name } : null;
    }).filter(Boolean) || [],

    teamMembers: filterParam.teamMembers?.map((value: string) => {
      const member = employee?.find((e: any) => e._id === value);
      return member ? {
        value: member._id,
        label: member.firstName && member.lastName 
          ? `${member.firstName} ${member.lastName}`
          : member.firstName
      } : null;
    }).filter(Boolean) || []
  };

  
  // QuickFilters array with proper typing
const quickFilters = [
  
  {
    key: 'date',
    label: 'Date',
    icon: CalendarDays,
    width: '120px',
    isMulti: false,
    options: dateLeadAdded,
    value: computeFilterValues.date,
    clearable: true
  },
  {
    key: 'status',
    label: 'Status',
    icon: Flag,
    width: '120px',
    isMulti: true,
    options: userPreferences?.status?.map((s: any) => ({ 
      value: s.value, 
      label: s.name 
    })) || [],
    value: computeFilterValues.status,
    clearable: true,
    getFilterValue: (selected: any[]) => selected?.length ? {
      status: selected.map(item => item.value)
    } : null
  },
  {
    key: 'label',
    label: 'Labels',
    icon: Tags,
    width: '120px',
    isMulti: true,
    options: userPreferences?.labels?.map((l: any) => ({ 
      value: l.value, 
      label: l.name 
    })) || [],
    value: computeFilterValues.label,
    clearable: true,
    getFilterValue: (selected: any[]) => selected?.length ? {
      label: selected.map(item => item.value)
    } : null
  },
  {
    key: 'teamMembers',
    label: 'Users',
    icon: Users,
    width: '120px',
    isMulti: true,
    options: employee?.map((v: any) => ({
      value: v._id,
      label: v.firstName && v.lastName ? `${v.firstName} ${v.lastName}` : v.firstName
    })) || [],
    value: computeFilterValues.teamMembers,
    clearable: true,
    getFilterValue: (selected: any[]) => selected?.length ? {
      teamMembers: selected.map(item => item.value)
    } : null
  }
] as const;

  return (
    // The existing render code should work, but verify the dropdown container
<FilterGroup>
  {quickFilters.map(({ key, label, icon: Icon, options, width, isMulti, value }, index) => {
    return (
      <React.Fragment key={key}>
        {index > 0 && <Separator>•</Separator>}
        <FilterWrapper width={width}>
          <DropdownContainer className="dropdown-container">
          <DropdownTrigger 
                onClick={(e: React.MouseEvent) => {
                  e.stopPropagation();
                  setOpenDropdowns(prev => ({
                    ...prev,
                    [key]: !prev[key]
                  }));
                }} 
                className={value?.length ? 'has-value' : ''}
              >
              <PlaceholderContent>
                <Icon size={14} />
                <span>
                  {value?.length ? 
                    value.length === 1 ? 
                      value[0].label : 
                      `${value[0].label} & ${value.length - 1} others` 
                    : label}
                </span>
              </PlaceholderContent>
              <ChevronIcon />
            </DropdownTrigger>

            {openDropdowns[key] && (
  <OptionsMenu position={menuPosition}>
    {options.map((option: DropdownOption) => (
      <OptionItem 
        key={option.value}
        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
          e.stopPropagation();
          if (key === 'date') {
            // Handle date selection
            handleFilterChange(key, option);
          } else {
            // Handle multi-select filters
            const newValue = value?.some((v: DropdownOption) => v.value === option.value)
              ? value.filter((v: DropdownOption) => v.value !== option.value)
              : [...(value || []), option];
            handleFilterChange(key, newValue);
          }
        }}
      >
        {key !== 'date' && (
          <Checkbox 
            checked={value?.some((v: DropdownOption) => v.value === option.value)}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              e.stopPropagation();
            }}
          />
        )}
        <OptionLabel>{option.label}</OptionLabel>
      </OptionItem>
    ))}
  </OptionsMenu>
)}
          </DropdownContainer>
        </FilterWrapper>
      </React.Fragment>
    );
  })}
</FilterGroup>
  );
};

// Styled components
const FilterGroup = styled.div`
  display: flex;
  align-items: center;
   gap: 0; 
  padding: 8px 16px;
  background: #ffffff;
  border-bottom: 1px solid #f1f5f9;
  width: 100%;
`;

const FilterWrapper = styled.div<{ width: string }>`
  min-width: 120px; // Even more compact
  width: ${props => props.width || 'fit-content'};
  max-width: 180px; // Further reduced
  z-index: 100;
   margin: 0;
  position: relative;
  
  // Add specific styling for clear button
  .select__clear-indicator {
    cursor: pointer;
    padding: 0 6px; // Slightly reduced padding
    &:hover {
      color: #ef4444;
    }
  }
  
  // Ensure single X button
  .select__multi-value__remove {
    padding: 0 3px; // Slightly reduced padding
    &:hover {
      background: #f3f4f6;
      color: #ef4444;
    }
  }
`;

const PlaceholderContent = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  color: #64748b;
`;

const Separator = styled.span`
  color:rgb(0, 0, 0);
  margin: 0 6px; 
  font-size: 6px;
  line-height: 1;
  user-select: none;
  vertical-align: middle;
  font-weight: bold;
`;

// Select component styles
const selectStyles = {
  control: (base: any, state: any) => ({
    ...base,
    minHeight: '26px',
    height: '26px',
    backgroundColor: state.isFocused ? '#dbeafe' : '#f8fafc',
    border: `1px solid ${state.isFocused ? '#93c5fd' : '#e2e8f0'}`,
    borderRadius: '4px',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#60a5fa',
      backgroundColor: '#dbeafe'
    },
    cursor: 'pointer',
    transition: 'all 0.2s ease'
  }),
  valueContainer: (base: any) => ({
    ...base,
    padding: '0 4px',
    height: '26px',
    fontSize: '13px'
  }),
  input: (base: any) => ({
    ...base,
    margin: 0,
    padding: 0
  }),
  placeholder: (base: any) => ({
    ...base,
    color: '#64748b',
    fontSize: '13px',
    margin: 0
  }),
  indicatorsContainer: (base: any) => ({
    ...base,
    height: '26px'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    padding: '0 4px',
    color: '#64748b',
    '&:hover': {
      color: '#475569'
    }
  }),
  clearIndicator: (
    base: Record<string, any>,
    props: ReactSelectClearIndicatorProps<unknown>
  ) => ({
    ...base,
    cursor: 'pointer',
    padding: '0 4px',
    '&:hover': {
      color: '#ef4444'
    }
  }),
  menu: (base: any) => ({
    ...base,
    fontSize: '13px',
    marginTop: '2px',
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    border: '1px solid #e2e8f0',
    borderRadius: '4px'
  }),
  menuPortal: (base: any) => ({ 
    ...base, 
    zIndex: 9999 
  }),
  option: (base: any, state: any) => ({
    ...base,
    fontSize: '13px',
    padding: '6px 8px',
    backgroundColor: state.isSelected ? '#2563eb' : state.isFocused ? '#f1f5f9' : 'white',
    color: state.isSelected ? 'white' : '#1e293b',
    '&:hover': {
      backgroundColor: state.isSelected ? '#2563eb' : '#f1f5f9'
    },
    cursor: 'pointer'
  }),
  multiValue: (base: any) => ({
    ...base,
    backgroundColor: '#dbeafe',
    borderRadius: '3px',
    margin: '2px'
  }),
  multiValueLabel: (base: any) => ({
    ...base,
    color: '#1e40af',
    fontSize: '12px',
    padding: '2px 4px'
  }),
  multiValueRemove: (base: any) => ({
    ...base,
    color: '#1e40af',
    padding: '0 4px',
    '&:hover': {
      backgroundColor: '#bfdbfe',
      color: '#1e40af'
    },
    cursor: 'pointer'
  })
};

const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
  &.dropdown-container {
    z-index: 50;
  }
`;

const DropdownTrigger = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 26px;
  padding: 0 8px;
  background: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: #dbeafe;
    border-color: #60a5fa;
  }

  &.has-value {
    background: #dbeafe;
    border-color: #93c5fd;
    color: #1e40af;
  }
`;

// Fix the OptionsMenu styling in our custom dropdown
const OptionsMenu = styled.div<{ position: MenuPosition | null }>`
position: fixed;
background: white;
border: 1px solid #e2e8f0;
border-radius: 4px;
box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
z-index: 9999;
min-width: 200px;

${({ position }) => position && `
  top: ${position.top}px;
  left: ${position.left}px;
  width: ${position.width}px;
`}
`;

const OptionItem = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 12px;
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: #f1f5f9;
  }
`;

const OptionLabel = styled.span`
  margin-left: 8px;
  font-size: 13px;
  color: #1e293b;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  margin: 0;
  cursor: pointer;
`;

const ChevronIcon = styled(ChevronDown)`
  width: 14px;
  height: 14px;
  color: currentColor;
  margin-left: 4px;
`;



export default QuickFilters;