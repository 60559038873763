import React, { useState } from "react";
import styled from "styled-components";
import { Each } from "../../utils/Each";
import Profile from "./Profile";
import SelectLeadLabel from "../../components/label";
import SelectLeadStatus from "../../components/status";
import LeadMenu from "../followUps/LeadMenu/leadMenu";
import { LeadDataI } from "../leads/leads";

interface StatusData {
  [key: string]: {
    color: string;
    name: string;
  };
}

interface User {
  userIcon: string;
  name: string;
  mobile: string;
  email?: string;
  assignedTo?: Array<{ firstName: string }>;
  _id?: string;
}

interface Label {
  value: string;
  name: string;
  color: string;
}

interface ChatDetailRightMenuProps {
  setShowUserDetails: (show: boolean) => void;
  selectedUser: User;
  addTaskHandler: () => void;
  addNoteHandler: () => void;
  renderMiniForm: (
    name: string,
    value: string,
    title: string,
    editable?: boolean
  ) => React.ReactNode;
  setAssignLeadDrawer: (show: boolean) => void;
  setAssignedLeadHeaderTitle: (title: string) => void;
  lead?: LeadDataI;
  statusData: StatusData;
  labelData: Label[];
  onLabelChange: (e: unknown, name: string) => void;
  from: string;
  setLead: React.Dispatch<React.SetStateAction<LeadDataI | undefined>>;
}

const MenuContainer = styled.div`
  width: 320px;
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  background: transparent;
  padding: 0 12px;
`;

const HeaderSection = styled.div`
  background: #ffffffb2;
  border-radius: 10px;
  padding: 12px;
  margin-bottom: 12px;
`;

const ProfileSection = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
`;

const UserInfo = styled.div`
  h2 {
    font-size: 14px;
    margin: 0;
    color: #000;
    line-height: 1.2;
    font-family: "Gilroy-Bold";
  }
  p {
    font-size: 11px;
    color: #666;
    margin: 0;
    font-family: "Gilroy-Medium";
  }
`;

const ActionButton = styled.button`
  width: 100%;
  background: white;
  border: 1px solid #eee;
  border-radius: 8px;
  padding: 8px 12px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.2s;
  font-family: "Gilroy-Medium";

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    background: #f5f5f5;
  }

  span {
    font-size: 13px;
    color: #000;
  }

  i {
    color: #666;
    font-size: 12px;
  }
`;

const InfoSection = styled.div`
  background: #ffffffb2;
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 12px;
  width: 100%;
  position: relative;

  .section-title {
    font-family: "Gilroy-Bold";
    font-size: 13px;
    color: #000;
    margin-bottom: 12px;
  }

  .section-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .edit-icon {
    position: absolute;
    top: 14px;
    right: 14px;
    opacity: 0;
    cursor: pointer;
    color: #666;
    padding: 4px;
    background: transparent;
    border: none;
    border-radius: 4px;
  }

  &:hover .edit-icon {
    opacity: 1;
  }
`;

const AssignmentCard = styled.div`
  background: #ffffffb2;
  border-radius: 10px;
  padding: 16px;
  margin-bottom: 12px;
  width: 100%;

  .title {
    font-size: 13px;
    color: #666;
    margin-bottom: 8px;
  }

  .value-container {
    background: white;
    border-radius: 8px;
    padding: 12px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .name {
      font-family: "Gilroy-Medium";
      font-size: 14px;
      color: #000;
    }

    .edit-icon {
      color: #666;
      opacity: 0;
      transition: opacity 0.2s;
      cursor: pointer;
    }

    &:hover .edit-icon {
      opacity: 1;
    }
  }
`;
const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
`;

const ChatDetailRightMenu: React.FC<ChatDetailRightMenuProps> = ({
  setShowUserDetails,
  selectedUser,
  addTaskHandler,
  addNoteHandler,
  renderMiniForm,
  setAssignLeadDrawer,
  setAssignedLeadHeaderTitle,
  lead,
  statusData,
  labelData,
  onLabelChange,
  from,
  setLead,
}) => {
  const [showLabelPopup, setShowLabelPopup] = useState(false);
  const [showStatusPopup, setShowStatusPopup] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [openLeadDrawer, setOpenLeadDrawer] = useState(false);

  const onStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedStatus(e.target.value);
  };

  return (
    <MenuContainer>
      <HeaderSection>
        <ProfileSection>
          <Profile
            style={{ width: "36px", height: "36px" }}
            src={selectedUser.userIcon}
          />
          <UserInfo>
            <h2>{selectedUser.name || "Unknown"}</h2>
            <p>{selectedUser.mobile}</p>
          </UserInfo>
        </ProfileSection>

        <ActionButton onClick={() => setOpenLeadDrawer(true)}>
          <span>Open details</span>
          <i className="fa-regular fa-arrow-up-right-from-square" />
        </ActionButton>
        <ActionButton onClick={addTaskHandler}>
          <span>Add Task</span>
          <i className="fa-regular fa-arrow-up-right-from-square" />
        </ActionButton>
        <ActionButton onClick={addNoteHandler}>
          <span>Add Note</span>
          <i className="fa-regular fa-arrow-up-right-from-square" />
        </ActionButton>
      </HeaderSection>

      <AssignmentCard>
        <div className="title">Chat assigned to</div>
        <div className="value-container">
          <span className="name">
            {selectedUser?.assignedTo?.[selectedUser?.assignedTo?.length - 1]
              ?.firstName ?? "Smallw"}
          </span>
          <i
            className="fa-regular fa-pen edit-icon"
            onClick={() => {
              setAssignLeadDrawer(true);
              setAssignedLeadHeaderTitle("Assign Chat");
            }}
          />
        </div>
      </AssignmentCard>

      <InfoSection>
        <div className="section-title">Status</div>
        <i
          className="fa-regular fa-pen edit-icon"
          onClick={() => setShowStatusPopup(true)}
        />
        <TagsContainer>
          <Each
            of={lead?.status || []}
            render={(status: string, index: number) => (
              <span
                key={`status-${status}-${index}`}
                style={{
                  backgroundColor: statusData[status]?.color,
                  color: "white",
                  padding: "4px 8px",
                  fontSize: "12px",
                  fontFamily: "Gilroy-Medium",
                  borderRadius: "4px",
                }}
              >
                {statusData[status]?.name}
              </span>
            )}
          />
        </TagsContainer>
      </InfoSection>

      <InfoSection>
        <div className="section-title">Labels</div>
        <i
          className="fa-regular fa-pen edit-icon"
          onClick={() => setShowLabelPopup(true)}
        />
        <TagsContainer>
          <Each
            of={labelData}
            render={(label: Label, index: number) => {
              if (!lead?.label.includes(label.value)) return null;
              return (
                <span
                  key={`label-${label.value}-${index}`}
                  style={{
                    backgroundColor: label.color,
                    color: "white",
                    padding: "4px 8px",
                    fontSize: "12px",
                    fontFamily: "Gilroy-Medium",
                    borderRadius: "4px",
                  }}
                >
                  {label.name}
                </span>
              );
            }}
          />
        </TagsContainer>
      </InfoSection>

      <SelectLeadLabel
        showLabelPopup={showLabelPopup}
        toggleLeadLabel={() => setShowLabelPopup((prev) => !prev)}
        onLabelChange={onLabelChange}
        selectedLabel={lead?.label || []}
        setShowLabelPopup={setShowLabelPopup}
        from={from}
        id={lead?._id || ""}
        leadList={""}
        getLeads={() => {}}
        setOpenWithHeader={() => {}}
        LabelData={labelData}
        updateEditedRow={(leads: LeadDataI | LeadDataI[]) => {
          setLead(Array.isArray(leads) ? leads[0] : leads);
        }}
      />

      <SelectLeadStatus
        showStatusPopup={showStatusPopup}
        toggleLeadStatus={() => setShowStatusPopup((prev) => !prev)}
        onStatusChange={onStatusChange}
        selectedStatus={selectedStatus}
        setShowStatusPopup={setShowStatusPopup}
        from={from}
        id={lead?._id || ""}
        leadList={""}
        getLeads={() => {}}
        setOpenWithHeader={() => {}}
        updateEditedRow={(leads: LeadDataI | LeadDataI[]) => {
          setLead(Array.isArray(leads) ? leads[0] : leads);
        }}
      />

      {lead && (
        <LeadMenu
          leadId={lead._id}
          leadLabelData={lead.label}
          setOpenWithHeader={() => {}}
          selectedLead={lead}
          openLeadDrawer={openLeadDrawer}
          setOpenLeadDrawer={setOpenLeadDrawer}
          onClickShowLabelPopup={() => setShowLabelPopup(true)}
          onClickShowStatusPopup={() => setShowStatusPopup(true)}
        />
      )}
    </MenuContainer>
  );
};

export default ChatDetailRightMenu;
