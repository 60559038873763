// TabContent.tsx
import React, { useState } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import LeadListTriggerSection from './LeadListTriggerSection';
import TeamMemberItem from './teamMemberItem'; 
import { LeadListTriggerData } from './LeadListTriggerTypes';
import { IOrganizationEmployee, IOrganizationRole, IOrganizationTeam } from '../../utils/types';

interface Props {
  mode: string;
  id?: string;
  isLoading: boolean;
  triggerData: LeadListTriggerData;
  setTriggerData: (data: LeadListTriggerData) => void;
  checkedState: {
    [_id: string]: { recepient: boolean; access: boolean };
  };
  setCheckedState: React.Dispatch<React.SetStateAction<{
    [_id: string]: { recepient: boolean; access: boolean };
  }>>;
  enableLeadDistribution: boolean;
  setEnableLeadDistribution: (enable: boolean) => void;
  distributionType: string;
  setDistributionType: (type: string) => void;
}

// Get user data from localStorage
let userData = localStorage.getItem("userData") || "{}";
if (userData === "undefined") {
  userData = "{}";
}
const teamMembersList: IOrganizationEmployee[] = JSON.parse(userData).organizationEmployee;
const organizationTeams: IOrganizationTeam[] = JSON.parse(userData).organizationTeams;
const organizationRoles: IOrganizationRole[] = JSON.parse(userData).organizationRoles;

const TabContent: React.FC<Props> = ({
  mode,
  id,
  isLoading,
  triggerData,
  setTriggerData,
  checkedState,
  setCheckedState,
  enableLeadDistribution,
  setEnableLeadDistribution,
  distributionType,
  setDistributionType
}) => {
  const [activeTab, setActiveTab] = useState('automation');

  const getActiveTeamMembers = () => {
    return teamMembersList?.filter((m) => m.isActive) || [];
  };

  const handleChange = (type: "recepient" | "access", id: string) => {
    const updatedCheckedState = {
      ...checkedState[id],
      [type]: !checkedState[id]?.[type],
    };
    setCheckedState({ ...checkedState, [id]: updatedCheckedState });
  };

  const renderEmptyState = (message: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined) => (
    <div className="text-center py-8">
      <i className="bi bi-people mb-3 d-block" style={{ fontSize: '24px' }}></i>
      <p className="text-muted">{message}</p>
    </div>
  );

  const renderTeamMembers = (type: "recepient" | "access") => {
  const activeMembers = getActiveTeamMembers();
  
  if (activeMembers.length === 0) {
    return renderEmptyState(
      type === "recepient" 
        ? "No team members available for lead distribution"
        : "No team members available to grant access"
    );
  }

  return activeMembers.map((member, i) => {
    const teamOrganization = organizationTeams.find(
      (o) => o.organization === member.organization
    );
    const organizationRole = organizationRoles.find(
      (o) => o.organization === member.organization
    );

    const teamMember = {
      name: member.lastName
        ? `${member.firstName} ${member.lastName}`
        : member.firstName,
      organization: teamOrganization?.name ?? "",
      _id: member._id,
      role: organizationRole?.displayName ?? "",
    };

    return (
      <div className="form-group2" key={i} style={{ marginBottom: '12px' }}>
        <div className="d-flex justify-content-between align-items-center">
          <TeamMemberItem
            teamMember={teamMember}
            checked={checkedState[member._id]?.[type]}
            handleChange={(id: string) => handleChange(type, id)}
          />
        </div>
      </div>
    );
  });
};
  
  return (
    <Tabs
      activeKey={activeTab}
      onSelect={(k) => k && setActiveTab(k)}
      className="mb-3"
    >
      {/* Automation Tab */}
     
<Tab eventKey="automation" title="Automation">
  <div className="p-3">
    <div className="mb-3">
      <h6>When This Happens...</h6>
      <p className="text-muted">
        Effortlessly keep your lead lists organized! Set up conditions like
        <strong> "status label updates" </strong> or 
        <strong> "lead source matches," </strong> and leads will automatically 
        move to this list when they meet your criteria.  
      </p>
      <p className="text-muted">
        For example, you can ensure all new Indiamart leads are instantly added 
        here without any manual effort.
      </p>
      <a
        href="https://help.3sigmacrm.com/welcome/managing-leads/custom-lead-list"
        className="text-primary"
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn how to set it up
      </a>
    </div>
    <LeadListTriggerSection 
      onChange={setTriggerData}
      initialData={triggerData}
      isLoading={isLoading}
    />
  </div>
</Tab>

{/* Distribution Tab */}
<Tab eventKey="distribution" title="Distribution">
  <div className="p-3">
    <div className="mb-4">
      <h6>Lead Distribution</h6>
      <p className="text-muted">
        Enabling this means all leads added to this list will be automatically distributed among team members.
      </p>
      <p className="text-muted">
        Learn more about <a 
          href="https://help.3sigmacrm.com/welcome/managing-leads/custom-lead-list#id-1.-round-robin-lead-distribution"
          className="text-primary"
          target="_blank"
          rel="noopener noreferrer"
        >
          round-robin lead distribution
        </a>.
      </p>
      <div className="form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          checked={enableLeadDistribution}
          onChange={(e) => setEnableLeadDistribution(e.target.checked)}
          id="enableDistribution"
        />
        <label className="form-check-label" htmlFor="enableDistribution">
          Enable lead distribution
        </label>
      </div>
    </div>

    {enableLeadDistribution && (
      <div className="mb-3">
        <div className="mb-3">
          <label className="form-label">Distribution Type</label>
          <select
            className="form-select"
            value={distributionType}
            onChange={(e) => setDistributionType(e.target.value)}
          >
            <option value="round-robin">Round Robin</option>
            <option value="weighted">Weighted</option>
          </select>
        </div>
        <div className="team-members-list">
  {renderTeamMembers("recepient")}
</div>
      </div>
    )}
  </div>
</Tab>

      {/* Access Tab */}
      <Tab eventKey="access" title="Access">
  <div className="p-3">
    <div className="mb-4">
      <h6>List Access</h6>
      <p className="text-muted">
        Grant access to all leads in this list to specific team members. This is useful when you want all users 
        to have shared access to the leads in this list. For example, teams with shared sales responsibilities, 
        multiple team leaders, or admins.
      </p>
      <p className="text-muted">
        Learn more about <a 
          href="https://help.3sigmacrm.com/welcome/managing-leads/custom-lead-list#managing-lead-access"
          className="text-primary"
          target="_blank"
          rel="noopener noreferrer"
        >
          managing lead access
        </a>.
      </p>
    </div>
    <div className="team-members-list">
  {renderTeamMembers("access")}
</div>
  </div>
</Tab>
    </Tabs>
  );
};

export default TabContent;
