import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  repeatOptions,
  taskDueDates,
  taskDueDatesValues,
} from "../utils/globalConstants";
import moment from "moment";
import Select from "react-select";
import ErrorText from "./errorText";
import * as yup from "yup";
import update from "immutability-helper";
import { FaClock } from "react-icons/fa";
import { DatePicker, TimePicker } from "antd";
import {
  createNewTask,
  updateTaskbyId,
  getUserOrganizationList,
} from "../services/taskService";
import toast, { Toaster } from "react-hot-toast";
import styled from "styled-components";
import { convertTimeToIST, convertToIST } from "../utils/helpers";
import _ from "lodash";
// import _, { isEmpty } from "lodash";
import "./taskItem.css";
import Form from "react-bootstrap/Form";

interface Props {
  action: string;
  status: string;
  updateTaskValue: any;
  leadId?: string;
  drawerClose: () => void;
  fetchTaskStatusFollowUps: (data?: any) => void;
}

interface taskType {
  task: string;
  type: string;
  dueDate: string | undefined;
  toBePerformAt: string | undefined;
  time: string;
  repeat: string;
  taskAssign: string;
  notes: string;
  leadIds: string;
}

const CreateTaskForm: React.FC<PropsWithChildren<Props>> = ({
  updateTaskValue,
  leadId,
  action,
  status,
  drawerClose,
  fetchTaskStatusFollowUps,
}) => {
  const DUE_DATE_FORMAT = "YYYY-MM-DD";
  const StoreData = useSelector((state: any) => {
    return state?.rootReducers;
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [date, setDate] = useState<any>(null);
  const [isCustomDate, toggleCustomDate] = useState(false);
  const [leadList, setLeadList] = useState<Array<any>>([]);
  const [taskList, setTaskList] = useState<Array<any>>([]);
  const [taskAssignTo, setTaskAssignTo] = useState<Array<any>>([]);
  const [isCompleted, setIsCompleted] = useState(false);

  const [taskData, setTaskData] = useState<taskType>({
    task: "",
    type: "",
    dueDate: "",
    toBePerformAt: "",
    time: "",
    repeat: "NoRepeat",
    taskAssign: "",
    notes: "",
    leadIds: "",
  });

  const [errors, setErrors] = useState({
    task: "",
    type: "",
    dueDate: "",
    toBePerformAt: "",
    time: "",
    repeat: "",
    taskAssign: "",
    notes: "",
  });

  useEffect(() => {
    handleGetUserOrganization();
    setTaskList(StoreData?.user?.userPreferences?.taskType);
    setLeadList(StoreData?.leads?.leads);
  }, []);

  useEffect(() => {
    if (action === "edit") {
      if (Object.values(updateTaskValue).length > 0) {
        handleGetUserOrganization();

        let someDate = new Date(updateTaskValue.toBePerformAt);
        let date = someDate.setDate(someDate.getDate());
        let defaultValue = new Date(date).toISOString().split("T")[0];
        let time = new Date(date).toISOString().split("T")[1];
        let tempTime = moment(time, "hh:mm:ss").format("hh:mm:ss");

        setTaskData({
          task: updateTaskValue?.lead?.[0]?._id ?? updateTaskValue?.lead ?? "",
          type: updateTaskValue.type,
          dueDate: "custom",
          // toBePerformAt: defaultValue,
          toBePerformAt: updateTaskValue.toBePerformAt,
          time: updateTaskValue.toBePerformAt,
          repeat: updateTaskValue.repeat || "NoRepeat",
          taskAssign: updateTaskValue?.assignedTo[0]?._id,
          // notes: updateTaskValue.notes,
          notes: "",
          leadIds: updateTaskValue.lead[0]._id,
        });
        toggleCustomDate(true);
        setIsCompleted(updateTaskValue?.isCompleted ?? false);
      }
    } else if (action === "add" && leadId) {
      setTaskData({
        ...taskData,
        task: leadId,
      });
    }
  }, [updateTaskValue]);

  const handleGetUserOrganization = async () => {
    const data = localStorage.getItem("userData");
    if (data) {
      let tempArray = [...JSON.parse(data).organizationEmployee];
      tempArray.forEach((el: any) => {
        let findRole = StoreData.userData.userDetails.organizationRoles.find(
          (x: any) => x._id == el.role
        ).displayName;
        el.roleName = findRole;
        el.organizationName = StoreData.userData.userDetails.organization.name;
      });

      setTaskAssignTo(tempArray);
    }

    // try {
    //   const response = await getUserOrganizationList({
    //     page: 1,
    //     perPage: 15,
    //   });
    //   if (response && response.status) {
    //     let tempArray = [...response.data.data];
    //     tempArray.forEach((el: any) => {
    //       let findRole = StoreData.userData.userDetails.organizationRoles.find(
    //         (x: any) => x._id == el.role
    //       ).displayName;
    //       el.roleName = findRole;
    //       el.organizationName =
    //         StoreData.userData.userDetails.organization.name;
    //     });

    //     setTaskAssignTo(tempArray);
    //   }
    // } catch (err) {
    //   console.log(err, "Error");
    // }
  };

  const handleSetDueDate = (dueDateType: string) => {
    setErrors({
      ...errors,
      toBePerformAt: "",
    });
    switch (dueDateType) {
      // case "today":
      //   setTaskData({
      //     ...taskData,
      //     toBePerformAt:
      //       moment().format(DUE_DATE_FORMAT) +
      //       (taskData.time ? "T" + taskData.time : ""),
      //     dueDate: dueDateType,
      //   });
      //   toggleCustomDate(false);
      //   break;
      // case "tomorrow":
      //   setTaskData({
      //     ...taskData,
      //     toBePerformAt:
      //       moment().add(1, "d").format(DUE_DATE_FORMAT) +
      //       (taskData.time ? "T" + taskData.time : ""),
      //     dueDate: dueDateType,
      //   });
      //   toggleCustomDate(false);
      //   break;
      // case "3d":
      //   setTaskData({
      //     ...taskData,
      //     toBePerformAt:
      //       moment().add(3, "d").format(DUE_DATE_FORMAT) +
      //       (taskData.time ? "T" + taskData.time : ""),
      //     dueDate: dueDateType,
      //   });
      //   toggleCustomDate(false);
      //   break;
      // case "1w":
      //   setTaskData({
      //     ...taskData,
      //     toBePerformAt:
      //       moment().add(7, "d").format(DUE_DATE_FORMAT) +
      //       (taskData.time ? "T" + taskData.time : ""),
      //     dueDate: dueDateType,
      //   });
      //   toggleCustomDate(false);
      //   break;
      // case "1m":
      //   setTaskData({
      //     ...taskData,
      //     toBePerformAt:
      //       moment().add(1, "M").format(DUE_DATE_FORMAT) +
      //       (taskData.time ? "T" + taskData.time : ""),
      //     dueDate: dueDateType,
      //   });
      //   toggleCustomDate(false);
      //   break;

      case "today":
      case "tomorrow":
      case "3d":
      case "1w":
      case "1m":
        let dt = moment()
          .add(taskDueDatesValues[dueDateType], "days")
          .format("YYYY-MM-DD");
        // let tm = moment(task.time).format("HH:mm");
        const timeAndDate = moment(dt + " ");
        let toBePerformAt = timeAndDate.toISOString();
        setTaskData({
          ...taskData,
          toBePerformAt,
          dueDate: dueDateType,
          time: "",
        });
        toggleCustomDate(false);

        break;

      case "custom":
        setTaskData({
          ...taskData,
          toBePerformAt: undefined,
          dueDate: dueDateType,
        });
        toggleCustomDate(true);
        break;
      default:
        break;
    }
  };

  let schema = yup.object().shape({
    task: yup.string().required("task is required"),
    type: yup.string().required("Task Type is required"),
    dueDate: yup
      .string()
      .required("due Date is required")
      .nullable()
      .notRequired(),
    toBePerformAt: yup.string().required("due Date is required"),
    // repeat: yup.string().required("Repeat is required"),
    time: yup.string().required("time is required"),
    // taskAssign: yup.string().required("task Assigned is required"),
    // notes: yup.string().required("Notes is required"),
  });

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.currentTarget;
    setTaskData({
      ...taskData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: null,
    });
  };
  const handleDateChange = (
    name: string,
    value: string | any | null,
    type = ""
  ) => {
    if (type === "time" && taskData.toBePerformAt) {
      let dt = moment(taskData.toBePerformAt).format("YYYY-MM-DD");
      let tm = moment(value).format("HH:mm");
      const timeAndDate = moment(dt + " " + tm);
      let toBePerformAt = timeAndDate.toISOString();

      setTaskData({
        ...taskData,
        [name]: toBePerformAt,
        time: toBePerformAt,
      });
    } else {
      setTaskData({
        ...taskData,
        [name]: value,
      });
    }

    setErrors({
      ...errors,
      [name]: null,
    });
  };

  const handleTaskSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const isFormValid = await schema.isValid(taskData, {
      abortEarly: false,
    });
    if (isFormValid) {
      // let UtcDate = moment
      //   .parseZone(`${taskData.toBePerformAt}T${taskData.time}`)
      //   .utc()
      //   .format();

      const UtcDate = () => {
        if (taskData.toBePerformAt) {
          const combinedDateTimeString =
            taskData.toBePerformAt.slice(0, 11) + taskData.time + "Z";
          const combinedDateTime = new Date(combinedDateTimeString);
          return combinedDateTime.toISOString();
        } else {
          return "";
        }
      };
      let tempLeadIds = [];
      let assignData = [];
      if (taskData.taskAssign?.length > 0) {
        assignData.push(taskData.taskAssign);
      } else {
        assignData = [];
      }

      tempLeadIds.push(taskData.task);
      let tempObj: any = {};
      if (action === "add") {
        tempObj = {
          leadIds: tempLeadIds,
          type: taskData.type,
          toBePerformAt: taskData.toBePerformAt,
          // toBePerformAt: UtcDate,
          extraDetails: {},
          ...(assignData?.length > 0 && {
            assignedTo: assignData,
          }),
          ...(taskData.repeat !== "" && {
            repeat: taskData.repeat,
          }),
          ...(taskData.notes?.length > 0 && {
            notes: taskData.notes,
          }),
        };
      } else {
        tempObj = {
          type: taskData.type,
          toBePerformAt: taskData.toBePerformAt,
          extraDetails: {},
          ...(assignData?.length > 0 && {
            assignedTo: assignData,
          }),
          ...(taskData.repeat !== "" && {
            repeat: taskData.repeat,
          }),
          ...(taskData.notes?.length > 0 && {
            notes: taskData.notes,
          }),
          isCompleted: isCompleted,
        };
      }

      try {
        let response: any = "";
        if (action === "add") {
          response = await createNewTask(tempObj);
        } else {
          response = await updateTaskbyId(updateTaskValue._id, tempObj);
        }
        if (response && response.status) {
          drawerClose();
          fetchTaskStatusFollowUps(response?.data);
          toast.success(response?.data?.message);
        }
      } catch (err) {
        toast.error("Error while creating new task");
      }
    } else {
      schema.validate(taskData, { abortEarly: false }).catch((err) => {
        const errors = err.inner.reduce(
          (acc: any, error: { path: string; message: string }) => {
            return {
              ...acc,
              [error.path]: error.message,
            };
          },
          {}
        );
        setErrors((prevErrors) =>
          update(prevErrors, {
            $set: errors,
          })
        );
      });
    }
  };

  const leadForTask = action == "edit" ? updateTaskValue?.lead : leadList;
  const previousDate = moment(taskData.toBePerformAt).format("DD MMM YYYY");

  const renderPreviousNotes = () => {
    if (!updateTaskValue?.noteLogs?.length) return <></>;

    const sortedNotes = _.orderBy(
      updateTaskValue?.noteLogs,
      [(obj) => new Date(obj.createdAt)],
      ["desc"]
    );

    return (
      <>
        <h6 className="text-dark mt-15 mb-3">Previous notes</h6>
        {sortedNotes
          ?.filter((e) => e.note)
          .map((e, i) => (
            <div key={i} className="row">
              {/* Left Container */}
              <div className="task_left_container">
                <div className="task_notes_left_icon_container">
                  <span className="fw-bold">
                    {moment(e.createdAt).format("DD MMM")}
                  </span>
                </div>
              </div>
              {/* Right Container */}
              <div className="col">
                <div className="task_info_container bg-white">
                  <span className="fw-bold text-dark">{e.note}</span>
                </div>

                <h6 className="fs-8 mt-1 task_bottom_time">
                  {moment(e.createdAt).format("hh:mm a")} by{" "}
                  {getNotesCreatedBy(e?.createdBy)}
                </h6>
              </div>
            </div>
          ))}
      </>
    );
  };

  const getNotesCreatedBy = (id: string) => {
    let employee = StoreData?.userData.userDetails.organizationEmployee ?? [];

    let name = "";

    name = employee?.find(({ _id }: any) => _id == id)?.firstName ?? "";

    return name;
  };

  const previousTimeValue = taskData.time ? moment(taskData.time) : undefined;

  return (
    <Root>
      {/* <form id="LeadsFilterForm" className="follwup_drower"> */}
      <div>
        {!leadId && (
          <div className="form-group">
            <label className="form_label">Task for </label>
            {/* <select
              onChange={(e) => handleChange(e)}
              value={taskData?.task}
              id="inputState"
              name="task"
              disabled={action === "edit" ? true : false}
              className="form-select"
            >
              <option value="">Select Task for</option>
              {leadForTask.map((lead: any, i: number) => (
                <option value={lead._id} key={i}>
                  {lead.name}
                </option>
              ))}
            </select> */}

            <select
              name="sortTeamsBy"
              className="form-select"
              value={taskData?.task}
              onChange={(e: any) => handleChange(e)}
            >
              <option value="" disabled selected>
                Select an option
              </option>
              {leadForTask.map((item: any) => (
                <option key={item._id} value={item._id}>
                  {item.name}
                </option>
              ))}
            </select>

            {errors.task ? <ErrorText message={errors?.task} /> : ""}
          </div>
        )}
        <div className="form-group">
          <label className="form_label">Task Type </label>
          <select
            id="inputState"
            value={taskData?.type}
            onChange={(e) => handleChange(e)}
            name="type"
            className="form-select"
          >
            <option value="">Select Task Type</option>
            {taskList?.map((task, j) => (
              <option value={task?.value} key={j}>
                {task?.name}
              </option>
            ))}
          </select>
          {errors.type ? <ErrorText message={errors?.type} /> : ""}
        </div>
        <div className="form-group">
          <label className="form_label">Due Date</label>
          <select
            className="form-select"
            value={taskData?.dueDate}
            name="dueDate"
            onChange={(e) => {
              handleSetDueDate(e.target.value);
            }}
          >
            <option value="">Select due date</option>
            {taskDueDates.map((dueDate, d) => (
              <option value={dueDate.value} key={d}>
                {dueDate.name}
              </option>
            ))}
          </select>
          {errors.dueDate ? <ErrorText message={errors?.dueDate} /> : ""}
        </div>
        {isCustomDate && (
          <div className="form-group">
            <label className="form_label">Custom Date</label>
            <DatePicker
              format={"DD MMM YYYY"}
              defaultValue={moment(previousDate)}
              disabledDate={(current) => {
                return current && current < moment().endOf("day");
              }}
              showToday={false}
              className="select_date"
              onChange={(date: any) => {
                handleDateChange(
                  "toBePerformAt",
                  moment(date).subtract(0, "d").utc().format()
                );
              }}
            />
            {errors.toBePerformAt ? (
              <ErrorText message={errors?.toBePerformAt} />
            ) : (
              ""
            )}
          </div>
        )}
        <div className="form-group">
          <label className="form_label">Time</label>
          <div className="timepicker-element">
            <TimePicker
              format="hh:mm A"
              className="select_date"
              value={previousTimeValue}
              onChange={(time) => {
                // handleDateChange(
                //   "toBePerformAt",
                //   moment(time).subtract(0, "d").utc().format(),
                //   "time"
                // );

                handleDateChange("toBePerformAt", time, "time");
              }}
            />
          </div>
          {errors.time ? <ErrorText message={errors?.time} /> : ""}
        </div>
        <div className="form-group">
          <label className="form_label">Repeat</label>
          <select
            id="inputState"
            value={taskData?.repeat}
            onChange={(e) => handleChange(e)}
            name="repeat"
            className="form-select"
          >
            {/* <option value="">Don't Repeat</option> */}
            {/* <option disabled selected>
              Select an option
            </option> */}
            {repeatOptions.map((option, o) => (
              <option value={option.value} key={o}>
                {option?.name}
              </option>
            ))}
          </select>
          {/* {errors.repeat ? <ErrorText message={errors?.repeat} /> : ""} */}
        </div>
        <div className="form-group">
          <label className="form_label">Task Assign to</label>
          <select
            id="inputState"
            value={taskData?.taskAssign}
            onChange={(e) => handleChange(e)}
            name="taskAssign"
            className="form-select"
          >
            <option value="">Select Task Assign to</option>
            {taskAssignTo?.map((item: any, j: number) => {
              return (
                <option value={item?._id} key={j}>
                  <label>
                    {/* {item.firstName} */}
                    {item.firstName} - {item.roleName} - {item.organizationName}
                  </label>
                </option>
              );
            })}
          </select>
          {/* {errors.taskAssign ? (
              <ErrorText message={errors?.taskAssign} />
            ) : (
              ""
            )} */}
        </div>
        <div className="form-group">
          <label className="form_label">Note</label>
          <textarea
            className="form-control note_area"
            value={taskData?.notes}
            onChange={(e) => handleChange(e)}
            name="notes"
            style={{ height: "120px" }}
            placeholder="Enter Note"
          ></textarea>
          {/* {errors.notes ? (
              <ErrorText message={errors?.notes} />
            ) : (
              ""
            )} */}
        </div>
        {renderPreviousNotes()}

        {action === "edit" ? (
          <Form.Check // prettier-ignore
            type={"checkbox"}
            id={`checkbox`}
            label={`Mark as completed`}
            className="mark_checkbox"
            checked={isCompleted}
            onChange={() => setIsCompleted(!isCompleted)}
          />
        ) : null}

        {/* {(status === "today" || status === "overdue") && (
          <>
            {action === "edit" && (
              <div className="form-group">
                <input
                  type="checkbox"
                  checked={isCompleted}
                  id="isCompleted"
                  name="isCompleted"
                  onChange={() => setIsCompleted(!isCompleted)}
                />
                &nbsp;
                <label htmlFor="isCompleted" className="form_label">
                  Mark as Complete
                </label>
                <br></br>
              </div>
            )}
          </>
        )} */}
      </div>

      <div className="auto_form_btn">
        <button
          type="button"
          onClick={(e) => handleTaskSubmit(e)}
          className="btn btn-primary-save"
        >
          {action === "add" ? "Add Task" : "Update Task"}
        </button>
      </div>
      <Toaster position="top-right" reverseOrder={false} />
    </Root>
  );
};

export default CreateTaskForm;
const Root = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  .form-group {
    margin: 6px 0px;
  }
  .auto_form_btn {
    position: sticky;
    margin-top: auto;
    bottom: 0px;
  }
  .select_date {
    display: block;
    background: #fff;
    border-radius: 2px;
    padding: 8px;
    border: 1px solid #ced4da;
    span.rs-picker-toggle-value {
      color: #000;
    }
    &:focus-visible {
      outline: none;
      box-shadow: none;
      border: none;
    }
  }
`;
