import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { getUserProfile } from "../services/authServices";
import { Spin } from "antd";

export default function PrivateRoute({ children }) {
  const [isLoading, setIsLoading] = useState(true);
  const data = localStorage.getItem("auth_token");

  const fetchUserProfile = async () => {
    // Check if we fetched recently (within last 5 minutes)
    const lastFetch = localStorage.getItem("last_profile_fetch");
    const now = Date.now();
    
    if (lastFetch && now - parseInt(lastFetch) < 300000) { // 5 minutes
      setIsLoading(false);
      return;
    }

    try {
      const response = await getUserProfile();

      if (response?.data) {
        localStorage.setItem("userData", JSON.stringify(response?.data?.data));
        localStorage.setItem(
          "user_preferences",
          JSON.stringify(response.data.data.userPreference)
        );
        localStorage.setItem("last_profile_fetch", now.toString());
      }
    } catch (error) {
      console.log(`[ERROR] ${error.message}`);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (data) fetchUserProfile();
    else setIsLoading(false);
  }, [data]);

  if (isLoading) {
    return (
      <div className="vh-100 w-100 d-flex align-items-center justify-content-center">
        <Spin size="large" tip="Loading..." spinning={true}></Spin>
      </div>
    );
  }

  return data ? children : <Navigate to="/" />;
}