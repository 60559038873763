// MessageBubble.tsx
import React, { ReactNode } from 'react';
import { Anchorme } from "react-anchorme";
import moment from 'moment';
import { MessageMedia } from './MessageMedia';

interface Message {
  _id: string;
  sender: any;
  messageType?: 'text' | 'reaction' | 'video' | 'image' | 'document' | 'audio';
  downloadUrl?: string;
  message: {
    emoji?: string;
    body: string | {
      text?: {
        body: string;
      };
      body?: string;
    };
    id?: string;
    mime_type?: string;
  };
  createdAt: string;
  messageDelivery?: 'sent' | 'delivered' | 'read';
}

interface MessageBubbleProps {
  message: Message;
  currentUser: {
    firstName: string;
  };
  onMediaDownload: (info: Message) => Promise<void>;
  integrationInfo: any;
  isFirstInGroup?: boolean;
  isLastInGroup?: boolean;
  showTimestamp?: boolean;
  isLoading?: boolean;
  downloadError?: string;
}

export const MessageBubble: React.FC<MessageBubbleProps> = ({
  message,
  currentUser,
  onMediaDownload,
  integrationInfo,
  isFirstInGroup = true,
  isLastInGroup = true,
  showTimestamp = true
}) => {
  const isOutgoing = message.sender !== null;

  const formatContent = (text: any): ReactNode[] => {
    let messageText: string = '';
    
    if (typeof text === 'object') {
      if (text?.text?.body) {
        messageText = text.text.body;
      } else if (text?.body?.text?.body) {
        messageText = text.body.text.body;
      } else if (text?.emoji) {
        messageText = text.emoji;
      } else if (text?.body) {
        messageText = typeof text.body === 'string' ? text.body : '';
      }
    } else if (typeof text === 'string') {
      messageText = text;
    }
  
    return messageText.split('\n').map((line: string, i: number, arr: string[]) => (
      <React.Fragment key={i}>
        <Anchorme target="_blank">
          {line || ' '}
        </Anchorme>
        {i < arr.length - 1 && <br />}
      </React.Fragment>
    ));
  };

  const renderContent = (): ReactNode => {
    const extractText = (content: any): string => {
      if (!content) return '';
      if (typeof content === 'string') return content;
      if (content.text?.body) return content.text.body;
      if (content.body) {
        if (typeof content.body === 'string') return content.body;
        if (content.body.text?.body) return content.body.text.body;
      }
      return '';
    };
  
    if (message?.messageType === "reaction") {
      return formatContent(message.message.emoji || '');
    }
  
    if (["document", "image", "video", "audio"].includes(message?.messageType || '')) {
      const mediaMessage = {
        _id: message._id,
        messageType: message.messageType as 'video' | 'image' | 'document' | 'audio',
        downloadUrl: message.downloadUrl,
        message: {
          body: extractText(message.message),
          id: message.message?.id,
          mime_type: message.message?.mime_type
        }
      };
  
      return (
        <MessageMedia 
          message={mediaMessage}
          onDownload={() => onMediaDownload(message)}
          integrationInfo={integrationInfo}
        />
      );
    }

    const messageText = typeof message.message.body === 'object'
      ? (message.message.body?.text?.body || '') 
      : (message.message.body || '');
  
    return (
      <div className="message-text-content">
        {formatContent(messageText)}
      </div>
    );
  };

  return (
    <div 
      className={`message ${isOutgoing ? 'outgoing' : 'incoming'}
        ${isFirstInGroup ? 'first-in-group' : ''} 
        ${isLastInGroup ? 'last-in-group' : ''}`}
    >
      <div className="message-container">
        {isFirstInGroup && isOutgoing && (
          <div className="message-sender">
            <span>{currentUser.firstName}</span>
          </div>
        )}
        
        <div className="message-content">
          {renderContent()}
          
          {isLastInGroup && showTimestamp && (
            <div className="message-footer">
              <span className="message-time">
                {moment(message.createdAt).format("HH:mm")}
              </span>
              
              {isOutgoing && (
                <div className="message-status">
                  {message.messageDelivery === "sent" && (
                    <i className="ri-check-line" />
                  )}
                  {message.messageDelivery === "delivered" && (
                    <i className="ri-check-double-line" />
                  )}
                  {message.messageDelivery === "read" && (
                    <i className="ri-check-double-line read" />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};