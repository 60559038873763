import React, { PropsWithChildren, useCallback, memo } from "react";
import { Modal } from "reactstrap";
import styled from "styled-components";

interface GenericModalProps {
  showModal: boolean;
  onConfirmation: () => void;
  toggleModal: () => void;
  message?: string;
  title?: string;
  confirmText?: string;
  cancelText?: string;
  confirmClassName?: string;
  cancelClassName?: string;
  isWarning?: boolean;
}

interface WarningProps {
  $isWarning?: boolean;
}

// Extend button props with our warning prop
interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, WarningProps {}

const StyledModal = styled(Modal)`
  .modal-content {
    border-radius: 8px;
    border: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
`;

const ModalHeader = styled.div<WarningProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  border-bottom: 1px solid #e9ecef;
  background: ${({ $isWarning }) => $isWarning ? 'rgba(255, 59, 48, 0.05)' : '#fff'};
  border-radius: 8px 8px 0 0;
`;

const Title = styled.h5`
  margin: 0;
  font-size: 18px;
  color: #2c3e50;
  font-weight: 500;
`;

const CloseButton = styled.button`
  background: transparent;
  border: none;
  padding: 4px;
  cursor: pointer;
  color: #6c757d;
  font-size: 20px;
  line-height: 1;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;

  &:hover {
    background: #f8f9fa;
    color: #343a40;
  }
`;

const ModalBody = styled.div<WarningProps>`
  padding: 20px;
  color: ${({ $isWarning }) => $isWarning ? '#dc3545' : '#6c757d'};
  font-size: 16px;
  line-height: 1.5;
   white-space: pre-line;  // This preserves the line breaks in the message
 p {
    margin-bottom: 16px;  // Add more spacing between paragraphs
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 16px 20px;
  background: #f8f9fa;
  border-top: 1px solid #e9ecef;
  border-radius: 0 0 8px 8px;
  gap: 12px;
`;

const BaseButton = styled.button<ButtonProps>`
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s;
  border: 1px solid transparent;
  cursor: pointer;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }
`;

const CancelButton = styled(BaseButton)`
  background: #fff;
  border-color: #dee2e6;
  color: #6c757d;

  &:hover {
    background: #f8f9fa;
    border-color: #cbd3da;
  }
`;

const ConfirmButton = styled(BaseButton)<ButtonProps>`
  background: ${({ $isWarning }) => $isWarning ? '#dc3545' : '#0d6efd'};
  color: white;

  &:hover {
    background: ${({ $isWarning }) => $isWarning ? '#c82333' : '#0b5ed7'};
  }
`;

const GenericConfirmationModal: React.FC<PropsWithChildren<GenericModalProps>> = ({
  showModal,
  toggleModal,
  onConfirmation,
  message = "Are you sure you want to proceed?",
  title = "Confirm Action",
  confirmText = "Ok",
  cancelText = "Cancel",
  confirmClassName = "",
  cancelClassName = "",
  isWarning = false,
  children,
}) => {
  const handleConfirmation = useCallback(() => {
    onConfirmation();
    toggleModal();
  }, [onConfirmation, toggleModal]);

  return (
    <StyledModal
      isOpen={showModal}
      toggle={toggleModal}
      centered
      backdrop="static"
      size="md"
    >
      <ModalHeader $isWarning={isWarning}>
        <Title>{title}</Title>
        <CloseButton onClick={toggleModal}>×</CloseButton>
      </ModalHeader>

      <ModalBody $isWarning={isWarning}>
        {message && <p className="mb-3">{message}</p>}
        {children}
      </ModalBody>

      <ModalFooter>
        <CancelButton
          onClick={toggleModal}
          className={cancelClassName}
        >
          {cancelText}
        </CancelButton>
        <ConfirmButton
          onClick={handleConfirmation}
          $isWarning={isWarning}
          className={confirmClassName}
        >
          {confirmText}
        </ConfirmButton>
      </ModalFooter>
    </StyledModal>
  );
};

export default GenericConfirmationModal;