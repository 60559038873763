import React, { useEffect, useState } from "react";
import { Settings2, Trash2, Plus } from "lucide-react";
import ConfirmationModal from "../../components/confirmationModal";
import { automationActionData } from "../../utils/globalConstants";

interface ActionData {
  type: string;
  data: any;
}

interface StatusData {
  value: string;
  name: string;
}

interface UserData {
  _id: string;
  firstName: string;
}

interface LabelData {
  value: string;
  name: string;
}

interface LeadListData {
  _id: string;
  name: string;
}

interface TemplateData {
  _id: string;
  name: string;
}

interface TaskData {
  value: string;
  name: string;
}

interface DayGroupProps {
  dayKey: string;
  actions: Array<ActionData>;
  onAddAction: (dayKey: string) => void;
  onActionChange: (dayKey: string, actionIndex: number, value: string) => void;
  onActionDelete: (dayKey: string, actionIndex: number) => void;
  onShowActionInfo: (value: string) => void;
  onDeleteDay: (dayKey: string) => void;
  isUpdateAuto: boolean;
  statusData?: Array<StatusData>;
  userListData?: Array<UserData>;
  labelData?: Array<LabelData>;
  leadListData?: Array<LeadListData>;
  whatsappTemplate?: Array<TemplateData>;
  emailTemplate?: Array<TemplateData>;
  taskData?: Array<TaskData>;
  errors?: {
    actions?: string;
  };
  isDelete?: boolean;
}

const DayGroup: React.FC<DayGroupProps> = ({
  dayKey,
  actions,
  onAddAction,
  onActionChange,
  onActionDelete,
  onShowActionInfo,
  onDeleteDay,
  isUpdateAuto,
  statusData = [],
  userListData = [],
  labelData = [],
  leadListData = [],
  whatsappTemplate = [],
  emailTemplate = [],
  taskData = [],
  errors,
  isDelete,
}) => {
  const [currentDay, setCurrentDay] = useState(dayKey);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const dayNumber = parseInt(dayKey.split("_")[1]);
  const [type, setType] = useState("");

  useEffect(() => {
    if (type) {
      setTimeout(() => {
        onShowActionInfo(type);
      }, 500);
    }
  }, [type]);

  const getActionDisplayName = (action: ActionData) => {
    const actionType = automationActionData.find(
      (d) => d.value === action.type
    );

    const baseName = actionType?.name || action.type;

    if (!action.data || Object.keys(action.data).length === 0) {
      return baseName;
    }

    switch (action.type) {
      case "push_notification":
        if (action.data.assignToUser) {
          const user = userListData.find(
            (u) => u._id === action.data.assignToUser
          );
          return user ? `${baseName} - ${user.firstName}` : baseName;
        }
        break;

      case "send_email":
        if (action?.data?.content) {
          const template: any = emailTemplate.find(
            (t) => t._id === action.data.content
          );
          return template
            ? `${baseName} - ${template?.details?.title}`
            : baseName;
        }
        break;

      case "send_whatsapp":
        if (action.data.template) {
          const template = whatsappTemplate.find(
            (t) => t._id === action.data.template
          );
          return template ? `${baseName} - ${template.name}` : baseName;
        }
        break;

      case "status_change":
        if (action.data.status) {
          const status = statusData.find((s) => s.value === action.data.status);
          return status ? `${baseName} - ${status.name}` : baseName;
        }
        break;

      case "assign_lead":
        if (action.data.assignToUser) {
          const user = userListData.find(
            (u) => u._id === action.data.assignToUser
          );
          return user ? `${baseName} - ${user.firstName}` : baseName;
        }
        break;

      case "label_change":
        if (action.data.label) {
          const label = labelData.find((l) => l.value === action.data.label[0]);
          return label ? `${baseName} - ${label.name}` : baseName;
        }
        break;

      case "move_to_list":
      case "copy_to_list":
        if (action.data.targetListId) {
          const list = leadListData.find(
            (l) => l._id === action.data.targetListId
          );
          return list ? `${baseName} - ${list.name}` : baseName;
        }
        break;

      case "create_task":
        if (action.data.type) {
          const task = taskData.find((t) => t.value === action.data.type);
          return task ? `${baseName} - ${task.name}` : baseName;
        }
        break;
    }

    return baseName;
  };

  return (
    <div className="day-section">
      <div className="day-header">
        <div className="day-badge">Day {dayNumber}</div>
        {isDelete && (
          <Trash2
            size={18}
            className="delete-day-button"
            onClick={() => setShowDeleteModal(true)}
          />
        )}
      </div>

      {actions.map((action, index) => (
        <div key={index} className="auto_select_action">
          <label className="action-label">Action {index + 1}:</label>
          <div className="action-row">
            <select
              className="form-select"
              value={action.type}
              onChange={(e) => {
                onActionChange(dayKey, index, e.target.value);
                setType(e.target.value);
              }}
            >
              <option value="">Select action</option>
              {automationActionData.map((data, j) => (
                <option value={data.value} key={j}>
                  {action.type === data.value
                    ? getActionDisplayName(action)
                    : data.name}
                </option>
              ))}
            </select>
            <div className="action-buttons">
              {action.type && (
                <Settings2
                  size={18}
                  className="action-button-settings"
                  onClick={() => onShowActionInfo(action.type)}
                />
              )}

              <Trash2
                size={18}
                className="action-button-delete"
                onClick={() => onActionDelete(dayKey, index)}
              />
            </div>
          </div>
        </div>
      ))}

      {errors?.actions && <div className="error-message">{errors.actions}</div>}

      <div className="add-action-button" onClick={() => onAddAction(dayKey)}>
        <Plus size={18} />
        <span>Add Action</span>
      </div>

      <ConfirmationModal
        showModal={showDeleteModal}
        toggleModal={() => setShowDeleteModal(false)}
        onConfirmation={() => {
          onDeleteDay(dayKey);
          setShowDeleteModal(false);
        }}
        message="Are you sure you want to delete this day?"
        title="Remove Day"
      />
    </div>
  );
};

export default DayGroup;
