
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
const BackToTop: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Add a scroll event listener to the window
    const handleScroll = () => {
      // Set isVisible to true when the user scrolls down, false when at the top
      setIsVisible(window.scrollY > 100);
    };

    window.addEventListener('scroll', handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    // Scroll to the top of the page
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Adds a smooth scrolling animation
    });
  };

  return (
    <Root
      className={`${isVisible ? 'active' : ''}`}
      onClick={scrollToTop}
    >
      <i className="bi bi-arrow-up-short"></i>
    </Root>
  );
};

export default BackToTop;
const Root = styled.button`
    display:none;
    width: 53px;
    height: 53px;
    position: fixed;
    right: 21px;
    bottom: 80px;
    background: #3da6f7;
    border-radius: 50%;
    font-size: 38px;
    color: #fff;
    &.active{
        display:block;
    }
`