import React, { useState } from "react";

export default function Profile({
  src,
  style,
  icon = "fa-md",
}: {
  src: string;
  style: any;
  icon?: string;
}) {
  const [error, setError] = useState(false);

  return error || !src || src?.includes("undefined") ? (
    <div
      style={{
        width: "25px",
        height: "25px",
        backgroundColor: "#e1e1e1",
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ...style,
      }}
    >
      <i className={`fa-regular fa-user ${icon}`} />
    </div>
  ) : (
    <img onError={() => setError(true)} src={src} />
  );
}
