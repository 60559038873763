// src/config/razorpay.config.ts

export const RAZORPAY_CONFIG = {
    SCRIPT_URL: 'https://checkout.razorpay.com/v1/checkout.js',
    THEME_COLOR: '#2563EB',
    COMPANY_NAME: '3Sigma',
    COMPANY_LOGO: 'https://firebasestorage.googleapis.com/v0/b/sigma-8207c.appspot.com/o/3sigma-logo%20(4).png?alt=media&token=425a0128-e577-44ee-91d5-3a790ebc7c2e',
    DESCRIPTION: '3sigma Premium plan'
  };
  
  // Function to load Razorpay script dynamically
  export const loadRazorpayScript = (): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (window.Razorpay) {
        resolve();
        return;
      }
  
      const script = document.createElement('script');
      script.src = RAZORPAY_CONFIG.SCRIPT_URL;
      script.onload = () => resolve();
      script.onerror = () => reject(new Error('Failed to load Razorpay SDK'));
      document.body.appendChild(script);
    });
  };
  
  // Type definitions for Razorpay
  declare global {
    interface Window {
      Razorpay: any;
    }
  }