import axios from "axios";
import { API_URL } from "../config/config";
import { CreateLeadDataI } from "../views/leads/createLeadForm";
import { getHeaderOptions } from "./getHeaderOptions";
import { FilterParams } from "../views/leads/leadsGrid/leads.types";

export interface GoogleContactLead {
  name: string;
  email?: string;
  phone?: string;
  leadListId?: string;
  customSource: string;
}
interface ApiErrorResponse {
  status: boolean;
  statusCode: number;
  message: string;
  data: Record<string, any>;
}

interface ApiResponse {
  status: boolean;
  statusCode: number;
  message: string;
  data: {
    successful?: number;
    failed?: number;
  };
}
export interface LeadPayload {
  name: string;
  email?: string;
  phone?: string;
  leadListId?: string;
  customSource?: string;
  integration?: string;
}
export interface Contact {
  id: string;
  name: string;
  email: string;
  phone?: string;
  imageUrl: string;
}

export interface GoogleContact {
  resourceName: string;
  names?: Array<{ displayName: string }>;
  phoneNumbers?: Array<{ value: string }>;
  emailAddresses?: Array<{ value: string }>;
  photos?: Array<{ url: string }>;
}

export interface BulkImportResult {
  successful: number;
  failed: number;
  total: number;
  errors?: Array<{
    email: string;
    error: string;
  }>;
}


const API_ENDPOINT = API_URL + "api/v1";
const API_V2_ENDPOINT = "https://mapi2.3sigmacrm.com/api/v2/lead";
const INTEGRATION_VALUE = "GOOGLE_CONTACTS";
const isValidPhoneNumber = (phone: string): boolean => {
  const digits = phone.replace(/\D/g, '');
  return digits.length >= 9 && digits.length <= 13;
};

const formatPhoneNumber = (phone: string): string => {
  return phone.replace(/\D/g, '');
};
const parsePhoneNumber = (phone: string): { number?: string; countryCode?: string } => {
  if (!phone) return {};
  
  // Remove all non-digit characters
  const cleaned = phone.replace(/\D/g, '');
  
  // Check for common country code patterns
  if (cleaned.startsWith('91') && cleaned.length >= 12) {
    return {
      countryCode: '+91',
      number: cleaned.slice(2)
    };
  }
  
  // Check for other international format (starting with +)
  if (phone.startsWith('+')) {
    const matches = cleaned.match(/^(\d{1,4})(\d+)$/);
    if (matches) {
      return {
        countryCode: `+${matches[1]}`,
        number: matches[2]
      };
    }
  }
  
  // If no country code is detected, return just the number
  return {
    number: cleaned
  };
};
const formatCountryCode = (code: string): string => {
  if (!code) return '';
  return code.startsWith('+') ? code : `+${code}`;
};

export const getAllLeads = (params: any) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.get(`${API_ENDPOINT}/lead`, { headers: obj, params: params });
};

export const createNewLead = (data: CreateLeadDataI) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.post(`${API_ENDPOINT}/lead`, data, { headers: obj });
};



export const bulkCreateLeadsFromGoogleContacts = async (
  contacts: Array<GoogleContactLead>
): Promise<BulkImportResult> => {
  try {
    const validContacts = contacts
      .filter(contact => 
        contact.name && 
        contact.name !== 'No Name' &&
        contact.phone // Only include contacts with phone numbers
      )
      .map(contact => ({
        name: contact.name,
        email: contact.email,
        phone: contact.phone?.replace(/\s/g, ''),
        leadListId: contact.leadListId !== "0" ? contact.leadListId : undefined,
        customSource: "phonebook",
        integration: "64109561e6c7e737227a6382" // Add the integration ID here
      }));

    if (validContacts.length === 0) {
      return {
        successful: 0,
        failed: 0,
        total: 0,
        errors: [{
          email: 'N/A',
          error: 'No valid contacts with phone numbers to import'
        }]
      };
    }

    const obj = {
      ...getHeaderOptions,
      Authorization: localStorage.getItem("auth_token") || "",
    };

    const response = await axios.post<ApiResponse>(
      API_V2_ENDPOINT,
      validContacts,
      { headers: obj }
    );

    if (response.data.status === true) {
      return {
        successful: validContacts.length,
        failed: 0,
        total: validContacts.length,
        errors: []
      };
    }

    const errorResponse = response.data as ApiErrorResponse;
    return {
      successful: 0,
      failed: validContacts.length,
      total: validContacts.length,
      errors: [{
        email: 'N/A',
        error: errorResponse.message || 'Failed to create leads'
      }]
    };

  } catch (error) {
    console.error('Bulk create leads error:', error);
    let errorMessage = 'Unknown error occurred';
    
    if (axios.isAxiosError(error)) {
      const errorResponse = error.response?.data as ApiErrorResponse | undefined;
      errorMessage = errorResponse?.message || error.message;
    } else if (error instanceof Error) {
      errorMessage = error.message;
    }
    
    return {
      successful: 0,
      failed: contacts.length,
      total: contacts.length,
      errors: [{
        email: 'N/A',
        error: errorMessage
      }]
    };
  }
};

export const createNewLeadByCsv = (data: FormData) => {
  let obj = {
    "Content-Type": "multipart/form-data",
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.post(`${API_ENDPOINT}/lead/csv`, data, { headers: obj });
};

export const deleteLead = (id: string) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.delete(`${API_ENDPOINT}/lead/${id}`, { headers: obj });
};


export const downloadCsvFile = (id: string) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.get(`${API_ENDPOINT}/lead/csv-template/${id}`, { headers: obj });
};
export const downloadCsv = (id: string) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.get(`${API_ENDPOINT}/lead/csv-template/`, { headers: obj });
};

export const updateLeadStatus = (data: any) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.put(`${API_ENDPOINT}/lead/status`, data, { headers: obj });
};

export const updateLeadLabel = (data: any) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.put(`${API_ENDPOINT}/lead/label`, data, { headers: obj });
};

export const getLeadDetail = (id: string) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.get(`${API_ENDPOINT}/lead/${id}`, { headers: obj });
};

export const updateLead = (id: string, data: CreateLeadDataI) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.put(`${API_ENDPOINT}/lead/${id}`, data, { headers: obj });
};

export const copyLeadToLeadList = (data: any) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.post(`${API_ENDPOINT}/lead`, data, { headers: obj });
};

export const moveLeadToLeadList = (data: any) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.post(`${API_ENDPOINT}/lead`, data, { headers: obj });
};

export class LeadService {
  private static BASE_URL = 'https://mapi2.3sigmacrm.com/api/v2/lead';
  private static INTEGRATION_VALUE = 'GOOGLE_CONTACTS'; // Replace with actual integration value

  static async createLead(payload: LeadPayload): Promise<any> {
    try {
      const response = await fetch(this.BASE_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // Add any required authentication headers here
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Failed to create lead: ${response.statusText}`);
      }

      return await response.json();
    } catch (error) {
      console.error('Error creating lead:', error);
      throw error;
    }
  }

  static async bulkCreateLeads(leads: Omit<LeadPayload, 'integration'>[]): Promise<any> {
    try {
      const processedLeads = leads.map(lead => ({
        ...lead,
        integration: this.INTEGRATION_VALUE
      }));

      // You might want to implement batching if there are many leads
      const results = await Promise.allSettled(
        processedLeads.map(lead => this.createLead(lead))
      );

      return {
        successful: results.filter(r => r.status === 'fulfilled').length,
        failed: results.filter(r => r.status === 'rejected').length,
        total: results.length
      };
    } catch (error) {
      console.error('Error in bulk lead creation:', error);
      throw error;
    }
  }
}

export const filterLeads = (data: FilterParams) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.post(`${API_ENDPOINT}/lead/filter`, data, { headers: obj });
};
export const addCSV = (data: any) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.post(`${API_ENDPOINT}/lead/csv`, data, { headers: obj });
};

export const putUserPreferences = (data: any) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.put(`${API_ENDPOINT}/user/preference`, data, {
    headers: obj,
  });
};

export const copyLeadInList = (data: any) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.post(
    `${API_ENDPOINT}/lead-distribution/copy-lead-in-list`,
    data,
    { headers: obj }
  );
};

export const deleteBulkLeads = (data: any) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.post(`${API_ENDPOINT}/lead/bulk-delete`, data, { headers: obj });
};

export const mergeDuplicateLeads = (mainLeadId: string) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.post(`${API_ENDPOINT}/lead/merge-leads`, { mainLeadId }, { headers: obj });
};

export const moveLeadInList = (data: any) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.post(
    `${API_ENDPOINT}/lead-distribution/move-lead-in-list`,
    data,
    { headers: obj }
  );
};
export const assignLeadInList = (data: any) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.put(`${API_ENDPOINT}/lead/bulk-lead-assign`, data, {
    headers: obj,
  });
};

export const shareContentLead = (data: any) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.post(`${API_ENDPOINT}/content/share`, data, { headers: obj });
};
