// src/components/CallLogsScreen.tsx

import React, { useEffect, useState, useCallback } from 'react';
import { Input, List, Spin, Button, Typography, Layout, message, Drawer } from 'antd';
import { SearchOutlined, ReloadOutlined, PlusOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  getCallLogs,
  CallLog,
  PaginationQuery,
  PaginatedResult,
} from '../services/callLogsService';
import CallLogItem from './CallLogItem';
import styles from './CallLogsScreen.module.css';

const { Header, Content } = Layout;
const { Title } = Typography;

const CallLogsScreen: React.FC = () => {
  const [callLogs, setCallLogs] = useState<CallLog[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [searchText, setSearchText] = useState<string>('');
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false);

  // Debounced fetch function to handle search
  const debouncedFetch = useCallback(
    debounce((query: string) => {
      setPage(1);
      fetchCallLogs({ page: 1, search: query }, true);
    }, 500),
    []
  );

  useEffect(() => {
    fetchCallLogs({ page: 1 }, true);
    // Cleanup debounce on unmount
    return () => {
      debouncedFetch.cancel();
    };
  }, []);

  useEffect(() => {
    if (searchText.trim() !== '') {
      debouncedFetch(searchText.trim());
    } else {
      setPage(1);
      fetchCallLogs({ page: 1 }, true);
    }
  }, [searchText, debouncedFetch]);

  const fetchCallLogs = async (query: PaginationQuery, isRefresh: boolean = false) => {
    try {
      if (isRefresh) {
        setIsRefreshing(true);
      }

      const response: PaginatedResult<CallLog, number> = await getCallLogs(query);
      setTotal(response.total);

      if (isRefresh || query.page === 1) {
        setCallLogs(response.data);
      } else {
        setCallLogs((prevLogs) => [...prevLogs, ...response.data]);
      }

      // Determine if more data is available
      setHasMore(callLogs.length + response.data.length < response.total);
    } catch (error) {
      console.error('Failed to fetch call logs:', error);
      message.error('Failed to fetch call logs. Please try again.');
    } finally {
      if (isRefresh) {
        setIsRefreshing(false);
      }
    }
  };

  const fetchMoreData = () => {
    if (callLogs.length >= total) {
      setHasMore(false);
      return;
    }
    const nextPage = page + 1;
    setPage(nextPage);
    fetchCallLogs({ page: nextPage, search: searchText.trim() }, false);
  };

  const handleRefresh = () => {
    setPage(1);
    setSearchText('');
    setHasMore(true);
    fetchCallLogs({ page: 1 }, true);
  };

  const openDrawer = () => {
    setDrawerVisible(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
  };

  // Function to render the call logs content
  const renderCallLogsContent = (isInDrawer: boolean) => (
    <>
      <div className={styles.searchContainer}>
        <Input
          placeholder="Search by name or phone number"
          prefix={<SearchOutlined />}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          allowClear
          className={styles.searchInput}
        />
        <Button
          icon={<ReloadOutlined />}
          onClick={handleRefresh}
          className={styles.refreshButton}
          loading={isRefreshing}
        >
          Refresh
        </Button>
      </div>
      <InfiniteScroll
        dataLength={callLogs.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={
          <div className={styles.loader}>
            <Spin tip="Loading more call logs..." />
          </div>
        }
        endMessage={
          <div className={styles.endMessage}>
            <b>No more call logs to display.</b>
          </div>
        }
        scrollableTarget={isInDrawer ? 'drawerScroll' : undefined}
      >
        <List
          dataSource={callLogs}
          renderItem={(item) => <CallLogItem key={item._id} log={item} />}
          className={styles.callLogsList}
        />
      </InfiniteScroll>
      {callLogs.length === 0 && !isRefreshing && (
        <div className={styles.emptyState}>
          <Title level={4}>No Call Logs Found</Title>
          <p>Try adjusting your search criteria.</p>
        </div>
      )}
    </>
  );

  return (
    <Layout className={styles.layout}>
      <Header className={styles.header}>
        <Title level={3} className={styles.headerTitle}>
          Call Logs
        </Title>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={openDrawer}
          className={styles.addButton}
        >
          Open Drawer
        </Button>
      </Header>
      <Content className={styles.content}>{renderCallLogsContent(false)}</Content>

      {/* Drawer Component */}
      <Drawer
        title="Call Logs in Drawer"
        placement="right"
        onClose={closeDrawer}
        visible={drawerVisible}
        width={600}
        getContainer={false}
        style={{ position: 'absolute' }}
      >
        <div id="drawerScroll" style={{ height: '100%', overflowY: 'auto' }}>
          {renderCallLogsContent(true)}
        </div>
      </Drawer>
    </Layout>
  );
};

export default CallLogsScreen;
