import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  FileText,
  FileImage,
  FileVideo,
  File,
  FileType, // for PDF
  Table, // for spreadsheets
  MonitorPlay, // for presentations
  FileCode,
  Upload,
  Trash2,
  Download,
} from "lucide-react";

const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50MB

interface FileUploadI {
  onLeadFileUpload: (e: any) => void;
  StateData: any;
  leadFileDelete: (obj: any) => void;
  leadId: string;
}

// File type to icon mapping
const getFileIcon = (filename: string) => {
  const ext = filename?.split(".")?.pop()?.toLowerCase() || "";

  switch (ext) {
    // Documents
    case "doc":
    case "docx":
    case "txt":
      return <FileText size={24} />;

    // Images
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
    case "webp":
      return <FileImage size={24} />;

    // Videos
    case "mp4":
    case "mov":
    case "avi":
    case "mkv":
    case "webm":
      return <FileVideo size={24} />;

    // PDF
    case "pdf":
      return <FileType size={24} />;

    // Spreadsheets
    case "csv":
    case "xlsx":
    case "xls":
      return <Table size={24} />;

    // Presentations
    case "ppt":
    case "pptx":
      return <MonitorPlay size={24} />;

    // Code files
    case "js":
    case "ts":
    case "html":
    case "css":
    case "jsx":
    case "tsx":
      return <FileCode size={24} />;

    // Default
    default:
      return <File size={24} />;
  }
};

const styles = {
  container: {
    width: "100%",
    fontFamily: "Gilroy, sans-serif",
  },
  title: {
    fontSize: "16px",
    fontWeight: 500,
    marginBottom: "16px",
    color: "#333",
  },
  dropzoneBase: {
    width: "100%",
    padding: "20px",
    marginBottom: "16px",
    borderWidth: "2px",
    borderRadius: "8px",
    borderColor: "#3faefd",
    borderStyle: "dashed",
    backgroundColor: "rgba(63, 174, 253, 0.05)",
    color: "#666",
    transition: "all 0.3s ease",
    cursor: "pointer",
    textAlign: "center" as const,
  },
  dropzoneActive: {
    backgroundColor: "rgba(63, 174, 253, 0.15)",
    borderColor: "#2196f3",
  },
  fileList: {
    marginTop: "20px",
  },
  fileItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "12px 16px",
    marginBottom: "8px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
  },
  fileInfo: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
  },
  fileIcon: {
    color: "#3faefd",
    display: "flex",
    alignItems: "center",
  },
  fileDetails: {
    margin: 0,
    fontSize: "0.875rem",
    color: "#666",
  },
  actionButtons: {
    display: "flex",
    gap: "12px",
  },
  emptyState: {
    textAlign: "center" as const,
    padding: "24px",
    backgroundColor: "#f8f9fa",
    borderRadius: "8px",
    color: "#666",
  },
};

const FileUpload: React.FC<FileUploadI> = ({
  onLeadFileUpload,
  StateData,
  leadFileDelete,
  leadId,
}) => {
  const [fileList, setFileList] = useState<any>([]);
  const [uploadQueue, setUploadQueue] = useState<File[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [currentUpload, setCurrentUpload] = useState<string | null>(null);

  const StoreData = useSelector((state: any) => state?.rootReducers);

  useEffect(() => {
    const processQueue = async () => {
      if (uploadQueue.length > 0 && !isUploading) {
        setIsUploading(true);
        const nextFile = uploadQueue[0];

        console.log("Processing file:", nextFile.name);
        setCurrentUpload(nextFile.name);

        try {
          const compatibleEvent = {
            target: { files: [nextFile] },
          };

          await onLeadFileUpload(compatibleEvent);
          console.log("Upload successful:", nextFile.name);
          toast.success(`Successfully uploaded ${nextFile.name}`);
        } catch (error) {
          console.error("Upload failed:", nextFile.name, error);
          toast.error(`Failed to upload ${nextFile.name}`);
        }

        setUploadQueue((prev) => prev.slice(1));
        setIsUploading(false);
        setCurrentUpload(null);
      }
    };

    processQueue();
  }, [uploadQueue, isUploading, onLeadFileUpload]);

  const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: any[]) => {
    console.log("Files received:", acceptedFiles.length);

    // Handle rejected files
    rejectedFiles.forEach(({ file, errors }) => {
      if (errors[0]?.code === "file-too-large") {
        toast.warning(`${file.name} exceeds 50MB size limit`);
      }
    });

    if (acceptedFiles.length > 0) {
      setUploadQueue((prev) => [...prev, ...acceptedFiles]);
      toast.info(
        `Queued ${acceptedFiles.length} file${
          acceptedFiles.length > 1 ? "s" : ""
        } for upload`
      );
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxSize: MAX_FILE_SIZE,
  });

  useEffect(() => {
    let tempArray = StoreData?.leadFile?.leadFile;
    let tempUserDetails = StoreData?.userData?.userDetails;

    const mergedArray = tempArray?.map((obj1: any) => {
      const matchingObjTeam = tempUserDetails?.organizationEmployee?.find(
        (obj2: any) => obj2._id === obj1.uploadedBy
      );

      let full_name = matchingObjTeam?.lastName
        ? matchingObjTeam?.firstName + " " + matchingObjTeam?.lastName
        : matchingObjTeam?.firstName;

      return {
        ...obj1,
        Fullname: full_name,
        bucketUrl: StoreData?.userData?.userDetails?.bucketUrl,
      };
    });

    setFileList(mergedArray || []);
  }, [StateData, StoreData]);

  const downloadFile = (file: string, filename: string) => {
    console.log("Downloading:", filename);
    toast.info("File will be downloaded shortly");

    fetch(file)
      .then((response) => {
        if (!response.ok) throw new Error("Download failed");
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => toast.error(`Failed to download ${filename}`));
  };

  const handleDelete = (item: any) => {
    try {
      leadFileDelete(item);
      toast.success("File deleted successfully");
    } catch (error) {
      toast.error("Failed to delete file");
    }
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Files ({fileList?.length || 0})</h2>

      <div
        {...getRootProps({
          style: {
            ...styles.dropzoneBase,
            ...(isDragActive ? styles.dropzoneActive : {}),
          },
        })}
      >
        <input {...getInputProps()} />
        <div>
          <Upload size={24} style={{ color: "#3faefd", marginBottom: "8px" }} />
          <p style={{ margin: "8px 0" }}>
            Drag & drop files here, or click to select
          </p>
          <small>Maximum file size: 50MB</small>

          {isUploading && currentUpload && (
            <div style={{ marginTop: "12px", color: "#2196f3" }}>
              <p>Uploading: {currentUpload}</p>
              {uploadQueue.length > 1 && (
                <small>{uploadQueue.length - 1} files remaining</small>
              )}
            </div>
          )}
        </div>
      </div>

      <div style={styles.fileList}>
        {fileList?.length === 0 ? (
          <div style={styles.emptyState}>
            <p>No files uploaded yet</p>
          </div>
        ) : (
          fileList?.map((item: any, i: number) => (
            <div key={i} style={styles.fileItem}>
              <div style={styles.fileInfo}>
                <div style={styles.fileIcon}>{getFileIcon(item?.fileName)}</div>
                <div>
                  <h6 style={{ margin: "0 0 4px 0" }}>{item?.fileName}</h6>
                  <p style={styles.fileDetails}>
                    {item?.fileSize} -{" "}
                    {moment(item?.uploadedAt).format("DD MMM YYYY")}
                  </p>
                  <p style={styles.fileDetails}>Created By: {item?.Fullname}</p>
                </div>
              </div>
              <div style={styles.actionButtons}>
                <button
                  onClick={() => handleDelete(item)}
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    padding: "4px",
                  }}
                >
                  <Trash2 size={20} style={{ color: "red" }} />
                </button>
                <button
                  onClick={() =>
                    downloadFile(item.bucketUrl + item.filePath, item?.fileName)
                  }
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                    padding: "4px",
                  }}
                >
                  <Download size={20} style={{ color: "#3faefd" }} />
                </button>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default FileUpload;
