import { Card, CardBody, CardHeader } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import RangeDatePicker from "../../components/RangeDatePicker";
import { DateRange } from "./dashboard";
import styled from "styled-components";
import { useEffect, useState } from "react";


// Type Definitions
interface IDashboardTaskStatusPieChart {
  title: string;
  pieChartData: PieChartData[];
  legendPosition?: "top" | "right" | "bottom" | "left";
  chartHeight?: number;
  callBackFun: Function;
  dateRangeValue: DateRange;
  taskCount: TaskCount[];
}

export interface PieChartData {
  label: string;
  value: number;
}

interface TaskCount {
  type: string;
  total: number;
  conversionRate: number;
}

interface IDashboardTaskStatusPieChartHeader {
  title: string;
}

// Styled Components with direct values matching existing UI
const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  max-height: 240px;
  min-height: 120px;
  overflow-y: auto;
  width: 100%;
  padding: 1.5rem;
  margin: 0.5rem auto;
  background: #f8f9fa;
  border-radius: 8px;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
  }

  /* Scrollbar Styling */
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #dee2e6;
    border-radius: 3px;
  }

  & > div {
    flex: 1;
    min-width: 160px;
  }
`;

const Heading = styled.h4`
  color: #333;
  font-size: 12px;
  font-family: "Gilroy-Bold", sans-serif;
  margin-bottom: 1rem;
`;

const ConversionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

const StatusRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0.75rem;
  background: #fff;
  border-radius: 6px;
  border: 1px solid #eee;
  transition: all 0.2s ease;

  &:hover {
    background: #f8f9fa;
  }

  span {
    color: #333;
    font-size: 12px;
    font-family: "Gilroy-Bold", sans-serif;
    text-transform: capitalize;
  }
`;

// Header Component
const DashboardTaskStatusPieChartHeader: React.FC<IDashboardTaskStatusPieChartHeader> = ({ title }) => {
  return (
    <div className="d-flex align-items-center">
      <div className="fw-bold text-dark d-flex flex-column align-items-center fm-gilroy">
        <span>{title}</span>
      </div>
    </div>
  );
};

// Main Component
const DashboardTaskStatusPieChart: React.FC<IDashboardTaskStatusPieChart> = ({
  title,
  pieChartData,
  chartHeight = 210,
  legendPosition = "right",
  callBackFun,
  dateRangeValue,
  taskCount,
}) => {
  const [isChartReady, setIsChartReady] = useState(false);
  useEffect(() => {
    if (pieChartData?.length && pieChartData.every(item => item.value !== undefined)) {
      setIsChartReady(true);
    } else {
      setIsChartReady(false);
    }
  }, [pieChartData]);
  
  const chartOptions: ApexOptions = {
    labels: pieChartData?.length ? 
      pieChartData.map((data) => `${data.label} - ${data.value}`) : 
      ['No Data'],
    series: pieChartData?.length ? 
      pieChartData.map((data) => data.value) : 
      [100], // Default value to prevent path calculation errors
    chart: {
      type: "pie",
      background: "transparent",
      fontFamily: "Gilroy, sans-serif",
      animations: {
        enabled: true,
        dynamicAnimation: {
          enabled: true,
          speed: 350
        }
      }
    },
    legend: {
      show: true,
      position: legendPosition,
      fontFamily: "Gilroy-Medium, sans-serif",
      fontSize: "12px",
    },
    stroke: {
      show: false,
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "12px",
        fontFamily: "Gilroy-Medium, sans-serif",
        fontWeight: 500,
      },
      formatter: function(value: any, { seriesIndex, dataPointIndex, w }: any) {
        if (!pieChartData?.length) return 'No Data';
        return `${pieChartData[dataPointIndex].label} - ${value}`;
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: '100%'
        },
        legend: {
          position: 'bottom'
        }
      }
    }],
    tooltip: {
      style: {
        fontSize: '12px',
        fontFamily: "Gilroy-Medium, sans-serif",
      }
    },
    noData: {
      text: 'No data available',
      align: 'center',
      verticalAlign: 'middle',
      style: {
        fontFamily: "Gilroy-Medium, sans-serif",
        fontSize: '14px'
      }
    }
  };
  

  const hasData = pieChartData.reduce((total, current) => total + current.value, 0) > 0;

  return (
    <Card>
      <CardHeader className="d-flex align-items-center justify-content-between">
        <DashboardTaskStatusPieChartHeader title={title} />
        <RangeDatePicker
          dateRangeValue={dateRangeValue}
          callBackFun={callBackFun}
        />
      </CardHeader>
      
      <CardBody className="p-0">
  {!hasData ? (
    <h2 className="no_data_found">No data yet.</h2>
  ) : isChartReady ? (
    <ReactApexChart
      height={chartHeight}
      options={{
        ...chartOptions,
        chart: {
          ...chartOptions.chart,
          events: {
            mounted: () => {
              // Force update after mount
              setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
              }, 10);
            }
          }
        }
      }}
      series={pieChartData.map(data => data.value)}
      type="pie"
      key={`pie-${JSON.stringify(pieChartData)}`}
    />
  ) : (
    <div className="d-flex justify-content-center align-items-center" style={{height: chartHeight}}>
      <span>Loading...</span>
    </div>
  )}
</CardBody>

      <InfoContainer>
        <div>
          <Heading>Count</Heading>
          <ConversionContainer>
            {taskCount?.map((task, index) => (
              <StatusRow key={`task-${index}`}>
                <span>{task.type.replaceAll("_", " ")}</span>
                <span>{task.total}</span>
              </StatusRow>
            ))}
          </ConversionContainer>
        </div>

        <div>
          <Heading>Completion rate</Heading>
          <ConversionContainer>
            {taskCount?.map((task, index) => (
              <StatusRow key={`completion-${index}`}>
                <span>{task.conversionRate}%</span>
              </StatusRow>
            ))}
          </ConversionContainer>
        </div>
      </InfoContainer>
    </Card>
  );
};

export default DashboardTaskStatusPieChart;