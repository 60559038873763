import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserGroup,
  faHeartPulse,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";
import { ArrowUpRight, Phone } from 'lucide-react'; // Import Lucide Phone icon
import { IDashboardTabs, Tabs } from "./dashboard.types";
import callLogsDrawerManager from '../../components/CallLogsDrawerManager'; 
import activityFeedDrawerManager from '../../views/ActivityFeed/ActivityFeedDrawerManager';

// Update the MainTab interface to handle both FontAwesome and Lucide icons
interface MainTab {
  key: Tabs;
  label: string;
  icon: any;
  hasDrawer?: boolean;
  onIconClick?: (fromDate?: string, toDate?: string) => void;  // Modified to accept dates
}

// Update the tabs array with the call logs drawer functionality
const mainTabsArray: MainTab[] = [
  { 
    key: "lead", 
    label: "Leads", 
    icon: faUserGroup 
  },
  { 
    key: "call", 
    label: "Calls", 
    icon: Phone,  // Use Lucide Phone icon
    hasDrawer: true,
    onIconClick: () => callLogsDrawerManager.openDrawer()
  },
  { 
    key: "activity", 
    label: "Activity", 
    icon: faHeartPulse,
    hasDrawer: true,
    onIconClick: (fromDate?: string, toDate?: string) => {
      // Only open drawer with dates if they exist
      if (fromDate && toDate) {
        activityFeedDrawerManager.openDrawer({
          fromDate,
          toDate
        });
      } else {
        activityFeedDrawerManager.openDrawer(); // fallback to default filters
      }
    }
  },
  { 
    key: "sales", 
    label: "Sales", 
    icon: faDollarSign 
  },
];

const DashboardTabs: React.FC<IDashboardTabs> = ({
  handleTabOnchange,
  selectedTab,
  totalLeadCount,
  totalCallCount,
  totalActivityCount,
  totalSalesCount,
}) => {
  return (
    <div className="dashboard-section-1">
      <div className="row">
        <ul className="nav nav-pills mb-3 ml-0 justify-content-start d-flex dashboard_cards">
          {mainTabsArray.map((tab, idx) => (
            <li
              key={idx}
              className="nav-item margin-right bg-transparent"
              role="presentation"
            >
             <button
               onClick={() => handleTabOnchange(tab.key as Tabs)}
               className={`nav-link ${tab.key === selectedTab ? 'active bg-blue-600' : 'bg-white'}`}
               style={{
                 padding: "20px 28px",
                 borderRadius: "16px",
                 minWidth: "260px",
                 height: "100px",
                 display: "flex",
                 flexDirection: "column",
                 justifyContent: "space-between",
                 boxShadow: tab.key === selectedTab 
                   ? "0 8px 24px rgba(65,113,245,0.2)"
                   : "0 2px 8px rgba(0,0,0,0.04)",
                 transition: "all 0.2s ease",
                 position: 'relative'  // Added for absolute positioning of circle button
               }}
             >
               {/* Circle button - Absolutely positioned */}
               {tab.hasDrawer && (
                 <div
                   onClick={(e) => {
                     e.stopPropagation();
                     tab.onIconClick?.();
                   }}
                   style={{
                     position: 'absolute',
                     top: '12px',
                     right: '12px',
                     width: "32px",
                     height: "32px",
                     borderRadius: "50%",
                     backgroundColor: tab.key === selectedTab ? "rgba(255, 255, 255, 0.2)" : "rgba(100, 116, 139, 0.1)",
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "center",
                     cursor: "pointer",
                     transition: "all 0.2s ease",
                     zIndex: 2  // Ensure it's above other content
                   }}
                   className="drawer-icon-container"
                 >
                   <ArrowUpRight
                     size={16}
                     color={tab.key === selectedTab ? "white" : "#64748b"}
                     style={{
                       strokeWidth: 2.5
                     }}
                   />
                 </div>
               )}

               {/* Main tab content */}
               <div style={{ 
                 display: "flex", 
                 alignItems: "center", 
                 gap: "8px"
               }}>
                 {tab.icon === Phone ? (
                   <Phone 
                     size={20}
                     color={tab.key === selectedTab ? "white" : "#64748b"}
                   />
                 ) : (
                   <FontAwesomeIcon 
                     icon={tab.icon} 
                     style={{ color: tab.key === selectedTab ? "white" : "#64748b" }}
                   />
                 )}
                 <span style={{ 
                   color: tab.key === selectedTab ? "white" : "#64748b",
                   fontSize: "16px",
                   fontFamily: "Gilroy-Medium"
                 }}>
                   {tab.label}
                 </span>
               </div>

               {/* Count display */}
               <div style={{
                 fontSize: "36px",
                 fontWeight: 600,
                 color: tab.key === selectedTab ? "white" : "#0f172a",
                 fontFamily: "Gilroy-Bold"
               }}>
                 {tab.key === "sales" ? `₹${totalSalesCount}` : 
                  tab.key === "lead" ? totalLeadCount :
                  tab.key === "call" ? totalCallCount :
                  totalActivityCount}
               </div>
             </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DashboardTabs;