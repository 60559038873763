import React from "react";
import DummyImage from "../assets/images/user-dummy-img.jpg";

interface ImgTagProps {
  className?: string;
  src?: string;
  placeholder?: string;
  id?: string;
}

const ImgTag: React.FC<ImgTagProps> = ({
  className = "object-cover w-100 h-100",
  src,
  placeholder = DummyImage,
  id,
}) => {
  const handleImageError = (e: React.SyntheticEvent<HTMLImageElement>) => {
    e.currentTarget.src = placeholder;
  };

  return (
    <img
      onError={handleImageError}
      id={id}
      className={className}
      src={src || placeholder}
      alt="..."
    />
  );
};

export default ImgTag;
