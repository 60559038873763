import styled from "styled-components";

export const Error = styled.div`
  color: red;
  width: 100%;
  font-size: 12px;
  padding-left: 8px;
`;

export const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.2);
`;

export const EmptyChatScreen = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50vh;

  img {
    width: 200px;
    margin-bottom: 20px;
  }
`;
