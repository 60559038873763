import axios from "axios";
import { API_URL } from "../config/config";
import { getHeaderOptions } from "./getHeaderOptions";

const API_ENDPOINT = API_URL + "api/v1";


export const getFormList = () => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.get(`${API_ENDPOINT}/custom-form`, {
    headers: obj,
  });
};
export const getFormListById = (id: string) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.get(`${API_ENDPOINT}/custom-form/${id}`, {
    headers: obj,
  });
};
export const createCustomForm = (data: object) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.post(`${API_ENDPOINT}/custom-form`, data, { headers: obj });
};
export const editCustomForm = (data: object, id: string) => {
  console.log('edit data =====>',data)
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem("auth_token") || "",
  };
  return axios.put(`${API_ENDPOINT}/custom-form/${id}`, {form:data}, { headers: obj });
};
export const deleteUserForm = (id: string) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem('auth_token') || ''
  }
  return axios.delete(`${API_ENDPOINT}/custom-form/${id}`, { headers: obj });


}
export const getWebFormView = (id:string) =>{
  let obj = {
      ...getHeaderOptions,
      Authorization: localStorage.getItem('auth_token') || ''
  }
  return axios.get(`${API_ENDPOINT}/custom-form/${id}/view`,{ headers: obj });
}
export const addWebFormResponse = (id:string ,data: object, ) =>{
  let obj = {
      ...getHeaderOptions,
      Authorization: localStorage.getItem('auth_token') || ''
  }
  return axios.post(`${API_ENDPOINT}/custom-form/${id}/response`,data , { headers: obj });
}

export const getCustomFormResponse = (responseId: string) =>{
  let obj = {
      ...getHeaderOptions,
      Authorization: localStorage.getItem('auth_token') || ''
  }
  return axios.get(`${API_ENDPOINT}/custom-form/response?customFormId=${responseId}`, { headers: obj });
}

export const getLeadFormResponse = (responseId: string) =>{
  let obj = {
      ...getHeaderOptions,
      Authorization: localStorage.getItem('auth_token') || ''
  }
  return axios.get(`${API_ENDPOINT}/custom-form/response?leadId=${responseId}`, { headers: obj });
}

export const publishForm = (data:any ,formId : string) =>{
  let obj = {
      ...getHeaderOptions,
      Authorization: localStorage.getItem('auth_token') || ''
  }
  return axios.put(`${API_ENDPOINT}/custom-form/${formId}`,data,{ headers: obj });
}
export const uploadFile = async (file:any ,formId : string,fileName:string) =>{
  let obj = {
      ...getHeaderOptions,
      Authorization: localStorage.getItem('auth_token') || ''
  }
  const fileAry = file.name.split('.')
  const fileExtension = fileAry.pop()
  const fileNames = fileAry.join('')
  
  const respoo = await axios.get(`${API_ENDPOINT}/custom-form/${formId}/sign-url?fileType=${fileExtension}&requestType=put&fileName=${fileNames}`,{ headers: obj });
  const url = respoo.data.data.data.signedUrl
  if(url){
    try {
      const fileUpload = await axios.put(url,file)
      console.log('fileUpload',fileUpload)
      if(fileUpload.status ==200){
        return respoo.data.data
      }
     
    } catch (error) {
      console.error('Error occurred during file upload:', error);
    }
  }
}

export const getPaymentLink = (data:any) =>{
  let obj = {
      ...getHeaderOptions,
      Authorization: localStorage.getItem('auth_token') || ''
  }
  return axios.post(`${API_ENDPOINT}/payment-link/generate`,data,{ headers: obj });
}
export const checkPaymentStatus = (link:any) =>{
  let obj = {
      ...getHeaderOptions,
      Authorization: localStorage.getItem('auth_token') || ''
  }
  return axios.get(`${API_ENDPOINT}/payment-link/status?paymentLink=${link}`,{ headers: obj });
}
export const formLeadList = (id: any) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem('auth_token') || ''
  }
  return axios.get(`${API_ENDPOINT}/lead-list${id}`, { headers: obj });
}

export const formDeleteLeadList = (id: string) => {
  let obj = {
    ...getHeaderOptions,
    Authorization: localStorage.getItem('auth_token') || ''
  }
  return axios.post(`${API_ENDPOINT}/lead-list/${id}`, { headers: obj });
}

export const postDraftFormData = (id:string,data:any) =>{
  return axios.post(`${API_ENDPOINT}/custom-form/${id}/draft-response`,data);
}