import React, { useState, ReactNode } from "react";
import { Tooltip } from "reactstrap";

interface MyToolTipProps {
  id: string;
  text: string;
  placement?:
    | "auto"
    | "auto-start"
    | "auto-end"
    | "top"
    | "bottom"
    | "left"
    | "right"
    | "top-start"
    | "top-end"
    | "bottom-start"
    | "bottom-end"
    | "right-start"
    | "right-end"
    | "left-start"
    | "left-end";
  children: ReactNode;
}

const MyToolTip: React.FC<MyToolTipProps> = ({
  id,
  text,
  placement = "right",
  children,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  return (
    <div id={`tooltip-${id}`} className="d-inline-block lh-normal">
      {children}
      {text && (
        <Tooltip
          style={{
            backgroundColor: "#4A4A4A",
            fontSize: 12,
            fontFamily: "Gilroy-Medium",
            maxWidth: "400px",
            width: "400px",
          }}
          autohide={false}
          placement={placement}
          target={`tooltip-${id}`}
          isOpen={tooltipOpen}
          toggle={toggleTooltip}
        >
          {text}
        </Tooltip>
      )}
    </div>
  );
};

export default MyToolTip;
